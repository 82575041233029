import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
        import('./features/property-management/property-management.module').then(
          (mod) => mod.PropertyManagementModule
        ),
          data: { breadCrumb: {
            label: 'Home'
          }}
      },
      {
        path: 'centres',
        loadChildren: () =>
          import('./features/property-management/property-management.module').then(
            (mod) => mod.PropertyManagementModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./features/users/users.module').then(
            (mod) => mod.UsersModule
          ),
      },
      {
        path: 'period-readings',
        loadChildren: () =>
          import('./features/period-readings/period-readings.module').then(
            (mod) => mod.PeriodReadingsModule
          ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./features/notifications/notifications.module').then(
            (mod) => mod.NotificationsModule
          ),
          data: {
            breadcrumbStart: '/notifications'
          }
      },
      {
        path: 'utility-suppliers',
        loadChildren: () =>
        import('./features/utility-suppliers/utility-suppliers.module').then(
          (mod) => mod.UtilitySuppliersModule
          ),
          data: {
            breadcrumbStart: '/utility-suppliers'
          }
      },

      {
        path: 'tariff-detail',
        loadChildren: () =>
        import('./features/tariff-details/tariff-details.module').then(
          (mod) => mod.TariffDetailsModule
        ),
        data: {
          breadcrumbStart: '/utility-suppliers'
        }
      },
      {
        path: 'target-dates',
        loadChildren: () =>
          import('./features/target-dates/target-dates.module').then(
            (mod) => mod.TargetDatesModule
          ),
      },
      {
        path: 'quality-control',
        loadChildren: () =>
        import('./features/quality-control/quality-control.module').then(
          (mod) => mod.QualityControlModule
        ),
        data: {
          breadcrumbStart: '/period-readings'
        }
      },
      {
        path: 'add-tariff',
        loadChildren: () =>
        import('./shared/components/add-tariff/add-tariff.module').then(
          (mod) => mod.AddTariffModule
        ),
        data: {
          breadcrumbStart: '/utility-suppliers'
        }
      },
      {
        path: 'review-tenant-changes',
        loadChildren: () => import('./features/review-tenant-changes/review-tenant-changes.module').then((mod) => mod.ReviewTenantChangesModule),
        data: {
          breadcrumbStart: '/tenant-list'
        }
      },
      {
        path: 'tenant-list',
        loadChildren: () =>
        import('./features/tenant-list/tenant-list.module').then(
          (mod) => mod.TenantListModule
        )
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./core/auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
