import { DatePipe } from '@angular/common';
import { AfterContentInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { from } from 'rxjs';
import { ShopsService } from 'src/app/services/shops.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { CaptureReadingService } from './capture-reading.service';
import { message_types } from '../../micro-components/user-messages/message_types';
import { UserMessagesService } from '../../micro-components/user-messages/user-messages.service';
import { NgxImageCompressService } from 'ngx-image-compress';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-capture-reading',
  templateUrl: './capture-reading.component.html',
  styleUrls: ['./capture-reading.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class CaptureReadingComponent implements OnInit, AfterContentInit {

  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;
  hasToUpload: boolean = false;
  title;
  meter;
  onePhase = false;
  threePhase = false;
  threePhaseTwoImage = false;
  singleReading: any;
  phase1: any;
  phase2: any;
  phase3: any;
  phase4: any;
  comment: any;
  threePhase_one: any;
  readingDate: any;
  uploadUrl: any;
  count: number = 0;
  uploadSize: number = 0;
  filesToUpload: any = {};
  item: any;
  superUser: boolean = false;
  minDate: any;
  showDates: boolean = false;
  maxDate: any;
  selectedMeter: any;
  documentExtension: any;
  currentDate: any;
  readingPeriod: string;
  readingTypes = ["PERIOD_READING","BO_READING", "VACATING_READING"];
  readingType: any = "PERIOD_READING";
  readingDetails = new UntypedFormGroup({
    readingDate: new UntypedFormControl(''),
    singleReading: new UntypedFormControl(''),
    phase1: new UntypedFormControl(''),
    phase2: new UntypedFormControl(''),
    phase3: new UntypedFormControl(''),
    phase4: new UntypedFormControl(''),
    readingType: new UntypedFormControl('PERIOD_READING'),
  });
  closeMethod = () => {
    this.closeDialog()
  }

  buttonProperties = {
    text: 'Save',
    type: 'primary',
    method: () => {
      this.submitReading()
    }
  }

  constructor(
    private shopsService: ShopsService,
    public loadingservice: LoadingService,
    private captureReadingService: CaptureReadingService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<CaptureReadingComponent>,
    private userMessagesService: UserMessagesService,
    @Inject(MAT_DIALOG_DATA) public data,
    private imageCompress: NgxImageCompressService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentDate = new Date().toLocaleDateString();
    this.superUser = this.data.meter.superUser;
    this.showDates = this.data.meter.showDates ? true : false;
    this.title = this.data.title;
    this.meter = this.data.meter;
    console.log(this.meter)
    this.readingPeriod = this.data.readingPeriod;
    if(this.meter.meter.utilityType === 'ELEC_3P_TOU') {
      this.uploadSize = 4;
    } else if(this.meter.meter.utilityType === 'ELEC_3P_3') {
      this.uploadSize = 3;
    } else if(this.meter.meter.utilityType === 'ELEC_1P_KVA' || this.meter.meter.utilityType === 'ELEC_3P_KVA') {
      this.uploadSize = 2;
    } else {
      this.uploadSize = 1;
    }
    this.uploadUrl = "assets/";
  
    if(this.data.meter.dates) {
      this.minDate = this.data.meter.dates.minDate;
      this.maxDate = this.data.meter.dates.maxDate;
    }
    if(this.meter.meter.utilityType === "WATER" || this.meter.meter.utilityType === "ELEC_1P" || this.meter.meter.utilityType === "ELEC_3P_1") {
      this.onePhase = true;
    }else if(this.meter.meter.utilityType === "ELEC_3P_KVA" || this.meter.meter.utilityType === "ELEC_1P_KVA"){
      this.threePhaseTwoImage = true;
    } else if (this.meter.meter.utilityType === "ELEC_3P_TOU") {
      this.threePhase_one = true;
    } else {
      this.threePhase = true;
    }
  }

  ngAfterContentInit(): void {
    this.cd.detectChanges();
  }

  submitReading() {
    if(this.superUser) {
      this.readingDate = new Date(this.readingDetails.value.readingDate)
      this.readingDate = new Date(this.readingDate.getTime() - this.readingDate.getTimezoneOffset() * 60000).toISOString();
    } else {
      this.readingDate = new Date().toISOString()
    }

    const username = JSON.parse(localStorage.getItem('email'));
    if(this.onePhase) {
       this.selectedMeter = {
        meterId: this.meter.meter.id,
        propertyId: this.meter.propertyId,
        readingBy: username,
        readingTS: this.readingDate,
        comment: this.comment ? this.comment : '',
        readingValueList: [
          {
            sequence: 1,
            value: this.readingDetails.value.singleReading,
          },
        ],
      };
    } else if (this.threePhase) {
      this.selectedMeter = {
        meterId: this.meter.meter.id,
        propertyId: this.meter.propertyId,
        readingBy: username,
        readingTS: this.readingDate,
        comment: this.comment ? this.comment : '',
        readingValueList: [
          {
            sequence: 1,
            value: this.readingDetails.value.phase1,
          },
          {
            sequence: 2,
            value: this.readingDetails.value.phase2,
          },
          {
            sequence: 3,
            value: this.readingDetails.value.phase3,
          },
        ],
      };
    } else if (this.threePhaseTwoImage) {
      this.selectedMeter = {
        meterId: this.meter.meter.id,
        propertyId: this.meter.propertyId,
        readingBy: username,
        readingTS: this.readingDate,
        comment: this.comment ? this.comment : '',
        readingValueList: [
          {
            sequence: 1,
            value: this.readingDetails.value.phase1,
          },
          {
            sequence: 2,
            value: this.readingDetails.value.phase2,
          },
        ],
      };
    } else if (this.threePhase_one) {
      this.selectedMeter = {
        meterId: this.meter.meter.id,
        propertyId: this.meter.propertyId,
        readingBy: username,
        readingTS: this.readingDate,
        comment: this.comment ? this.comment : '',
        readingValueList: [
          {
            sequence: 1,
            value: this.readingDetails.value.phase1,
          },
          {
            sequence: 2,
            value: this.readingDetails.value.phase2,
          },
          {
            sequence: 3,
            value: this.readingDetails.value.phase3,
          },
          {
            sequence: 4,
            value: this.readingDetails.value.phase4,
          },
        ],
      };
    }
    this.selectedMeter.readingType = this.readingDetails.value.readingType;

    this.loadingservice.openBlockingLoader("Processing the period reading", "primary");
    from(this.shopsService.captureNewReading(this.selectedMeter)).subscribe(
      (res: any) => {
        let flag = res.data.captureMeterReadingSync.flag;
        if (this.count > 0) {
          this.uploadImage(flag);
        } else {

          let messageBody = '';
          
          if(flag && !flag.includes('NEGATIVE')) {
            messageBody = `Processing ${this.selectedMeter.readingValueList.length > 1 ? 'readings' : 'reading'} and charges`;
            message_types.in_progress.messageBody = messageBody;
            this.userMessagesService.openMessage(message_types.in_progress);
          } else {
            if(this.superUser) {
              messageBody = `The ${this.selectedMeter.readingValueList.length > 1 ? 'readings are' : 'reading is'} in a negative consumption`;
            } else {
              messageBody = `The ${this.selectedMeter.readingValueList.length > 1 ? 'readings are' : 'reading is'} in a negative consumption. Please correct the ${this.selectedMeter.readingValueList.length > 1 ? 'readings' : 'reading'} or provide a reason for the negative consumption`;
            }
            message_types.access_error.messageHeader = 'Caution';
            message_types.access_error.messageBody = messageBody;
            this.userMessagesService.openMessage(message_types.access_error);
          }

          
          
          this.selectedMeter.flag = flag;
          this.closeDialog(this.selectedMeter);
        }
      },
      (error) => {
        message_types.error.messageBody = 'Failed to capture new reading';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      }
    );
  }

  uploadImage(flag) {
    let insideCount = 0;
    for(let i = 0; i < this.selectedMeter.readingValueList.length; i++) {
      let fileToUpload;
      if(i === 0 && this.filesToUpload.r1 && this.filesToUpload.r1.name) {
        fileToUpload = this.filesToUpload.r1.blob;
        this.selectedMeter.fileName = this.filesToUpload.r1.name;
      } else if(i === 1 && this.filesToUpload.r2 && this.filesToUpload.r2.name) {
        fileToUpload = this.filesToUpload.r2.blob;
        this.selectedMeter.fileName = this.filesToUpload.r2.name;
      } else if(i === 2 && this.filesToUpload.r3 && this.filesToUpload.r3.name) {
        fileToUpload = this.filesToUpload.r3.blob;
        this.selectedMeter.fileName = this.filesToUpload.r3.name;
      } else if(i === 3 && this.filesToUpload.r4 && this.filesToUpload.r4.name) {
        fileToUpload = this.filesToUpload.r4.blob;
        this.selectedMeter.fileName = this.filesToUpload.r4.name;
      }

      if(fileToUpload) {
        from(this.shopsService.getPresignedURL(this.selectedMeter,this.selectedMeter.readingValueList[i].sequence, this.readingPeriod)).subscribe((res:any) => {
          let imageURL = res.data.captureImageReading.presignedURL;
          const splitUrl = imageURL.substring(
            imageURL.lastIndexOf("Signature="),
            imageURL.lastIndexOf("x-amz-security-token=")
          );
          const split = splitUrl.split('Signature=');
          this.shopsService.setAuth(split[1]);
          this.captureReadingService.uploadImage(fileToUpload, imageURL).subscribe((res:any) => {
            insideCount++;
            if(insideCount === this.count) {
              if(!flag.includes('NEGATIVE')) {
                message_types.success.messageBody = `Processing ${this.selectedMeter.readingValueList.length > 1 ? 'readings' : 'reading'} and charges`;
                this.userMessagesService.openMessage(message_types.success);
              } else {
                message_types.success.messageBody = `Please review the ${this.selectedMeter.readingValueList.length > 1 ? 'readings' : 'reading'}. If the ${this.selectedMeter.readingValueList.length > 1 ? 'readings are' : 'reading is'} correct then please provide a reason for submitting the ${this.selectedMeter.readingValueList.length > 1 ? 'readings' : 'reading'} in a negative status.`;
                this.userMessagesService.openMessage(message_types.success);
              }
              
              this.selectedMeter.flag = flag;
              this.loadingservice.closeBlockingLoader();
              this.closeDialog(this.selectedMeter);
            }
          },(error) => {
            this.closeDialog(this.selectedMeter);
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to upload image';
            this.userMessagesService.openMessage(message_types.error);
            this.loadingservice.closeBlockingLoader();
          })
          
        },(error) => {
          this.closeDialog(this.selectedMeter);
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to upload image';
          this.userMessagesService.openMessage(message_types.error);
          this.loadingservice.closeBlockingLoader();
        })
      }
      
      
    }
  }

  removeFile(reading) {
    this.filesToUpload['r'+reading] = {};

    if(--this.count === 0 && this.meter.meter.utilityType !== 'ELEC_3P_3') {
      console.log('Removed HasToUpload');
      this.hasToUpload = false;
    }
  }

  closeDialog(exitState?: string) {
    this.dialogRef.close(exitState);
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  checkWindowSpacing() {
    let width = 1920 - window.innerWidth;
    if(width <= 0) {
      return 0;
    } else {
      return Math.round(width * 0.01830985915492957746478873239437)
    }
  }

  addFile(reading) {
    this.imageCompress.uploadFile().then(({image, orientation, fileName}) => {
      console.log(image)
      console.log('Size in bytes of the uploaded image was:', this.imageCompress.byteCount(image));

      let imageSize = this.imageCompress.byteCount(image);
      const shouldCompress = imageSize > 2000000;

      if(this.meter.meter.utilityType !== 'ELEC_3P_3') {
        this.hasToUpload = true;
      }

      if(shouldCompress) {
        this.imageCompress
          .compressFile(image, orientation, 100, 70) // 50% ratio, 50% quality
          .then(compressedImage => {
            console.log(compressedImage);
            console.log('Size in bytes after compression is now:', this.imageCompress.byteCount(compressedImage));
            console.log(compressedImage)
            this.count++;
            console.log(this.filesToUpload)
            this.filesToUpload['r' + reading] = {
              name: fileName,
              size: this.imageCompress.byteCount(compressedImage),
              blob: compressedImage
            }
          });
      } else {
        this.count++;
        console.log(this.filesToUpload)
        this.filesToUpload['r' + reading] = {
          name: fileName,
          size: this.imageCompress.byteCount(image),
          blob: image
        }
      }
      
    });
  }

}
