import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReadingDownloadDialogComponent } from './reading-download-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ReadingDownloadDialogService {

  constructor(public dialog: MatDialog) { }

  openReadingDownload(data) {
    this.dialog.open(ReadingDownloadDialogComponent, {
      data: data,
      panelClass: 'custom-user-dialog-container',
      width: '40%'
    })
  }
}
