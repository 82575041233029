<div class="page-layout blank" fxLayout="column">
  <sym-page-header [breadcrumbText]="'T'" *ngIf="!updateTariff" title="Add Tariff - {{supplierName}}" ></sym-page-header>
  <sym-page-header [breadcrumbText]="'T'" *ngIf="updateTariff" title="Update Tariff - {{tariffName}} - {{supplierName}}" ></sym-page-header>
  <sym-content-card>
    <div >
      <mat-tab-group mat-align-tabs="start" #tabGroup animationDuration="200ms" (selectedTabChange)="changeTab($event)" [selectedIndex]="tabIndex">
        <mat-tab label="Detail">
          <form [formGroup]="tariffDetailForm" fxLayout="column" style="padding-top: 24px;">
            <mat-form-field appearance="outline">
              <mat-label>Tariff Name</mat-label>
              <input matInput type="text" formControlName="tariffName" />
            </mat-form-field>
    
            <mat-form-field appearance="outline">
              <mat-label>Tariff Detail</mat-label>
              <input matInput type="text" formControlName="tariffDetail" />
            </mat-form-field>
    
            <mat-form-field appearance="outline">
              <mat-label>Tariff Type</mat-label>
              <mat-select (selectionChange)="setConstraintFields()" placeholder="Tariff Types" formControlName="utilityType" required>
                <mat-option *ngFor="let tariff of tariffTypes" [value]="tariff">
                  {{tariff}}
                </mat-option>
              </mat-select>
            </mat-form-field>
    
            <mat-form-field appearance="outline">
              <mat-label>Validity Dates</mat-label>
              <mat-select placeholder="Validity Dates" (selectionChange)="setSeasonDate($event)" formControlName="validityDates" required>
                <mat-option *ngFor="let date of tariffValidityDates" [value]="date.value">
                  {{date.display}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Tariff Category</mat-label>
              <mat-select placeholder="Tariff Types" formControlName="tariffCategory" required>
                <mat-option *ngFor="let category of tariffCategories" [value]="category">
                  {{category}}
                </mat-option>
              </mat-select>
            </mat-form-field>
    
            <div *ngIf="tariffDetailForm.get('utilityType').value === 'Electricity'">
              <span style="color: #9B9020;font-weight: bold;margin-bottom: 4px;font-size: 16px;">Seasons</span>
              <div style="padding: 12px 0;">
                <mat-divider></mat-divider>
              </div>
    
              <div formArrayName="seasons">
                <div *ngFor="let season of getTariffSeasons().controls; let i = index" [formGroupName]="i" fxLayout="column">
                  <div style="display: flex;align-items: center;">
                    <div class="season-box">
                      <mat-form-field appearance="outline" style="margin-right: 8px;width: 20%;">
                        <mat-label>Season Name</mat-label>
                        <mat-select formControlName="name" (selectionChange)="setSeasonalDate($event, i)">
                          <mat-option *ngFor="let name of seasonNames" [value]="name">
                            {{name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <div formArrayName="seasonDates" style="width: 100%;">
                        <div *ngFor="let seasonDate of getSeasonDates(i).controls; let date = index" style="display: flex;flex-direction: column;width: 100%;">
                          <div [formGroupName]="date" style="width: 100%;display: flex;">
                            <mat-form-field appearance="outline" style="width: 80%;">
                              <mat-label>Season Dates</mat-label>
                              <!-- <mat-date-range-input [rangePicker]="picker"> -->
                              <mat-date-range-input [rangePicker]="picker">  
                                <input matStartDate matInput placeholder="Start date" formControlName="startDate"/>
                                <input matEndDate matInput placeholder="End date" formControlName="endDate"/>
                              </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                            <button mat-icon-button matTooltip="Removes the date range input" fxFlex="5" *ngIf="date > 0" style="margin-top: 10px;" (click)="removeSeasonDate(i, date)">
                              <mat-icon style="color: black;">close</mat-icon>
                            </button>
                          </div>
                        </div>
                        <div style="display: flex;justify-content: end;padding: 10px;">
                          <button matTooltip="Adds a date range input" mat-icon-button (click)="addSeasonDates(i)">
                            <mat-icon style="font-size: x-large;color: black;">add</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
    
                    <button mat-icon-button fxFlex="5" matTooltip="Removes an entire season block" style="margin-top: 10px;" (click)="removeSeason(i)">
                      <mat-icon style="color: red;">delete</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
    
              <div fxLayout="row" fxLayoutAlign="end center" style="margin: 12px 0;">
                <button (click)="addSeason()" matTooltip="Adds a new Season Block" style="background-color: #9B9020;color: white;box-shadow: 0px 1px 22px 0px rgba(0, 0, 0, 0.15);" mat-fab>
                  <mat-icon style="font-size: x-large;">add</mat-icon>
                </button>
              </div>
            </div>
    
          </form>
    
          <mat-divider style="margin-bottom: 16px;"></mat-divider>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-flat-button style="margin-bottom: 10px; background-color: #41535D;color: white;"
              id="btnClass" (click)="tabIndex = 1">
              Next
            </button>
          </div>
        </mat-tab>
    
        <mat-tab label="Constraints">
          <div fxLayout="column" style="padding-top: 24px;">
            <mat-form-field appearance="outline">
              <mat-label>Tariff Constraints</mat-label>
              <mat-select #select [(ngModel)]="selectedConstraints" placeholder="Tariff Constraints" multiple>
                <mat-checkbox [(ngModel)]="selectedAllConstraints"
                      [ngModelOptions]="{standalone: true}"
                      (change)="selectAll(false)" style="line-height: 3em;height: 3em;width: 90%;left: 16px;">Select All</mat-checkbox>
                <mat-option style="padding-left: 36px;" (onSelectionChange)="setConstraints(constraint);selectChange(false)" *ngFor="let constraint of tariffConstraints" [value]="constraint.value">
                  {{constraint.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
    
            <form [formGroup]="tariffConstraintForm" fxLayout="column">
    
              <div style="display: flex;align-items: center;" *ngIf="tariffConstraintForm.get('pipeSizeDiameter')">
                <span class="tariff-label">Connection Pipe Diameter (mm):</span>
                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Pipe Size</mat-label>
                  <input matInput type="number" formControlName="pipeSizeDiameter" />
                </mat-form-field>
              </div>

              <div style="display: flex;align-items: center;" *ngIf="tariffConstraintForm.get('connectionPoint')">
                <span class="tariff-label">Connection Point:</span>
                <mat-form-field class="tariff-input" appearance="outline" >
                  <mat-label>Connection Point</mat-label>
                  <input matInput type="text" formControlName="connectionPoint"/>
                </mat-form-field>
              </div>
    
              <form *ngIf="tariffConstraintForm.get('circuitBreaker')" style="align-items: center;display: flex;" [formGroup]="tariffConstraintForm.get('circuitBreaker')">
                <span class="tariff-label">Circuit Breaker (A):</span>

                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Min Breaker</mat-label>
                  <input matInput type="number" formControlName="min" />
                </mat-form-field>
    
                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Max Breaker</mat-label>
                  <input matInput type="number" formControlName="max" />
                </mat-form-field>
              </form>
    
              <form *ngIf="tariffConstraintForm.get('demand')" style="display: flex;align-items: center;" [formGroup]="tariffConstraintForm.get('demand')">
                <span class="tariff-label">Demand (kVA) Rolling 12M:</span>

                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Min Demand</mat-label>
                  <input matInput type="number" formControlName="min" />
                </mat-form-field>
    
                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Max Demand</mat-label>
                  <input matInput type="number" formControlName="max" />
                </mat-form-field>
              </form>
    
              <form *ngIf="tariffConstraintForm.get('utilisation')" style="display: flex;align-items: center;" [formGroup]="tariffConstraintForm.get('utilisation')">
                <span class="tariff-label">Energy Utilization:</span>

                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Min Util</mat-label>
                  <input matInput type="number" formControlName="min" />
                </mat-form-field>
    
                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Max Util</mat-label>
                  <input matInput type="number" formControlName="max" />
                </mat-form-field>

                <mat-form-field class="tariff-input-small" appearance="outline">
                  <mat-label>Util Description</mat-label>
                  <input matInput type="text" formControlName="description" />
                </mat-form-field>
              </form>
    
              <form *ngIf="tariffConstraintForm.get('supplyVoltage')" style="display: flex;align-items: center;" [formGroup]="tariffConstraintForm.get('supplyVoltage')">
                <span class="tariff-label">Supply (V):</span>

                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Min Supply</mat-label>
                  <input matInput type="number" formControlName="min" />
                </mat-form-field>
    
                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Max Supply</mat-label>
                  <input matInput type="number" formControlName="max" />
                </mat-form-field>
              </form>

              <div style="display: flex;align-items: center;" *ngIf="tariffConstraintForm.get('phases')">
                <span class="tariff-label">Phases:</span>

                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Phases</mat-label>
                  <input matInput type="text" formControlName="phases" />
                </mat-form-field>
              </div>

              <form *ngIf="tariffConstraintForm.get('transmissionZone')" style="display: flex;align-items: center;" [formGroup]="tariffConstraintForm.get('transmissionZone')">
                <span class="tariff-label">Transmission Zone (km):</span>

                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Min Transmission</mat-label>
                  <input matInput type="number" formControlName="min" />
                </mat-form-field>
    
                <mat-form-field class="tariff-input" appearance="outline">
                  <mat-label>Max Transmission</mat-label>
                  <input matInput type="number" formControlName="max" />
                </mat-form-field>
              </form>
            </form>
          </div>
          
          <mat-divider style="margin-bottom: 16px;"></mat-divider>
          <div fxLayout="row" fxLayoutAlign="end center">
    
            <div fxLayout="row">
              <button mat-flat-button style="margin-bottom: 10px; margin-right: 8px; background-color: white;color: #41535D;border: 1px solid var(--greys-shade-20, #E7E7E7);"
              id="btnClass" (click)="tabIndex = 0">
              Previous
            </button>
    
            <button mat-flat-button style="margin-bottom: 10px; background-color: #41535D;color: white;"
              id="btnClass" (click)="tabIndex = 2">
              Next
            </button>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Variable Parameters" *ngIf="tariffDetailForm.get('utilityType').value">
          <div style="padding-top: 1em;">
            <div *ngIf="tariffDetailForm.get('utilityType').value === 'Electricity'">
              <div class="demand-detail" >
                <span class="input-label">Demand Percentage Over:</span>
                <mat-form-field class="demand-detail-input" appearance="outline">
                  <input matInput placeholder="Percentage Over" [(ngModel)]="maxDemandWindow.percentageOver" type="number" />
                </mat-form-field>
              </div>
        
              <div class="demand-detail" >
                <span class="input-label">Max kVA over how many months:</span>
                <mat-form-field class="demand-detail-input" appearance="outline">
                  <mat-label></mat-label>
                  <input matInput placeholder="12 Month Over" [(ngModel)]="maxDemandWindow.value" type="number" />
                </mat-form-field>
              </div>

              <div class="demand-detail" >
                <span class="input-label">Monthly minimum kVA:</span>
                <mat-form-field class="demand-detail-input" appearance="outline">
                  <mat-label></mat-label>
                  <input matInput placeholder="Min kVA" [(ngModel)]="tariffElecCharge.minUnits" type="number" />
                </mat-form-field>
              </div>

              <div class="demand-detail">
                <span class="input-label">Surcharge Percentage:</span>
                <mat-form-field class="demand-detail-input" appearance="outline">
                  <mat-label>Surcharge</mat-label>
                  <input matInput placeholder="Surcharge" [(ngModel)]="tariffElecCharge.surchargePercentage" type="number" />
                </mat-form-field>
              </div>
            </div>
            
            <div *ngIf="tariffDetailForm.get('utilityType').value !== 'Electricity'">
              <div class="demand-detail">
                <span class="input-label">Surcharge Percentage:</span>
                <mat-form-field class="demand-detail-input" appearance="outline">
                  <mat-label>Surcharge</mat-label>
                  <input matInput placeholder="Surcharge" [(ngModel)]="tariffWaterCharge.surchargePercentage" type="number" />
                </mat-form-field>
              </div>

              <div class="demand-detail" *ngIf="tariffDetailForm.get('utilityType').value === 'Sanitation'">
                <span class="input-label">Percentage of Water Consumption:</span>
                <mat-form-field class="demand-detail-input" appearance="outline">
                  <mat-label>Percentage</mat-label>
                  <input matInput placeholder="Percentage" [(ngModel)]="tariffWaterCharge.percentageQty" type="number" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <mat-divider style="margin-bottom: 16px;"></mat-divider>
          <div fxLayout="row" fxLayoutAlign="end center">
    
            <div fxLayout="row">
              <button mat-flat-button style="margin-bottom: 10px; margin-right: 8px; background-color: white;color: #41535D;border: 1px solid var(--greys-shade-20, #E7E7E7);"
              id="btnClass" (click)="tabIndex = 1">
              Previous
            </button>
    
            <button mat-flat-button style="margin-bottom: 10px; background-color: #41535D;color: white;"
              id="btnClass" (click)="tabIndex = 3">
              Next
            </button>
            </div>
          </div>
        </mat-tab>
    
        <mat-tab label="Charges" *ngIf="tariffDetailForm.get('utilityType').value">

          <sym-electricity-charges [seasons]="getTariffSeasons().value" *ngIf="tariffDetailForm.get('utilityType').value === 'Electricity'" [isTou]="tariffBoolObject.tou" [tariffElecCharge]="tariffElecCharge"></sym-electricity-charges>
          <sym-water-charges *ngIf="tariffDetailForm.get('utilityType').value !== 'Electricity'" [utilityType]="tariffDetailForm.get('utilityType').value" [levelCharges]="selectedWaterRestrictions" [tariffWaterCharge]="tariffWaterCharge"></sym-water-charges>
          
          <mat-divider style="margin-bottom: 16px;"></mat-divider>
          <div fxLayout="row" fxLayoutAlign="end center">
    
            <div fxLayout="row">
              <button mat-flat-button style="margin-bottom: 10px; margin-right: 8px; background-color: white;color: #41535D;border: 1px solid var(--greys-shade-20, #E7E7E7);"
              id="btnClass" (click)="tabIndex = 2">
              Previous
            </button>
    
            <button mat-flat-button style="margin-bottom: 10px; background-color: #41535D;color: white;"
              id="btnClass" (click)="checkControls()">
              {{updateTariff && !isNewPeriodTariff ? 'Update' : 'Save'}}
            </button>
            </div>
          </div>
    
        </mat-tab>
      </mat-tab-group>
    </div>
  </sym-content-card>
  
</div>
