import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { BaseModule } from 'src/app/core/base/base.module';
import { MaterialModule } from 'src/app/core/material/material.module';
import { CardDialogModule } from 'src/app/shared/components/card-dialog/card-dialog.module';
import { DeleteDialogComponent } from './delete-dialog.component';


@NgModule({
    declarations: [DeleteDialogComponent],
    imports: [
        BaseModule,
        MaterialModule,
        CardDialogModule,
        MatDividerModule
    ],
    exports: [
        DeleteDialogComponent
    ]
})
export class DeleteDialogModule { }
