import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DisplayImageDialogComponent } from './display-image-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DisplayImageDialogService {

  constructor(public dialog: MatDialog) { }

  openImageDialog(reading, thumbnail) {
    localStorage.setItem("qs", "true");
    this.dialog.open(DisplayImageDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      data: {reading: reading, meter: thumbnail}
    });
  }
}
