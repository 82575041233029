import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'sym-tou-charges',
  templateUrl: './tou-charges.component.html',
  styleUrls: ['./tou-charges.component.scss']
})
export class TouChargesComponent implements OnInit {

  @Input() title: string;
  @Input() touCharge;

  constructor() { }

  ngOnInit(): void {
  }

}
