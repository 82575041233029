import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { PropertiesService } from 'src/app/services/properties.service';
import { ShopsService } from 'src/app/services/shops.service';
import { LoadingService } from '../../../components/loading/loading.service';
import { UserMessagesService } from '../../micro-components/user-messages/user-messages.service';
import { message_types } from '../../micro-components/user-messages/message_types';

@Component({
  selector: 'sym-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {
  commentType: any;
  displayPropertyDropDown = false;
  displayRentableUnitProperty = false;
  commentTypeDisplay: any;
  propertyList = [];
  selectedUnit: any;
  selectedProperty: any;
  comment: any;
  unitsList = [];
  periodReading: any;
  commentBy: any;
  propertyName: any;
  btnClose = () => {
    this.dialogRef.close()
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    public userMessagesService: UserMessagesService,
    public loadingservice: LoadingService,
    private propertyService: PropertiesService,
    private rentableUnitService: ShopsService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.commentBy = JSON.parse(localStorage.getItem('email'));
    this.commentType = this.data.type;
    switch (this.commentType) {
      case 'tenantComment':
        this.processTenantComment();
        break;
      case 'tenantMeterComment':
        break;
      case 'periodReadingComment':
        this.processPeriodReadingComment();
        break;
      case 'propertyComment':
        this.processPropertyComment();
        break;
      default: break;
    }
  }

  processTenantComment() {
    this.unitsList = this.data.propertyList;
    this.displayRentableUnitProperty = true;
    this.commentTypeDisplay = 'Unit'
  }
  processPeriodReadingComment() {
    this.periodReading = this.data.propertyList;
    this.commentTypeDisplay = 'Period Reading'
  }
  
  processPropertyComment() {
    this.propertyList = this.data.propertyList;
    this.displayPropertyDropDown = true;
    this.commentTypeDisplay = 'Property'
  }

  submitComment() {
    switch (this.commentType) {
      case 'tenantComment':
       this.submitTenantComment();
        break;
      case 'tenantMeterComment':
        this.submitReadingComment();
        break;
      case 'periodReadingComment':
        this.submitPeriodReadingComment();
        break;
      case 'propertyComment':
        this.submitPropertyComment();
        break;
      default: break;
    }
  }

  submitTenantComment(){
    let date = new Date();
    let commentDate = new Date(Date.parse(date.toISOString()));
    let unit =  this.unitsList.find(unit => unit.id === this.selectedUnit);
    const object = {
      unitId: this.selectedUnit,
      comment: this.comment,
      commentBy: this.commentBy,
      commentDate: commentDate,
      unitNo: unit.unitNo,
      propertyId: unit.propertyId
    }
    this.loadingservice.openBlockingLoader("Submitting Comment","primary");
    from(this.rentableUnitService.addRentableUnitComment(object)).subscribe((res: any) => {
      this.loadingservice.closeBlockingLoader();
      this.propertyService.pingNotifications(true);
      this.dialogRef.close();
      this.displayMessage(false)
    },(error) => {
      this.loadingservice.closeBlockingLoader();
      this.displayMessage(true);
    })
  }
  submitReadingComment(){}

  submitPeriodReadingComment(){
    const commentObject = {
      comment: this.comment,
      meterId: this.periodReading.meterId,
      propertyId: this.periodReading.propertyId,
      readingPeriod: this.periodReading.readingPeriod,
      unitId: this.periodReading.unitId,
    }
    this.loadingservice.openBlockingLoader("Submitting Comment","primary");
    from(this.rentableUnitService.addPeriodReadingComment(commentObject)).subscribe((res: any) => {
      this.loadingservice.closeBlockingLoader();
      this.propertyService.pingNotifications(true);
      this.dialogRef.close();
      this.displayMessage(false)
    },(error) => {
      this.loadingservice.closeBlockingLoader();
      this.displayMessage(true);
    })
  }

  submitPropertyComment(){
    let date = new Date();
    let commentDate = new Date(Date.parse(date.toISOString()));
    let property =  this.propertyList.find(property => property.id === this.selectedProperty);
    const object = {
      propertyId: this.selectedProperty,
      comment: this.comment,
      commentBy: this.commentBy,
      commentDate: commentDate,
      propertyName: property.name
    }
    this.loadingservice.openBlockingLoader("Submitting Comment","primary");
    from(this.propertyService.addPropertyComment(object)).subscribe((res: any) => {
      this.loadingservice.closeBlockingLoader();
      this.propertyService.pingNotifications(true);
      this.dialogRef.close();
      this.displayMessage(false);
    },(error) => {
      this.loadingservice.closeBlockingLoader();
      this.displayMessage(true);
    })
  }

  displayMessage(isError) {
    if(isError) {
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to submit comment';
      this.userMessagesService.openMessage(message_types.error);
    } else {
      message_types.success.messageBody = 'Comment Submitted Successfully';
      this.userMessagesService.openMessage(message_types.success);
    }
  }

  checkInfo() {
    const test: string = '';
    if(this.commentType === 'propertyComment') {
      return (this.comment === undefined || this.comment === '') || (this.selectedProperty === undefined)
    } else if (this.commentType === 'tenantComment') {
      return (this.comment === undefined || this.comment === '') || (this.selectedUnit === undefined)
    }
  }
}
