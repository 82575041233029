import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

@Component({
  selector: 'sym-qr-booklet',
  templateUrl: './qr-booklet.component.html',
  styleUrls: ['./qr-booklet.component.scss']
})
export class QrBookletComponent implements OnInit {

  @ViewChild("Meter", {static: false}) meterPaginator: MatPaginator;
  @ViewChild("Document", {static: false}) documentPaginator: MatPaginator;
  meterTitle = "Generate Meter QR Booklet"
  propertyTitle = "Generate Property QR Booklet"
  @Input() property: any;
  documentList: any;
  meterList: any;
  fileName: any;
  responseLoaded = false;
  showLoader = false;
  superUser = false;
  tabIndex = 0;
  searchText: string = '';
  selectedButton = false;
  userRole: any;
  meterSource = new MatTableDataSource();
  documentsSource = new MatTableDataSource();
  selectedFileType: string = '';
  isMeterList: boolean = false;
  meterColumns: string[] = [
    "select",
    "number",
    "meterType",
    "utilityType",
  ];
  documentColumns: string[] = [
    "date",
    "documentType",
    "fileName",
    "actions"
  ]
  stickerTypes: any[] = [
    {
      name: 'Internal',
      val: 'internal'
    },
    {
      name: 'External',
      val: 'external'
    },
  ]
  primaryBtn = {
    icon: 'download',
    text: 'Generate Booklet',
    method: () => {
      this.generatePropertyBooklet(this.property)
    }
  }
  primaryBtn2 = {
    icon: 'download',
    text: 'Generate Stickers',
    method: () => {
      this.generateMeterBooklet(this.selectedFileType)
    }
  }
  titleButton: any = {
    method: () => {
      this.refreshTable()
    },
    toolTip: 'Refresh Table',
    icon: 'refresh'
  }
  constructor(
    public route: ActivatedRoute,
    public propertyService: PropertiesService,
    public loadingservice: LoadingService,
    public userMessagesService: UserMessagesService,
    private http: HttpClient,
    public datepipe: DatePipe,
    private meterManagementService: MeterManagementService
  ) { }

  checkDisabled() {
    return this.selectedFileType.length === 0
  }

  ngOnInit(): void {
    this.userRole = JSON.parse(localStorage.getItem('userRole'));
    if(this.userRole === "ADMIN" || this.userRole === "Admin" || this.userRole === "SUPER_USER"){this.superUser = true};
  }

  applyFilter() {
    // if(this.searchText) {

    // }
    // const filterValue = (event.target as HTMLInputElement).value;
    this.meterSource.filter = this.searchText.trim().toLocaleLowerCase();
  }

  changeButtonStyle(selection) {
    this.isMeterList = selection;
    if(selection) {
      return {
        'background': 'linear-gradient(94.12deg, #384952 25.25%, #232E34 100%)',
        'color': 'white'
      }
    } else {
      return {
        'background-color': '#E7E7E7',
        'color': 'black'
      }
    }
  }

  changeButton(val) {
    this.selectedButton = val;
    if(val && this.property) {
      this.getMeterList()
    }
  }

  changeTab(event) {
    this.tabIndex = event.index;
    if (this.tabIndex === 1) {
      this.getDocumentsList();
    }
  }

  selectedProperty(event) {
    this.property = event;
    if(this.tabIndex === 0 && this.isMeterList) {
      this.getMeterList();
    } else if(this.tabIndex === 1) {
      this.getDocumentsList();
    }
  }

  getMeterList() {
    this.meterList = [];
    this.responseLoaded = false;
    this.loadingservice.openBlockingLoader("Retrieving All Meters");
    from(this.meterManagementService.getMeterNodesByProperty(this.property.id, 'METER')).subscribe((res: any) => {
      const nodes = res.data.getNodesPerProperty.utilityNodeList;

      for(let i = 0; i < nodes.length; i++) {
        this.meterList.push(nodes[i].meterNode)
        this.meterList[i].status = false
        this.meterList[i].id = nodes[i].id
      }
      this.meterList = Object.values(this.meterList.reduce((acc,cur)=>Object.assign(acc,{[cur.id]:cur}),{}))
      this.meterSource = new MatTableDataSource(this.meterList);
      setTimeout(() => this.meterSource.paginator = this.meterPaginator);
      this.responseLoaded = true;
      this.loadingservice.closeBlockingLoader();
    })
  }
  getDocumentsList(){
    this.documentList = [];
    this.fileName = "";
    this.loadingservice.openBlockingLoader("Retrieving Property Documents", "primary" );
    from(this.propertyService.getDocumentsPerProperty(this.property.id)).subscribe((res: any) => {
        this.documentList = res.data.getPropertyDocumentList.documentList;
        this.responseLoaded = true;
        this.documentsSource = new MatTableDataSource(this.documentList);
        setTimeout(() => this.documentsSource.paginator = this.documentPaginator);
        this.loadingservice.closeBlockingLoader();
      },
      (error) => {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to retrieve documents for property';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      }
    );
  }

  generatePropertyBooklet(property){
    this.loadingservice.openBlockingLoader("Generating Property Booklet");
    from(this.propertyService.generatePropertyBooklet(this.fileName, property.id)).subscribe((res: any) => {
      this.loadingservice.closeBlockingLoader();
      message_types.success.messageBody = 'Document generation has started, this may take a few minutes. See Generated Files tab';
      this.userMessagesService.openMessage(message_types.success);
    },(error) => {
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to generate document"';
      this.userMessagesService.openMessage(message_types.error);
      this.loadingservice.closeBlockingLoader();
    })
  }

  generateMeterBooklet(type) {
    let meterArray = [];
    for(let meter of this.meterList){
      if(meter.status){
        meterArray.push(meter.id);
      }
    }
    if(meterArray.length > 0){
      let date= new Date();
      let latest_date =this.datepipe.transform(date, 'yyyy-MM-dd');
      let fileName = this.property.name + ' '+ type + ' ' + latest_date;

      this.loadingservice.openBlockingLoader("Generating Meter Stickers");
      if(type === 'internal'){
        from(this.propertyService.generateMeterBooklet(fileName, this.property.id, meterArray)).subscribe((res: any) => {
          this.loadingservice.closeBlockingLoader();
          message_types.success.messageBody = 'Document generation has started, this may take a few minutes. See Generated Files tab';
          this.userMessagesService.openMessage(message_types.success);
        },(error) => {
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to generate document';
          this.userMessagesService.openMessage(message_types.error);
          this.loadingservice.closeBlockingLoader();
        })
      }else {
        from(this.propertyService.generateExternalMeterBooklet(fileName, this.property.id, meterArray)).subscribe((res: any) => {
          this.loadingservice.closeBlockingLoader();
          message_types.success.messageBody = 'Document generation has started, this may take a few minutes. See Generated Files tab';
          this.userMessagesService.openMessage(message_types.success);
        },(error) => {
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to generate document';
          this.userMessagesService.openMessage(message_types.error);
          this.loadingservice.closeBlockingLoader();
        })
      }
    }
  }

  masterToggle(event) {
    if(event.checked){
      for(let meter of this.meterList){
        meter.status = true;
      }
    }else {
      for(let meter of this.meterList){
        meter.status = false;
      }
    }
  }

  downloadDocument(element){
    const id = element.id;
    this.loadingservice.openBlockingLoader("Downloading booklet");
    from(this.propertyService.getDocumentUrlPerProperty(id, this.property.id)).subscribe((res: any) => {
     if(res.data.getPropertyDocumentPresignedUrl.presignedUrl){
      this.getDocumentFromS3(res.data.getPropertyDocumentPresignedUrl.presignedUrl, element.filename);
     }else {
       this.loadingservice.closeBlockingLoader();
     }
    },(error) => {
      this.loadingservice.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to download document';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

  getDocumentHttp(url) {
    return this.http.get(url, {responseType: 'blob'});
  }

  getDocumentFromS3(url, filename) {
    localStorage.setItem("periodReading", JSON.stringify(true));
    this.getDocumentHttp(url).subscribe(
      (res: any) => {
        this.downloadExportReading(res, filename);
      },
      (error) => {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to export reading';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      }
    );
  }

  downloadExportReading(res, filename) {
    if(!filename || filename === null) {
      filename = this.property.name;
    }
    this.loadingservice.closeBlockingLoader();
    var file = new Blob([res], { type: 'application/pdf' })
    var fileURL = URL.createObjectURL(file);
    var a         = document.createElement('a');
    a.href        = fileURL; 
    a.download    = filename + ".pdf";
    //document.body.appendChild(a);
    a.click();
  }

  refreshTable() {
    this.showLoader = true;
    from(this.propertyService.getDocumentsPerProperty(this.property.id)).subscribe((res: any) => {
        this.documentList = res.data.getPropertyDocumentList.documentList;
        this.responseLoaded = true;
        this.showLoader = false;
        this.documentsSource = new MatTableDataSource(this.documentList);
        setTimeout(() => this.documentsSource.paginator = this.documentPaginator);  
    }, (error) => {
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to refresh table';
      this.userMessagesService.openMessage(message_types.error);
      this.showLoader = false;
    })
  }

}
