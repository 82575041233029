import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

@Component({
  selector: 'app-unit-link',
  templateUrl: './unit-link.component.html',
  styleUrls: ['./unit-link.component.scss']
})
export class UnitLinkComponent implements OnInit, AfterViewInit {

  closeMethod = () => {
    this.closeDialog(false);
  }

  generalUtilitytypes: string[] = ['Water', 'Electricity']
  utilityTypes: string[] =[
    'ELEC_1P','ELEC_1P_KVA','ELEC_3P_KVA','ELEC_3P_1','ELEC_3P_3','ELEC_3P_TOU','WATER'
  ];
  nodeToUnitLink: boolean = false;
  selectedUnit: any;

  tariffList;
  bulkMeters: any[];
  meter:any = {
    isManual: ''
  };
  captureType = [{name: 'Online', value: 'ONLINE'}, {name: 'Manual', value: 'MANUAL'}, {name: 'Online Manual', value: 'ONLINE_MANUAL'}]
  displayCommission2 = false;
  displayCommission3 = false;
  displayCommission4 = false;
  tariffShow = false;
  waterList: any[] = [];
  elecList: any[] = [];

  newMeterDetails = new UntypedFormGroup({
    amps: new UntypedFormControl(1, Validators.min(1)),
    utilityType: new UntypedFormControl(''),
    readingMethod: new UntypedFormControl(''),
    meterNo: new UntypedFormControl(''),
    commissionDate: new UntypedFormControl(''),
    captureType: new UntypedFormControl(''),
    tariff: new UntypedFormControl(''),
    isManual: new UntypedFormControl(''),
    // meterType: new UntypedFormControl('')
  });

  linkButtonProperties = {
    text: 'Link',
    method: () => {
      this.createMeteredLink()
    }
  };

  createButtonProperties = {
    text: 'Create and Link New Meter',
    method: () => {
      
    }
  }

  meterUtility = [{
    name: 'ELEC_1P',
    image: 1
  },
  {
    name : 'ELEC_1P_KVA',
    image: 2
  },
  {
    name: 'ELEC_3P_KVA',
    image: 2
  },
  {
    name: 'ELEC_3P_1',
    image: 1
  },
  {
    name: 'ELEC_3P_3',
    image: 3
  },
  {
    name: 'ELEC_3P_TOU',
    image: 4
  },
  {
    name: 'WATER',
    image: 1
  }];
  readingMethods = ["Manual", "Smart"];
  bulkShow: boolean = false;

  unitColumns: string[] = [
    'unit', 'split', 'allocationRule', 'remove'
  ];
  timeout: any = null;
  checkExists: boolean = false;
  meterNoExists: boolean = false;

  nodeTypes: any[] = [{
    value: 'ALLOCATION',
    name: 'Allocation'
  }, {
    value: 'METER',
    name: 'Meter'
  }];

  nodeLinkTypes: string[] = ['SQM', 'PERCENTAGE', 'METERED']

  nodeType = new UntypedFormControl('');
  meterSelection = new UntypedFormControl('')
  filterSelection = new UntypedFormControl('')
  allocationSelection = new UntypedFormControl('')
  nodeLinkType = new UntypedFormControl('')

  allocationNodes: any[] = [];
  meterNodes: any[] = [];
  unitList: any[] = [];
  propertyId: any;
  selectedMeter: any;
  unitId: any;
  unitDatasource = new MatTableDataSource();
  count: number = 1;
  totalSplit: number = 0;
  showTable: boolean = false;
  unitName: string = '';
  filteredNodes: any[] = [];
  showNewMeterDetails: boolean = false;
  showMeterDetails: boolean = false;
  searchInput: any;
  showWater: boolean = false;
  tabIndex: number = 0;

  constructor(public loadingservice: LoadingService, 
    public dialogRef: MatDialogRef<UnitLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private meterManagementService: MeterManagementService, 
    private userMessagesService: UserMessagesService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.unitName = this.data.node.name;
    this.totalSplit = 0;
    this.unitId = this.data.node.id;
    this.propertyId = this.data.propertyId;
    this.meterNodes = [...this.data.meterList];
    this.filteredNodes = [...this.meterNodes];
    this.allocationNodes = this.data.allocationList;
    this.tariffList = JSON.parse(sessionStorage.getItem('tariffs'));
    if(this.tariffList.length != 0) {
      this.waterList = this.sortList([...this.tariffList], true);
      this.elecList = this.sortList([...this.tariffList], false);
    }
    this.unitList = this.data.unitList;
    this.selectedMeter = this.data.meter;
    this.nodeToUnitLink = this.data.nodeToUnitLink;
  }

  ngAfterViewInit(): void {
      this.cd.detectChanges();
  }

  focusOutFunction(isSelect) {
    if(this.filteredNodes.length === 0 || isSelect) {
      this.filteredNodes = this.meterNodes; 
    }
  }

  onInputChange(event: any) {
    this.searchInput = event.target.value.toLowerCase();
    this.showNewMeterDetails = false;
    this.showMeterDetails = true;
    this.filteredNodes = this.meterNodes.filter((meter:any) => {
      return meter.meterNo.toLowerCase().includes(this.searchInput);
    });
  }

  selectionChange(event) {
    const meterDetails = event.value;
    let tariffDetails = {};
    this.filteredNodes = this.meterNodes;

    if(meterDetails.generalUtilityType === 'ELEC') {
      for(let tariffList of this.elecList) {
        for(let tariff of tariffList) {
          if(tariff.id === meterDetails.tariffId) {
            tariffDetails = tariff;
            break;
          }
        }
      }
    } else {
      for(let tariffList of this.waterList) {
        for(let tariff of tariffList) {
          if(tariff.id === meterDetails.tariffId) {
            tariffDetails = tariff;
            break;
          }
        }
      }
    }
  }

  changeTab(event) {
    this.tabIndex = event.index;
  }

  filterSelectionChange(event) {
    this.filteredNodes = [...this.meterNodes];
    if(event && !this.nodeToUnitLink) {
      if(event.value === 'Electricity' || event.value === 'Water') {
        const val = event.value === 'Electricity' ? 'ELEC' : 'WATER';
        this.filteredNodes = this.filteredNodes.filter(n => n.generalUtilityType === val)
      } else {
        this.filteredNodes = this.filteredNodes.filter(n => n.utilityType === event.value)
      }
    }
  }

  checkIfNumberExists() {
    if(this.meter.meterNo != this.newMeterDetails.value.meterNo) {
      clearTimeout(this.timeout);
      this.meterNoExists = true;
      this.checkExists = true;
      this.timeout = setTimeout(() => {
        from(this.meterManagementService.checkIfMeterNumberExists(this.propertyId, this.newMeterDetails.value.utilityType.name, this.newMeterDetails.value.meterNo)).subscribe((res:any) => {
          this.meterNoExists = res.data.checkIfMeterNumberExists.meterNoExists;
          this.checkExists = false;
        })
      }, 1000)
    } else {
      this.meterNoExists = false;
      this.checkExists = false;
    }
  }

  meterChange() {
    const meterUtility = this.newMeterDetails.value.utilityType;
    this.newMeterDetails.patchValue({
      noOfPhotos: meterUtility.image
    })

    if(this.newMeterDetails.get('utilityType').value.name === 'WATER') {
      this.showWater = true;
    } else {
      this.showWater = false;
    }

    if(this.tariffList.length != 0) {
      this.checkUtilityType();
    }

    this.checkIfNumberExists();

    this.checkCaptureType();
  }

  checkmeterUtility(meterUtility) {
    if(meterUtility === 'UNIT') {
      this.bulkShow = true;
      this.newMeterDetails.value.bulkMeters = '';
    } else {
      this.bulkShow = false;
    }
  }

  sortList(tariffList, isWater) {
    let tariffType = []
    if(isWater) {
      tariffType = ['Water', 'Sanitation'];
    } else {
      tariffType = ['Electricity']
    }
    return tariffList.map(t => {
      if(t.tariffList && t.tariffList.length > 0) {
        return t.tariffList.filter(tariff => tariff.utilityType && tariffType.includes(tariff.utilityType))
      }
    });
  }

  checkCaptureType() {
    const utilityType = this.newMeterDetails.value.utilityType;
    const readingMethod = this.newMeterDetails.value.isManual;

    if(readingMethod === 'Manual') {
      this.newMeterDetails.controls['captureType'].disable();
      this.newMeterDetails.controls['captureType'].setValue('MANUAL');
    } else {
      this.newMeterDetails.controls['captureType'].enable();
    }

  }

  checkUtilityType() {
    let meterUtility = this.newMeterDetails.value.utilityType.name;
    if(meterUtility && meterUtility === 'WATER') {
      this.newMeterDetails.setControl('amps', new UntypedFormControl(0));
      this.tariffShow = true;
      for(let i = 0; i < this.tariffList.length; i++) {
        this.tariffList[i].tariffList = this.waterList[i];
      }
    } else if(!meterUtility || meterUtility === null) {
      this.tariffShow = false;
    } else {
      this.tariffShow = true;
      const amps = this.newMeterDetails.value.amps;
      this.newMeterDetails.setControl('amps', new UntypedFormControl(amps, Validators.min(1)));
      for(let i = 0; i < this.tariffList.length; i++) {
        this.tariffList[i].tariffList = this.elecList[i];
      }
    }
    this.checkIfNumberExists();
  }

  changeMeterSelection() {
    if(this.nodeLinkType.value === 'PERCENTAGE' || this.nodeType.value === 'ALLOCATION') {
      this.showTable = true;
    } else {
      this.showTable = false;
    }
  }

  addToTable() {
    let unitList = this.unitDatasource.data;
    unitList.push({
      unit: '',
      split: 0,
      allocationRule: ''
    })
    this.unitDatasource = new MatTableDataSource(unitList);
  }

  removeFromTable(index) {
    let unitList = this.unitDatasource.data;
    unitList.splice(index, 1);
    this.unitDatasource = new MatTableDataSource(unitList);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.data.unitList.filter(option =>
      option.name.toLowerCase().includes(filter)
    );
  }

  checkCloseDialog() {
    if(this.count === this.unitDatasource.data.length) {
      message_types.success.messageBody = 'Successfully created link';
      this.userMessagesService.openMessage(message_types.success);
      this.loadingservice.closeBlockingLoader();
      this.closeDialog(true);
    }
  }

  createMeteredLink() {
    let firstName = '';
    let count = 0;
    let name = '';
    
    if(this.nodeType.value === 'ALLOCATION') {
      const allocationName: string = this.allocationSelection.value.name;
      firstName = allocationName.substring(0, allocationName.indexOf(' -'));
      if(allocationName.includes('Direct')) {
        count = 1;
      } else {
        const removeName: string = allocationName.replace(firstName + ' - ', '');
        count = Number(removeName.substring(0, removeName.indexOf(' -')));
      }
    }

    if(this.nodeLinkType.value === 'PERCENTAGE' || this.nodeType.value === 'ALLOCATION') {
      let nodeList: any[] = this.unitDatasource.data;
      this.loadingservice.openBlockingLoader(`Linking Unit to ${this.nodeType.value === 'ALLOCATION' ? 'Allocation Node' : 'Meter'}`);
      for(let i = 0; i < nodeList.length; i++) {
        if(nodeList[i].unit.name === 'Allocation') {
          if(this.nodeType.value === 'ALLOCATION') {
            name = firstName + ' - ' + count + ' - ' + (i+1);
            from(this.meterManagementService.createAllocationNode(this.propertyId, this.allocationSelection.value.id, nodeList[i].split, name)).subscribe(() => {
              this.count++;
              this.checkCloseDialog();
            }, (error) => {
              
            })
          } else {
            name = this.meterSelection.value.meterNo + ' - Direct - '  + (i+1)
            from(this.meterManagementService.createAllocationNode(this.propertyId, this.meterSelection.value.id, nodeList[i].split, name)).subscribe(() => {
              this.count++;
              this.checkCloseDialog();
            }, (error) => {
              
            })
          }
          
        } else {
          if(this.nodeType.value === 'ALLOCATION') {
            from(this.meterManagementService.createNodeUnitLink(nodeList[i].unit.id, this.allocationSelection.value.id, 'PERCENTAGE', nodeList[i].split)).subscribe((res:any) => {
              this.count++;
              this.checkCloseDialog();
            })
          } else {
            from(this.meterManagementService.createNodeUnitLink(nodeList[i].unit.id, this.meterSelection.value.id, this.nodeLinkType.value, nodeList[i].split)).subscribe((res:any) => {
              this.count++;
              this.checkCloseDialog();
            })
          }
          
        }
      }
    } else {
      let meterId = this.meterSelection.value.id ? this.meterSelection.value.id : this.selectedMeter.id; 
      if(this.nodeToUnitLink) {
        this.unitId = this.selectedUnit.id;
      }

      this.loadingservice.openBlockingLoader(`Linking Unit to ${this.nodeType.value === 'ALLOCATION' ? 'Allocation Node' : 'Meter'}`);
      from(this.meterManagementService.createMeteredUnitLink(this.unitId, meterId, 'SQM')).subscribe((res:any) => {
        message_types.success.messageBody = 'Successfully created link';
        this.userMessagesService.openMessage(message_types.success);
        this.loadingservice.closeBlockingLoader();
        this.closeDialog(!this.nodeToUnitLink ? true : 'unit');
      })
    }
  }

  saveMeter() {
    this.meter = this.newMeterDetails.value;
      if(this.meter.isManual === "Manual") {
        this.meter.isManual = true;
      }else {
        this.meter.isManual = false;
      }

      if(this.meter.isManual === "" || this.meter.isManual === undefined || this.meter.isManual === null) {this.meter.isManual = true};
      this.meter.commissionDate = new Date(this.meter.commissionDate).toISOString();
      if(this.tariffList.length != 0) {
        this.meter.tariffId = this.meter.tariff.id;
        this.meter.tariff = this.meter.tariff.name;
      }
      this.meter.meterReadingType = this.meter.utilityType.name.includes('TOU') ? 'UTILIZATION' : 'READING';
      this.meter.isCommonArea = false;
      this.meter.bulkMeter =  null;
      this.meter.meterUtility = 'UNIT'
      
      this.loadingservice.openBlockingLoader("Adding New Meter","primary");
      from(this.meterManagementService.createMeterNode(this.propertyId, this.meter)).subscribe((res:any) => {
        const meterId = res.data.createMeterNode.meterNodeId;

        from(this.meterManagementService.createMeteredUnitLink(this.unitId, meterId, 'METERED')).subscribe((res:any) => {
          message_types.success.messageBody = 'Successfully created link';
          this.userMessagesService.openMessage(message_types.success);
          this.loadingservice.closeBlockingLoader();
          this.closeDialog(true);
        })
      }, (error) => {
        this.loadingservice.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to create meter';
        this.userMessagesService.openMessage(message_types.error);
      })
  }

  closeDialog(callClosed) {
    this.dialogRef.close(callClosed);
  }

  getTotalSplit() {
    this.totalSplit = this.unitDatasource.data.map((s:any) => s.split).reduce((s, val) => s + val, 0);
    return this.totalSplit;
  }

}
