import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TOU_LIST } from './charge-readings/charge-readings.component';

@Component({
  selector: 'app-display-meter-charges',
  templateUrl: './display-meter-charges.component.html',
  styleUrls: ['./display-meter-charges.component.scss']
})
export class DisplayMeterChargesComponent implements OnInit {

  selectedReading;
  chargeList: any[] = [];
  meter: any;
  utilizationList: any[] = [];
  //Change During Testing, checks to see if the charge list contains
  //TOU values like Peak, Standard, Off-Peak
  hasTou: boolean = true;  
  presentTariffList: any[] = [];
  isMultiple: boolean = false;
  touList: string[] = TOU_LIST;
  utility: any;
  totalCharge: number;
  calculatedChargeList: any[] = [];
  readingCharges: any[] = [];
  chargeColumns: string[] = ['chargeType', 'unitType', 'units', 'rate', 'amount']
  olderColumns: string[] = [
    'tariff',
    'units',
    'rate',
    'amount'
  ];

  
  closeMethod = () => {
    this.closeDialog()
  }

  constructor(public dialogRef: MatDialogRef<DisplayMeterChargesComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.utility = this.data.utility;
    this.selectedReading = this.data.selectedReading;
    this.utilizationList = this.selectedReading.utilizationList;
    this.isMultiple = this.data.isMultiple;

    if(this.data.readingCharges) {
      this.readingCharges = [...this.data.readingCharges];
      this.totalCharge = this.readingCharges[0].total;  
      this.readingCharges.shift();
    }    

    this.meter = this.data.meter;

    if(this.selectedReading.unitCalculatedChargeList && this.selectedReading.unitCalculatedChargeList.length != 0) {
      this.calculatedChargeList = this.selectedReading.unitCalculatedChargeList;
    }    
  }

  hasReadingsList(selectedReading) {

    return (selectedReading.prevReading.readingValueList
              && selectedReading.prevReading.readingValueList.length != 0)
            && (selectedReading.reading.readingValueList
                && selectedReading.reading.readingValueList.length != 0);
  }

  

  getDecimalParts(charge) {
    if(charge) {
      let chargeArray: string[] = charge.toString().split('.');
      if(chargeArray.length === 2) {
        if(chargeArray[1].length < 2) {
          return 'R ' + chargeArray[0] + '.' + chargeArray[1] + '0';
        } else {
          return 'R ' + chargeArray[0] + '.' + chargeArray[1].substring(0, 2);
        }
      } else {
        return 'R ' + chargeArray[0] + '.00';
      }
    } else {
      return '-';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
