import { Component, Input, OnInit } from '@angular/core';
import { Message } from './message.model';
import { UserMessagesService } from './user-messages.service';

@Component({
  selector: 'sym-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.scss']
})
export class UserMessagesComponent implements OnInit {

  @Input() messageInfo: Message;

  constructor(public userMessagesService: UserMessagesService) { }

  ngOnInit(): void {
  }

  openHelp() {
    
  }

}
