<div fxLayout="row" fxLayoutAlign="space-between center" style="padding-top: 16px;">
    <div fxLayout="row">
      <button mat-flat-button [class.selectedButton]="selectedCharge === 0" [class.normalButton]="selectedCharge !== 0" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
        id="btnClass" (click)="selectedCharge = 0">
        FIXED CHARGES
      </button>
      <button mat-flat-button [class.selectedButton]="selectedCharge === 1" [class.normalButton]="selectedCharge !== 1" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
        id="btnClass" (click)="selectedCharge = 1">
        VARIABLE CHARGES
      </button>
    </div>

    <div style="width: 281px;height: 70px;" *ngIf="selectedCharge === 1">
      <mat-form-field appearance="outline">
        <mat-label></mat-label>
        <mat-select [(ngModel)]="tariffWaterCharge.variableCharge">
          <mat-option *ngFor="let charge of variableCharges" [value]="charge.value">
            {{charge.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
</div>

<div *ngIf="selectedCharge === 0" class="grid-container">
    <div class="tariff-row">
      <span class="tariff-label">Basic Charge (Per Month):</span>
      <mat-form-field class="tariff-input" appearance="outline">
        <mat-label>Tariff Charge</mat-label>
        <input matInput placeholder="Basic Charge" [(ngModel)]="tariffWaterCharge.basicCharge" type="number" />
      </mat-form-field>      
    </div>

    <div class="tariff-row" *ngIf="utilityType === 'Water'">
      <span class="tariff-label">Demand Management Levy (Per Day/Month):</span>
      <mat-form-field class="tariff-input" appearance="outline">
        <mat-label>Tariff Charge</mat-label>
        <input matInput placeholder="Demand Management Levy" [(ngModel)]="tariffWaterCharge.demandMangementLevy" type="number" />
      </mat-form-field>
      <mat-form-field  class="tariff-input-small" appearance="outline">
        <mat-label>Tariff Unit</mat-label>
        <mat-select [(ngModel)]="tariffWaterCharge.demandManagementUnit">
          <mat-option [value]="'Day'">
            Day
          </mat-option>
          <mat-option [value]="'Month'">
            Month
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="selectedCharge === 1" style="padding-top: 24px;display: flex;flex-direction: column;">
    <div *ngIf="tariffWaterCharge.variableCharge === 0"  style="display: flex;flex-direction: column;">
      <div class="tariff-row" style="width: 100px;">
        <mat-form-field appearance="outline">
          <mat-label>Block Type</mat-label>
          <mat-select [(ngModel)]="tariffWaterCharge.blockType">
            <mat-option *ngFor="let block of blockTypes" [value]="block">
              {{block}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center " fxFlex="100" *ngFor="let blockCharge of tariffWaterCharge.blockCharges; let i = index">
        <mat-form-field appearance="outline" fxFlex="25" style="margin-right: 8px;">
          <mat-label>Block Start</mat-label>
          <input matInput placeholder="Insert Block Start" (change)="onBlockStartChange(i)" [(ngModel)]="blockCharge.blockStart" type="number" />
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="25" style="margin-right: 8px;">
          <mat-label>Block End</mat-label>
          <input matInput placeholder="Insert Block End" (change)="onBlockEndChange(i)" [(ngModel)]="blockCharge.blockEnd" type="number" />
        </mat-form-field>
  
        <mat-form-field appearance="outline" fxFlex="45" style="margin-right: 4px">
          <mat-label>Block Charge</mat-label>
          <input matInput placeholder="Insert Block Charge" [(ngModel)]="blockCharge.charge" type="number" />
        </mat-form-field>

        <button mat-icon-button fxFlex="5" style="margin-top: 10px;" (click)="removeRange(i)">
          <mat-icon style="color: red;">delete</mat-icon>
        </button>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" *ngIf="tariffWaterCharge.blockCharges.length > 0">
        <div class="tariff-column" fxFlex="25" style="margin-right: 8px;">
          <span style="font-weight: bold;margin-bottom: 8px;">Final Block Start</span>
          <span>{{tariffWaterCharge.blockCharges[tariffWaterCharge.blockCharges.length - 1].blockEnd + 1}}</span>
        </div>

        <div class="tariff-column" fxFlex="25" style="margin-right: 8px;">
          <span style="font-weight: bold;margin-bottom: 8px;">Final Block End</span>
          <span>&#8734;</span>
        </div>

        <mat-form-field fxFlex="45" appearance="outline" style="margin-right: 4px;">
          <mat-label>Final Block Charge</mat-label>
          <input matInput placeholder="Insert Final Block Charge" [(ngModel)]="tariffWaterCharge.finalBlock" type="number" />
        </mat-form-field>

        <div fxFlex="5"></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" style="margin: 8px 0;">
        <button (click)="addRangeCharge()" style="background-color: #9B9020;color: white;box-shadow: 0px 1px 22px 0px rgba(0, 0, 0, 0.15);" mat-fab>
          <mat-icon style="font-size: x-large;">add</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="tariffWaterCharge.variableCharge === 1" class="grid-container">
      <div class="tariff-row">
        <span class="tariff-label">Standard Charge:</span>
        <mat-form-field class="tariff-input" appearance="outline">
          <mat-label>Tariff Charge</mat-label>
          <input matInput placeholder="Standard Charge" [(ngModel)]="tariffWaterCharge.standardCharge" type="number" />
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="tariffWaterCharge.percentageQty > 0" style="margin-bottom: 1em;">
      <div style="margin-bottom: 1.5em;">
        <mat-divider></mat-divider>
      </div>
      <div class="tariff-row" *ngIf="tariffWaterCharge.percentageQty > 0 ">
        <span class="tariff-label">Percentage of Water Consumption:</span>
        <mat-form-field class="tariff-input" appearance="outline">
          <mat-label>Tariff Charge</mat-label>
          <input matInput placeholder="Water Charge" [(ngModel)]="tariffWaterCharge.waterConsCharge" type="number" />
        </mat-form-field>      
      </div>
    </div>
  </div>