<div class="white-bg mat-elevation-z2 m-8" >
    <div class="background" fxLayoutAlign="space-between center" fxLayout="row wrap">
      <div style="margin-left: 5px;">
        <span style="font-size: 18px;">{{name}}</span>
      </div>
      <button *ngIf="hasButton && !hasMethod" type="button" mat-icon-button (click)="onButtonClicked()">
        <mat-icon class="white-fg" matTooltip={{tooltip}} *ngIf="buttonIcon === 'qr_code' || buttonIcon === 'refresh'">{{buttonIcon}}</mat-icon>
      </button>
      <!-- <button *ngIf="hasMethod" type="button" mat-icon-button (click)="test()">
        <mat-icon class="white-fg" matTooltip={{tooltip}} *ngIf="buttonIcon === 'refresh'">{{buttonIcon}}</mat-icon>
      </button> -->
    </div>
  
    <div class="p-12">
      <ng-content #body>
      </ng-content>
    </div>
  </div>