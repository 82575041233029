import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tariffDisplay'
})
export class TariffDisplayPipe implements PipeTransform {

  transform(tariffName: string[]): unknown {
    let newTariffs: string = '';

    for(let name of tariffName) {
        if(name.toLocaleLowerCase().includes('water')) {
            if(newTariffs !== '') {
              newTariffs = 'Water' + newTariffs;
            } else {
              newTariffs += 'Water';
            }
        } else if(name.toLocaleLowerCase().includes('sanitation')) {
            newTariffs += ', Sanitation';
        } else {
            newTariffs += name;
        }
    }

    return newTariffs;
  }

}