// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const METER_READING = "https://niquu52vhbey5b6tkuskwhb27y.appsync-api.eu-west-1.amazonaws.com/graphql";
const CHARGE_CALCULATION = "https://fweu265vzvgtzoq3swx5zeld4a.appsync-api.eu-west-1.amazonaws.com/graphql";
const USER_MANAGEMENT_API = "https://mtera4f9rf.execute-api.eu-west-1.amazonaws.com/Prod/v1";
const USER_MANAGEMENT = "https://lwm2zdszizdrxlriyoxibt6owe.appsync-api.eu-west-1.amazonaws.com/graphql";
const QUICK_SIGHT = "https://9qvhob8uuh.execute-api.eu-west-1.amazonaws.com/prod/analytics/v1/embedded-url";
const PROPERTY_STRUCTURE = "https://cnf32frlpbdwxmpyw44kprr7be.appsync-api.eu-west-1.amazonaws.com/graphql";
const METER_MANAGEMENT = "https://ycj5xzkos5cedenksqqpxvl6xy.appsync-api.eu-west-1.amazonaws.com/graphql";


export const environment = {
  production: false,
  
  api: {
    meterReading: METER_READING,
    chargeCalculation: CHARGE_CALCULATION,
    userManagement: USER_MANAGEMENT,
    loginAPI: USER_MANAGEMENT_API,
    quicksight: QUICK_SIGHT,
    propertyStructure: PROPERTY_STRUCTURE,
    meterManagement: METER_MANAGEMENT
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
