export const aws_exports = {
  "aws_project_region": "eu-west-1",
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_anZyBJLIV",
  "aws_user_pools_web_client_id": "76v4h9p3lpu5co8qdq1t29d1dk",
  "oauth": {},
  "aws_appsync_region": "eu-west-1",
  "aws_appsync_authenticationType": "AWS_LAMBDA",
  "aws_appsync_graphqlEndpoint": "https://mxx2guou7ffw5jdyzfan543fsy.appsync-api.eu-west-1.amazonaws.com/graphql",
  "aws_appsync_apiKey": "da2-jfsv6c4agfc2vluw7vic4q7f3u",
  "AWS_COGNITO_USERNAME": "7ad8417b-eec1-4529-a237-de2965866619",
  "AWS_COGNITO_PASSWORD": "P@ssw0rd"
};
// export const aws_exports = {
//   "aws_project_region": "eu-west-1",
//   "aws_cognito_identity_pool_id": "eu-west-1:d9924c31-2dad-4abb-acc0-e50c762c7504",
//   "aws_cognito_region": "eu-west-1",
//   "aws_user_pools_id": "eu-west-1_zM7MR5JTi",
//   "aws_user_pools_web_client_id": "40ttvjao4imlsok9rp1lkpbcla",
//   "oauth": {},
//   "aws_appsync_region": "eu-west-1",
//   "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
// };
