<div fxLayout="row" fxLayoutAlign="start center" style="padding-top: 16px;">
    <button mat-flat-button [class.selectedButton]="selectedCharge === 0" [class.normalButton]="selectedCharge !== 0" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
      id="btnClass" (click)="selectedCharge = 0">
      FIXED CHARGES
    </button>
    <button mat-flat-button [class.selectedButton]="selectedCharge === 1" [class.normalButton]="selectedCharge !== 1" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
      id="btnClass" (click)="selectedCharge = 1">
      DEMAND CHARGES
    </button>
    <button mat-flat-button [class.selectedButton]="selectedCharge === 2" [class.normalButton]="selectedCharge !== 2" style="margin-bottom: 10px;padding: 8px 16px;"
      id="btnClass" (click)="selectedCharge = 2">
      ENERGY CHARGES
    </button>
  </div>

  <div *ngIf="selectedCharge === 0" fxLayout="column" style="padding-top: 24px;">
    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.fixedCharge.basicCharge" [chargeLabel]="'Basic Charge (Per Month):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.fixedCharge.serviceCharge" [chargeLabel]="'Service Charge (Per Day):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.fixedCharge.adminCharge" [chargeLabel]="'Administration Charge (Per Day):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.fixedCharge.capacityCharge" [chargeLabel]="'Capacity Charge (Per Month)'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.fixedCharge.capacityAmpCharge" [chargeLabel]="'Capacity Amp Charge (R/Ampere):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.fixedCharge.networkCapacityCharge" [chargeLabel]="'Network Capacity Charge (Per Day):'"></sym-charge-input>
    
  </div>

  <div *ngIf="selectedCharge === 1" fxLayout="column" style="padding-top: 24px;">
    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.demandCharge.demandEnergyCharge" [chargeLabel]="'Energy Demand Charge (Per kVA):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.demandCharge.networkDemandCharge" [chargeLabel]="'Network Demand Charge (Per kVA):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.demandCharge.networkAccessCharge" [chargeLabel]="'Network Access Charge (Per kVA):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.demandCharge.excessNetworkCharge" [chargeLabel]="'Excess Network Charge (Per kVA):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.demandCharge.reactiveEnergyCharge" [chargeLabel]="'Reactive Energy Charge (Per kVArh):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.demandCharge.subsidyCharge" [chargeLabel]="'Low Votlage Subsidy Charge (Per kVA):'"></sym-charge-input>

    <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.demandCharge.transNetworkCharge" [chargeLabel]="'Transmission Network Charge (Per kVA):'"></sym-charge-input>
  </div>

  <div *ngIf="selectedCharge === 2" fxLayout="column" style="padding-top: 24px;">
    <sym-charge-input *ngIf="!isTou" [seasons]="seasons" [tariffCharge]="tariffElecCharge.energyCharge.activeCharge" [chargeLabel]="'Active Energy Charge (Per kWh):'"></sym-charge-input>

    <div fxLayout="column" *ngIf="isTou">
      <sym-tou-charges [title]="'Active Energy Charge'" [touCharge]="this.tariffElecCharge.energyCharge.touCharges"></sym-tou-charges>

      <sym-tou-charges [title]="'Distribution Network Demand Charge'" [touCharge]="this.tariffElecCharge.energyCharge.distributionCharge"></sym-tou-charges>
    </div>

    <div fxLayout="column">

      <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.energyCharge.networkSurcharge" [chargeLabel]="'Network SurCharge (Per kWh):'"></sym-charge-input>

      <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.energyCharge.networkDemandCharge" [chargeLabel]="'Network Demand Charge (Per kWh):'"></sym-charge-input>

      <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.energyCharge.ancillaryCharge" [chargeLabel]="'Ancillary Service Charge (Per kWh):'"></sym-charge-input>

      <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.energyCharge.elecRuralCharge" [chargeLabel]="'Electrification & Rural Network Subsidy (Per kWh):'"></sym-charge-input>

      <sym-charge-input [seasons]="seasons" [tariffCharge]="tariffElecCharge.energyCharge.affordabilityCharge" [chargeLabel]="'Affordability Subsidy (Per kWh):'"></sym-charge-input>
    </div>
  </div>