import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sym-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() titleButton: any;
  @Input() smallDivider: any = '92%';
  @Input() searchBar: any;
  searchText: string = '';
  
  constructor() { }

  ngOnInit(): void {
  }

  search(event) {
    this.searchText = (event.target as HTMLInputElement).value;

    if(this.searchBar) {
      this.searchBar.method(this.searchText);
    }
  }

  clearSearch() {
    this.searchText = '';

    if(this.searchBar) {
      this.searchBar.method(this.searchText);
    }
  }

}
