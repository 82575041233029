import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { FloorplanService } from 'src/app/services/floor-plan.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss']
})
export class AddPropertyComponent implements OnInit {

  title;
  centre;
  displayAgentCode;
  supplierList;
  waterSuppliers: any[] = [];
  electricitySuppliers: any[] = [];
  selectedWaterSuppliers: any[] = [''];
  selectedElectricitySuppliers: any[] = [''];
  supplierId: any;
  userRole: any;
  disableSuppliers = false;
  selectedTab: number = 0;
  isSuperUser = false;
  closeMethod = () => {
    this.closeDialog(false)
  }
  isUpdate: any;
  constructor(public userMessagesService: UserMessagesService, public loadingservice: LoadingService,private propertyService: FloorplanService,public dialogRef: MatDialogRef<AddPropertyComponent>, @Inject(MAT_DIALOG_DATA) public data,) { }
  
  propertyDetails = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    code: new UntypedFormControl(''),
    address: new UntypedFormControl('')
  });

  // addressDetails = new FormGroup({
  //   line1: new FormControl(''),
  //   line2: new FormControl(''),
  //   suburb: new FormControl(''),
  //   city: new FormControl(''),
  //   postcode: new FormControl('', Validators.pattern("^[0-9]*$"))
  // })

  ngOnInit() {
    //service call will be made on this screen to return list of suppliers, and that will be used to set the supplierList[]
    this.title = this.data.title;
    this.centre = this.data.centre;
    this.isUpdate = this.data.isUpdate
    this.supplierList = this.data.suppliers;
    this.displayAgentCode = this.data.displayAgentCode;
    // this.waterSuppliers = su
    this.electricitySuppliers = this.setSupplier([...this.supplierList], 'ELEC');
    this.waterSuppliers = this.setSupplier([...this.supplierList], 'WATER');

    if(this.centre != null && this.centre != undefined && this.centre.name != undefined) {
      this.disableSuppliers = true;
      this.propertyDetails.setValue({
        name: this.centre.name,
        code: this.centre.code,
        address: this.centre.address
      });

      this.selectedElectricitySuppliers = [];
      this.selectedWaterSuppliers = [];

      for(let supplier of this.electricitySuppliers) {
        if(this.centre.supplierIds.includes(supplier.id)) {
          this.selectedElectricitySuppliers.push(supplier.id);
        }
      }
      
      for(let supplier of this.waterSuppliers) {
        if(this.centre.supplierIds.includes(supplier.id)) {
          this.selectedWaterSuppliers.push(supplier.id);
        }
      }
    }

    this.userRole = JSON.parse(localStorage.getItem("userRole"));
    if(this.userRole === "SUPER_USER") {
      this.isSuperUser = true;
    }
  }
  
  closeDialog(saved) {
    this.dialogRef.close(saved);
  }

  setSupplier(supplierList: any[], tariffType: string): any[] {
    let newSupplierList = [];

    for(let sList of supplierList) {
      if(sList.tariffSetList && sList.tariffSetList.length > 0) {
        let tariffSetNumb = sList.tariffSetList.length - 1;

        if(sList.tariffSetList[tariffSetNumb].tariffList.filter(t => t.tariffType === tariffType).length != 0) {
          newSupplierList.push({
            id: sList.id,
            name: sList.name
          })
        }
      }
    }

    return newSupplierList;
  }

  checkSupplier(event, isWaterSupplier) {
    const supplier = event.value;
    let elecId = '';
    let waterId = '';

    for(let suppl of this.electricitySuppliers) {
      if(suppl.id === supplier) {
        suppl.disabled = true;
        elecId = supplier;
      } else if(!this.selectedElectricitySuppliers.includes(suppl.id)) {
        if(this.selectedWaterSuppliers.includes(suppl.id)) {
          this.deleteSupplier(suppl.id, true, false);
        }
        suppl.disabled = false;
      }
    }

    for(let suppl of this.waterSuppliers) {
      if(suppl.id === supplier) {
        suppl.disabled = true;
        waterId = supplier;
      } else if(!this.selectedWaterSuppliers.includes(suppl.id)) {
        if(this.selectedElectricitySuppliers.includes(suppl.id)) {
          this.deleteSupplier(suppl.id, false, false);
        }
        suppl.disabled = false;
      }
    }
    
    if(waterId === elecId) {
      if(isWaterSupplier) {
        this.selectedElectricitySuppliers.push(supplier);
      } else {
        this.selectedWaterSuppliers.push(supplier);
      }
      this.selectedElectricitySuppliers = this.selectedElectricitySuppliers.filter(s => s != '');
      this.selectedWaterSuppliers = this.selectedWaterSuppliers.filter(s => s != '')
    }
  }

  checkSupplierId(supplier, chosenId){
    if(supplier.id === chosenId) {
      return supplier.name
    }

  }

  changeTabIndex(event){
    this.selectedTab = event.index;
  }

  addSupplier(isWaterSupplier) {
    if(isWaterSupplier) {
      this.selectedWaterSuppliers.push('');
    } else {
      this.selectedElectricitySuppliers.push('');
    }
  }

  deleteSupplier(supplier, isWaterSupplier, deletedCall){
    if(isWaterSupplier) {
      let sWaterIndex = this.selectedWaterSuppliers.indexOf(supplier);
      this.selectedWaterSuppliers.splice(sWaterIndex,1);

      if(deletedCall && this.selectedElectricitySuppliers.includes(supplier)) {
        const index = this.selectedElectricitySuppliers.indexOf(supplier);
        this.selectedElectricitySuppliers.splice(index,1);
      }
    } else {
      const index = this.selectedElectricitySuppliers.indexOf(supplier);
      this.selectedElectricitySuppliers.splice(index,1);

      if(deletedCall && this.selectedWaterSuppliers.includes(supplier)) {
        const index = this.selectedWaterSuppliers.indexOf(supplier);
        this.selectedWaterSuppliers.splice(index,1);
      }      
    }

    if(this.selectedElectricitySuppliers.length === 0 && deletedCall) {
      this.selectedElectricitySuppliers.push('');
    }

    if(this.selectedWaterSuppliers.length === 0 && deletedCall) {
      this.selectedWaterSuppliers.push('');
    }

    for(let suppl of this.electricitySuppliers) {
      if(!this.selectedElectricitySuppliers.includes(suppl.id)) {
        suppl.disabled = false;
      }
    }

    for(let suppl of this.waterSuppliers) {
      if(!this.selectedWaterSuppliers.includes(suppl.id)) {
        suppl.disabled = false;
      }
    }
  }

  updateCentre() {
    this.centre.code = this.propertyDetails.value.code;
    this.centre.name = this.propertyDetails.value.name;
    this.centre.address = this.propertyDetails.value.address;
    this.loadingservice.openBlockingLoader("Updating Property Details","primary");
    from(this.propertyService.updateProperty(this.centre)).subscribe((res:any) => {
      message_types.success.messageBody = 'Successfully updated Property';
      this.userMessagesService.openMessage(message_types.success);

      let selectedProperty = JSON.parse(sessionStorage.getItem('propertySelection'));
      let propertyList = JSON.parse(sessionStorage.getItem('properties'));

      for(let property of propertyList) {
        if(property.id === this.centre.id) {
          property.address = this.centre.address;
          property.code = this.centre.code;
          property.name = this.centre.name;
        }
      }

      selectedProperty.property.address = this.centre.address;
      selectedProperty.property.code = this.centre.code;
      selectedProperty.property.name = this.centre.name;
      sessionStorage.setItem('properties', JSON.stringify(propertyList))
      sessionStorage.setItem('propertySelection', JSON.stringify(selectedProperty))

      this.dialogRef.close(true);
      this.loadingservice.closeBlockingLoader();
    })
  }

  addCenter(property) {
    let newSupplier = [];
    let selectedSuppliers = this.selectedWaterSuppliers.concat(this.selectedElectricitySuppliers);
    selectedSuppliers = selectedSuppliers.filter(s => {
      if(s != '') {
        return s
      }
    });

    if(selectedSuppliers.length === 0) {
      this.centre.supplier = [''];
    } else {
      for(let supplier of selectedSuppliers) {
        if(!newSupplier.includes(supplier)) {
          newSupplier.push(supplier)
        }
      }
      this.centre.supplier = newSupplier;
    }

    this.centre.code = this.propertyDetails.value.code;
    this.centre.name = this.propertyDetails.value.name;
    this.centre.address = this.propertyDetails.value.address;

    this.loadingservice.openBlockingLoader("Creating Property","primary");
    from(this.propertyService.createPropertyNode(property, this.centre.supplier)).subscribe((res:any) => {
      message_types.success.messageBody = 'Successfully added Property';
      this.userMessagesService.openMessage(message_types.success);
      const centre = {
        id: res.data.createProperty.propertyId,
        name: this.centre.name,
        code: this.centre.code,
        address: this.centre.address,
        meterCounts: {
          "totalCapturedMeters": 0,
          "totalMeters": 0,
          "totalNotCapturedMeters": 0
        },
        numberOfAreas: 0,
        numberOfUnits: 0,
        supplierIds: this.centre.supplier
      }
      this.closeDialog(centre)
      this.loadingservice.closeBlockingLoader();
    })
  }

}
