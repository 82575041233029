import { Component, Input, OnInit} from '@angular/core';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'sym-tariff-constraints',
  templateUrl: './tariff-constraints.component.html',
  styleUrls: ['./tariff-constraints.component.scss']
})
export class TariffConstraintsComponent implements OnInit {

  @Input() constraints;
  @Input() constraintCount;

  constructor() { }

  ngOnInit(): void {
  }

}
