import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sym-water-charges',
  templateUrl: './water-charges.component.html',
  styleUrls: ['./water-charges.component.scss']
})
export class WaterChargesComponent implements OnInit {

  @Input() tariffWaterCharge: any = {
    blockCharges: [],
    blockType: 'Step',
    finalBlock: 0
  };
  @Input() utilityType: string;
  @Input() levelCharges: string[] = [];
  selectedCharge: number = 0;
  variableCharges = [{
    value: 0,
    name: 'Block Charges'
  },{
    value: 1,
    name: 'Standard Charges'
  }];

  blockTypes = ['Step','Total'];
  constructor() { }

  ngOnInit(): void {
  }

  removeRange(i) {
    this.tariffWaterCharge.blockCharges.splice(i, 1);
  }

  onBlockEndChange(i) {
    if(this.tariffWaterCharge.blockCharges.length > 1) {
      this.tariffWaterCharge.blockCharges[i+1].blockStart = this.tariffWaterCharge.blockCharges[i].blockEnd + 1;
    }
  }

  onBlockStartChange(i) {
    if(i - 1 >= 0 && this.tariffWaterCharge.blockCharges[i].blockStart != 0) {
      this.tariffWaterCharge.blockCharges[i-1].blockEnd = this.tariffWaterCharge.blockCharges[i].blockStart - 1;
    }
  }

  addRangeCharge() {
    let endValue = 0
    if(this.tariffWaterCharge.blockCharges.length > 0) {
      endValue = this.tariffWaterCharge.blockCharges[this.tariffWaterCharge.blockCharges.length - 1].blockEnd + 1;
    }
    this.tariffWaterCharge.blockCharges.push({
      blockStart: endValue,
      blockEnd: 0,
      charge: 0
    })
  }

}
