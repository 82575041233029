import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  accessToken: any = localStorage.getItem("accessToken");
  email: any = JSON.parse(localStorage.getItem("email"));
  quickSightEndpoint: any = environment.api.quicksight;

  quickSightCall(): Observable<any> {
    localStorage.setItem('qs', 'true');
    return this.http.get<any>(`${this.quickSightEndpoint}/${this.email}`);
  }
}
