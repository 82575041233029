<div class="page-layout blank" fxLayout="column">
    <sym-content-card [hidden]="!(periodReadings && periodReadings.length != 0) && !(showMessage && periodReadings.length === 0)">
      <div class="table-responsive" fxLayout="column" style="margin: 10px;" *ngIf="periodReadings && periodReadings.length != 0">
        <mat-table [dataSource]="periodReadingDataSource" style="margin-top: 15px;">
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef fxFlex="13" style="color: grey;">
              Date Submitted
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="13" style="color: black;">
              <div fxLayout="column" style="padding: 5px;">
                <div *ngIf="element.reading.readingTS">
                  {{element.reading.readingTS | date:'yyyy-MM-dd'}}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="shopName">
            <mat-header-cell *matHeaderCellDef fxFlex="18" style="color: grey;">
              Tenant
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="18" style="color: black;">
              <div fxLayout="column" style="padding: 5px;">
                <div *ngIf="element.tenantNames">
                  <div *ngIf="element.tenantNames.length > 1">
                    MULTIPLE
                  </div>
                  <div *ngIf="element.tenantNames.length === 1">
                    {{element.tenantNames[0]}}
                  </div>
                </div>
                <div *ngIf="!element.tenantNames">
                  -
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tenant">
            <mat-header-cell *matHeaderCellDef fxFlex="27" style="color: grey;">
              Captured By
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="27" style="color: black;">
              <div fxLayout="column" style="padding: 5px;">
                <div>
                  {{element.reading.readingBy}}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="currentReading">
            <mat-header-cell *matHeaderCellDef fxFlex="15" style="color: grey;">
              Reading
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
              <div fxLayout="column" style="padding: 5px;">
                <div *ngIf="element.reading.readingValueList">
                  <div *ngFor="let reading of element.reading.readingValueList;let i = index">
                    <div *ngIf="reading.name === 'Max KVA'">
                     <mat-label  matTooltip="Max KVA" matTooltipPosition="right"> KWA:{{reading.value}}</mat-label>
                    </div>
                    <div *ngIf="reading.name === 'Standard'">
                      <mat-label  matTooltip="Standard" matTooltipPosition="right">  S:{{reading.value}}</mat-label>
                    </div>
                    <div *ngIf="reading.name === 'Off-Peak'">
                      <mat-label  matTooltip="Off Peak" matTooltipPosition="right">  OP:{{reading.value}}</mat-label>
                    </div>
                    <div *ngIf="reading.name === 'Peak'">
                      <mat-label  matTooltip="Peak" matTooltipPosition="right">  P:{{reading.value}}</mat-label>
                    </div>
                    <div *ngIf="reading.name === 'Reactive Energy'">
                      <mat-label  matTooltip="Reactive Energy" matTooltipPosition="right">  RE:{{reading.value}}</mat-label>
                    </div>
                  </div>
                </div>
                <div *ngIf="!element.reading.readingValueList">-</div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef fxFlex="12" style="color: grey;">
              Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="12" style="color: black;">
              <div fxLayout="column" style="padding: 5px;">
                {{element.approvalStatus}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef fxFlex="15" style="color: grey;">
              Comment
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;" matTooltip="{{element.reading.comment}}" matTooltipPosition="above">
              <div fxLayout="column" style="padding: 5px;">
                <div *ngIf="element.reading.comment ">
                  {{element.reading.comment.length > 12 ? (element.reading.comment | slice: 0:12) + '...' : element.reading.comment }}
                </div>
                <div *ngIf="!element.reading.comment ">
                  -
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="meterColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: meterColumns"></mat-row>
        </mat-table>

        

      </div>
      <div [hidden]="periodReadings.length == 0">
        <mat-paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
      </div>
      <div class="table-responsive" fxLayout="column" style="margin: 10px;" *ngIf="showMessage && periodReadings.length === 0">
        <h3>No Meter Readings found for date ranges</h3>
      </div>
    </sym-content-card>
</div>