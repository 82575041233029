import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayImageDialogComponent } from './display-image-dialog.component';
import { MaterialModule } from 'src/app/core/material/material.module';
import { BaseModule } from 'src/app/core/base/base.module';
import { DisplayImageDialogService } from './display-image-dialog.service';
import { CardDialogModule } from '../card-dialog/card-dialog.module';



@NgModule({
    declarations: [
        DisplayImageDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        BaseModule,
        CardDialogModule
    ],
    exports: [DisplayImageDialogComponent],
    providers: [DisplayImageDialogService]
})
export class DisplayImageDialogModule { }
