import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../layout.service';
import { NavService } from './nav-service';
import { animateText, onMainContentChange, onSideNavChange } from './animation';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'sa-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [animateText, onMainContentChange, onSideNavChange]
})
export class SidenavComponent implements OnInit {
  nav: any[] = [];
  public linkText = false;
  nameText = 'null';
  name = 'null';
  surname = '';
  shortHand = '';
  public sideNavState = false;
  topVal: number = 0;
  bottomVal: number = 0;
  userRole: any;
  superUser: boolean = false;
  @Input() screenType: string = 'Small'

  constructor(public ls: LayoutService, public authService: AuthService, public navService: NavService, public cdt: ChangeDetectorRef, private location: Location) {
    this.userRole = JSON.parse(localStorage.getItem('userRole'));
    if(this.userRole === undefined || this.userRole === null) {
      this.authService.setUserDetails();
      this.userRole = localStorage.getItem('userRole');
    }
    if(this.userRole.includes("ADMIN") || this.userRole === "Admin" || this.userRole === "SUPER_USER"){this.superUser = true};
    this.ls.sideNavState$.subscribe(res => {
      this.sideNavState = res;
    });
  }

  getScreenType(type) {
    return type === this.screenType;
  }

  openSideNav() {
    this.sideNavState = !this.sideNavState;

    if(this.sideNavState) {
      this.ls.sideNavState$.next(true);
    } else {
      this.ls.sideNavState$.next(false);
    }
  }

  checkRoute(route) {
    return this.location.path().includes(route);
  }

  ngOnInit() {
    this.nav = this.navService.getNavigation();
  }
}
