/**
 * Created by netba on 6/23/2023.
 */
export const message_types = {
    success: {
      messageHeader: 'Success',
      messageBody: 'Your request was completed successfully(default message)',
      messageType: 'success',
      icon: 'check_circle',
      iconColor: 'linear-gradient(#26C277, #017B40)',
      duration: 50000,
      img: '../../../assets/notifications/success.png',
      displaySupport: false,
      notify: false
    }, info: {
      messageHeader: 'Information',
      messageBody: 'Information to user(default message)',
      messageType: 'information',
      icon: 'info',
      iconColor: 'linear-gradient(180deg, #226EDF 0%, #00409F 100%)',
      duration: 50000,
      img: '../../../assets/notifications/info.png',
      displaySupport: false,
      notify: false
    }, in_progress: {
      messageHeader: 'In Progress',
      messageBody: 'Your request is being processed.',
      messageType: 'in-progress',
      icon: 'loop',
      iconColor: 'linear-gradient(180deg, #fff 0%, #fff 0.01%, #fff 100%)',
      img: '../../../assets/notifications/loop_light.png',
      duration: 50000,
      displaySupport: false,
      notify: false
    }, error: {
      messageHeader: 'Error',
      messageBody: 'We could not response due to a technical issue on our side.',
      messageType: 'error',
      icon: 'info',
      iconColor: 'linear-gradient(180deg, #D33030 0%, #940000 100%)',
      img: '../../../assets/notifications/error.png',
      duration: 50000,
      displaySupport: true,
      notify: false
    }, notification: {
      messageHeader: 'Note',
      messageBody: 'Your request is done',
      messageType: 'notification',
      icon: 'notification',
      iconColor: 'linear-gradient(180deg, #000000 0%, #9E9E9E 0.01%, #000000 100%)',
      img: '../../../assets/notifications/info.png',
      displaySupport: false,
      duration: 50000,
      notify: false
    }, access_error: {
      messageHeader: 'Not Authorized',
      messageBody: `<p>Sorry, you do not have access to this functionality.</p><p>Please contact your system administrator.</p>`,
      messageType: 'access_error',
      icon: 'info',
      iconColor: 'linear-gradient(180deg, #D33030 0%, #940000 100%)',
      img: '../../../assets/notifications/warning.png',
      displaySupport: false,
      duration: 5000000,
      notify: false
    }
  };
  