import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { from } from 'rxjs'
import { FloorplanService } from 'src/app/services/floor-plan.service';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';

@Component({
  selector: 'app-add-node',
  templateUrl: './add-node.component.html',
  styleUrls: ['./add-node.component.scss']
})
export class AddNodeComponent implements OnInit {

  node: any = {};
  title: any;
  nodeType: any;
  nodeValues: any = {};
  propertyId: any;
  areaId: string;
  selectedItem: any;
  isUpdate: boolean = false;

  createType: string;

  unitType: any[] = [
    {
      view: 'Rentable Unit',
      value: 'RENTABLE_UNIT'
    },
    {
      view: 'Common Area',
      value: 'COMMON_AREA'
    }
  ]

  propertyDetails = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    numberofAreas: new UntypedFormControl(''),
    numberOfUnits: new UntypedFormControl(''),
    totalSQM: new UntypedFormControl(''),
  });

  totalSQM: number = 0;

  areaDetails = new UntypedFormGroup({
    name: new UntypedFormControl(''),
  });

  unitDetails = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    unitType: new UntypedFormControl(''),
    code: new UntypedFormControl('')  
  });

  closeMethod = () => {
    this.closeDialog('exit')
  }

  constructor(public loadingservice: LoadingService, public dialogRef: MatDialogRef<AddNodeComponent>, @Inject(MAT_DIALOG_DATA) public data, private floorplanService: FloorplanService, public userMessagesService: UserMessagesService) { }

  ngOnInit(): void {
    
    this.title = this.data.title;
    this.isUpdate = this.data.isUpdate === undefined ? false : this.data.isUpdate;
    this.propertyId = this.data.propertyId;

    if(this.isUpdate) {
      this.selectedItem = this.data.node;
      this.createType = this.selectedItem.type;

      if(this.createType === 'Area') {
        this.areaDetails.patchValue({
          name: this.selectedItem.name
        })
      } else {
        this.totalSQM = this.selectedItem.sqm;
        this.unitDetails.patchValue({
          name: this.selectedItem.name,
          unitType: this.unitType.find(u => u.view === this.selectedItem.unitType).value,
          code: this.selectedItem.code
        })
      }
    } else {
      this.nodeType = this.data.nodeType
      this.areaId = this.data.areaId;
      this.createType = this.data.createType;
    }
  }

  closeDialog(hasUpdated) {
    this.dialogRef.close(hasUpdated);
  }

  updateNode() {
    this.loadingservice.openBlockingLoader('Updating ' + this.selectedItem.type + ' Details', 'primary');
    if(this.selectedItem.type === 'Area') {
      this.nodeValues = this.areaDetails.value;
      from(this.floorplanService.updateArea(this.selectedItem.id, this.nodeValues.name)).subscribe((res:any) => {
        message_types.success.messageBody = 'Successfully Updated Area';
        this.userMessagesService.openMessage(message_types.success);
        this.closeDialog(true);
        this.loadingservice.closeBlockingLoader();
      })
    } else if(this.selectedItem.type === 'Unit'){
      this.nodeValues = this.unitDetails.value;
      this.nodeValues.id = this.selectedItem.id;
      from(this.floorplanService.updateUnit(this.propertyId, this.nodeValues, this.totalSQM)).subscribe((res:any) => {
        message_types.success.messageBody = 'Successfully Updated Unit';
        this.userMessagesService.openMessage(message_types.success);
        this.closeDialog(true);
        this.loadingservice.closeBlockingLoader();
      })
    }
  }

  createNode() {
    if(this.createType === 'Area') {
        this.nodeValues = this.areaDetails.value;
        if(this.nodeType === 'Property') {
          this.loadingservice.openBlockingLoader("Adding Area","primary");
          from(this.floorplanService.createAreaNode(this.nodeValues, this.propertyId)).subscribe((res: any) => {
            message_types.success.messageBody = 'Successfully added Area';
            this.userMessagesService.openMessage(message_types.success);
            this.closeDialog(false);
            this.loadingservice.closeBlockingLoader();
          })
        } else {
          this.loadingservice.openBlockingLoader("Adding Area","primary");
          from(this.floorplanService.createAreaForArea(this.nodeValues, this.propertyId, this.areaId)).subscribe((res: any) => {
            message_types.success.messageBody = 'Successfully added Area';
            this.userMessagesService.openMessage(message_types.success);
            this.closeDialog(true);
            this.loadingservice.closeBlockingLoader();
          })
        }
        
    } else {
      this.nodeValues = this.unitDetails.value;
      this.loadingservice.openBlockingLoader("Adding Unit","primary");
      
      if(this.nodeType === 'Property') {
        from(this.floorplanService.createUnitNode(this.nodeValues, this.propertyId, this.totalSQM)).subscribe((res: any) => {
          message_types.success.messageBody = 'Successfully added Uni';
          this.userMessagesService.openMessage(message_types.success);
          this.closeDialog(false);
          this.loadingservice.closeBlockingLoader();
        })
      } else {
        from(this.floorplanService.createUnitNodeForArea(this.nodeValues, this.propertyId, this.areaId, this.totalSQM)).subscribe((res: any) => {
          message_types.success.messageBody = 'Successfully added Uni';
          this.userMessagesService.openMessage(message_types.success);
          this.closeDialog(true);
          this.loadingservice.closeBlockingLoader();
        })
      }
    }
  }

}
