import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from 'src/app/layout/sidenav/nav-service';

@Component({
  selector: 'sym-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  constructor(private navService: NavService, public router: Router, private route: ActivatedRoute) { }
  @Input() breadcrumbText: string;
  @Input() routerNav: any;
  routerData: any;
  navItem: any;
  selectedTariffDate = JSON.parse(localStorage.getItem('selectedTariffDate'));
  breadCrumbs: any = [];
  navChildren: any = [
    {
      link: '/tariff-detail',
      name: 'Tariff Detail' + (this.selectedTariffDate ? ' - ' + this.selectedTariffDate : '')
    },
    {
      link: '/tenants',
      name: 'Centre Details'
    },
    {
      link: '/utility-suppliers',
      name: 'Utility Suppliers'
    },
    {
      link: '/tenant-detail',
      name: 'Tenant Details'
    },
    {
      link: '/review-tenant-changes',
      name: 'Review Tenant Changes'
    },
    {
      link: '/quality-control',
      name: 'Reading Details'
    },
    {
      link: '/notifications',
      name: 'Notifications'
    },
    {
      link: '/bulk-meters',
      name: 'Bulk Meters'
    }
  ]

  ngOnInit(): void {
    this.navItem = this.navService.getNavItems().find(n => n.link.includes(this.router.url));
    this.routerData = this.router.config[0].children.find(c => this.router.url.includes(c.path) && c.path != '').data;
    if(this.navItem && this.navItem.link) {
      localStorage.removeItem(this.navItem.link);
      localStorage.setItem(this.navItem.link, this.navItem.link);
      this.breadCrumbs[0] = this.navItem.link;
    } else {
      if(this.routerData.breadcrumbSecond && !localStorage.getItem(this.routerData.breadcrumbStart).includes(this.router.url)) {
        localStorage.removeItem(this.routerData.breadcrumbStart)
        let navText: string = this.routerNav[0] + ';';
        for(let name of Object.keys(this.routerNav[1])) {
          if(name === 'sid' && !navText.includes('sid')) {
            navText += name + '=' + this.routerNav[1][name].join(',')
          } else {
            navText += name + '=' + this.routerNav[1][name] + ';'
          }
        }
        navText = this.addChanges(navText)
        this.breadCrumbs[0] = this.routerData.breadcrumbStart;
        this.breadCrumbs[1] = navText;
        this.breadCrumbs[1].replaceAll(' ', '%20')
        this.breadCrumbs[2] = this.router.url;
        localStorage.setItem(this.routerData.breadcrumbStart, this.breadCrumbs.join('|'));
      } else if(localStorage.getItem(this.routerData.breadcrumbStart) && localStorage.getItem(this.routerData.breadcrumbStart).includes(this.router.url)) {
        this.breadCrumbs = localStorage.getItem(this.routerData.breadcrumbStart).split('|');
      } else {
        localStorage.setItem(this.routerData.breadcrumbStart, JSON.stringify(this.routerData.breadcrumbStart));
        this.breadCrumbs = JSON.parse(localStorage.getItem(this.routerData.breadcrumbStart)).split('|');
      }
      this.breadCrumbs = this.breadCrumbs.filter(b => b != null && b != 'null');
    }
  }

  addChanges(text) {
    text = text.replaceAll(' ', '%20');
    text = text.replaceAll('(', '%28');
    text = text.replaceAll(')', '%29');
    return text
  }

  checkNavName(breadcrumb) {
    let navItem = this.navService.getNavItems().find(n => n.link.includes(breadcrumb));

    if(navItem && navItem.name) {
      return navItem.name;
    } else {
      let name = this.navChildren.find(n => breadcrumb.includes(n.link)).name;
      return this.addToName(name, breadcrumb);
    }
  }

  addToName(name, breadcrumb) {
    if(name === 'Centre Details') {
      return this.removeOtherElements(breadcrumb, 'pName=');
    } else if(this.breadcrumbText !== 'T') {
      return this.breadcrumbText
    }

    return name;
  }

  removeOtherElements(urlText, findText) {
    urlText = urlText.substring(urlText.indexOf(findText));
    urlText = urlText.slice(findText.length, urlText.indexOf(';'));
    urlText = urlText.replaceAll('%20', ' ');
    urlText = urlText.replaceAll('%28', '(');
    urlText = urlText.replaceAll('%29', ')');
    return urlText;
  }

  goToUrl(url) {
    if(url === '/tariff-detail') {
      let tariffDetailParam = JSON.parse(localStorage.getItem('tariffDetailRoute'));
      this.router.navigate(['tariff-detail', tariffDetailParam]);
      localStorage.removeItem('tariffDetailRoute');
    } else {
      this.router.navigateByUrl(url);
    }
  }

}
