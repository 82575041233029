import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadingDownloadDialogComponent } from './reading-download-dialog.component';
import { BaseModule } from 'src/app/core/base/base.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/core/material/material.module';
import { InfoCardModule } from '../info-card/info-card.module';
import { SaCardModule } from '../sa-card/sa-card.module';
import { MatIconModule } from '@angular/material/icon';
import { CardDialogModule } from 'src/app/shared/components/card-dialog/card-dialog.module';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
    declarations: [
        ReadingDownloadDialogComponent
    ],
    imports: [
        MaterialModule,
        CommonModule,
        BaseModule,
        FlexLayoutModule,
        FlexModule,
        InfoCardModule,
        SaCardModule,
        MatDividerModule,
        CardDialogModule
    ],
    exports: [
        ReadingDownloadDialogComponent
    ]
})
export class ReadingDownloadDialogModule { }
