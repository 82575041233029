<sym-card-dialog [title]="title" [closeMethod]="closeMethod">
  <div class="white-bg m-8" id="addCard">
    <div fxLayout="row" style="padding: 10px">
      <mat-form-field *ngIf="suppliers" appearance="outline" style="width: 100%;padding-right: 1em; margin-bottom: -1.25em">
        <mat-label>Supplier -optional</mat-label>
        <mat-select 
             [(ngModel)]="supplierId" placeholder="Supplier" multiple>
          <mat-option *ngFor="let supplier of suppliers" [value]="supplier.id">
            {{supplier.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field *ngIf="importMeters" appearance="outline" style="width: 50%;padding-right: 1em; margin-bottom: -1.25em">
        <mat-label>Choose Import</mat-label>
        <mat-select 
             [(ngModel)]="uploadType" placeholder="Import">
          <mat-option *ngFor="let meter of meterUploads" [value]="meter.value">
            {{meter.id}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div style="margin: 32px 0;" *ngIf="uploader && uploader.queue.length <= 0" fxLayoutAlign="start center">
      <label class="uploadButton" matTooltip="Select files from computer" mat-mini-fab for="files"
      (change)="addFile($event)">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon aria-label="Example icon-button with a heart icon">note_add</mat-icon><span style="color: white;">Upload File</span>
        </div>
      </label>
      <input id="files" type="file" (change)="addFile($event)" accept=".csv" ng2FileSelect [uploader]="uploader" style="display: none;">  
    </div>
    
    <div class="p-10" fxLayout="row">
      <div fxFlex="100" fxLayout="row"
        class="my-drop-zone m-12" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayout="column" *ngIf="uploader && uploader.queue.length > 0" style="margin: 32px">
          <mat-progress-bar *ngIf="uploader && uploader.progress > 0" color="primary" mode="determinate"
            [value]="uploader.progress">
          </mat-progress-bar>
          <table>
            <thead>
              <tr>
                <th style="width:5%"></th>
                <th style="width:40%">Name</th>
                <th style="width:35%">Size</th>
                <th style="width:10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of uploader.queue" style="width:100%">
                <td style="width:5%" class="text-center">
                  <span *ngIf="item.isSuccess">
                    <mat-icon>done</mat-icon>
                  </span>
                  <span *ngIf="item.isCancel">
                    <mat-icon>cancel</mat-icon>
                  </span>
                  <span *ngIf="item.isError">
                    <mat-icon>error</mat-icon>
                  </span>
                </td>
                <td style="width:40%"><strong>{{ item?.name }}</strong></td>
                <td style="width:25%" nowrap>{{ item?.size/1024/1024 | number:'1.0-2' }} MB</td>

                <td style="width:10%" nowrap>
                  <button matTooltip="Cancel upload" mat-icon-button type="button" (click)="item.cancel()"
                    *ngIf="item.isUploading">
                    <mat-icon>cancel</mat-icon>
                  </button>
                  <button matTooltip="Delete the file" mat-icon-button type="button" class="btn btn-danger btn-xs"
                    (click)="item.remove()" *ngIf="!item.isSuccess">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
    </div>
    <mat-divider style="margin: 34px 12px;"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="end center" class="p-12">
      <button matTooltip="Cancel" [disabled]="!uploader || uploader.queue.length <= 0" mat-raised-button type="button" style="margin-right: 8px;" (click)="closeDialog()">
        Cancel
     </button>
      <button *ngIf="importMeters && uploadType != 'UPDATE_TENANCY_SCHEDULE'" [disabled]="(uploader && uploader.queue.length <= 0) || uploadType === undefined" matTooltip="Upload" mat-raised-button type="button" (click)="processFile()">
         Upload
      </button>
      <button *ngIf="!importMeters && uploadType != 'UPDATE_TENANCY_SCHEDULE'" [disabled]="(uploader && uploader.queue.length <= 0) || supplierId === undefined" matTooltip="Upload" mat-raised-button type="button" (click)="processFile()"> Upload
      </button>
      <button *ngIf="uploadType === 'UPDATE_TENANCY_SCHEDULE'" [disabled]="(uploader && uploader.queue.length <= 0)" matTooltip="Upload" mat-raised-button type="button" (click)="processFile()">
         Upload
      </button>
      <button matTooltip="Cancel upload" mat-raised-button type="button" color="warn" (click)="uploader.cancelAll()"
        *ngIf="uploader && uploader.isUploading">
        <mat-icon matPreFix>cancel</mat-icon> Cancel
      </button>
    </div>
  </div>
</sym-card-dialog>