import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { message_types } from '../../micro-components/user-messages/message_types';
import { UserMessagesService } from '../../micro-components/user-messages/user-messages.service';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'dd/mm/yyyy',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-update-meter-node-dialog',
  templateUrl: './update-meter-node-dialog.component.html',
  styleUrls: ['./update-meter-node-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class UpdateMeterNodeDialogComponent implements OnInit {

  title;
  meter;
  propertyId;
  tariffList;
  checkExists: boolean = false;
  meterNoExists: boolean = false;
  tariff: any;
  tariffNames: any[] = [];
  displayCommission2 = false;
  displayCommission3 = false;
  displayCommission4 = false;
  meterId: any;
  meterNo: any;
  waterList: any[] = [];
  elecList: any[] = [];
  timeout: any = null;
  disableCaptureType: boolean = false;
  firstTimeDisable: boolean = false;
  disableTOU: boolean = false;
  oldMeterNo: string = '';

  meterUtility = [{
    name: 'ELEC_1P',
    image: 1
  },
  {
    name : 'ELEC_1P_KVA',
    image: 2
  },
  {
    name: 'ELEC_3P_KVA',
    image: 2
  },
  {
    name: 'ELEC_3P_1',
    image: 1
  },
  {
    name: 'ELEC_3P_3',
    image: 3
  },
  {
    name: 'ELEC_3P_TOU',
    image: 4
  },
  {
    name: 'WATER',
    image: 1
  }];
  closeMethod = () => {
    this.closeDialog(false)
  }

  buttonProperties = {
    text: 'Update',
    type: 'primary',
    method: () => {
      this.checkMeterNo()
    }
  }
  utilityType: any;
  readingMethods = [{name: 'Manual', value: 'MANUAL'}, {name: 'Smart', value: 'SMART'}];

  captureType = [{name: 'Online', value: 'ONLINE'}, {name: 'Manual', value: 'MANUAL'}, {name: 'Online Manual', value: 'ONLINE_MANUAL'}]

  meterDetails = new UntypedFormGroup({
    amps: new UntypedFormControl(1, Validators.min(1)),
    utilityType: new UntypedFormControl({value: '', disabled: true }),
    meterNo: new UntypedFormControl(''),
    commissionDate: new UntypedFormControl(''),
    noOfPhotos: new UntypedFormControl({
      value: '',
      disabled: true
    }),
    // commissionReading: new UntypedFormControl('0'),
    // commissionReading2: new UntypedFormControl(''),
    // commissionReading3: new UntypedFormControl(''),
    // commissionReading4: new UntypedFormControl(''),
    // meterType: new UntypedFormControl(''),
    tariff: new UntypedFormControl(''),
    isManual: new UntypedFormControl(''),
    captureType: new UntypedFormControl('')
  });
  showWater: boolean = false;

  constructor(
    private userMessagesService: UserMessagesService,
    public loadingservice: LoadingService,
    public dialogRef: MatDialogRef<UpdateMeterNodeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private meterManagementService: MeterManagementService
  ) { }

  ngOnInit() {
    this.title = this.data.title;
    this.meter = this.data.meter;
    this.utilityType = this.meterUtility.find(m => m.name === this.meter.utilityType)
    this.oldMeterNo = this.meter.meterNo;
    this.meterNo = this.meter.meterNo;
    this.propertyId = this.data.propertyId;
    this.tariffList = JSON.parse(sessionStorage.getItem('tariffs'));
    if(this.tariffList.length != 0) {
      this.waterList = this.sortList([...this.tariffList], true);
      this.elecList = this.sortList([...this.tariffList], false);
    }

    this.tariffNames = this.tariffList.map(x => {
      return x.name;
    })
    this.tariff = this.meter.tariff;
    this.meterId = this.meter.id;
    this.meterDetails.setValue({
      utilityType: this.meterUtility.find(m => m.name === this.meter.utilityType),
      amps: this.meter.amps && this.utilityType != 'WATER' ? this.meter.amps : 1,
      meterNo: this.meter.meterNo,
      commissionDate: this.meter.commissionDate,
      noOfPhotos: this.meterUtility.find(m => m.name === this.meter.utilityType).image,
      tariff: this.meter.tariffIds ? (this.utilityType.name !== 'WATER'  ? this.meter.tariffIds[0] : this.meter.tariffIds) : null,
      // commissionReading: this.meter.commissionReading ? this.meter.commissionReading : '0',
      // commissionReading2: this.meter.commissionReading2 ? this.meter.commissionReading2 : '',
      // commissionReading3: this.meter.commissionReading3 ? this.meter.commissionReading3 : '',
      // commissionReading4: this.meter.commissionReading4 ? this.meter.commissionReading4 : '',
      // meterType: this.meter.meterType ? this.meter.meterType : '',
      captureType: this.meter.captureType ? this.meter.captureType : '',
      isManual: this.meter.isManual ? 'MANUAL' : 'SMART',
    })
    
    this.meterChange();
  }

  closeDialog(meterCreated) {
    this.dialogRef.close(meterCreated);
  }

  meterChange() {   
    const meterUtility = this.utilityType;
    if(this.tariffList.length != 0) {
      this.checkUtilityType();
    }

    if(this.meterDetails.get('utilityType').value.name === 'WATER') {
      this.showWater = true;
    } else {
      this.showWater = false;
    }

    this.meterDetails.patchValue({
      noOfPhotos: meterUtility.image
    })

    this.checkCaptureType();

    if(!this.firstTimeDisable && this.utilityType != undefined) {
      this.utilityType = this.utilityType;
      this.meterDetails.controls['utilityType'].disable();
      this.utilityType = this.utilityType
      this.firstTimeDisable = true;

      if(this.utilityType.name.includes('TOU')) {
        this.disableTOU = true;
      }
    }
  }

  checkCaptureType() {
    const utilityType = this.utilityType;
    const readingMethod = this.meterDetails.value.isManual;

    if(readingMethod.toLowerCase() === 'manual') {
      this.meterDetails.controls['captureType'].disable();
      this.meterDetails.controls['captureType'].setValue('MANUAL');
    } else {
      this.meterDetails.controls['captureType'].enable();
    }
    // else if(this.disableTOU || (readingMethod.toLowerCase() === 'smart' && (utilityType != undefined && utilityType.name === 'ELEC_3P_TOU'))) {
    //   this.meterDetails.controls['captureType'].disable();
    //   this.meterDetails.controls['captureType'].setValue('ONLINE');
    // } 
    

  }

  sortList(tariffList, isWater) {
    let tariffType = [], newList = [];

    if(isWater) {
      tariffType = ['Water', 'Sanitation'];
    } else {
      tariffType = ['Electricity']
    }

    for(let supplier of tariffList) {
      if(supplier.tariffList && supplier.tariffList.length > 0) {
        newList.push(supplier.tariffList.filter(tariff => tariff.utilityType && tariffType.includes(tariff.utilityType) && tariff.utilitySupplierId === supplier.supplierId));
      } else {
        newList.push([]);
      }
    }

    return newList;
  }

  checkUtilityType() {
    let meterUtility = this.utilityType.name;
    if(meterUtility === 'WATER') {
      this.meterDetails.setControl('amps', new UntypedFormControl(0));
      for(let i = 0; i < this.tariffList.length; i++) {
        this.tariffList[i].tariffList = this.waterList[i];
      }
    } else {
      const amps = this.meterDetails.value.amps;
      this.meterDetails.setControl('amps', new UntypedFormControl(amps, Validators.min(1)));
      for(let i = 0; i < this.tariffList.length; i++) {
        this.tariffList[i].tariffList = this.elecList[i];
      }
    }
  }

  checkMeterNo() {
    const meterId = this.meter.id;
    this.meter = this.meterDetails.value;
    this.meter.captureType = this.meterDetails.controls['captureType'].value;
    this.meter.utilityType = this.utilityType;
    this.meter.id = this.meterId
    if(this.meter.isManual === "MANUAL") {
      this.meter.isManual = true;
    }else {
      this.meter.isManual = false;
    }

    if(this.utilityType.name !== 'WATER') {
      this.meter.tariff = [this.meter.tariff];
    }

    if(this.meter.utilityType && this.meter.utilityType.name) {
      this.meter.meterReadingType = this.meter.utilityType.name.includes('TOU') ? 'UTILIZATION' : 'READING';
    } else {
      this.meter.meterReadingType = this.meter.utilityType.includes('TOU') ? 'UTILIZATION' : 'READING';
    }
    

    if(this.oldMeterNo != this.meter.meterNo) {
      this.loadingservice.openBlockingLoader("Updating Meter Number","primary");
      from(this.meterManagementService.updateMeterNumber(this.meter, this.propertyId,  this.oldMeterNo)).subscribe((res: any) => {
        if(res.data.updateMeterNode.isSuccessful) {
          this.loadingservice.closeBlockingLoader();
          this.closeDialog(this.meter);
          message_types.success.messageBody = 'Meter has been successfully updated';
          this.userMessagesService.openMessage(message_types.success);
        } else {
          this.loadingservice.closeBlockingLoader();
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to update meter';
          this.userMessagesService.openMessage(message_types.error);
        }
      },(error) => {
        this.loadingservice.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to update meter';
        this.userMessagesService.openMessage(message_types.error);
      })
    } else {
      this.updateMeter();
    }
  }

  checkIfNumberExists(event: any) {
    if(this.meter.meterNo != this.meterDetails.value.meterNo) {
      clearTimeout(this.timeout);
      this.meterNoExists = true;
      this.checkExists = true;
      this.timeout = setTimeout(() => {
        from(this.meterManagementService.checkIfMeterNumberExists(this.propertyId, this.utilityType.name, this.meterDetails.value.meterNo)).subscribe((res:any) => {
          this.meterNoExists = res.data.checkIfMeterNumberExists.meterNoExists;
          this.checkExists = false;
        })
      }, 1000)
    } else {
      this.meterNoExists = false;
      this.checkExists = false;
    }
  }

  updateMeter() {
    console.log(this.meter)
    if(this.meter.utilityType && this.meter.utilityType.name) {
      this.meter.meterReadingType = this.meter.utilityType.name.includes('TOU') ? 'UTILIZATION' : 'READING';
    } else {
      this.meter.meterReadingType = this.meter.utilityType.includes('TOU') ? 'UTILIZATION' : 'READING';
    }
    
    this.loadingservice.openBlockingLoader("Updating Meter","primary");
    from(this.meterManagementService.updateMeterNode(this.meter, this.propertyId)).subscribe((res:any) => {
      this.closeDialog(this.meter);
      this.loadingservice.closeBlockingLoader();
      message_types.success.messageBody = 'Meter has been successfully updated';
      this.userMessagesService.openMessage(message_types.success);
    },(error) => {
      this.loadingservice.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to update meter';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

}
