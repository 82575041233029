<mat-toolbar #header style="border-bottom: 4px solid white;padding-top: 5px;border-radius: 0 0 15px 15px;box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.1);" [color]="color" saHeader [fixed]="fixed"
    [headerHeight]='headerHeight' fxLayoutAlign="space-between center">
    <div class="logo-area" fxLayout="row" fxLayoutAlign="space-between center" >
        <img class="logo" [src]="logo" alt="">
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px">
        <div fxFlex="100" fxLayoutGap="8px">
          <div fxFlex>
            <mat-form-field *ngIf="!hideSelectors" appearance="outline" style="width: 100%;font-size: small; margin-bottom: -1.25em;">
              <mat-label>Select a Property</mat-label>
              <mat-select [formControl]="selectedProperty" disableOptionCentering  (selectionChange)="propertyChange($event, true)" style="color: black;" required>
                <mat-option *ngFor="let property of propertySelector" style="color: black;" [value]="property">
                  {{property.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
            <div fxFlex="20" >
              <mat-form-field *ngIf="!hideSelectors" appearance="outline" style="width: 100%;font-size: small;margin-bottom: -1.25em;">
                  <mat-label>Year</mat-label>
                  <mat-select 
                       [(ngModel)]="selectedYear" 
                       (ngModelChange)="propertyChange(selectedProperty, true)" placeholder="Year" [disabled]="disableDates">
                    <mat-option *ngFor="let year of yearValueList" [value]="year">
                      {{year}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="20">
              <mat-form-field *ngIf="!hideSelectors" appearance="outline" style="width: 100%;font-size: small;margin-bottom: -1.25em;">
                <mat-label>Month</mat-label>
                <mat-select 
                     [(ngModel)]="selectedMonth" disableOptionCentering 
                     (ngModelChange)="propertyChange(selectedProperty, true)" placeholder="Month" [disabled]="disableDates">
                  <mat-option *ngFor="let month of monthsValueList" [value]="month">
                    {{month.month}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" style="padding-right: 24px;">
          <sym-user-profile></sym-user-profile>
          <button mat-mini-fab style="background-color: white;margin-left: .5em;" (click)="goToLogin()">
          <mat-icon>logout</mat-icon>
          </button>
        </div>
    </div>
</mat-toolbar>

<sym-user-messages class="container" [messageInfo]="userMessagesService.message"></sym-user-messages>