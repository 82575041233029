import { NestedTreeControl } from '@angular/cdk/tree';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { FloorplanService } from 'src/app/services/floor-plan.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import { AddPropertyComponent } from '../property-management/add-property/add-property.component';
import { AddNodeComponent } from './add-node/add-node.component';
import { AreaTablesComponent } from './area-tables/area-tables.component';
import { UnitLinkComponent } from './unit-link/unit-link.component';
import { AddMeterNodeDialogComponent } from 'src/app/shared/components/add-meter-node-dialog/add-meter-node-dialog.component';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';

interface PropertyNode {
  name: string;
  id: string;
  type: string;
  path?: string[];
  startingPoint?: number;
  numberOfAreas?: number;
  numberOfUnits?: number;
  selected?: boolean;
  unitType?: string;
  occupied?: string;
  sqm?: number;
  children?: PropertyNode[];
  parentAreaId?: string;
  code?: string;
}

interface DropInfo {
  targetId: string;
  action?: string;
}

@Component({
  selector: 'sym-property-structure',
  templateUrl: './property-structure.component.html',
  styleUrls: ['./property-structure.component.scss']
})
export class PropertyStructureComponent implements OnInit {

  @Input() property;
  mousePosition = {
    x: 0,
    y: 0
  };
  @ViewChild('areaTables') areaTables: AreaTablesComponent;
  propertyId: any;
  propertyList: PropertyNode[] = [];
  showScroll;
  unitList: any[] = [];
  tariffList: any[] = [];
  tariffSetList: any[] = [];
  tariffNameList: any[] = [];
  propertyDS = new MatTableDataSource();
  propertyColumns: string[] = ['name','address', 'numberOfAreas', 'numberOfUnits'];
  supplierDetails: any;
  updatedNode: boolean = false;
  showDeleteDialog: boolean = false;

  primaryBtn: any = {
    text: 'Add Property',
    method: () => {
      this.addNode('Property')
    }
  }

  treeControl = new NestedTreeControl<PropertyNode>(node => node.children)

  dataSource = new MatTreeNestedDataSource<PropertyNode>();
  areaDS = new MatTableDataSource();
  unitDS = new MatTableDataSource();
  selectedNode: any = {};
  selectedMeterNode: any = {};
  unit: any = {};
  hasError: boolean = false;
  count: number = 0;
  meterList: any[] = [];
  allocationList: any[] = [];
  unitLinkList: any[] = [];
  selectedNodes: any[] = [];
  linkDS = new MatTableDataSource();
  isDragging: boolean = false;
  isSelecting: boolean = false;

  dropTargetIds = [];
  nodeLookup = {};
  areaIdList: any[] = []
  dropActionTodo: DropInfo = null;
  movingNodeType: any;
  dragList: any[] = [];
  parentNode: any;
  deletingNode: boolean = false;

  menuTopLeftPosition =  {x: 0, y: 0}
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  constructor(private tariffService: TariffsService, public dialog: MatDialog, private floorPlanService: FloorplanService, private loadingService: LoadingService, private meterManagementService: MeterManagementService, private userMessagesService: UserMessagesService, @Inject(DOCUMENT) private document: Document) {
  }

  hasChild = (_: number, node: PropertyNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    const properties = JSON.parse(sessionStorage.getItem('properties'));
    const selectedProperty = JSON.parse(sessionStorage.getItem('propertySelection'));
    if(properties && !selectedProperty) {
      this.setPropertyTable(properties)
    }
  }

  setPropertyTable(prop) {
    this.propertyDS = new MatTableDataSource(prop);
  }

  onMouseDown($event: MouseEvent) {
    this.mousePosition.x = $event.screenX;
    this.mousePosition.y = $event.screenY;
  }

  onRightClick(event: MouseEvent, item) {
    this.selectedMeterNode = item.content;
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX;
    this.menuTopLeftPosition.y = event.clientY;

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = {item: item}

    // we open the menu
    if(!this.selectedMeterNode.type.includes('Meter')) {
      this.matMenuTrigger.openMenu();
    }

  }

  closeLoader(error?) {
    if(this.count === 3 && !this.hasError) {
      this.loadingService.closeBlockingLoader();
    } else if(this.hasError){
      this.loadingService.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to retrieve property list';
      this.userMessagesService.openMessage(message_types.error);
    }
  }

  getNodeDetails() {
    from(this.meterManagementService.getNodeByProperty(this.property.id)).subscribe((res:any) => {
      const nodeList: any[] = res.data.getNodesPerProperty.utilityNodeList;
      this.meterList = [];
      this.allocationList = [];
      
      if(nodeList.length != 0) {
        for(let node of nodeList) {
          if(node.nodeType === 'METER') {
            if(node.meterNode.status != 'DECOMMISSIONED') {
              node.meterNode.id = node.id;
              node.meterNode.linkStatus = node.linkStatus;
              node.meterNode.parentUtilityNodeId = node.parentUtilityNodeId;
              this.meterList.push(node.meterNode);
            }
          } else {
            node.allocationNode.id = node.id;
            this.allocationList.push(node.allocationNode);
          }
        }
      }
      this.count++;
      const unlinkedMeters = this.meterList.filter(m => m.linkStatus === null || (m.linkStatus != null && m.linkStatus === 'UNLINKED'));
  
      this.areaTables.setupUnlinkedMetersDatasource(unlinkedMeters);
      this.selectedNode = {type: 'Property'}
      this.closeLoader();
    }, (error) => {
      if(!this.hasError) {
        this.hasError = true;
        this.closeLoader(error);
      }
    })
    
  }

  getPropertyDetails(property) {
    this.dataSource.data = null;
    this.property = property;
    this.supplierDetails = property.supplierDetails;
    this.count = 0;
    this.getNodeDetails();
      
    this.loadingService.openBlockingLoader("Retrieving Property Details","primary");
    from(this.floorPlanService.getUnitsAndAreasForProperty(this.property.id)).subscribe((res: any) => {
      this.propertyId = this.property.id;
      this.propertyList = [];
      const areaList = res.data.getTopLevelAreaListByPropertyId.areaList;
      const unitList = res.data.getTopLevelUnitListPerProperty.unitList;

      this.unitList = res.data.getUnitListPerProperty.unitList;
      let count = 0;
      let countList = 0

      for(let area of areaList) {
          this.propertyList.push({
            id: area.id,
            name: area.name,
            children: [],
            path: [],
            sqm: area.totalSQM,
            startingPoint: count++,
            numberOfAreas: area.numberOfAreas,
            numberOfUnits: area.numberOfUnits,
            type: 'Area',
            parentAreaId: area.parentAreaId
          })
          this.areaIdList.push(area.id);
          countList++;
        }

        for(let unit of unitList) {
          this.propertyList.push({
            id: unit.id,
            path: [],
            sqm: unit.sqm,
            occupied: unit.occupied,
            unitType: unit.unitType === 'COMMON_AREA' ? 'Common Area' : 'Rentable Unit',
            startingPoint: count++,
            name: unit.unitNo,
            code: unit.code,
            children: [],
            type: 'Unit'
          })
          countList++;
        }
        this.prepareDragDrop(this.propertyList)
        
        this.dataSource.data = this.propertyList;
        this.loadingService.closeBlockingLoader();
      }, (error) => {
        if(!this.hasError) {
          this.hasError = true;
          this.closeLoader(error);
        }
      })
  }

  openNode($event, node: any) {
    if (this.mousePosition.x === $event.screenX && this.mousePosition.y === $event.screenY || this.updatedNode || this.deletingNode) {
      if(node.type != 'Property') {
        let al = [];
        let ul = [];
        
        if(node.type != 'Unit') {
          al = node.children.filter(i => i.type === 'Area');
          ul = node.children.filter(i => i.type === 'Unit');
        }

        const unitList = [];
        const areaList = [];
        al.forEach(val => areaList.push(Object.assign({}, val)));
        ul.forEach(val => unitList.push(Object.assign({}, val)));

        this.areaTables.resetDataSources(unitList, areaList);

        if(this.selectedNode.type === 'Unit') {
          this.loadingService.openBlockingLoader('Retrieving Unit Details', 'primary');
          from(this.meterManagementService.getLinksForUnit(this.selectedNode.id)).subscribe((res:any) => {
            let unitPath = [];
            let unitLinkList = [...res.data.getLinksForUnit.nodeUnitLinkList];
            for(let i = 0; i < unitLinkList.length; i++) {
              if(unitLinkList[i].utilityNode.meterNode != null) {
                unitPath.push(unitLinkList[i].utilityNode.id);
                let meterNode = unitLinkList[i].utilityNode.meterNode;
                meterNode.id = meterNode.utilityNodeId;
                unitLinkList[i] = meterNode;
              }
            }

            this.linkDS = new MatTableDataSource(unitLinkList);
            this.dataSource.data = null;
            this.dataSource.data = this.propertyList;
            this.loadingService.closeBlockingLoader();

            if(this.showDeleteDialog && unitLinkList.length === 0) {
              const dialogRef = this.dialog.open(DeleteUnitDialog, {
                width: '400px'
              });

              dialogRef.afterClosed().subscribe((res: any) => {
                if(res) {
                  this.getParentNodeId(this.selectedNode.id, this.propertyList, 'main');
                  this.deletingNode = true;

                  this.loadingService.openBlockingLoader('Deleting Unit', 'primary');
                  from(this.floorPlanService.deleteUnitNode(this.selectedNode.id, this.selectedNode.code)).subscribe((res:any) => {
                    if(res.data.deleteUnit.isSuccessful) {
                      this.loadingService.closeBlockingLoader();
                      message_types.success.messageBody = 'Unit was successfully Deleted';
                      this.userMessagesService.openMessage(message_types.success);
                      if(this.parentNode) {
                        this.getNodesForArea({}, this.parentNode);
                      } else {
                        this.openNode({}, {type: 'Property'});
                      }
                    }
                  }, (error) => {
                    this.loadingService.closeBlockingLoader();
                    message_types.error.displaySupport = true;
                    message_types.error.messageBody = 'Failed to delete Unit';
                    this.userMessagesService.openMessage(message_types.error);
                  });
                }
              });
            } else if(this.showDeleteDialog && unitLinkList.length > 0) {
              message_types.access_error.messageBody = 'Cannot delete unit. Please Unlink all Meters from Unit';
              message_types.access_error.messageHeader = 'Caution';
              this.userMessagesService.openMessage(message_types.access_error);
            }
            this.showDeleteDialog = false;
          }, (error) => {
            this.loadingService.closeBlockingLoader();
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to Retrieve Unit Details';
            this.userMessagesService.openMessage(message_types.error);
          })
        }
      } else {
        this.refreshUnlinkedTable();
      }
    }
    this.deletingNode = false;
    this.updatedNode = false;
  }

  refreshUnlinkedTable() {
    this.count = 2;
    this.loadingService.openBlockingLoader('Retrieving Unlinked Meters');
    this.getNodeDetails();
    this.selectedNode = {type: 'Property'}
  }

  creatNodeUnitLink() {
    this.loadingService.openBlockingLoader('Retrieving Additional Details for Unit', 'primary')
    from(this.meterManagementService.getMeterIdsForUnit(this.selectedMeterNode.id)).subscribe((res:any) => {
      this.loadingService.closeBlockingLoader();
      const nodeList: any[] = res.data.getLinksForUnit.nodeUnitLinkList;
      let linkedList = []
      for(let meter of nodeList) {
        if(meter.utilityNode.nodeType === 'METER') {
          linkedList.push({
            meterNo: meter.utilityNode.meterNode.meterNo,
            id: meter.utilityNode.id
          })
        }
      }

      let meterList = [];
      if(linkedList.length > 0) {
        for(let meter of this.meterList) {
          if(linkedList.filter(m => meter.id === m.id).length === 0 && meter.status != 'DECOMMISSIONED') {
            meterList.push(meter);
          }
        }
      } else {
        meterList = [... this.meterList]
      }

      const dialogRef = this.dialog.open(UnitLinkComponent, {
        panelClass: 'custom-user-dialog-container',
        height: '64%',
        width: '60%',
        data: {
          node: this.selectedMeterNode,
          meterList: meterList,
          allocationList: this.allocationList,
          propertyId: this.propertyId
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe((res:any) => {
        if(res) {
          this.updatedNode = true;
          this.getNodesForArea({}, this.selectedMeterNode);
        }
      });

    }, (error) => {

    })
  }

  dragMoved(event) {
    this.isDragging = true;
    let e = this.document.elementFromPoint(event.pointerPosition.x,event.pointerPosition.y);
    
    if (!e) {
        this.clearDragInfo();
        return;
    }
    let container = e.classList.contains("node-item") ? e : e.closest(".node-item");
    if (!container) {
      this.clearDragInfo();
      return;
    }
    this.dropActionTodo = {
        targetId: container.getAttribute("data-id")
    };
    const targetRect = container.getBoundingClientRect();
    const oneThird = targetRect.height / 3;

    if (event.pointerPosition.y - targetRect.top < oneThird) {
        // before
        this.dropActionTodo["action"] = "before";
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
        // after
        this.dropActionTodo["action"] = "after";
    } else {
        // inside
        this.dropActionTodo["action"] = "inside";
    }
    this.showDragInfo();
  }


  drop(event) {
    if (!this.dropActionTodo) return;

    const draggedItemId = event.item.data.id;
    const parentItemId = event.previousContainer.id;
    const targetListId = this.getParentNodeId(this.dropActionTodo.targetId, this.propertyList, 'main');
    this.isDragging = false;

    
    if(this.movingNodeType === 'Area' && event.item.data.type === 'Unit' && this.dropActionTodo['action'] === 'inside') {
      const oldItemContainer = parentItemId != 'main' ? this.nodeLookup[parentItemId].children : this.propertyList;

      let i = oldItemContainer.findIndex(c => c.id === draggedItemId);
      oldItemContainer.splice(i, 1);

      const moveId = targetListId === 'main' || (this.areaIdList.includes(this.dropActionTodo.targetId) && this.dropActionTodo.action === 'inside') ? this.dropActionTodo.targetId : targetListId;

      if(event.item.data.type === 'Unit') {
        if(this.dragList.length === 0) {
          this.dragList.push({
            areaId: event.item.data.areaId === undefined ? null : event.item.data.areaId,
            propertyId: this.propertyId,
            unitId: event.item.data.id,
            unitNo: event.item.data.name
          })
        }

        this.loadingService.openBlockingLoader('Dragging Units to Area', 'primary');
        from(this.floorPlanService.moveUnitsToArea(this.propertyId, moveId, this.dragList)).subscribe((res:any) => {
          this.dragList = [];
          if(res.data.updateUnitsArea.isSuccessful) {
            if(this.selectedNodes.length > 0) {
              this.propertyList = this.propertyList.filter(p => {
                if(!this.selectedNodes.includes(p)) {
                  return p;
                }
              })
              this.selectedNodes.forEach(n => n.isChild = true);
              this.nodeLookup[moveId].children = this.nodeLookup[moveId].children.concat(this.selectedNodes);
              this.selectedNodes = [];
            } else {
              this.nodeLookup[event.item.data.id].isChild = true;
              this.nodeLookup[moveId].children.push(event.item.data)
            }
            message_types.success.messageBody = 'Successfully moved Units';
            this.userMessagesService.openMessage(message_types.success);
            this.loadingService.closeBlockingLoader();
          } else {
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to move Units';
            this.userMessagesService.openMessage(message_types.error);
            this.loadingService.closeBlockingLoader();
          }
        }, (error) => {
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to move Units';
          this.userMessagesService.openMessage(message_types.error);
          this.loadingService.closeBlockingLoader();
        })
      }
    }

    this.clearDragInfo(true)
  }

  isParentSelected(nodeId: any) {
    const parentNode = this.getParentNodeId(nodeId, this.propertyList, 'main');
  }

  getParentNodeId(id: string, nodesToSearch: any[], parentId: string): string {
      for (let node of nodesToSearch) {
        if(node.type === 'Area' && (node.children && node.children.length > 0 && node.children.find(c => c.id === id))) {
          this.parentNode = node;
        }
        if (node.id == id) {
          this.movingNodeType = node.type;
          return parentId;
        }
        let ret = this.getParentNodeId(id, node.children, node.id);
        if (ret) {
          this.movingNodeType = node.type;
          return ret;
        }
      }
      return null;
  }

  showDragInfo() {
      this.clearDragInfo();
      if (this.dropActionTodo) {
          this.document.getElementById("node-" + this.dropActionTodo.targetId).classList.add("drop-" + this.dropActionTodo.action);
      }
  }

  clearDragInfo(dropped = false) {
      if (dropped) {
        this.isDragging = false;
        this.dropActionTodo = null;
      }

      this.document
          .querySelectorAll(".drop-before")
          .forEach(element => element.classList.remove("drop-before"));
      this.document
          .querySelectorAll(".drop-after")
          .forEach(element => element.classList.remove("drop-after"));
      this.document
          .querySelectorAll(".drop-inside")
          .forEach(element => element.classList.remove("drop-inside"));
  }

  getNodesForArea($event, node: any) {
    if (this.mousePosition.x === $event.screenX && this.mousePosition.y === $event.screenY || this.updatedNode || this.deletingNode) {
      if($event.shiftKey) {
        if(node.type === 'Unit') {
          this.isSelecting = true;
          if(this.selectedNodes.length > 0 && this.selectedNodes.includes(node)) {
            this.selectedNodes = this.selectedNodes.filter(n => n != node)
            this.dragList = this.dragList.filter(d => d.unitId != node.id);
          } else {
            this.dragList.push({
              areaId: node.areaId === undefined ? null : node.areaId,
              propertyId: this.propertyId,
              unitId: node.id,
              unitNo: node.name
            })
            this.selectedNodes.push(node);
          }

          if(this.selectedNodes.length === 0) {
            this.isSelecting = false;
          }
        }
      } else {
        this.isSelecting = false;
        this.selectedNodes = [];
        this.selectedNode = node;
        if(node.type === 'Area') {
          if(this.updatedNode || this.deletingNode) {
            node.isExpanded = true;
          } else {
            node.isExpanded = !node.isExpanded;
          }
          
          this.loadingService.openBlockingLoader("Retrieving Area Details","primary");
            from(this.floorPlanService.getUnitsAndAreasForArea(node)).subscribe((res:any) => {
              node.hasFetched = true;
              let areaList = res.data.getSubAreaListByparentAreaId.areaList;
              let unitList = res.data.getUnitListPerArea.unitList;
              let areaPath = [];
              let unitPath = [];
      
              for(let i = 0; i < areaList.length; i++) {
                areaPath.push(areaList[i].id);
                areaList[i] = {
                  id: areaList[i].id,
                  name: areaList[i].name,
                  sqm: areaList[i].totalSQM,
                  selected: false,
                  startingPoint: node.startingPoint,
                  numberOfAreas: areaList[i].numberOfAreas,
                  numberOfUnits: areaList[i].numberOfUnits,
                  type: 'Area',
                  children: [],
                  isChild: true,
                  parentAreaId: areaList[i].parentAreaId
                }
                this.areaIdList.push(areaList[i].id)
              }
      
              for(let i = 0; i < unitList.length; i++) {
                unitPath.push(unitList[i].id);
                unitList[i] = {
                  id: unitList[i].id,
                  startingPoint: node.startingPoint,
                  sqm: unitList[i].sqm,
                  selected: false,
                  code: unitList[i].code,
                  occupied: unitList[i].occupied,
                  areaId: node.id,
                  unitType: unitList[i].unitType === 'COMMON_AREA' ? 'Common Area' : 'Rentable Unit',
                  name: unitList[i].unitNo,
                  type: 'Unit',
                  children: [],
                  isChild: true
                }
                
              }
      
              let combinedPaths = areaPath.concat(unitPath)
      
      
              this.propertyList[node.startingPoint].path = this.propertyList[node.startingPoint].path.concat(combinedPaths);
      
              //Check if current node matches property first child node
              //If they do match then the unit- and arealist get attached as the areas child node
              //If the do not match, we will have to find them using the starting point value
              node.children = [];
              node.children = areaList.concat(unitList);

              this.nodeLookup = {};
              this.prepareDragDrop(this.propertyList)              
      
              this.dataSource.data = null;
              this.dataSource.data = this.propertyList;
              this.loadingService.closeBlockingLoader();
              this.openNode($event, node);
            }, (error) => {
              message_types.error.displaySupport = true;
              message_types.error.messageBody = 'Failed to retrieve property nodes';
              this.userMessagesService.openMessage(message_types.error);
              this.loadingService.closeBlockingLoader();
            })
        } else if(node.type === 'Unit') {
          this.openNode($event, node)
        }
      }
    }
    
  }

  checkNodeStyle(node) {
    let style = {
      'background-color': 'white',
      'color': 'black',
      'visibility': 'visible'
    };

    if(this.selectedNodes.length > 0 && this.selectedNodes.includes(node)) {
      style['background-color'] = '#B9AB1C'
    } else if(this.selectedNode === node) {
      style.color = '#B9AB1C'
    }

    if(this.isDragging && this.selectedNodes.includes(node)) {
      style['visibility'] = 'hidden'
    } else {
      style['visibility'] = 'visible'
    }
    
    return style;
  } 

  prepareDragDrop(nodes: any[]) {
    nodes.forEach(node => {
        this.dropTargetIds.push(node.id);
        this.nodeLookup[node.id] = node;
        this.prepareDragDrop(node.children);
    });
  }

  updateNode(node) {
    const title = 'Update ' + node.type + ' Details';
    const dialogRef = this.dialog.open(AddNodeComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        title: title,
        node: node,
        propertyId: this.propertyId,
        isUpdate: true,
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((res:any)=>{

      if(res !== 'exit') {
        this.getPropertyDetails(this.property);
      }
    });
  }

  updateProperty(node) {
    let property = JSON.parse(sessionStorage.getItem('propertySelection')).property;

    property = {
      address: property.address,
      code: property.code,
      id: property.id,
      supplierIds: property.supplierIds,
      name: property.name
    }

    const dialogRef = this.dialog.open(AddPropertyComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        title: 'Update ',
        centre: property,
        isUpdate: true,
        displayAgentCode: true,
        suppliers: JSON.parse(sessionStorage.getItem('supplierList'))
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((data:any)=>{
      if(data) {
        location.reload();
      }
    });
  }

  openCreateNodeDialog() {
    this.tariffList = JSON.parse(sessionStorage.getItem('tariffs'));
    const dialogRef = this.dialog.open(AddMeterNodeDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '64%',
      width: '60%',
      data: {
        supplier: this.supplierDetails,
        propertyId: this.propertyId,
        tariffList: this.tariffList,
        title: 'Add New '
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res && res.id) {
        this.refreshUnlinkedTable();
      }
    });
  }

  addNode(createType: string) {
    const title = 'Add New ' +  (createType === 'Property' ? 'Property' : (createType === 'Area' ? 'Area' : 'Unit'))
    const dialogRef = this.dialog.open(AddNodeComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        title: title,
        createType: createType,
        areaId: this.selectedMeterNode.id,
        propertyId: this.propertyId,
        nodeType: this.selectedMeterNode.type
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((res:any)=>{
      if(res !== 'exit') {
        if(res) {
          this.updatedNode = true;
          this.getNodesForArea({}, this.selectedMeterNode);
        } else {
          this.getPropertyDetails(this.property)
        }
      }
      
      // this.getPropertyDetails(this.property)
    });
  }

}

@Component({
  selector: 'delete-unit-dialog',
  templateUrl: './delete-unit-dialog.html',
})
export class DeleteUnitDialog {
  constructor(public dialogRef: MatDialogRef<DeleteUnitDialog>) {}

  closeDialog(close: boolean) {
    this.dialogRef.close(close)
  }
}