<sym-card-dialog [title]="'Image View'" [closeMethod]="closeMethod">
    <mat-dialog-content>
      <div fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 2em;z-index: 100;position: sticky;">
        <button mat-icon-button (click)="rotateCCW()" >
          <mat-icon style="padding-right: 5px;">rotate_left</mat-icon>
        </button>
        <button mat-icon-button (click)="rotateCW()" >
          <mat-icon style="padding-right: 5px;">rotate_right</mat-icon>
        </button>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" [ngStyle]="getDimensions()">
        
        <br/>
        <img id="myimage" (click)="imageView = !imageView" [src]="thumbnail" [ngStyle]="getDimensions()" style="cursor: pointer;" />
      </div>
    </mat-dialog-content>
</sym-card-dialog>
