<sym-card-dialog [title]="'Migrate Meter'" [closeMethod]="closeMethod">
    <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
        <div fxLayout="column">
    
          <div fxLayout="row wrap" fxLayoutGap="14px">
  
            <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
              <mat-label>Meter Number</mat-label>
              <input matInput style="color: black;" disabled [(ngModel)]="meterNo" required>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
                <mat-label>Utility Type</mat-label>
                <input matInput style="color: black;" disabled [(ngModel)]="utilityType" required>
            </mat-form-field>

            <div fxLayout="column" fxFlex="48">
                <mat-form-field appearance="outline" style="margin-top: 5px;" >
                    <mat-label>Select New Property</mat-label>
                    <mat-select style="color: black;" [(ngModel)]="newProperty" required>
                      <mat-option *ngFor="let property of propertyList" style="color: black;" [value]="property">
                        {{property.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="checkError()" style="margin-top: -20px;padding-bottom: 1em;">Meter is already on the selected property</mat-error>
            </div>

            <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
              <mat-label>Migration Reading</mat-label>
              <input matInput style="color: black;" type="number" [(ngModel)]="migrationReading" required>
            </mat-form-field>

              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
                <mat-label>Migration Reason</mat-label>
                <input matInput style="color: black;" [(ngModel)]="migrationReason" required>
            </mat-form-field>
          </div>
    
        </div>
        <mat-divider style="margin: 34px 0px;"></mat-divider>
        <div fxLayout="row" fxLayoutAlign="end center">
            
          <button mat-raised-button style="margin-bottom: 10px; background-color: #41535D;color: white;" id="btnClass" (click)="migrateMeter()">Migrate Meter
          </button>
        </div>
      </div>
  </sym-card-dialog>