import { NgModule } from '@angular/core';
import { BaseModule } from '../../core/base/base.module';
import { MaterialModule } from '../../core/material/material.module';
import { MatIconModule } from '@angular/material/icon';
import { SaCardComponent } from './sa-card.component';

@NgModule({
  declarations: [SaCardComponent],
  imports: [
    BaseModule,
    MaterialModule,
    MatIconModule
  ],
  exports:[
    SaCardComponent
  ]
})
export class SaCardModule { }
