import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxImageCompressService } from 'ngx-image-compress';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { ShopsService } from 'src/app/services/shops.service';
import { CaptureReadingService } from 'src/app/shared/components/capture-reading/capture-reading.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';


@Component({
  selector: 'app-update-readings',
  templateUrl: './update-readings.component.html',
  styleUrls: ['./update-readings.component.scss']
})
export class UpdateReadingsComponent implements OnInit {

  meter: any;

  closeMethod = () => {
    this.closeDialog()
  }
  hasToUpload: boolean = false;
  comment: string;
  propertyId: any;
  period: any;
  supplierIds;
  isUpdate: boolean = true;
  hasImages: boolean = false;
  imageURIs: string[] =[];
  item: any;
  filesToUpload: any = {};
  uploadSize: number = 0;
  uploadUrl: string;
  count: number = 0;
  readingDetails = new UntypedFormGroup({
    reading1: new UntypedFormControl(''),
    reading2: new UntypedFormControl(''),
    reading3: new UntypedFormControl(''),
    reading4: new UntypedFormControl(''),
  });

  constructor(private imageCompress: NgxImageCompressService, 
    public loadingservice: LoadingService,
     public dialogRef: MatDialogRef<UpdateReadingsComponent>, @Inject(MAT_DIALOG_DATA) public data, private userMessagesService: UserMessagesService, private shopsService: ShopsService, private captureReadingService: CaptureReadingService,) {
console.log(this.data)
   }

  ngOnInit(): void {
    this.imageURIs = this.data.meter.thumbNail;
    this.hasImages = this.data.hasImages;
    this.isUpdate = this.data.isUpdate;
    this.meter = this.data.meter;
    this.supplierIds = [].concat(this.data.supplierIds);
    this.propertyId = this.data.propertyId;
    this.period = this.data.period;
    console.log(this.meter)
    if(this.meter.readingList[0].value) {
      this.readingDetails.setValue({
        reading1: this.checkReadingValue(0),
        reading2: this.checkReadingValue(1),
        reading3: this.checkReadingValue(2),
        reading4: this.checkReadingValue(3),
      });
      this.uploadSize = this.meter.readingList.length;
    } else {
      this.uploadSize = this.meter.readingList.length - 1;

      this.readingDetails.setValue({
        reading1: this.checkReadingValue(1),
        reading2: this.checkReadingValue(2),
        reading3: this.checkReadingValue(3),
        reading4: this.checkReadingValue(4),
      });
    }

    this.uploadUrl = "assets/";
  }

  checkReadingValue(i: number) {
    if(this.meter && this.meter.readingList && this.meter.readingList.length > 0 && this.meter.readingList[i] && this.meter.readingList[i].value) {
      return this.meter.readingList[i].value;
    } else {
      return 0;
    }
  }

  removeFile(reading) {
    this.filesToUpload['r'+reading] = {};

    if(--this.count === 0 && this.meter.utilityType.type !== 'ELEC_3P_3') {
      console.log('Removed HasToUpload');
      this.hasToUpload = false;
    }

  }

  checkWindowSpacing() {
    let width = 1920 - window.innerWidth;
    if(width <= 0) {
      return 0;
    } else {
      return Math.round(width * 0.01830985915492957746478873239437)
    }
  }

  addFile(reading) {
    this.imageCompress.uploadFile().then(({image, orientation, fileName}) => {
      console.log(image)
      console.log('Size in bytes of the uploaded image was:', this.imageCompress.byteCount(image));

      let imageSize = this.imageCompress.byteCount(image);
      const shouldCompress = imageSize > 2000000;

      if(this.meter.utilityType !== 'ELEC_3P_3') {
        this.hasToUpload = true;
      }

      if(shouldCompress) {
        this.imageCompress
          .compressFile(image, orientation, 100, 70)
          .then(compressedImage => {
            console.log(compressedImage);
            console.log('Size in bytes after compression is now:', this.imageCompress.byteCount(compressedImage));
            console.log(compressedImage)

            this.count++;
            console.log(this.filesToUpload)
            this.filesToUpload['r' + reading] = {
              name: fileName,
              size: this.imageCompress.byteCount(compressedImage),
              blob: compressedImage
            }
            console.log(this.filesToUpload)
          });
      } else {
        this.count++;
        console.log(this.filesToUpload)
        this.filesToUpload['r' + reading] = {
          name: fileName,
          size: this.imageCompress.byteCount(image),
          blob: image
        }
      }
      
    });
  }

  checkUtilityTypeText(i) {
    let reading = 'Reading ';

    if(this.meter.utilityType.type === 'ELEC_3P_TOU') {
      if(i === 1) {
        reading = 'Standard'
      } else if(i === 2) {
        reading = 'Off Peak'
      } else if(i === 3) {
        reading = 'Peak'
      } else {
        reading = 'KVA'
      }
    } else {
      if(i === this.meter.utilityType.num && this.meter.utilityType.hasKva) {
        reading = 'KVA'
      } else {
        reading += i;
      }
    }

    return reading;
  }

  closeDialog(meter?) {
    this.dialogRef.close(meter);
  }

  updateReading() {
    let meter = {
      meterId: this.meter.readingDetails.meterId,
      approvalStatus: this.meter.readingDetails.approvalStatus,
      reading: {
        meterId: this.meter.readingDetails.meterId,
        propertyId: this.propertyId,
        readingBy: JSON.parse(localStorage.getItem('email')),
        readingTS: new Date().toISOString(),
        readingType: this.meter.readingDetails.reading.readingType,
        readingValueList: []
      }
    }
    let readingList = [];

    if(this.meter.readingList.length > this.meter.utilityType.num) {
      this.meter.readingList.shift()
    }

    console.log(this.filesToUpload)

    for(let i = 0; i < this.meter.utilityType.num;i++) {
      readingList.push({
        sequence: i + 1,
        value: i === 0 ? this.readingDetails.value.reading1 : i === 1 ? this.readingDetails.value.reading2 : i === 2 ? this.readingDetails.value.reading3 : this.readingDetails.value.reading4,
        category: this.meter.readingList[i].category,
        imageKey: this.filesToUpload['r'+(i+1)] && this.filesToUpload['r'+(i+1)].name ? null : this.meter.readingList[i].imageKey,
        name: this.meter.readingList[i].name,
      })
    }

    meter.reading.readingValueList = readingList;
    console.log(meter)

    this.loadingservice.openBlockingLoader(`${this.isUpdate ? 'Updating' : 'Overwritting'} Period ${this.meter.readingList.length > 2 ? 'Readings' : 'Reading'}`, 'primary');

    from(this.shopsService.updatePeriodReading(meter, this.comment ,this.period, this.supplierIds)).subscribe((res:any) => {
      if(res.data.updatePeriodReading.isSuccessful) {
        if (this.count > 0) {
          this.uploadImage(meter);
        } else {
          this.closeDialog(meter);
          message_types.success.messageBody = `Processing utilization and charges`;
          this.userMessagesService.openMessage(message_types.success);
        }
        this.closeDialog(meter);
        message_types.success.messageBody = `Processing ${readingList.length > 1 ? 'readings' : 'reading'} and charges`;
        this.userMessagesService.openMessage(message_types.success);
      } else {
        this.loadingservice.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = `Failed to ${this.isUpdate ? 'Update' : 'Overwrite'} Period ${this.meter.readingList.length > 2 ? 'Readings' : 'Reading'}`;
        this.userMessagesService.openMessage(message_types.error);
      }
    }, (error) => {
      this.loadingservice.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = `Failed to ${this.isUpdate ? 'Update' : 'Overwrite'} Period ${this.meter.readingList.length > 2 ? 'Readings' : 'Reading'}`;
      this.userMessagesService.openMessage(message_types.error);
    })
  }

  uploadImage(meter) {
    let insideCount = 0;
    for(let i = 0; i < meter.reading.readingValueList.length; i++ ) {
      let fileToUpload;
      if(i === 0 && this.filesToUpload.r1 && this.filesToUpload.r1.name) {
        fileToUpload = this.filesToUpload.r1.blob;
        meter.reading.fileName = this.filesToUpload.r1.name;
      } else if(i === 1 && this.filesToUpload.r2 && this.filesToUpload.r2.name) {
        fileToUpload = this.filesToUpload.r2.blob;
        meter.reading.fileName = this.filesToUpload.r2.name;
      } else if(i === 2 && this.filesToUpload.r3 && this.filesToUpload.r3.name) {
        fileToUpload = this.filesToUpload.r3.blob;
        meter.reading.fileName = this.filesToUpload.r3.name;
      } else if(i === 3 && this.filesToUpload.r4 && this.filesToUpload.r4.name) {
        fileToUpload = this.filesToUpload.r4.blob;
        meter.reading.fileName = this.filesToUpload.r4.name;
      }

      if(fileToUpload) {
        from(this.shopsService.getPresignedURL(meter.reading, meter.reading.readingValueList[i].sequence, this.period)).subscribe((res:any) => {
          let imageURL = res.data.captureImageReading.presignedURL;
          const splitUrl = imageURL.substring(
            imageURL.lastIndexOf("Signature="),
            imageURL.lastIndexOf("x-amz-security-token=")
          );
          const split = splitUrl.split('Signature=');
          this.shopsService.setAuth(split[1]);
          this.captureReadingService.uploadImage(fileToUpload, imageURL).subscribe((res:any) => {
            insideCount++;
            if(insideCount === this.count) {
              this.loadingservice.closeBlockingLoader();
              this.closeDialog(meter);
            }
          },(error) => {
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to upload image';
            this.userMessagesService.openMessage(message_types.error);
            this.loadingservice.closeBlockingLoader();
          })
        },(error) => {
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to upload image';
          this.userMessagesService.openMessage(message_types.error);
          this.loadingservice.closeBlockingLoader();
        })
      }
    }
  }

}
