import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

@Component({
  selector: 'sym-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  constructor(private dashBoardService: DashboardService, private loadingService: LoadingService, private userMessagesService: UserMessagesService) { }
  @ViewChild('dashboardContainer') dashBoardContainer : ElementRef;
  container: any;

  dashboard: any;

  ngOnInit(): void {
    this.getAnalytics();
  }

  ngAfterViewInit(): void {
    this.container = document.getElementById('dashboardContainer');
  }

  getAnalytics() {
    const container = document.getElementById('dashboardContainer');
    this.loadingService.openBlockingLoader("Retrieving Analytics", "primary");
    this.dashBoardService.quickSightCall().subscribe((res) => {
      if(res) {
        const url = res.url;
        if(url) {
          const container = document.getElementById('dashboardContainer');
          const options = {
            url: url,
            container: container,
            scrolling: 'no',
            height: '100%',
            width: '100%',
            footerPaddingEnabled: true
          };
          this.dashboard = QuickSightEmbedding.embedDashboard(options);
          this.loadingService.closeBlockingLoader();
        } else {
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Unable to embed quicksight dashboard without a url';
          this.userMessagesService.openMessage(message_types.error);
          this.loadingService.closeBlockingLoader();
        }
      } else {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'No response data available';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      }
    }, error => {
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Unable to embed dashboard';
      this.userMessagesService.openMessage(message_types.error);
      this.loadingService.closeBlockingLoader();
    })
  }

}
