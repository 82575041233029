import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})

export class ImageViewComponent implements OnInit {

  currentPosition = 0;

  closeMethod = () => {
    this.closeDialog()
  }
  imageView = false;
  thumbnail;

  constructor(
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<ImageViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.thumbnail = this.data.thumbnail;
  }

  rotateCW() {
    this.currentPosition = this.currentPosition >= 270? 0 : this.currentPosition += 90;

    const image = document.getElementById('myimage');
    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.currentPosition}deg)`
    )
  }

  rotateCCW() {
    this.currentPosition = this.currentPosition >= 360? 0 : this.currentPosition -= 90;

    const image = document.getElementById('myimage');
    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.currentPosition}deg)`
    )
  }

  getDimensions() {
    if(this.imageView) {
      return {
        'width': '100%',
        'height': 'auto'
      }
    } else {
      return {
        'width': '350px',
        'height': '300px'
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
