<sym-card-dialog [title]="title + 'Meter'" [buttonProperties]="buttonProperties" [isValid]="meterDetails.valid && !meterNoExists" [closeMethod]="closeMethod">
  <mat-dialog-content>
    <div style="height: 48vh;padding: 8px;">
      <form [formGroup]="meterDetails">
        <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
          <div fxLayout="column">
      
            <div fxLayout="row wrap" fxLayoutGap="30px">
  
              <!-- <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Meter Type</mat-label>
                <mat-select data-cy="meterType" style="color: black;" formControlName="meterType" required>
                  <mat-option [value]="'BULK'">Bulk Meter</mat-option>
                  <mat-option [value]="'UNIT'">Unit Meter</mat-option>
                </mat-select>
              </mat-form-field> -->
              
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Utility Type</mat-label>
                <mat-select data-cy="utilityType" style="color: black;" formControlName="utilityType"
                  (ngModelChange)="meterChange()" required>
                  <mat-option *ngFor="let meter of meterUtility" data-cy="{{'Meter'}}" style="color: black;" [value]="meter">
                    {{meter.name}}</mat-option>
                </mat-select>
              </mat-form-field>
  
              <div fxLayout="column" style="margin-top: 5px;" fxFlex="45">
                <mat-form-field appearance="outline" >
                  <mat-label>Meter Number</mat-label>
                  <input matInput style="color: black;"  formControlName="meterNo" (ngModelChange)="checkIfNumberExists()" required>
                  <mat-hint *ngIf="checkExists">Checking if number exists</mat-hint>
                </mat-form-field>
                <mat-error *ngIf="!checkExists && meterNoExists" style="margin-top: -1em;padding-bottom: 1.5em; font-size: 75%;">Meter Number already exists</mat-error>
              </div>
    
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Reading Method</mat-label>
                <mat-select data-cy="readingMethod" style="color: black;" formControlName="isManual" (ngModelChange)="checkCaptureType()" [(value)] ="meter.isManual" required>
                  <mat-option *ngFor="let readingMethod of readingMethods" [value]="readingMethod" >
                    {{readingMethod}}</mat-option>
                </mat-select>
              </mat-form-field>
  
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Capture Type</mat-label>
                <mat-select data-cy="captureType" style="color: black;" formControlName="captureType" [(value)] ="meter.captureType" required>
                  <mat-option *ngFor="let type of captureType" [value]="type.value" >
                    {{type.name}}</mat-option>
                </mat-select>
              </mat-form-field>
      
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="tariffList.length != 0 && !showWater">
                <mat-label>Tariff Type</mat-label>
                <mat-select data-cy="tariffType" style="color: black;" formControlName="tariff" [(value)] ="meter.tariff" required>
                  <mat-optgroup *ngFor="let supplier of tariffList" [label]="supplier.supplierName">
                    <mat-option *ngFor="let tariff of supplier.tariffList" [value]="[tariff.id]" >
                      {{tariff.name}}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="tariffList.length != 0 && showWater">
                <mat-label>Tariff Type</mat-label>
                <mat-select data-cy="tariffType" style="color: black;" formControlName="tariff" [(value)] ="meter.tariff"  multiple required>
                  <mat-optgroup *ngFor="let supplier of tariffList" [label]="supplier.supplierName">
                    <mat-option *ngFor="let tariff of supplier.tariffList" [value]="tariff.id" >
                      {{tariff.name}}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
  
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="!showWater">
                <mat-label>Amps</mat-label>
                <input matInput data-cy="amps" style="color: black;" formControlName="amps" type="number" required>
                <mat-error *ngIf="!showWater && meterDetails.value.amps <= 0">Amps cannot be smaller than 1</mat-error>
              </mat-form-field>  
  
                <!-- <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="bulkShow">
                  <mat-label>Bulk Meter Link</mat-label>
                  <mat-select data-cy="bulkMeter" style="color: black;" formControlName="bulkMeter" required>
                    <mat-option *ngFor="let meter of bulkMeters" [value]="meter.id" >
                      {{meter.meterNo}}</mat-option>
                  </mat-select>
                </mat-form-field> -->
    
                <mat-form-field appearance="outline" style="color: black;" fxFlex="45">
                  <mat-label>Commission date</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="commissionDate" required>
                  <mat-datepicker-toggle matSuffix data-cy="commissionDate" [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
  
                <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                  <mat-label>Commission Reading 1</mat-label>
                  <input matInput data-cy="commissionReading" style="color: black;" formControlName="commissionReading" type="number">
                </mat-form-field>
        
                <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission2"
                  fxFlex="45">
                  <mat-label>Commission Reading 2</mat-label>
                  <input matInput data-cy="commissionReading2" style="color: black;" formControlName="commissionReading2" type="number">
                </mat-form-field>
        
                <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission3"
                  fxFlex="45">
                  <mat-label>Commission Reading 3</mat-label>
                  <input matInput data-cy="commissionReading3" style="color: black;" formControlName="commissionReading3" type="number">
                </mat-form-field>
        
                <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission4"
                  fxFlex="45">
                  <mat-label>Commission Reading 4</mat-label>
                  <input matInput data-cy="commissionReading4" style="color: black;" formControlName="commissionReading4" type="number">
                </mat-form-field>
            </div>
      
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  </sym-card-dialog>
