import { Injectable } from '@angular/core';
import { Message } from './message.model';
import { MatDialog } from '@angular/material/dialog';
import { UserMessagesComponent } from './user-messages.component';

@Injectable({
  providedIn: 'root'
})
export class UserMessagesService {

  message: Message;
  timeoutId: any;

  openMessage(message: Message) {
    this.message = message;
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.closeMessage();
    }, this.message.duration)
  }

  closeMessage() {
    this.message = new Message;
  }

  constructor(public dialog: MatDialog) { }
}
