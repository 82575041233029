import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileLookupService {

  item: any;
  public clientList: any;

  constructor(private http: HttpClient) {
  }

  getDocumentFromS3(url) {
    return this.http.get(url, {responseType: 'blob'});
  }

  getImageReadingFromS3(url) {
    return this.http.get(url, {responseType: 'text'});
  }
}
