import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { ClientDocumentUploaderComponent } from 'src/app/shared/components/clientDocumentUploader/clientDocument-uploader.component';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss']
})
export class TenantListComponent implements OnInit {

  @ViewChild('Captures') paginator: MatPaginator;
  @ViewChild('PropTenants') propTenantPagnator: MatPaginator;
  @ViewChild('Documents') docPaginator: MatPaginator;
  tenantColumns = [
    'tradingName',
    'tenantCode',
    'contactName',
    'contact',
    'email',
    'leaseNum'
  ];

  documentColumns = [
    'date',
    'fileName',
    'fileType',
    'status'
  ];
  titleButton: any = {
    method: () => {
      this.getAllFilesForTenants()
    },
    toolTip: 'Refresh Table',
    icon: 'refresh'
  }
  nextToken: any;
  pageSize: any = 50;
  tenantCount: number = 0;
  showUpload: boolean = false;
  previousToken: any[] = [];
  dataSource = new MatTableDataSource();
  uploadSource = new MatTableDataSource();
  retrievedTenantsForProperty: boolean = false;
  selectedProperty: any;
  primaryBtn: any = {
    text: 'Upload File',
    method: () => {
      this.uploadFile()
    }
  }

  refreshButton: any = {
    text: 'Refresh Page',
    method: () => {
      this.getAllFilesForTenants()
    }
  }

  constructor(private changeDetector: ChangeDetectorRef, private loadingService: LoadingService, private userMessagesService: UserMessagesService, private propertiesService: PropertiesService, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.selectedProperty = JSON.parse(sessionStorage.getItem('propertySelection'));
    if(this.selectedProperty && this.selectedProperty.property) {
      this.retrievedTenantsForProperty = true;
      this.changeDetector.detectChanges();
      setTimeout(() => {
        this.retrieveTenantsForProperty(this.selectedProperty.property);
      }, 10);
    } else {
      this.retrievedTenantsForProperty = false;
      this.changeDetector.detectChanges();
      setTimeout(() => {
        this.getAllTenants(this.pageSize);
      }, 10)
    }
  }

  onPageChangeEvent(event) {
    if(this.pageSize != event.pageSize) {
      this.pageSize = event.pageSize;
      this.getAllTenants(this.pageSize);
      this.paginator.pageIndex = 0;
    }else if(event.pageIndex > event.previousPageIndex) {
      this.previousToken.push(this.nextToken);
      this.retrieveOtherTenants(this.nextToken, this.pageSize)
    } else if(event.pageIndex != event.previousPageIndex) {
      if(event.pageIndex === 0) {
        this.getAllTenants(this.pageSize);
        this.previousToken = [];
      } else {
        this.retrieveOtherTenants(this.previousToken[event.pageIndex-1], this.pageSize);
        this.previousToken.pop();
      }
    }
  }

  setProperty(property) {
    this.selectedProperty = property;
    this.retrieveTenantsForProperty(this.selectedProperty.property);
  }

  retrieveTenantsForProperty(property) {
    this.loadingService.openBlockingLoader('Retrieving Tenants');
    from(this.propertiesService.getTenantByPropertyId(property.id, this.pageSize)).subscribe((res:any) => {
      this.retrievedTenantsForProperty = true;
      let unitList = res.data.getAllTenantsPerProperty.unitsOnProperty;
      let tenantList: any[] = [];
      for(let unit of unitList) {
        if(unit.tenantsPerUnitPerPropertyList.tenantOnProperty) {
          let tenant = tenantList.find(t => {
            return t.id === unit.tenantsPerUnitPerPropertyList.tenantOnProperty.id
          })
          
          if(!tenant) {
            tenantList.push(unit.tenantsPerUnitPerPropertyList.tenantOnProperty); 
          }
        }
      }

      tenantList.sort((a, b) => {
        return a.tradingName > b.tradingName ? 1 : -1;
      })

      this.dataSource = new MatTableDataSource(tenantList);

      this.changeDetector.detectChanges();
      
      this.dataSource.paginator = this.propTenantPagnator;

      this.loadingService.closeBlockingLoader();
    }, (error) => {
      if(error.errors && error.errors[0].message.includes('401')) {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = false;
        message_types.error.messageBody = 'Session expired. Please log back in';
        this.userMessagesService.openMessage(message_types.error);
      } else {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to retrieve tenants';
        this.userMessagesService.openMessage(message_types.error);
      }
    })
  }

  retrieveOtherTenants(token, limit) {
    this.retrievedTenantsForProperty = false;
    this.loadingService.openBlockingLoader('Retrieving Tenants');
    from(this.propertiesService.getNextTenant(token, limit)).subscribe((res:any) => {
      this.nextToken = res.data.getAllTenants.nextToken;
      this.dataSource = new MatTableDataSource(res.data.getAllTenants.tenantList);

      this.loadingService.closeBlockingLoader();
    }, (error) => {
      if(error.errors && error.errors[0].message.includes('401')) {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = false;
        message_types.error.messageBody = 'Session expired. Please log back in';
        this.userMessagesService.openMessage(message_types.error);
      } else {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to retrieve tenants';
        this.userMessagesService.openMessage(message_types.error);
      }
    })
  }

  goToReviewTenantScreen(element) {
    localStorage.setItem('processedFileName', element.fileName);
    if(element.uploadStatus && element.uploadStatus === 'Action Required') {
      localStorage.setItem('fileName', element.fileName);
      this.router.navigate(['review-tenant-changes', {fid: element.id}]);
    }
  }

  getAllTenants(limit) {
    this.retrievedTenantsForProperty = false;
    this.loadingService.openBlockingLoader('Retrieving Tenants');
    from(this.propertiesService.getAllTenants(limit)).subscribe((res:any) => {
      this.tenantCount = res.data.getAllTenantsCount.count;
      this.nextToken = res.data.getAllTenants.nextToken;
      this.dataSource = new MatTableDataSource(res.data.getAllTenants.tenantList);
      this.loadingService.closeBlockingLoader();
    }, (error) => {
      if(error.errors && error.errors[0].message.includes('401')) {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = false;
        message_types.error.messageBody = 'Session expired. Please log back in';
        this.userMessagesService.openMessage(message_types.error);
      } else {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to retrieve tenants';
        this.userMessagesService.openMessage(message_types.error);
      }
    });
  }

  changeTab(event) {
    if(event.index === 0) {
      this.showUpload = false;
      if(this.selectedProperty && this.selectedProperty.property && this.selectedProperty.property.id) {
        this.retrieveTenantsForProperty(this.selectedProperty.property);
      } else {
        this.getAllTenants(this.pageSize);
      }
    } else {
      this.showUpload = true;
      this.getAllFilesForTenants();
    }
  }

  getAllFilesForTenants() {
    this.loadingService.openBlockingLoader('Retrieving processed file list');
    from(this.propertiesService.getAllFilesForTenant()).subscribe((res:any) => {
      let fileList = res.data.getAllFiles.fileList;
      if(fileList && fileList.length > 0) {
        fileList = fileList.sort((a,b) => new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime());
      }
      this.uploadSource = new MatTableDataSource(fileList);
      this.uploadSource.paginator = this.docPaginator;
      this.loadingService.closeBlockingLoader();
    }, (error) => {
      if(error.errors && error.errors[0].message.includes('401')) {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Session expired. Please log back in';
        this.userMessagesService.openMessage(message_types.error);
      } else {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to retrieve processed files list';
        this.userMessagesService.openMessage(message_types.error);
      }
    })
  }

  uploadFile() {
    const dialogRef = this.dialog.open(ClientDocumentUploaderComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '60%',
      height: 'Upload Tenancy Schedule',
      data: {
        meterDetails: '',
        uploadType: 'UPDATE_TENANCY_SCHEDULE',
        suppliers: '',
        importMeters: false,
        title: 'Upload Tenancy Schedule'
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.getAllFilesForTenants();
      }, 1000);      
    })
  }

}
