<div *ngIf="!nodeToUnitLink">
  <sym-card-dialog *ngIf="tabIndex === 0" [isValid]="true" [buttonProperties]="linkButtonProperties" [title]="'Link Meter to Unit - ' + unitName" [closeMethod]="closeMethod">
    <ng-template [ngTemplateOutlet]="meterForm"></ng-template>
  </sym-card-dialog>
  
  <sym-card-dialog *ngIf="tabIndex === 1" [isValid]="newMeterDetails.valid && !meterNoExists" [buttonProperties]="createButtonProperties" [title]="'Link Meter to Unit - ' + unitName" [closeMethod]="closeMethod">
    <ng-template [ngTemplateOutlet]="meterForm"></ng-template>
  </sym-card-dialog>
</div>



<ng-template #meterForm>
  <mat-dialog-content>
    <div>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"  [selectedIndex]="tabIndex" (selectedTabChange)="changeTab($event)">
        <mat-tab label="Existing Meter">
          <div fxLayout="column" style="margin-top: 1.3em;">
    
            <div fxFlex="100" fxLayout="row">
              <mat-form-field appearance="outline" fxFlex="45">
                <mat-label>Utility Type:</mat-label>
                <mat-select style="color: black;" (selectionChange)="filterSelectionChange($event)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let type of generalUtilitytypes" [value]="type">{{type}}</mat-option>
                </mat-select>
              </mat-form-field>
    
              <div fxFlex="4"></div>
    
              <mat-form-field appearance="outline" fxFlex="45">
                <mat-label>Meter Type:</mat-label>
                <mat-select style="color: black;" (selectionChange)="filterSelectionChange($event)">
                  <mat-option>-- None --</mat-option>
                  <mat-option *ngFor="let type of utilityTypes" [value]="type">{{type}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
    
            <div fxFlex="100">
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Select Pre-Existing Meter:</mat-label>
                <mat-select style="color: black;" [formControl]="meterSelection" (selectionChange)="selectionChange($event)" (focusout)="focusOutFunction(true)">
                  <div (focusout)="focusOutFunction(false)">
                    <input type="text" autocomplete="off" matInput placeholder="Search Meter" [(ngModel)]="searchInput" (input)="onInputChange($event)" style="height: 3em;padding-left:1em"/>
                  <mat-option *ngFor="let meter of filteredNodes" style="color: black;" [value]="meter">
                    {{meter.meterNo  + ' - ' + meter.utilityType}}</mat-option>
                  </div>
                  
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="New Meter">
          <form [formGroup]="newMeterDetails" >
            <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;">
              <div fxLayout="column">
          
                <div fxLayout="row wrap" fxLayoutGap="14px">
    
                  <!-- <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                    <mat-label>Meter Type</mat-label>
                    <mat-select data-cy="meterType" style="color: black;" formControlName="meterType" required>
                      <mat-option [value]="'BULK'">Bulk Meter</mat-option>
                      <mat-option [value]="'UNIT'">Unit Meter</mat-option>
                    </mat-select>
                  </mat-form-field> -->
        
                  <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                    <mat-label>Utility Type</mat-label>
                    <mat-select data-cy="utilityType" style="color: black;" formControlName="utilityType"
                      (ngModelChange)="meterChange()" required>
                      <mat-option *ngFor="let meter of meterUtility" data-cy="{{'Meter'}}" style="color: black;" [value]="meter">
                        {{meter.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
        
                  <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                    <mat-label>Reading Method</mat-label>
                    <mat-select data-cy="readingMethod" style="color: black;" (ngModelChange)="checkCaptureType()" formControlName="isManual" [(value)] ="meter.isManual" required>
                      <mat-option *ngFor="let readingMethod of readingMethods" [value]="readingMethod" >
                        {{readingMethod}}</mat-option>
                    </mat-select>
                  </mat-form-field>
          
                  <div fxLayout="column" style="margin-top: 5px;" fxFlex="45">
                    <mat-form-field appearance="outline" fxFlex="45">
                      <mat-label>Serial Number</mat-label>
                      <input matInput data-cy="meterNo" formControlName="meterNo" (ngModelChange)="checkIfNumberExists()" required>
                      <mat-hint *ngIf="checkExists">Checking if number exists</mat-hint>
                    </mat-form-field>
                    <mat-error *ngIf="!checkExists && meterNoExists" style="margin-top: 1em; font-size: 75%;">Meter Number already exists</mat-error>
                  </div>
    
                  <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                    <mat-label>Capture Type</mat-label>
                    <mat-select data-cy="captureType" style="color: black;" formControlName="captureType" [(value)] ="meter.captureType" required>
                      <mat-option *ngFor="let type of captureType" [value]="type.value" >
                        {{type.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                    
      
                    <!-- <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="bulkShow">
                      <mat-label>Bulk Meter Link</mat-label>
                      <mat-select data-cy="bulkMeter" style="color: black;" formControlName="bulkMeter" required>
                        <mat-option *ngFor="let meter of bulkMeters" [value]="meter.id" >
                          {{meter.meterNo}}</mat-option>
                      </mat-select>
                    </mat-form-field> -->
        
                    <mat-form-field appearance="outline" style="color: black;" fxFlex="45">
                      <mat-label>Commission date</mat-label>
                      <input matInput [matDatepicker]="picker" formControlName="commissionDate" required>
                      <mat-datepicker-toggle matSuffix data-cy="commissionDate" [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
          
                  <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="!showWater">
                    <mat-label>Amps</mat-label>
                    <input matInput data-cy="amps" style="color: black;" formControlName="amps" type="number" required>
                    <mat-error *ngIf="!showWater && newMeterDetails.value.amps <= 0">Amps cannot be smaller than 1</mat-error>
                  </mat-form-field>  
          
                  <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="tariffList.length != 0 && !showWater">
                    <mat-label>Tariff Type</mat-label>
                    <mat-select data-cy="tariffType" style="color: black;" formControlName="tariff" [(value)] ="meter.tariff" required>
                      <mat-optgroup *ngFor="let supplier of tariffList" [label]="supplier.supplierName">
                        <mat-option *ngFor="let tariff of supplier.tariffList" [value]="[tariff.id]" >
                          {{tariff.name}}</mat-option>
                      </mat-optgroup>
                    </mat-select>
                  </mat-form-field>
    
                  <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="tariffList.length != 0 && showWater">
                    <mat-label>Tariff Type</mat-label>
                    <mat-select data-cy="tariffType" style="color: black;" formControlName="tariff" [(value)] ="meter.tariff"  multiple required>
                      <mat-optgroup *ngFor="let supplier of tariffList" [label]="supplier.supplierName">
                        <mat-option *ngFor="let tariff of supplier.tariffList" [value]="tariff.id" >
                          {{tariff.name}}</mat-option>
                      </mat-optgroup>
                    </mat-select>
                  </mat-form-field>
                </div>
          
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-dialog-content>
</ng-template>

<sym-card-dialog [title]="'Link Meter to Unit - ' + selectedMeter.meterNo" *ngIf="nodeToUnitLink" [closeMethod]="closeMethod">

  <mat-dialog-content>
    <div style="height: 50vh;">
      <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;">
        <mat-form-field appearance="outline" fxFlex="45">
          <mat-label>Units:</mat-label>
          <mat-select style="color: black;" [(ngModel)]="selectedUnit" (selectionChange)="filterSelectionChange($event)">
            <mat-option *ngFor="let unit of unitList" [value]="unit">{{unit.unitNo}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-divider style="margin: 34px 0px;"></mat-divider>
        <div fxLayout="row" fxLayoutAlign="end center">
         <button mat-raised-button data-cy="saveButton" [ngStyle]="{'opacity': (newMeterDetails.valid && !meterNoExists ? '1' : '.5')}" style="margin-bottom: 10px; background-color: #41535D;color: white;"
            id="btnClass" (click)="createMeteredLink()" [disabled]="!newMeterDetails.valid || meterNoExists">Link Meter
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  
    
</sym-card-dialog>