<!-- PASS LOGO AND BUTTON TYPE TO HEADER -->
<div [ngClass]="{'full-screen':ls.config.header.fixed}">
    <sa-header #saHeader [fixed]='ls.config.header.fixed' [headerHeight]="ls.config.header.headerHeight"
    (propertySelect)="setProperty($event)" [color]="ls.config.header.backgroundColor" [logo]="ls.config.header.logo"
    [buttonIcon]="ls.config.header.buttonIcon" (buttonClicked)="sideNavButtonClicked()">
    </sa-header>

    <!-- SIDENAV DRAWER -->

    <mat-drawer-container class="full-screen">
        <mat-drawer #drawer saSidenav class="sideNavBar" mode="side" opened="true">
            <div saLayout style="padding-top: 104px;">
                <sa-sidenav [screenType]="screenType"></sa-sidenav>
            </div>
        </mat-drawer>

        <div class="pt-{{ls.config.header.headerHeight}}" [@onMainContentChange]="sideNavChange ? 'open': 'close'">

            <router-outlet (activate)="onOutletLoaded($event)">
            </router-outlet>
        </div>
    </mat-drawer-container>
</div>

