import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TariffsService {

  constructor(private http: HttpClient) { }

  chargeCalculationEndPoint = environment.api.chargeCalculation;
  chargeCalculationAPI = {
    "aws_appsync_graphqlEndpoint" : this.chargeCalculationEndPoint
  }

  deleteTariffCharges(tariffCharges, periodTariffId) {
    let callString = '';
    for(let i = 0; i < tariffCharges.length; i++) {
      callString += `s${i}: deleteTariffCharge(id: "${tariffCharges[i]}", periodTariffId: "${periodTariffId}") {
        errorMessage
        isSuccessful
      }`
    };

    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: gql`
        mutation MyMutation {
          ${callString}
        }
      `
    });
  }

  getUtilitySupplierDetails(supplierId) {
    let callString = '', arguString = '', variables: any = {
      utilitySupplierIds: supplierId
    };
    if(supplierId) {
      for(let i = 0; i < supplierId.length; i++) {
        callString += `s${i}: getAllTariffTypesByUtilitySupplierId(utilitySupplierId: $utilitySupplierId${i}) {
          errorMessage
          isSuccessful
          tariffTypes {
            name
            id
            tariffConstraint {
              utilityType
            }
            utilitySupplierId
          }
        }`
        arguString += `, $utilitySupplierId${i}: ID = ""`;
        let test = 'utilitySupplierId' + i;
        variables = {...variables,
          [test]: supplierId[i]
        }
      }
    }
    
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: gql`
      query MyQuery($utilitySupplierIds: [ID] = ""${arguString}) {
        getUtilitySupplierDetails(utilitySupplierIds: $utilitySupplierIds) {
          utilitySupplierList {
            accountDueDate
            contactDetails {
              cellphone
              email
              website
            }
            description
            id
            name
            province
            shortName
          }
          isSuccessful
          errorMessage
        }
        ${callString}
      }
      
      
      `,
      variables: variables
    });
  }

  getWaterPipeSize(tariffTypeId) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      query MyQuery($id: ID = "") {
        getTariffType(id: $id) {
          errorMessage
          isSuccessful
          tariffType {
            tariffConstraint {
              pipeSizeDiameter
            }
          }
        }
      }`,
      variables: {
        id: tariffTypeId
      }
    })
  }

  getTariffType(tariffTypeId) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      query MyQuery($tariffTypeId: ID = "", $id: ID = "") {
        getTariffType(id: $id) {
          tariffType {
            tariffConstraint {
              circuitBreaker {
                max
                min
              }
              connectionPoint
              demand {
                max
                min
              }
              phases
              supplyVoltage {
                max
                min
              }
              tariffTypeId
              utilisation {
                max
                min
                description
              }
              utilityType
              availableNewCustomers
              availablePrepaymentCustomers
              transmissionZone {
                max
                min
              }
              keyCustomer
            }
            description
            name
            tariffCategory
            displaySeq
          }
          errorMessage
          isSuccessful
        }
        getPeriodTariffs(tariffTypeId: $tariffTypeId, limit: 10) {
          errorMessage
          isSuccessful
          periodTariffs {
            maxDemandWindow {
              percentageOver
              type
              units
              value
            }
            name
            startDate
            status
            tariffTypeId
            seasons {
              endDate
              name
              startDate
            }
            endDate
            id
          }
        }
      }
                           
      `,
      variables: {
        tariffTypeId: tariffTypeId,
        id: tariffTypeId
      }
    })
  }

  getActivePeriodTariff(tariffTypeId) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `query MyQuery($tariffTypeId: ID = "") {
        getActivePeriodTariff(tariffTypeId: $tariffTypeId) {
          periodTariff {
            endDate
            id
            maxDemandWindow {
              type
              units
              value
            }
            name
            seasons {
              endDate
              name
              startDate
            }
            startDate
            status
            tariffTypeId
          }
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        tariffTypeId: tariffTypeId
      }
    })
  }

  updateTariffCharges(tariffCharges) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      mutation MyMutation($input: [UpdateTariffsChargeInputBody] = {periodTariffId: "", updateTariffChargeInput: {display: "", minUnits: null, percentageQty: null, rates: {blockType: Step, blocks: {display: "", end: null, rate: null, start: null}, rate: null, season: All, tou: {display: "", rate: null}}, surcharge: null, type: Fixed, units: Month}, tariffChargeId: ""}) {
        updateTariffsCharge(input: $input) {
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        input: tariffCharges
      }
    })
  }

  getTariffCharges(periodTariffId) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      query MyQuery($periodTariffId: ID = "") {
        getTariffChargesForPeriodTariff(periodTariffId: $periodTariffId) {
          errorMessage
          isSuccessful
          tariffCharges {
            display
            id
            periodTariffId
            rates {
              rate
              season
              tou {
                display
                rate
              }
              blockType
              blocks {
                display
                end
                rate
                start
              }
            }
            type
            units
            surcharge
            percentageQty
            minUnits
          }
        }
      }                 
      `,
      variables: {
        periodTariffId: periodTariffId
      }
    })
  }
  

  createTariffType(tariffType, supplierId) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      mutation MyMutation($description: String = "", $displaySeq: Int = 0, $name: String = "", $tariffCategory: String = "", $utilitySupplierId: ID = "") {
        createTariffType(input: {tariffCategory: $tariffCategory, description: $description, displaySeq: $displaySeq, name: $name, utilitySupplierId: $utilitySupplierId}) {
          errorMessage
          isSuccessful
          tariffType {
            id
          }
        }
      }      
      `,
      variables: {
        description: tariffType.tariffDetail,
        displaySeq: 0,
        name: tariffType.tariffName,
        utilitySupplierId: supplierId,
        tariffCategory: tariffType.tariffCategory
      }
    })
  }

  deleteTariffType(tariffTypeId) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query:
      `
      mutation MyMutation($id: ID = "") {
        deleteTariffType(id: $id) {
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        id: tariffTypeId
      }
    })
  }

  updateAllOfTariffType(tariffType, tariffConstraints, periodTariff, utilitySupplierId, maxDemandWindow, tariffCharges, periodTariffName) {
    let callText = `
    updateTariffType(id: $tariffTypeId, input: {description: $description, displaySeq: $displaySeq, name: $tariffTypeName, tariffCategory: $tariffCategory, utilitySupplierId: $utilitySupplierId}) {
      errorMessage
      isSuccessful
    }
    updateTariffConstraint(tariffTypeId: $tariffTypeId, input: {availableNewCustomers: $availableNewCustomers, availablePrepaymentCustomers: $availablePrepaymentCustomers, circuitBreaker: $circuitBreaker, connectionPoint: $connectionPoint, demand: $demand, keyCustomer: $keyCustomer, phases: $phases, supplyVoltage: $supplyVoltage, transmissionZone: $transmissionZone, utilisation: $utilisation, utilityType: $utilityType, pipeSizeDiameter: $pipeSizeDiameter}) {
      errorMessage
      isSuccessful
    }
    updatePeriodTariff(id: $periodTariffId, input: {endDate: $endDate, maxDemandWindow: $maxDemandWindow, name: $name, nextPeriodTariffId: $nextPeriodTariffId, previousPeriodTariffId: $previousPeriodTariffId, seasons: $seasons, startDate: $startDate, status: $status}, tariffTypeId: $tariffTypeId) {
      errorMessage
      isSuccessful
    }
    `

    let variables: any = {
      utilitySupplierId: utilitySupplierId,
      tariffTypeId: tariffType.id,
      periodTariffId: periodTariff.id,
      availableNewCustomers: tariffConstraints.availableNewCustomers,
      availablePrepaymentCustomers: tariffConstraints.availablePrepaymentCustomers,
      pipeSizeDiameter: tariffConstraints.pipeSizeDiameter,
      transmissionZone: tariffConstraints.transmissionZone,
      phases: tariffConstraints.phases,
      circuitBreaker: tariffConstraints.circuitBreaker,
      connectionPoint: tariffConstraints.connectionPoint,
      demand: tariffConstraints.demand,
      keyCustomer: tariffConstraints.keyCustomer,
      utilisation: tariffConstraints.utilisation,
      supplyVoltage: tariffConstraints.supplyVoltage,
      startDate: periodTariff.startDate,
      endDate: periodTariff.endDate,
      seasons: periodTariff.seasons,
      name: periodTariffName,
      status: periodTariff.status,
      utilityType: tariffConstraints.utilityType,
      description: tariffType.tariffDetail,
      displaySeq: 0,
      tariffTypeName: tariffType.tariffName,
      tariffCategory: tariffType.tariffCategory,
      maxDemandWindow: maxDemandWindow,
    };

    let arguString: string = '';
    
    if(tariffCharges.length > 0) {
      callText += `updateTariffsCharge(input: $tariffCharges) {
        errorMessage
        isSuccessful
      }`

      arguString = '$tariffCharges: [UpdateTariffsChargeInputBody] = {periodTariffId: "", tariffChargeId: "", updateTariffChargeInput: {display: "", minUnits: null, percentageQty: null, rates: {blockType: Step, blocks: {display: "", end: null, rate: null, start: null}, rate: null, season: All, tou: {display: "", rate: null}}, surcharge: null, type: Fixed, units: Month}}'

      variables = {...variables, tariffCharges: tariffCharges} 
    }
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query:`
      mutation MyMutation($tariffTypeId: ID = "", $tariffTypeId: ID = "", $periodTariffId: ID = "", $maxDemandWindow: MaxDemandWindowInput = {type: "", units: Month, value: null}, $endDate: AWSDateTime!, $name: String = "", $nextPeriodTariffId: ID = "", $previousPeriodTariffId: ID = "", $seasons: [PeriodTariffSeasonInput] = {endDate: null, name: All, startDate: null}, $startDate: AWSDateTime!, $status: String = "", $availableNewCustomers: Boolean = false, $availablePrepaymentCustomers: Boolean = false, $circuitBreaker: MinMaxInput = {max: null, min: null}, $connectionPoint: String = "", $demand: MinMaxInput = {max: null, min: null}, $keyCustomer: Boolean = false, $phases: Int = 0, $supplyVoltage: MinMaxInput = {max: null, min: null}, $transmissionZone: MinMaxInput = {max: null, min: null}, $utilityType: String = "", $description: String = "", $displaySeq: Int = 0, $tariffTypeName: String = "", $tariffCategory: String = "", $utilitySupplierId: ID = "", $pipeSizeDiameter: Float = null, $utilisation: MinMaxInput = {max: null, min: null, description: ""}, ${arguString}) {
        ${callText}
      }                
      `,
      variables: variables
    })
  }

  createPeriodTariff(tariffTypeId, tariffPeriod, maxDemandWindow, periodTariffName) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      mutation MyMutation($endDate: AWSDateTime!, $maxDemandWindow: MaxDemandWindowInput = {percentageOver: null, type: "", units: Month, value: null}, $name: String = "", $seasons: [PeriodTariffSeasonInput] = {endDate: null, name: All, startDate: null}, $tariffTypeId: ID = "", $status: String = "", $startDate: AWSDateTime!) {
        createPeriodTariff(input: {endDate: $endDate, maxDemandWindow: $maxDemandWindow, name: $name, seasons: $seasons, tariffTypeId: $tariffTypeId, status: $status, startDate: $startDate}) {
          errorMessage
          isSuccessful
          periodTariff {
            id
          }
        }
      }
      `,
      variables: {
        tariffTypeId: tariffTypeId,
        startDate: tariffPeriod.startDate,
        endDate: tariffPeriod.endDate,
        seasons: tariffPeriod.seasons,
        name: periodTariffName,
        status: tariffPeriod.status,
        maxDemandWindow: maxDemandWindow
      }
    })
  }

  createTariffConstraintAndPeriod(supplierId, tariffTypeId, tariffConstraints, tariffPeriod, maxDemandWindow, periodTariffName) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      mutation MyMutation($availableNewCustomers: Boolean = false, $availablePrepaymentCustomers: Boolean = false, $circuitBreaker: MinMaxInput = {max: null, min: null}, $demand: MinMaxInput = {max: null, min: null}, $keyCustomer: Boolean = false, $phases: Int = null, $pipeSizeDiameter: Float = null, $supplyVoltage: MinMaxInput = {max: null, min: null}, $tariffTypeId: ID = "", $transmissionZone: MinMaxInput = {max: null, min: null}, $utilisation: MinMaxInput = {max: null, min: null, description: ""}, $utilitySupplierId: ID = "", $utilityType: String = "", $endDate: AWSDateTime!, $maxDemandWindow: MaxDemandWindowInput = {percentageOver: null, type: "", units: Month, value: null}, $seasons: [PeriodTariffSeasonInput] = {endDate: null, name: All, startDate: null}, $startDate: AWSDateTime!, $status: String = "", $name: String = "", $connectionPoint: String = "") {
        createTariffConstraint(input: {availableNewCustomers: $availableNewCustomers, availablePrepaymentCustomers: $availablePrepaymentCustomers, circuitBreaker: $circuitBreaker, connectionPoint: $connectionPoint, demand: $demand, keyCustomer: $keyCustomer, phases: $phases, pipeSizeDiameter: $pipeSizeDiameter, supplyVoltage: $supplyVoltage, tariffTypeId: $tariffTypeId, transmissionZone: $transmissionZone, utilisation: $utilisation, utilitySupplierId: $utilitySupplierId, utilityType: $utilityType}) {
          errorMessage
          isSuccessful
        }
        createPeriodTariff(input: {endDate: $endDate, maxDemandWindow: $maxDemandWindow, name: $name, seasons: $seasons, startDate: $startDate, status: $status, tariffTypeId: $tariffTypeId}) {
          errorMessage
          isSuccessful
          periodTariff {
            id
          }
        }
      }                                             
      `,
      variables: {
        availableNewCustomers: tariffConstraints.availableNewCustomers,
        availablePrepaymentCustomers: tariffConstraints.availablePrepaymentCustomers,
        pipeSizeDiameter: tariffConstraints.pipeSizeDiameter,
        transmissionZone: tariffConstraints.transmissionZone,
        utilitySupplierId: supplierId,
        tariffTypeId: tariffTypeId,
        keyCustomer: tariffConstraints.keyCustomer,
        phases: tariffConstraints.phases,
        circuitBreaker: tariffConstraints.circuitBreaker,
        connectionPoint: tariffConstraints.connectionPoint,
        demand: tariffConstraints.demand,
        utilisation: tariffConstraints.utilisation,
        supplyVoltage: tariffConstraints.supplyVoltage,
        startDate: tariffPeriod.startDate,
        endDate: tariffPeriod.endDate,
        seasons: tariffPeriod.seasons,
        name: periodTariffName,
        status: tariffPeriod.status,
        utilityType: tariffConstraints.utilityType,
        maxDemandWindow: maxDemandWindow
      }
    })
  }

  createTariffCharges(periodTariffId, tariffCharge) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      mutation MyMutation($periodTariffId: ID = "", $tariffChargeInputList: [TariffChargeInput] = {units: Month, type: Fixed, display: "", percentageQty: null, minUnits: null, surcharge: null, rates: {tou: {rate: null, display: ""}, season: All, rate: null, blockType: Step, blocks: {display: "", end: null, rate: null, start: null}}}) {
        createTariffChargeList(input: {periodTariffId: $periodTariffId, tariffChargeInputList: $tariffChargeInputList}) {
          isSuccessful
          errorMessage
        }
      }                            
      `,
      variables: {
        tariffChargeInputList: tariffCharge,
        periodTariffId: periodTariffId,
      }
    });
  }

  getBillingPeriodCharges(readingPeriod: string, unitIds, utilityNodeId, hasPrevReading) {
    let oldReadingPeriod;
    let lastDigits = Number(readingPeriod.substring(4, 6));

    if(lastDigits !== 1) {
      oldReadingPeriod = (Number(readingPeriod) - 1).toString();
    } else {
      oldReadingPeriod = (Number(readingPeriod.substring(0, 4)) - 1).toString() + '12';
    }

    let chargeCall = '';

    let readingCount = hasPrevReading ? 2: 1;

    for(let i = 0; i < readingCount; i++) {
      let varString = i === 0 ? 'a' : 'b';
      for(let o = 0; o < unitIds.length; o++) {
        chargeCall += `${varString}${o}: getBillingPeriodChargesPerPeriodReading(unitId: "${unitIds[o].unitId}", utilityNodeId: "${utilityNodeId}", readingPeriod: "${i === 0 ? readingPeriod : oldReadingPeriod}") {
          billingPeriodCharges {
            periodCharges {
              demandCharges {
                amount
                label
                quantity
                rate
                type
                units
              }
              fixedCharges {
                amount
                label
                quantity
                rate
                type
                units
              }
              periodName
              total
              utilisationCharges {
                amount
                label
                quantity
                rate
                type
                units
              }
            }
            readingDetails {
              readingDate
              readingType
            }
            periodDetails {
              name
              tariffName
            }
          }
          errorMessage
          isSuccessful
        }`
      }
    }

    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      query MyQuery {
        ${chargeCall}
      }     
      `
    })
  }
}
