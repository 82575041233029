<sym-card-dialog [title]="'Meter - ' + reading.meterNo" [closeMethod]="closeMethod">
  <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field *ngIf="thumbNails.length > 1" appearance="outline" style="margin-top: 5px;width: 90%;">
        <mat-label>Select</mat-label>
        <mat-select (selectionChange)="viewImage($event)" style="color: black;" [(ngModel)]="readingSelected">
          <mat-option *ngFor="let reading of readingList" style="color: black;" [value]="reading">
            {{reading.text + ' - ' + reading.reading}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="thumbNails.length === 1" style="display: flex;flex-direction: column;padding-bottom: 1.5em;">
        <span *ngFor="let reading of readingList" style="padding-bottom: .5em;font-weight: bold;">{{reading.text + ' - ' + reading.reading}}</span>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 2em;z-index: 10;position: sticky;" *ngIf="pictureLoaded">
        <button mat-icon-button (click)="rotateCCW()" >
          <mat-icon style="padding-right: 5px;">rotate_left</mat-icon>
        </button>
        <button mat-icon-button (click)="rotateCW()" >
          <mat-icon style="padding-right: 5px;">rotate_right</mat-icon>
        </button>
      </div>

      <div fxLayoutAlign="center center" style="padding-top: 20px;padding-bottom: 20px;" *ngIf="pictureLoaded">
        <img id="myimage" (click)="imageView = !imageView" [src]="thumbnail"  [ngStyle]="getDimensions()" style="cursor: pointer;" />
      </div>
    </div>
</div>
</sym-card-dialog>