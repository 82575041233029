import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FloorplanService {

  constructor() { }

  floorplanEndPoint = environment.api.propertyStructure;
  floorPlanManagementAPI = {
    "aws_appsync_graphqlEndpoint" : this.floorplanEndPoint
  };

  getUnitIdWithUnitNo(unitNo) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: `
      query MyQuery($unitNo: String = "") {
        getUnitByName(unitNo: $unitNo) {
          unit {
            id
          }
          errorMessage
          isSuccessful
        }
      }`,
      variables: {
        unitNo: unitNo
      }
    })
  }

  deleteUnitNode(unitId, code) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: `
      mutation MyMutation($unitId: ID = "", $code: String = "") {
        deleteUnit(unitId: $unitId, code: $code) {
          errorMessage
          isSuccessful
        }
      }      
      `,
      variables: {
        unitId: unitId,
        code: code
      }
    })
  }

  getUnitListPerProperty(propertyId) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $limit: Int = 10) {
        getUnitListPerProperty(limit: $limit, propertyId: $propertyId) {
          unitList {
            occupied
            sqm
            unitType
            id
            unitNo
          }
          isSuccessful
          nextToken
          errorMessage
        }
      }           
      `,
      variables: {
        propertyId: propertyId
      }
    })
  }

  createPropertyNode(property, supplierIds) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($address: String = "", $code: String = "", $name: String = "", $utilitySupplierIds: [String] = "") {
        createProperty(input: {address: $address, code: $code, name: $name, utilitySupplierIds: $utilitySupplierIds}) {
          errorMessage
          isSuccessful
          propertyId
        }
      }      
      `,
      variables: {
        address: property.address,
        name: property.name,
        code: property.code,
        utilitySupplierIds: supplierIds
      }
    })
  }

  createUnitNodeForArea(unit, propertyId, areaId, sqm) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($unitType: UnitType = RENTABLE_UNIT, $unitNo: String = "", $sqm: Float = 1.5, $propertyId: String = "", $areaId: String = "", $code: String = "") {
        createUnit(input: {unitType: $unitType, unitNo: $unitNo, sqm: $sqm, propertyId: $propertyId, areaId: $areaId, code: $code}) {
          errorMessage
          isSuccessful
          unitId
        }
      }   
      
      `,
      variables: {
        areaId: areaId,
        propertyId: propertyId,
        sqm: sqm,
        unitType: unit.unitType,
        unitNo: unit.name,
        code: unit.code
      }
    })
  }

  createUnitNode(unit, propertyId, sqm) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($unitType: UnitType = RENTABLE_UNIT, $unitNo: String = "", $sqm: Float = 1.5, $propertyId: String = "", $code: String = "") {
        createUnit(input: {unitType: $unitType, unitNo: $unitNo, sqm: $sqm, propertyId: $propertyId, code: $code}) {
          errorMessage
          isSuccessful
          unitId
        }
      }        
      
      `,
      variables: {
        propertyId: propertyId,
        sqm: sqm,
        unitType: unit.unitType,
        unitNo: unit.name,
        code: unit.code
      }
    })
  }

  createAreaNode(area, propertyId) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($name: String = "", $propertyId: String = "") {
        createArea(input: {name: $name, propertyId: $propertyId}) {
          isSuccessful
          areaId
          errorMessage
        }
      }      
      
      `,
      variables: {
        propertyId: propertyId,
        name: area.name
      }
    })
  }

  moveUnitsToArea(propertyId, areaId, unitList) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($newAreaId: String = "", $propertyId: String = "", $updateUnitInput: [UpdatesUnitsInputs] = {unitId: "", areaId: "", propertyId: "", unitNo: ""}) {
        updateUnitsArea(input: {newAreaId: $newAreaId, propertyId: $propertyId, updateUnitInput: $updateUnitInput}) {
          errorMessage
          isSuccessful
        }
      }      
      `,
      variables: {
        newAreaId: areaId,
        propertyId: propertyId,
        updateUnitInput: unitList
      }
    })
  }

  updateProperty(property) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($address: String = "", $code: String = "", $id: String = "", $name: String = "", $utilitySupplierIds: [String] = "") {
        updateProperty(input: {address: $address, code: $code, id: $id, name: $name, utilitySupplierIds: $utilitySupplierIds}) {
          errorMessage
          isSuccessful
        }
      }
      `,
      variables:{
        id: property.id,
        utilitySupplierIds: property.supplierIds,
        code: property.code,
        name: property.name,
        address: property.address
      }
    })
  }

  updateArea(id, name) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($id: String = "", $name: String = "") {
        updateArea(input: {id: $id, name: $name}) {
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        id: id,
        name: name
      }
    })
  }

  updateUnit(propertyId, unit, sqm) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($propertyId: String = "", $sqm: Float = 1.5, $unitId: String = "", $unitNo: String = "", $unitType: UnitType = RENTABLE_UNIT, $code: String = "") {
        updateUnit(input: {propertyId: $propertyId, sqm: $sqm, unitId: $unitId, unitNo: $unitNo, unitType: $unitType, code: $code}) {
          errorMessage
          isSuccessful
        }
      }
           
      `,
      variables: {
        unitType: unit.unitType,
        sqm: sqm,
        unitId: unit.id,
        propertyId: propertyId,
        unitNo: unit.name,
        code: unit.code
      }
    })
  }

  getUnitsAndAreasForProperty(propertyId) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $limit: Int = 500) {
        getTopLevelUnitListPerProperty(limit: $limit, propertyId: $propertyId) {
          unitList {
            id
            occupied
            sqm
            unitType
            unitNo
            code
          }
          isSuccessful
          errorMessage
        }
        getUnitListPerProperty(limit: 300, propertyId: $propertyId) {
          unitList {
            id
            unitNo
            areaId
            code
          }
        }
        getTopLevelAreaListByPropertyId(limit: $limit, propertyId: $propertyId) {
          areaList {
            name
            numberOfAreas
            numberOfUnits
            id
            totalSQM
            parentAreaId
          }
          isSuccessful
          errorMessage
        }
      }     
            
      `,
      variables: {
        propertyId: propertyId
      }
    })
  }

  getUnitsAndAreasForArea(area) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($parentAreaId: String = "", $areaId: String = "", $limit: Int = 100) {
        getSubAreaListByparentAreaId(limit: $limit, parentAreaId: $parentAreaId) {
          areaList {
            numberOfAreas
            numberOfUnits
            id
            name
            totalSQM
            parentAreaId
          }
          isSuccessful
          errorMessage
        }
        getUnitListPerArea(areaId: $areaId, limit: $limit) {
          unitList {
            id
            occupied
            sqm
            unitType
            unitNo
            code
          }
          errorMessage
          isSuccessful
        }
      }
      
            
      `,
      variables: {
        parentAreaId: area.id,
        areaId: area.id
      }
    })
  }

  getAllProperties(readingPeriod) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($limit: Int = 50, $readingPeriod: String = "") {
        getPeriodReadingLandingPageList(limit: $limit, readingPeriod: $readingPeriod) {
          isSuccessful
          errorMessage
          propertyList {
            address
            code
            id
            name
            numberOfAreas
            numberOfUnits
            totalSQM
            utilitySupplierIds
            meterCounts {
              totalCapturedMeters
              totalMeters
              totalNotCapturedMeters
              notSubmitted
              notApproved
            }
          }
        }
      }
      `,
      variables: {
        readingPeriod: readingPeriod
      }
    })
  }

  getAllPropertyMeterCounts(readingPeriod) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($limit: Int = 50, $readingPeriod: String = "") {
        getPeriodReadingLandingPageList(limit: $limit, readingPeriod: $readingPeriod) {
          isSuccessful
          errorMessage
          propertyList {
            id
            meterCounts {
              totalCapturedMeters
              totalMeters
              totalNotCapturedMeters
              notApproved
              notSubmitted
            }
            name
          }
        }
      }
      
      
      `,
      variables: {
        readingPeriod: readingPeriod
      }
    })
  }


  createAreaForArea(area, propertyId, parentAreaId?) {
    API.configure(this.floorPlanManagementAPI);
    return API.graphql({
      query: gql`
        mutation MyMutation($name: String = "", $parentAreaId: String = "", $propertyId: String = "") {
          createArea(input: {name: $name, parentAreaId: $parentAreaId, propertyId: $propertyId}) {
            isSuccessful
            areaId
            errorMessage
          }
        }
      
      `,
      variables: {
        propertyId: propertyId,
        parentAreaId: parentAreaId,
        name: area.name,
      }
    })
  }
}
