import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  item: any;
  public clientList: any;

  constructor(private http: HttpClient) {
  }

  uploadDocumentToS3(url, file) {
    return this.http.put(url, file);
  }
}
