import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'sym-view-comment-dialog',
  templateUrl: './view-comment-dialog.component.html',
  styleUrls: ['./view-comment-dialog.component.scss']
})
export class ViewCommentDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    "date",
    "comment",
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data,  public dialogRef: MatDialogRef<ViewCommentDialogComponent>) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.commentList);
    this.dataSource.paginator = this.paginator;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
