<sym-card-dialog *ngIf="!hasToUpload" [buttonProperties]="buttonProperties" [isValid]="readingDetails.valid" [title]="title + (onePhase ? ' Reading' : ' Readings')" [closeMethod]="closeMethod">
  <ng-template [ngTemplateOutlet]="readingForm"></ng-template>
</sym-card-dialog>

<sym-card-dialog *ngIf="hasToUpload" [buttonProperties]="buttonProperties" [isValid]="readingDetails.valid && count === uploadSize" [title]="title + (onePhase ? ' Reading' : ' Readings')" [closeMethod]="closeMethod">
  <ng-template [ngTemplateOutlet]="readingForm"></ng-template>
</sym-card-dialog>

<ng-template #readingForm>
  <mat-dialog-content>
    <div >
      <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;height: 120%!important;">
        <div fxLayout="column" style="padding-bottom: 2em;">
          <!-- <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Tenant Name</mat-label>
            <input matInput readonly [(ngModel)]="meter.tradingName" [disabled]="true">
          </mat-form-field> -->
          <mat-form-field appearance="outline">
            <mat-label>Serial Number</mat-label>
            <input matInput readonly [(ngModel)]="meter.meter.serialNumber" [disabled]="true">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Utility Type</mat-label>
            <input matInput readonly [(ngModel)]="meter.meter.utilityType" [disabled]="true">
          </mat-form-field>
      
        </div>
        <form [formGroup]="readingDetails" fxLayout="column">
          <div fxLayout="column">
            <mat-form-field *ngIf="superUser" appearance="outline" style="margin-top: 5px; width: 100%;color: black;">
              <mat-label>Reading date</mat-label>
              <input matInput required *ngIf="!showDates" [matDatepicker]="picker" formControlName="readingDate">
              <!-- <input matInput required [matDatepicker]="picker" formControlName="readingDate" *ngIf="showDates"> -->
              <input matInput required [matDatepicker]="picker" formControlName="readingDate" *ngIf="showDates" [min]="minDate" [max]="maxDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
    
            <div *ngIf="!superUser" style="margin-bottom: 1em;">
              <span style="font-weight: bold;font-size: 18px;">Reading Date: </span><span style="font-size: 18px;">{{currentDate | date:'yyyy-MM-dd'}}</span>
            </div>
        
            
          </div>
          <div fxLayout="column" *ngIf="onePhase">
            <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;">
              <mat-label>Capture Reading</mat-label>
              <input matInput required style="color: black;" formControlName="singleReading" type="number">
            </mat-form-field>
            <div fxLayout="row">  
              <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r1 || !filesToUpload.r1.name">
                <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 1" (click)="addFile(1)"><mat-icon style="color: white;">note_add</mat-icon></button>
              </div>
            </div>
            <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r1 && filesToUpload.r1.name">
              <div fxFlex="45">
                <div style="padding: 10px">
                  <label style="padding-right: 5px;">Image Name</label>
                </div>
              </div>
              <div fxFlex="45">
                <div style="padding: 10px">
                  <label style="padding-right: 5px;">Image Size</label>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r1 && filesToUpload.r1.name">
              <div fxFlex="45">
                <div fxLayout="row" style="padding: 10px">
                  <label style="font-weight: bold;" matTooltip="{{filesToUpload.r1.name}}">{{ filesToUpload.r1.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r1.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r1.name}}</label>
                </div>
              </div>
              <div fxFlex="45">
                <div fxLayout="row" style="padding: 10px">
                  <strong>{{ filesToUpload.r1.size/1024/1024 | number:'1.0-2' }} MB</strong>
                </div>
              </div>
              <div fxFlex="10">
                <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(1)">
                <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                    delete
                </mat-icon>
             </button>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="100" *ngIf="(threePhase || threePhaseTwoImage) && !threePhase_one" fxLayoutGap="5px" style="margin-top: 5px;width: 100%;">
            <div fxLatout="row" fxLayoutGap="5px">
              <div fxFlex="50" fxLayout="column">
                <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;">
                  <mat-label>Phase 1</mat-label>
                  <input matInput required style="color: black;" formControlName="phase1" type="number">
                </mat-form-field>
                <div fxLayout="row">  
                  <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r1 || !filesToUpload.r1.name">
                    <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 1" (click)="addFile(1)"><mat-icon style="color: white;">note_add</mat-icon></button>
                  </div>
                </div>
                <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r1 && filesToUpload.r1.name">
                  <div fxFlex="45">
                    <div style="padding: 10px">
                      <label style="padding-right: 5px;">Image 1 Name</label>
                    </div>
                  </div>
                  <div fxFlex="45">
                    <div style="padding: 10px">
                      <label style="padding-right: 5px;">Image 1 Size</label>
                    </div>
                  </div>
                </div>
                <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r1 && filesToUpload.r1.name">
                  <div fxFlex="45">
                    <div fxLayout="row" style="padding: 10px">
                      <label style="font-weight: bold;" matTooltip="{{filesToUpload.r1.name}}">{{ filesToUpload.r1.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r1.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r1.name}}</label>
                    </div>
                  </div>
                  <div fxFlex="45">
                    <div fxLayout="row" style="padding: 10px">
                      <strong>{{ filesToUpload.r1.size/1024/1024 | number:'1.0-2' }} MB</strong>
                    </div>
                  </div>
                  <div fxFlex="10">
                    <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(1)">
                    <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                        delete
                    </mat-icon>
                 </button>
                  </div>
                </div>
              </div> 
              <div fxFlex="50" fxLayout="column">
                <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;">
                  <mat-label>{{threePhaseTwoImage ? 'KVA' : 'Phase 2'}}</mat-label>
                  <input matInput required style="color: black;" formControlName="phase2" type="number">
                </mat-form-field>
                <div fxLayout="row">  
                  <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r2 || !filesToUpload.r2.name">
                    <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 2" (click)="addFile(2)"><mat-icon style="color: white;">note_add</mat-icon></button>
                  </div>
                </div>
                <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r2 && filesToUpload.r2.name">
                  <div fxFlex="45">
                    <div style="padding: 10px">
                      <label style="padding-right: 5px;">Image 2 Name</label>
                    </div>
                  </div>
                  <div fxFlex="45">
                    <div style="padding: 10px">
                      <label style="padding-right: 5px;">Image 2 Size</label>
                    </div>
                  </div>
                </div>
                <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r2 && filesToUpload.r2.name">
                  <div fxFlex="45">
                    <div fxLayout="row" style="padding: 10px">
                      <label style="font-weight: bold;" matTooltip="{{filesToUpload.r2.name}}">{{ filesToUpload.r2.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r2.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r2.name}}</label>
                    </div>
                  </div>
                  <div fxFlex="45">
                    <div fxLayout="row" style="padding: 10px">
                      <strong>{{ filesToUpload.r2.size/1024/1024 | number:'1.0-2' }} MB</strong>
                    </div>
                  </div>
                  <div fxFlex="10">
                    <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(2)">
                    <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                        delete
                    </mat-icon>
                 </button>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlexLayout="row">
              <div fxFlex="50" fxLayout="column" *ngIf="!threePhaseTwoImage">
                <mat-form-field appearance="outline" style="margin-top: 5px; width: 100%;">
                  <mat-label>Phase 3</mat-label>
                  <input matInput required style="color: black;" formControlName="phase3" type="number">
                </mat-form-field>
                <div fxLayout="row">  
                  <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r3 || !filesToUpload.r3.name">
                    <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 3" (click)="addFile(3)"><mat-icon style="color: white;">note_add</mat-icon></button>
                  </div>
                </div>
                <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r3 && filesToUpload.r3.name">
                  <div fxFlex="45">
                    <div style="padding: 10px">
                      <label style="padding-right: 5px;">Image 3 Name</label>
                    </div>
                  </div>
                  <div fxFlex="45">
                    <div style="padding: 10px">
                      <label style="padding-right: 5px;">Image 3 Size</label>
                    </div>
                  </div>
                </div>
                <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r3 && filesToUpload.r3.name">
                  <div fxFlex="45">
                    <div fxLayout="row" style="padding: 10px">
                      <label style="font-weight: bold;" matTooltip="{{filesToUpload.r3.name}}">{{ filesToUpload.r3.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r3.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r3.name}}</label>
                    </div>
                  </div>
                  <div fxFlex="45">
                    <div fxLayout="row" style="padding: 10px">
                      <strong>{{ filesToUpload.r3.size/1024/1024 | number:'1.0-2' }} MB</strong>
                    </div>
                  </div>
                  <div fxFlex="10">
                    <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(3)">
                    <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                        delete
                    </mat-icon>
                 </button>
                  </div>
                </div>
              </div>
            </div>
              
            </div>
            <div *ngIf="threePhase_one" fxLayout="column">
              <div fxLayout="row" fxFlex="100" fxLayoutGap="5px" style="margin-top: 5px;width: 100%;">
                <div fxFlex="50" fxLayout="column">
                  <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;">
                    <mat-label>Phase 1</mat-label>
                    <input matInput required style="color: black;" formControlName="phase1" type="number">
                  </mat-form-field>
                  <div fxLayout="row">  
                    <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r1 || !filesToUpload.r1.name">
                      <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 1" (click)="addFile(1)"><mat-icon style="color: white;">note_add</mat-icon></button>
                    </div>
                  </div>
                  <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r1 && filesToUpload.r1.name">
                    <div fxFlex="45">
                      <div style="padding: 10px">
                        <label style="padding-right: 5px;">Image 1 Name</label>
                      </div>
                    </div>
                    <div fxFlex="45">
                      <div style="padding: 10px">
                        <label style="padding-right: 5px;">Image 1 Size</label>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r1 && filesToUpload.r1.name">
                    <div fxFlex="45">
                      <div fxLayout="row" style="padding: 10px">
                        <label style="font-weight: bold;" matTooltip="{{filesToUpload.r1.name}}">{{ filesToUpload.r1.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r1.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r1.name}}</label>
                      </div>
                    </div>
                    <div fxFlex="45">
                      <div fxLayout="row" style="padding: 10px">
                        <strong>{{ filesToUpload.r1.size/1024/1024 | number:'1.0-2' }} MB</strong>
                      </div>
                    </div>
                    <div fxFlex="10">
                      <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(1)">
                      <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                          delete
                      </mat-icon>
                   </button>
                    </div>
                  </div>
                </div>
    
                <div fxFlex="50" fxLayout="column">
                  <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;">
                    <mat-label>Phase 2</mat-label>
                    <input matInput required style="color: black;" formControlName="phase2" type="number">
                  </mat-form-field>
                  <div fxLayout="row">  
                    <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r2 || !filesToUpload.r2.name">
                      <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 2" (click)="addFile(2)"><mat-icon style="color: white;">note_add</mat-icon></button>
                    </div>
                  </div>
                  <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r2 && filesToUpload.r2.name">
                    <div fxFlex="45">
                      <div style="padding: 10px">
                        <label style="padding-right: 5px;">Image 2 Name</label>
                      </div>
                    </div>
                    <div fxFlex="45">
                      <div style="padding: 10px">
                        <label style="padding-right: 5px;">Image 2 Size</label>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r2 && filesToUpload.r2.name">
                    <div fxFlex="45">
                      <div fxLayout="row" style="padding: 10px">
                        <label style="font-weight: bold;" matTooltip="{{filesToUpload.r2.name}}">{{ filesToUpload.r2.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r2.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r2.name}}</label>
                      </div>
                    </div>
                    <div fxFlex="45">
                      <div fxLayout="row" style="padding: 10px">
                        <strong>{{ filesToUpload.r2.size/1024/1024 | number:'1.0-2' }} MB</strong>
                      </div>
                    </div>
                    <div fxFlex="10">
                      <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(2)">
                      <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                          delete
                      </mat-icon>
                   </button>
                    </div>
                  </div>
                </div>
              </div>
    
              <div fxLayout="row" fxFlex="100" fxLayoutGap="5px" style="margin-top: 5px;width: 100%;">
                <div fxFlex="50" fxLayout="column">
                  <mat-form-field appearance="outline" style="margin-top: 5px; width: 100%;">
                    <mat-label>Phase 3</mat-label>
                    <input matInput required style="color: black;" formControlName="phase3" type="number">
                  </mat-form-field>
                  <div fxLayout="row">  
                    <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r3 || !filesToUpload.r3.name">
                      <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 3" (click)="addFile(3)"><mat-icon style="color: white;">note_add</mat-icon></button>
                    </div>
                  </div>
                  <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r3 && filesToUpload.r3.name">
                    <div fxFlex="45">
                      <div style="padding: 10px">
                        <label style="padding-right: 5px;">Image 3 Name</label>
                      </div>
                    </div>
                    <div fxFlex="45">
                      <div style="padding: 10px">
                        <label style="padding-right: 5px;">Image 3 Size</label>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r3 && filesToUpload.r3.name">
                    <div fxFlex="45">
                      <div fxLayout="row" style="padding: 10px">
                        <label style="font-weight: bold;" matTooltip="{{filesToUpload.r3.name}}">{{ filesToUpload.r3.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r3.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r3.name}}</label>
                      </div>
                    </div>
                    <div fxFlex="45">
                      <div fxLayout="row" style="padding: 10px">
                        <strong>{{ filesToUpload.r3.size/1024/1024 | number:'1.0-2' }} MB</strong>
                      </div>
                    </div>
                    <div fxFlex="10">
                      <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(3)">
                      <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                          delete
                      </mat-icon>
                   </button>
                    </div>
                  </div>
                </div>
                <div fxFlex="50" fxLayout="column">
                  <mat-form-field appearance="outline" style="margin-top: 5px; width: 100%;">
                    <mat-label>KVA</mat-label>
                    <input matInput required style="color: black;" formControlName="phase4" type="number">
                  </mat-form-field>
                  <div fxLayout="row">  
                    <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r4 || !filesToUpload.r4.name">
                      <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 4" (click)="addFile(4)"><mat-icon style="color: white;">note_add</mat-icon></button>
                    </div>
                  </div>
                  <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r4 && filesToUpload.r4.name">
                    <div fxFlex="45">
                      <div style="padding: 10px">
                        <label style="padding-right: 5px;">Image 4 Name</label>
                      </div>
                    </div>
                    <div fxFlex="45">
                      <div style="padding: 10px">
                        <label style="padding-right: 5px;">Image 4 Size</label>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r4 && filesToUpload.r4.name">
                    <div fxFlex="45">
                      <div fxLayout="row" style="padding: 10px">
                        <label style="font-weight: bold;" matTooltip="{{filesToUpload.r4.name}}">{{ filesToUpload.r4.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r4.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r4.name}}</label>
                      </div>
                    </div>
                    <div fxFlex="45">
                      <div fxLayout="row" style="padding: 10px">
                        <strong>{{ filesToUpload.r4.size/1024/1024 | number:'1.0-2' }} MB</strong>
                      </div>
                    </div>
                    <div fxFlex="10">
                      <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(4)">
                      <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                          delete
                      </mat-icon>
                   </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div fxLayout="column">
              <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;">
                <mat-label>Reading Method</mat-label>
                <mat-select style="color: black;" formControlName="readingType" required>
                  <mat-option *ngFor="let type of readingTypes" style="color: black;" [value]="type" required>
                    {{type}}</mat-option>
                </mat-select>
              </mat-form-field>
    
              <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;">
                <mat-label>Comment</mat-label>
                <input matInput style="color: black;" [ngModelOptions]="{standalone: true}" [(ngModel)]="comment">
              </mat-form-field>
            </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>