import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MeterManagementService {

  constructor() { }

  meterManagementEndPoint = environment.api.meterManagement;
  meterManagementAPI = {
    "aws_appsync_graphqlEndpoint" : this.meterManagementEndPoint
  };

  getMeterDetails(meterId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query RetrieveTariffIdForMeter($id: String = "") {
        getUtilityNode(id: $id) {
          utilityNode {
            meterNode {
              tariffIds
              captureType
              meterType
              meterReadingType
              isManual
              capturedStatus
            }
            parentUtilityNodeId
          }
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        id: meterId,
      }
    })
  }

  getTariffIdForMeter(meterId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query RetrieveTariffIdForMeter($id: String = "", $utilityNodeId: ID = "") {
        getUtilityNode(id: $id) {
          utilityNode {
            meterNode {
              tariffIds
              captureType
              meterType
              meterReadingType
              isManual
              capturedStatus
            }
            parentUtilityNodeId
          }
          errorMessage
          isSuccessful
        }
        getLinksPerNode(utilityNodeId: $utilityNodeId, unitLinkType: SQM) {
          nodeUnitLinkList {
            unitId
          }
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        id: meterId,
        utilityNodeId: meterId
      }
    })
  }

  createAllocationNode(propertyId, nodeId, value, name) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($allocationNodeType: AllocationNodeType = PERC, $allocationNodeValue: Float = 1.5, $parentUtilityNodeId: ID = "", $propertyId: String = "") {
        createAllocationNode(input: {allocationNodeType: $allocationNodeType, allocationNodeValue: $allocationNodeValue, parentUtilityNodeId: $parentUtilityNodeId, propertyId: $propertyId}) {
          allocationNodeId
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        parentUtilityNodeId: nodeId,
        allocationNodeValue: value,
        name: name
      }
    })
  }

  getLinkForNode(nodeId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($utilityNodeId: ID = "") {
        getLinksPerNode(utilityNodeId: $utilityNodeId) {
          nodeUnitLinkList {
            allocationRules {
              allocationRuleType
              value
            }
            unitId
            unitLinkType
            unitLinkValue
            utilityNode {
              allocationNode {
                name
                allocationNodeValue
              }
              id
              meterNode {
                meterNo
                name
              }
              parentUtilityNodeId
              nodeType
            }
            utilityNodeId
          }
          isSuccessful
          errorMessage
        }
      }
      `,
      variables: {
        utilityNodeId: nodeId
      }
    })
  }

  createNodeUnitLink(unitId, nodeId, linkType, value) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($unitId: ID = "", $unitLinkType: UnitLinkType = SQM, $unitLinkValue: Float = 1.5, $utilityNodeId: ID = "") {
        createNodeUnitLink(input: {unitLinkType: $unitLinkType, unitLinkValue: $unitLinkValue, utilityNodeId: $utilityNodeId, unitId: $unitId}) {
          isSuccessful
          errorMessage
        }
      }
      `,
      variables: {
        unitId: unitId,
        utilityNodeId: nodeId,
        unitLinkType: linkType,
        unitLinkValue: value
      }
    })
  }

  createMeteredUnitLink(unitId, nodeId, linkType) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($unitId: ID = "", $utilityNodeId: ID = "", $unitLinkType: UnitLinkType = SQM) {
        createNodeUnitLink(input: {unitLinkType: $unitLinkType, utilityNodeId: $utilityNodeId, unitId: $unitId}) {
          isSuccessful
          errorMessage
        }
      }
      `,
      variables: {
        unitId: unitId,
        utilityNodeId: nodeId,
        unitLinkType: linkType
      }
    })
  }

  checkIfMeterNumberExists(propertyId, utilityType, meterNo) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($meterNo: String = "", $propertyId: ID = "", $utilityType: UtilityType = ELEC_3P_1) {
        checkIfMeterNumberExists(meterNo: $meterNo, propertyId: $propertyId, utilityType: $utilityType) {
          errorMessage
          isSuccessful
          meterNoExists
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        utilityType: utilityType,
        meterNo: meterNo
      }
    })
  }

  getLinksForUnit(unitId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($unitId: ID = "") {
        getLinksForUnit(unitId: $unitId) {
          errorMessage
          isSuccessful
          nodeUnitLinkList {
            allocationRules {
              allocationRuleType
              value
            }
            unitLinkType
            unitLinkValue
            utilityNodeId
            utilityNode {
              allocationNode {
                allocationNodeType
                allocationNodeValue
                name
              }
              id
              meterNode {
                amps
                commissionDate
                generalUtilityType
                isManual
                meterNo
                meterType
                name
                noOfPhotos
                status
                tariffIds
                voltageClassId
                utilityType
                captureType
                meterReadingType
                capturedStatus
                utilityNodeId
              }
              nodeType
              parentUtilityNodeId
            }
          }
        }
      }           
      `,
      variables: {
        unitId: unitId
      }
    })
  }

  getMeterIdsForUnit(unitId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: `
      query MyQuery($unitId: ID = "") {
        getLinksForUnit(unitId: $unitId) {
          errorMessage
          isSuccessful
          nodeUnitLinkList {
            utilityNode {
              meterNode {
                meterNo
              }
              id
              nodeType
            }
          }
        }
      }
      
      `,
      variables: {
        unitId: unitId
      }
    })
  }

  migrateMeter(oldPropertyId, newPropertyId, meter) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterNo: String = "", $meterNodeId: String = "", $migrationReading: String = "", $migrationReason: String = "", $newPropertyId: String = "", $oldPropertyId: String = "", $utilityType: UtilityType = ELEC_3P_1) {
        migrateMeterNode(input: {meterNo: $meterNo, meterNodeId: $meterNodeId, migrationReading: $migrationReading, migrationReason: $migrationReason, newPropertyId: $newPropertyId, oldPropertyId: $oldPropertyId, utilityType: $utilityType}) {
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        oldPropertyId: oldPropertyId,
        newPropertyId: newPropertyId,
        migrationReading: meter.reading,
        meterNo: meter.meterNo,
        utilityType: meter.utilityType,
        migrationReason: meter.reason,
        meterNodeId: meter.id
      }
    })
  }

  updateMeterNode(meter, propertyId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($amps: String = "", $commissionDate: AWSDateTime, $isManual: Boolean = false, $meterType: MeterType = UNIT, $noOfPhotos: Int = 10, $voltageClassId: String = "", $utilityNodeId: ID = "", $propertyId: ID = "", $captureType: CaptureType = ONLINE, $meterReadingType: MeterReadingType = READING, $utilityType: UtilityType = ELEC_3P_1, $tariffIds: [String] = "") {
        updateMeterNode(input: {amps: $amps, commissionDate: $commissionDate, isManual: $isManual, meterType: $meterType, noOfPhotos: $noOfPhotos, voltageClassId: $voltageClassId, captureType: $captureType, meterReadingType: $meterReadingType, tariffIds: $tariffIds}, utilityNodeId: $utilityNodeId, propertyId: $propertyId, utilityType: $utilityType) {
          errorMessage
          isSuccessful
        }
      }                          
      `,
      variables: {
        utilityNodeId : meter.id,
        commissionDate : meter.commissionDate,
        isManual : meter.isManual,
        noOfPhotos : meter.utilityType.image,
        tariffIds: meter.tariff,
        voltageClassId: meter.voltageId,
        utilityType: meter.utilityType.name,
        amps: meter.amps,
        meterType: 'UNIT',
        propertyId: propertyId,
        captureType: meter.captureType,
        meterReadingType: meter.meterReadingType
      }
    })
  }

  updateMeterNumber(meter, propertyId, oldMeterNumber) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($amps: String = "", $commissionDate: AWSDateTime, $isManual: Boolean = false, $meterType: MeterType = UNIT, $noOfPhotos: Int = 10, $voltageClassId: String = "", $utilityNodeId: ID = "", $propertyId: ID = "", $newMeterNumber: String = "", $oldMeterNumber: String = "", $captureType: CaptureType = ONLINE, $utilityType: UtilityType = ELEC_3P_1, $tariffIds: [String] = "") {
        updateMeterNode(input: {amps: $amps, commissionDate: $commissionDate, isManual: $isManual, meterType: $meterType, noOfPhotos: $noOfPhotos, voltageClassId: $voltageClassId, oldMeterNumber: $oldMeterNumber, newMeterNumber: $newMeterNumber, captureType: $captureType, tariffIds: $tariffIds}, utilityNodeId: $utilityNodeId, propertyId: $propertyId, utilityType: $utilityType) {
          errorMessage
          isSuccessful
        }
      }                                    
      `,
      variables: {
        utilityNodeId : meter.id,
        commissionDate : meter.commissionDate,
        isManual : meter.isManual,
        noOfPhotos : meter.utilityType.image,
        tariffIds: meter.tariff,
        voltageClassId: meter.voltageId,
        amps: meter.amps,
        meterType: 'UNIT',
        utilityType: meter.utilityType.name,
        oldMeterNumber: oldMeterNumber,
        propertyId: propertyId,
        newMeterNumber: meter.meterNo,
        captureType: meter.captureType,
        meterReadingType: meter.meterReadingType
      }
    })
  }

  createMeterNode(propertyId, meter) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($utilityType: UtilityType = ELEC_3P_1, $propertyId: String = "", $noOfPhotos: Int = 10, $meterType: MeterType = UNIT, $meterNo: String = "", $isManual: Boolean = false, $commissionDate: AWSDateTime, $amps: String = "", $captureType: CaptureType = ONLINE, $commissionReading: [ReadingInput!] = {sequence: "", value: 1.5}, $meterReadingType: MeterReadingType = READING, $tariffIds: [String] = "") {
        createMeterNode(input: {utilityType: $utilityType, propertyId: $propertyId, noOfPhotos: $noOfPhotos, meterType: $meterType, meterNo: $meterNo, isManual: $isManual, commissionDate: $commissionDate, amps: $amps, captureType: $captureType, commissionReading: $commissionReading, meterReadingType: $meterReadingType, tariffIds: $tariffIds}) {
          isSuccessful
          meterNodeId
          errorMessage
        }
      }                             
      `,
      variables: {
        utilityType: meter.utilityType.name,
        propertyId: propertyId,
        isManual: meter.isManual,
        meterNo: meter.meterNo,
        commissionReading: meter.commissionReadings,
        commissionDate: meter.commissionDate,
        noOfPhotos: meter.utilityType.image,
        tariffIds: meter.tariff,
        amps: meter.amps,
        meterType: 'UNIT',
        // parentUtilityNodeId: meter.bulkMeter
        captureType: meter.captureType,
        meterReadingType: meter.meterReadingType
      }
    })
  }

  decommissionAndCreateMeterNode(meter, propertyId, oldMeterId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($decommissionReason: String = "", $meterNodeId: String = "", $voltageClassId: String = "", $utilityType: UtilityType = ELEC_3P_1, $propertyId: String = "", $noOfPhotos: Int = 10, $meterType: MeterType = UNIT, $meterNo: String = "", $isManual: Boolean = false, $commissionDate: AWSDateTime, $amps: String = "", $captureType: CaptureType = ONLINE, $commissionReading: [ReadingInput!] = {sequence: "", value: 1.5}, $meterReadingType: MeterReadingType = READING, $tariffIds: [String] = "") {
        decommissionMeterNode(input: {decommissionReason: $decommissionReason, meterNodeId: $meterNodeId, replacementMeterNode: {voltageClassId: $voltageClassId, utilityType: $utilityType, propertyId: $propertyId, noOfPhotos: $noOfPhotos, meterType: $meterType, meterNo: $meterNo, isManual: $isManual, commissionDate: $commissionDate, amps: $amps, captureType: $captureType, commissionReading: $commissionReading, meterReadingType: $meterReadingType, tariffIds: $tariffIds}, propertyId: $propertyId}) {
          replacementMeterNodeId
          isSuccessful
          errorMessage
        }
      }
      `,
      variables: {
        decommissionReason: meter.decommissionReason,
        meterNodeId: oldMeterId,
        utilityType: meter.utilityType.name,
        propertyId: propertyId,
        isManual: meter.isManual,
        commissionReading: meter.commissionReadings,
        meterNo: meter.meterNo,
        commissionDate: meter.commissionDate,
        noOfPhotos: meter.utilityType.image,
        tariffIds: meter.tariff,
        voltageClassId: meter.voltageId,
        amps: meter.amps,
        meterType: 'UNIT',
        captureType: meter.captureType,
        meterReadingType: meter.meterReadingType
      }
    })
  }

  decommissionMeterNode(decommissionDetails, meterId, propertyId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($decommissionReason: String = "", $meterNodeId: String = "", $propertyId: String = "") {
        decommissionMeterNode(input: {decommissionReason: $decommissionReason, meterNodeId: $meterNodeId, propertyId: $propertyId}) {
          errorMessage
          isSuccessful
          replacementMeterNodeId
        }
      }              
      `,
      variables: {
        decommissionReason: decommissionDetails.decommissionReason,
        meterNodeId: meterId,
        propertyId: propertyId
      }
    })
  }



  deleteNodeUnitLink(unitId, nodeId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($utilityNodeId: ID = "", $unitId: ID = "") {
        deleteNodeUnitLink(unitId: $unitId, utilityNodeId: $utilityNodeId) {
          isSuccessful
          errorMessage
        }
      }`,
      variables: {
        unitId: unitId,
        utilityNodeId: nodeId
      }
    })
  }

  getNodeByProperty(propertyId) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: ID = "") {
        getNodesPerProperty(propertyId: $propertyId) {
          utilityNodeList {
            allocationNode {
              utilityNodeId
              allocationNodeValue
              allocationNodeType
              name
            }
            id
            meterNode {
              meterNo
              generalUtilityType
              utilityType
              tariffId
              status
              isManual
              capturedStatus
              meterType
              voltageClassId
              name
              commissionDate
              amps
              captureType
              meterReadingType
              propertyId
              tariffIds
            }
            parentUtilityNodeId
            nodeType
            linkStatus
          }
          errorMessage
          isSuccessful
        }
      }                 
      `,
      variables: {
        propertyId: propertyId
      }
    })
  }

  getMeterNodesByProperty(propertyId, nodeType) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: ID = "", $nodeType: NodeType = METER) {
        getNodesPerProperty(propertyId: $propertyId, nodeType: $nodeType) {
          isSuccessful
          utilityNodeList {
            allocationNode {
              allocationNodeType
              allocationNodeValue
              utilityNodeId
              name
            }
            id
            meterNode {
              generalUtilityType
              isManual
              meterType
              meterNo
              name
              tariffId
              utilityType
              voltageClassId
              commissionDate
              status
              phases
              amps
              tariffIds
            }
            nodeType
            parentUtilityNodeId
          }
          errorMessage
        }
      }          
      `,
      variables: {
        propertyId: propertyId,
        nodeType: nodeType
      }
    })
  }

  getMeterNodesWithLimitByProperty(propertyId, nodeType, limit) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: ID = "", $nodeType: NodeType = METER, $limit: Int = 10) {
        getNodesPerProperty(propertyId: $propertyId, nodeType: $nodeType, limit: $limit) {
          isSuccessful
          utilityNodeList {
            allocationNode {
              allocationNodeType
              allocationNodeValue
              utilityNodeId
              name
            }
            id
            meterNode {
              generalUtilityType
              isManual
              meterType
              meterNo
              name
              utilityType
              voltageClassId
              commissionDate
              status
              phases
              amps
              captureType
              decommissionDate
              tariffIds
            }
            nodeType
            parentUtilityNodeId
          }
          errorMessage
          nextToken
        }
      }                     
      `,
      variables: {
        propertyId: propertyId,
        nodeType: nodeType,
        limit: limit
      }
    })
  }

  getMeterNodesWithLimitByPropertyWithToken(propertyId, nodeType, limit, token) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: ID = "", $nodeType: NodeType = METER, $limit: Int = 10) {
        getNodesPerProperty(propertyId: $propertyId, nodeType: $nodeType, limit: $limit) {
          isSuccessful
          utilityNodeList {
            allocationNode {
              allocationNodeType
              allocationNodeValue
              utilityNodeId
              name
            }
            id
            meterNode {
              generalUtilityType
              isManual
              meterType
              meterNo
              name
              tariffId
              utilityType
              voltageClassId
              commissionDate
              status
              phases
              amps
              captureType
              tariffIds
            }
            nodeType
            parentUtilityNodeId
          }
          errorMessage
          nextToken
        }
      }         
      `,
      variables: {
        propertyId: propertyId,
        nodeType: nodeType,
        limit: limit,
        nextToken: token
      }
    })
  }

  getNumberOfMeterNodesWithLimitByProperty(propertyId, nodeType) {
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: ID = "", $nodeType: NodeType = METER) {
        getNodesPerProperty(propertyId: $propertyId, nodeType: $nodeType, limit: 500) {
          isSuccessful
          utilityNodeList {
            id
          }
          errorMessage
          nextToken
        }
      }         
      `,
      variables: {
        propertyId: propertyId,
        nodeType: nodeType
      }
    })
  }
}
