import { Component, OnInit, Inject, Input, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar, MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { from, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { ShopsService } from 'src/app/services/shops.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

@Component({
  selector: 'sym-target-dates',
  templateUrl: './target-dates.component.html',
  styleUrls: ['./target-dates.component.scss']
})
export class TargetDatesComponent implements OnInit {

  selectedDate: any = {val: 0, date: new Date()};
  public CLOSE_ON_SELECTED = false;
  public init = new Date();
  public resetModel = new Date(0);
  minDate: Date;
  maxDate: Date;
  yearList: any[] = [];
  selectedYear: any;
  public selectionIndex: number;
  exampleHeader = ExampleHeader;
  @Input() selectedProperty: any;
  allShow: boolean = false;
  currentVal = 0;
  propertyList: any[] = [];
  dateLoop: number = 0;
  dateText: string = '';
  monthNames: string[] = [
    'January:', 'February:', 'March:', 'April:', 'May:', 'June:',
    'July:', 'August:', 'September:', 'October:', 'November:', 'December:'
  ];
  oldDatesVal: any[] = [];
  loopText: any[] = ['startDate', 'targetDate', 'endDate'];
  currentIndex = 0;
  modelArray: any[] = [];
  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;
  @ViewChild('dateInput', { read: MatInput }) dateInput: MatInput;
  public dateClass = (date: Date) => {
    if (this.findDate(date) !== -1 && this.allShow) {
      return ['selected'];
    }
    return [];
  };

  primaryBtn = {
    text: 'Retrieve Target Dates',
    method: () => {
      this.retrieveTargetDates()
    }
  }

  constructor(private loadingService: LoadingService, private shopService: ShopsService, private userMessagesService: UserMessagesService) {}

  ngOnInit(): void {
    this.selectionIndex = 0;
    let year = new Date().getFullYear() + 1;
    for(let i = year; i >= 2022; i--) {
      this.yearList.push(i);
    }
    // this.loadingService.openBlockingLoader('Retrieving Properties', 'primary');
    // from(this.propertyService.getPropertiesForPeriod()).subscribe((res:any) => {
      
    //   this.propertyList = res.data.getPropertyList.properties;
    //   this.loadingService.closeBlockingLoader();
    // }, (error)=> {
    //   if(error.errors && error.errors[0].message.includes('401')) {
    //     this.loadingService.closeBlockingLoader();
    //     this.comsService.showMsg('Session expired. Please log back in', 'error');
    //   } else {
    //     this.loadingService.closeBlockingLoader();
    //     this.comsService.showMsg('Failed to retrieve properties', 'error');
    //   }
    // })
  }

  retrieveTargetDates() {
    this.oldDatesVal = [];
    this.modelArray = [];
    this.oldDatesVal = [];
    this.dateInput.value = '';
    this.loadingService.openBlockingLoader('Retrieving Target Dates', 'primary');
    from(this.shopService.getTargetDatesForProperty(this.selectedProperty, this.selectedYear)).subscribe((res:any)=> {
      this.modelArray = res.data.getAllTargetDatesByPropertyAndYear.targetDateList;
      this.oldDatesVal = this.modelArray.map(m => ({...m}));
      this.loadingService.closeBlockingLoader();
    }, (error) => {
      this.loadingService.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to retrieve target dates';
      this.userMessagesService.openMessage(message_types.error);
    });
  }

  setSingleDate(value, i, dateText) {
    this.dateText = dateText;
    this.selectedDate = value;
    this.init = this.selectedDate;
    this.setDateLimits(i);
    this.allShow = false;
    this.selectionIndex = this.loopText.indexOf(dateText);
    localStorage.setItem('dateSelectionShow', (this.selectionIndex).toString());
    this._picker.open();
    this.currentIndex = i;
  }

  setDateLimits(date) {
    this.minDate = new Date(this.selectedYear - 0, date, 22);
    this.maxDate = new Date(this.selectedYear + 0, date + 1, 21);
    // this.minDate = new Date(this.selectedYear - 0, date, 16);
    // this.maxDate = new Date(this.selectedYear + 0, date, 26);
  }

  setProperty(property) {
    this.selectedProperty = property;
    if(this.selectedYear) {
      this.retrieveTargetDates();
    }
    
  }

  updateTargetDates() {
    for(let i = 0; i < this.modelArray.length; i++) {
      this.modelArray[i].propertyId = this.selectedProperty.id;
      this.modelArray[i].readingPeriod = this.selectedYear.toString() + (i > 8 ? '' : '0') + (i+1);
      let endDate = new Date(this.modelArray[i].endDate);
      endDate.setHours(23, 59, 59);
      this.modelArray[i].endDate = endDate.toISOString();
      let targetDate = new Date(this.modelArray[i].targetDate);
      targetDate.setHours(23, 59, 59);
      this.modelArray[i].targetDate = targetDate.toISOString();
    }
    this.loadingService.openBlockingLoader('Updating target dates', 'primary');
    from(this.shopService.updateTargetDatesForProperty(this.modelArray)).subscribe((res:any) => {
      this.loadingService.closeBlockingLoader();
      this.oldDatesVal = this.modelArray.map(m => ({...m}));
      this.modelArray = this.oldDatesVal.map(m => ({...m}));
      message_types.success.messageBody = 'Target dates updated successfully';
      this.userMessagesService.openMessage(message_types.success);
    }, (error) => {
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to update target dates';
      this.userMessagesService.openMessage(message_types.error);
    });
  }

  cancelUpdate() {
    this.modelArray = [];
    this.selectedYear = '';
  }

  setMultipleDates(i) {
    this.currentIndex = i;
    this.allShow = true;
    this.init = this.modelArray[i].startDate;
    this.setDateLimits(i);
    this.currentVal = 0;
    this.selectionIndex = 0;
    localStorage.setItem('dateSelectionShow', (this.selectionIndex).toString());
    this._picker.open();
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      if(!this.allShow) {
        this.modelArray.find(d => {
          if(d.startDate === this.selectedDate) {
            return d.startDate
          }

          if(d.endDate === this.selectedDate) {
            return d.endDate
          }

          if(d.targetDate === this.selectedDate) {
            return d.targetDate
          }
        })[this.dateText] = new Date(event.value.getTime() - event.value.getTimezoneOffset() * 60000).toISOString();
        this._picker.close();
      } else {
        if(this.currentVal < 2) {
          this.currentVal++;
          this.modelArray[this.currentIndex][this.loopText[this.selectionIndex]] = new Date(event.value.getTime() - event.value.getTimezoneOffset() * 60000).toISOString();
          localStorage.setItem('dateSelectionShow', (++this.selectionIndex).toString());
          this.dateClass(event.value);
        } else {
          this.currentVal = 0;
          this.selectionIndex = 0;
          localStorage.setItem('dateSelectionShow', (this.selectionIndex).toString());
          this.modelArray[this.currentIndex][this.loopText[2]] = new Date(event.value.getTime() - event.value.getTimezoneOffset() * 60000).toISOString();
          this._picker.close();
        }
      }
      if (!this.CLOSE_ON_SELECTED) {
        const closeFn = this._picker.close;
        this._picker.close = () => {};
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }
    }
  }

  checkChanges(index, dateText) {
    if(this.oldDatesVal.length != 0 && this.modelArray.length != 0) {
      if(this.oldDatesVal[index][dateText] != this.modelArray[index][dateText]) {
        return {'border': '2px solid #A59A00'}
      }
    }
  }

  findDate(date) {
    if(new Date(this.modelArray[this.currentIndex].startDate).toString().slice(0, 10) === new Date(date).toString().slice(0, 10)) {
      return 0;
    }
    if(new Date(this.modelArray[this.currentIndex].targetDate).toString().slice(0, 10) === new Date(date).toString().slice(0, 10)) {
      return 0;
    }
    if(new Date(this.modelArray[this.currentIndex].endDate).toString().slice(0, 10) === new Date(date).toString().slice(0, 10)) {
      return 0;
    }
    return -1;
  }

}

@Component({
  selector: 'example-header',
  styles: [
    `
    .example-header {
      display: flex;
      align-items: center;
      padding: 0.5em;
    }

    .example-header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }

    .example-double-arrow .mat-icon {
      margin: -22%;
    }
  `,
  ],
  template: `
  <div class="example-header">
  <button mat-icon-button class="example-double-arrow" (click)="previousClicked('year')">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button (click)="previousClicked('month')">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <span class="example-header-label">{{displayVal}}</span>
  <button mat-icon-button (click)="nextClicked('month')">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
  <button mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
    <mat-icon>keyboard_arrow_right</mat-icon>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  displayVal;
  displayArray = [
    'Start',
    'Target',
    'Deadline',
  ];

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    if(localStorage.getItem('dateSelectionShow')) {
      this.displayVal = this.displayArray[+localStorage.getItem('dateSelectionShow')] + ' Date for ' + this.periodLabel;
    } else {
      this.displayVal = this.periodLabel;
    }
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
      cdr.markForCheck()
      if(localStorage.getItem('dateSelectionShow')) {
        this.displayVal = this.displayArray[+localStorage.getItem('dateSelectionShow')] + ' Date for ' + this.periodLabel;;
      } else {
        this.displayVal = this.periodLabel;
      }
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }

}
