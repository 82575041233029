import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(
    public dialog: MatDialog
  ) { }

  openConfirmDialog(e) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {application: e}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}