import { Component, Input, OnInit } from '@angular/core';

interface UtilityTypeVal {
  type: string;
  loop: number;
  val: number;
  kva: boolean;
}

const METER_TYPE: UtilityTypeVal[] = [
  {type: 'ELEC_1P_KVA', loop: 1, kva: true, val: 2},
  {type: 'ELEC_3P_3', loop: 3, kva: false, val: 3},
  {type: 'ELEC_3P_KVA', loop: 1, kva: true, val: 2},
  {type: 'ELEC_3P_TOU', loop: 3, kva: true, val: 4},
  {type: 'ELEC_1P', loop: 1, kva: false, val: 1},
  {type: 'ELEC_3P_1', loop: 1, kva: false, val: 1},
  {type: 'WATER', loop: 1, kva: false, val: 1}
];

export const TOU_LIST = ['Standard', 'Off-Peak', 'Peak', 'KVA'];

@Component({
  selector: 'sym-charge-readings',
  templateUrl: './charge-readings.component.html',
  styleUrls: ['./charge-readings.component.scss']
})
export class ChargeReadingsComponent implements OnInit {

  @Input() selectedReading;
  @Input() utilizationList;
  @Input() isMultiple: boolean = false;
  @Input() utilityType: string;
  utility: UtilityTypeVal;
  loopNumber: any[] = [];
  utilityReadingNum: number = 0;
  kva: boolean;
  touList = TOU_LIST;
  @Input() meter;

  constructor() { }

  ngOnInit(): void {
    if(this.utilityType != 'null' || this.utilityType != undefined) {
      const utilityType = METER_TYPE.find(m => m.type === this.utilityType);
      this.utilityReadingNum = utilityType.val;
      this.utility = utilityType;
      this.kva = utilityType === undefined ||  this.selectedReading.reading.readingValueList.length === 1 ? false : utilityType.kva;
      this.loopNumber = Array(this.kva ? (this.selectedReading.reading.readingValueList.length > 3 ? 3 : 1) : this.selectedReading.reading.readingValueList.length).fill(0).map((x,i) => i);
      if(this.selectedReading.prevReading && this.selectedReading.prevReading.length != 0 && this.selectedReading.prevReading.length != this.loopNumber.length) {
        this.checkReadingLength();
      }
    }
  }

  checkReadingLength() {
    if(this.loopNumber.length > this.selectedReading.prevReading.readingValueList.length) {
      let loop = this.loopNumber.length;
      let prev = this.selectedReading.prevReading.readingValueList.length;
      for(let i = 1; i <= loop - prev; i++) {
        this.selectedReading.prevReading.readingValueList.push({
          "sequence": i+1,
          "value": "0"
      })
      }
      if(this.kva) {
        this.selectedReading.prevReading.readingValueList.push({
          "sequence": 4,
          "value": "0"
      })
      }
    }
  }

}
