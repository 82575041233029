import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TariffDetailsService {

  constructor() { }

  ordering = {};

  fixedChargeOrder = [
    'Basic', 'Service',
    'Administration', 'Capacity',
    'Network Capacity'
  ];

  demandChargeOrder = [
    'Network Demand', 'Network Access',
    'Excess Network', 'Reactive Energy',
    'Low Votlage Subsidy'
  ];

  energyChargeOrder = [
    'Active Energy', 'Active Energy', 'Distribution Network Demand',
    'Energy Demand', 'Network Demand', 'Transmission Network',
    'Ancillary Service', 'Electrification & Rural Network Subsidy',
    'Affordability Subsidy'
  ];

  sortCharges(tariffCharges: any[], tariffType: string) {
    this.ordering = {};
    for(let i = 0; i < this[tariffType+'ChargeOrder'].length; i++) {
      this.ordering[this[tariffType+'ChargeOrder'][i]] = i;
    }

    tariffCharges.sort((a, b) => {
      if(a.display) {
        let aIndex = a.display.indexOf(' Charge');
        let bIndex = b.display.indexOf(' Charge');
        return this.ordering[a.display.substr(0, aIndex)] - this.ordering[b.display.substr(0, bIndex)];
      } else if(a.label) {
        let aIndex = a.label.indexOf(' Charge');
        let bIndex = b.label.indexOf(' Charge');
        return this.ordering[a.label.substr(0, aIndex)] - this.ordering[b.label.substr(0, bIndex)];
      } else {
        return a;
      }
      
    })
  }
}
