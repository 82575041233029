<sym-card-dialog [title]="'Link Unit'" [closeMethod]="closeMethod">
    <div class="table-container">
        <mat-table [dataSource]="unitDatasource">
          <ng-container matColumnDef="unit">
            <mat-header-cell *matHeaderCellDef fxFlex="40" style="height: 80%;" class="table-header">Unit
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="40">
              <mat-form-field appearance="outline" style="padding-right: 1em;" fxFlex="90">
                <mat-label></mat-label>
                <mat-select style="color: black;" [(ngModel)]="element.unit" required>
                  <input placeholder="Search..."  matInput (keyup)="onKey($event.target.value)" style="height: 3em !important" class="example-input">
                  <mat-option *ngFor="let unit of unitList" style="color: black;" [value]="unit">
                    {{unit.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="40">  </mat-footer-cell>
          </ng-container>
    
          <ng-container matColumnDef="split">
            <mat-header-cell *matHeaderCellDef fxFlex="20" style="height: 80%;" class="table-header">Split %
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="20">
              <mat-form-field appearance="outline" style="padding-right: 1em;" fxFlex="90">
                <mat-label></mat-label>
                <input matInput min=0 max="100" type="number" [(ngModel)]="element.split">
              </mat-form-field>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="20"> <span [ngStyle]="{'color':(totalSplit <= 100 ? 'black' : 'red' )}">{{getTotalSplit() + ' %'}}</span> </mat-footer-cell>
          </ng-container>
    
          <ng-container matColumnDef="allocationRule">
            <mat-header-cell *matHeaderCellDef fxFlex="30" style="height: 80%;" class="table-header">Allocation Rule
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="30">
              <mat-form-field appearance="outline" style="padding-right: 1em;" fxFlex="90">
                <mat-label>Rule</mat-label>
                <input matInput [(ngModel)]="element.allocationRule">
              </mat-form-field>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="30"> </mat-footer-cell>
          </ng-container>
    
          <ng-container matColumnDef="remove">
            <mat-header-cell *matHeaderCellDef fxFlex="10" style="height: 80%;" class="table-header">Remove
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index" fxFlex="10">
              <button mat-icon-button (click)="removeFromTable(i)"><mat-icon style="color: red">delete</mat-icon></button>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="10">  </mat-footer-cell>
          </ng-container>
    
          <mat-header-row *matHeaderRowDef="unitColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: unitColumns;"></mat-row>
          <mat-footer-row *matFooterRowDef="unitColumns"></mat-footer-row>
        </mat-table>
      </div>
      <div fxLayout="row" style="padding-top: 1.5em; padding-bottom: 2em;" fxLayoutAlign="end center">
        <button mat-fab (click)="addToTable()" style="background-color: #41535D;color: white;"><mat-icon>add</mat-icon></button>
      </div>
</sym-card-dialog>