<div fxLayout="column" fxLayoutAlign="start start">
    <div>
      <sym-content-card *ngIf="areaDS && areaDS.data.length != 0" [ngStyle]="{'display': ((areaDS && areaDS.data.length) && (treeNode && treeNode.type === 'Area') != 0 ? 'block' : 'none')}" [title]="treeNode.name + ' - Areas'">
        <mat-table [dataSource]="areaDS">
            <ng-container matColumnDef="areaName">
              <mat-header-cell *matHeaderCellDef fxFlex="48" class="table-header"> Area Name
              </mat-header-cell>
              <mat-cell matTooltip="{{element.name}}" matTooltipPosition="above" *matCellDef="let element" fxFlex="48">
                <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;"> 
                  <mat-label>Area Name</mat-label>
                  <input style="color: black;" [(ngModel)]="element.name" matInput [attr.data-cy]="element.name" value="{{element.name}}">
                </mat-form-field>
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="areaSize">
              <mat-header-cell *matHeaderCellDef fxFlex="15" class="table-header"> Area Size
              </mat-header-cell>
              <mat-cell matTooltip="{{element.sqm}}" matTooltipPosition="above" *matCellDef="let element" fxFlex="15">
                <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;"> 
                  <mat-label>Area Size</mat-label>
                  <input style="color: black;" disabled matInput [attr.data-cy]="element.sqm" value="{{element.sqm}}">
                </mat-form-field>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="numberAreas">
              <mat-header-cell *matHeaderCellDef fxFlex="15" class="table-header"> #Areas
              </mat-header-cell>
              <mat-cell matTooltip="{{element.numberOfAreas}}" matTooltipPosition="above" *matCellDef="let element" fxFlex="15">
                <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;">
                  <mat-label>Number of Areas</mat-label>
                  <input style="color: black;" disabled matInput [attr.data-cy]="element.numberOfAreas" value="{{element.numberOfAreas}}">
                </mat-form-field>
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="numberUnits">
              <mat-header-cell *matHeaderCellDef fxFlex="15" class="table-header"> #Units
              </mat-header-cell>
              <mat-cell matTooltip="{{element.numberOfUnits}}" matTooltipPosition="above" *matCellDef="let element" fxFlex="15">
                <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;"> 
                  <mat-label>Number of Units</mat-label>
                  <input style="color: black;" disabled matInput [attr.data-cy]="element.numberOfUnits" value="{{element.numberOfUnits}}">
                </mat-form-field>
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef fxFlex="7" style="color: grey;">Actions </mat-header-cell>
              <mat-cell *matCellDef="let element; index as i;" fxFlex="7">
                <button matTooltipPosition="left" matTooltip="View Menu" (click)="selectedElement = element" mat-icon-button
                  class="mobile-hidden mat-light-green-700-bg" [matMenuTriggerFor]="userMenu">
                  <mat-icon style="font-size: x-large;color: black;">more_vert</mat-icon>
                </button>
              </mat-cell>
        
              <mat-menu #userMenu="matMenu" xPosition="before" style="padding: 10px;">
                <div>
                  <button (click)="updateArea()" mat-menu-item>Update Area</button>
                </div>
              </mat-menu>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="displayedAreaColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedAreaColumns;"></mat-row>
            <mat-paginator #areaListPaginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </mat-table>
    </sym-content-card>
    </div>

    <div>
      <sym-content-card [title]="treeNode.name + ' - Units'" style="width: 100%;" [ngStyle]="{'display': ((unitDS && unitDS.data.length != 0) && (treeNode && treeNode.type === 'Area')? 'block' : 'none')}" >
        <mat-table [dataSource]="unitDS">
          <ng-container matColumnDef="unitName">
            <mat-header-cell *matHeaderCellDef fxFlex="48" class="table-header"> Unit Name
            </mat-header-cell>
            <mat-cell matTooltip="{{element.name}}" matTooltipPosition="above" *matCellDef="let element" fxFlex="48">
              <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;"> 
                <mat-label>Unit Name</mat-label>
                <input [(ngModel)]="element.name" style="color: black;" matInput [attr.data-cy]="element.name" value="{{element.name}}">
              </mat-form-field>
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="unitType">
            <mat-header-cell *matHeaderCellDef fxFlex="25" class="table-header"> Unit Type
            </mat-header-cell>
            <mat-cell matTooltip="{{element.unitType}}" matTooltipPosition="above" *matCellDef="let element" fxFlex="25">
              <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;"> 
                <mat-label>Unit Type</mat-label>
                <mat-select [(value)]="element.unitType">
                  <mat-option *ngFor="let type of unitType" [value]="type" >
                    {{type}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="unitSqm">
            <mat-header-cell *matHeaderCellDef fxFlex="20" class="table-header"> Unit SQM
            </mat-header-cell>
            <mat-cell matTooltip="{{element.sqm}}" matTooltipPosition="above" *matCellDef="let element" fxFlex="20">
              <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;"> 
                <mat-label>Unit SQM</mat-label>
                <input [(ngModel)]="element.sqm" type="number" style="color: black;" matInput [attr.data-cy]="element.sqm" value="{{element.sqm}}">
              </mat-form-field>
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef fxFlex="7" style="color: grey;">Actions </mat-header-cell>
            <mat-cell *matCellDef="let element; index as i;" fxFlex="7">
              <button matTooltipPosition="left" matTooltip="View Menu" mat-icon-button
                class="mobile-hidden mat-light-green-700-bg" (click)="selectedElement = element" [matMenuTriggerFor]="userMenu">
                <mat-icon style="font-size: x-large;color: black;">more_vert</mat-icon>
              </button>
            </mat-cell>
      
            <mat-menu #userMenu="matMenu" xPosition="before" style="padding: 10px;">
              <div>
                <button (click)="updateUnit()" mat-menu-item>Update Unit</button>
              </div>
            </mat-menu>
          </ng-container>
    
          <mat-header-row *matHeaderRowDef="displayedUnitColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedUnitColumns;"></mat-row>
        </mat-table>
        <mat-paginator #unitListPaginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </sym-content-card>
    </div>

  <!-- <sym-content-card [title]="treeNode.name" *ngIf="treeNode && treeNode.type === 'Unit'">
    <div fxFlexAlign="row" fxFlex="100">
      <mat-form-field appearance="outline" style="margin-top: 5px;"> 
        <mat-label>Trading Name</mat-label>
        <input style="color: black;" matInput [attr.data-cy]="unit.tradingName" value="{{unit.tradingName}}">
      </mat-form-field>
      <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;"> 
        <mat-label>Unit Number</mat-label>
        <input style="color: black;" matInput [attr.data-cy]="unit.unitNumber" value="{{unit.unitNumber}}">
      </mat-form-field>
      <mat-form-field appearance="outline" style="margin-top: 5px;width: 80%;"> 
        <mat-label>Lease Period</mat-label>
        <input style="color: black;" matInput [attr.data-cy]="unit.leasePeriod" value="{{unit.leasePeriod}}">
      </mat-form-field>
    </div>
  </sym-content-card> -->

  <sym-content-card [title]="treeNode.name" [titleButton]="titleButton" style="width: 100%;" *ngIf="treeNode && treeNode.type === 'Unit'">
    <mat-table [dataSource]="linkDS">
      <ng-container matColumnDef="meterNo">
        <mat-header-cell *matHeaderCellDef fxFlex="25" class="table-header">Meter Number
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="25" style="color: black;">
          <div *ngIf="element.meterNo">
            {{element.meterNo}}
          </div>
          <div *ngIf="!element.meterNo">
            N/A
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="utilityType">
        <mat-header-cell *matHeaderCellDef fxFlex="20" style="height: 80%;" class="table-header">Utility Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;">
          <div *ngIf="element.utilityType">
            {{element.utilityType}}
          </div>
          <div *ngIf="!element.utilityType">
            N/A
          </div>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="meterType">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Meter Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
          <div *ngIf="element.meterType">
            {{element.meterType  | titlecase}}
          </div>
          <div *ngIf="!element.meterType">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef fxFlex="20" style="height: 80%;" class="table-header">Link Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;">
          <div *ngIf="element.status">
            {{element.status}}
          </div>
          <div *ngIf="!element.status">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amps">
        <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Amps
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
          <div *ngIf="element.amps">
            {{element.amps}}
          </div>
          <div *ngIf="!element.amps">
            N/A
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="5" style="color: grey;">Actions </mat-header-cell>
        <mat-cell *matCellDef="let element; index as i;" fxFlex="5">
          <button matTooltipPosition="left" matTooltip="View Menu" mat-icon-button
            class="mobile-hidden mat-light-green-700-bg" (click)="selectedLinkNode = element; selectedElement = element" [matMenuTriggerFor]="userMenu">
            <mat-icon style="font-size: x-large;color: black;">more_vert</mat-icon>
          </button>
        </mat-cell>
  
        <mat-menu #userMenu="matMenu" xPosition="before" style="padding: 10px;">
          <div>
            <button *ngIf="selectedLinkNode != undefinded && selectedLinkNode.linkType === 'PERCENTAGE'" (click)="updateUnit()" mat-menu-item>Show Node Structure</button>
            <mat-divider *ngIf="selectedLinkNode != undefinded && selectedLinkNode.linkType === 'PERCENTAGE'"></mat-divider>
            <button (click)="delinkNode()" mat-menu-item>Unlink Meter</button>
          </div>
          <mat-divider></mat-divider>
          <div>
            <button (click)="editMeterDialog()" mat-menu-item>Update Meter</button>
          </div>
        </mat-menu>
      </ng-container>

      <mat-header-row *matHeaderRowDef="linkColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: linkColumns;"></mat-row>
    </mat-table>
  </sym-content-card>

  <sym-content-card [title]="'Unlinked Meters'" [titleButton]="meterCardButton" style="width: 100%;" [ngStyle]="{'display': (treeNode && treeNode.type === 'Property'? 'block' : 'none')}">
    <mat-table [dataSource]="linkDS">
      <ng-container matColumnDef="meterNo">
        <mat-header-cell *matHeaderCellDef fxFlex="21" class="table-header">Meter Number
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="21" style="color: black;">
          <div *ngIf="element.meterNo">
            {{element.meterNo}}
          </div>
          <div *ngIf="!element.meterNo">
            N/A
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="utilityType">
        <mat-header-cell *matHeaderCellDef fxFlex="21" style="height: 80%;" class="table-header">Utility Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="21" style="color: black;">
          <div *ngIf="element.utilityType">
            {{element.utilityType}}
          </div>
          <div *ngIf="!element.utilityType">
            N/A
          </div>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="meterType">
        <mat-header-cell *matHeaderCellDef fxFlex="16" style="height: 80%;" class="table-header">Meter Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="16" style="color: black;">
          <div *ngIf="element.meterType">
            {{element.meterType  | titlecase}}
          </div>
          <div *ngIf="!element.meterType">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef fxFlex="21" style="height: 80%;" class="table-header">Link Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="21" style="color: black;">
          <div *ngIf="element.status">
            {{element.status}}
          </div>
          <div *ngIf="!element.status">
            -
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amps">
        <mat-header-cell *matHeaderCellDef fxFlex="16" style="height: 80%;" class="table-header">Amps
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="16" style="color: black;">
          <div *ngIf="element.amps">
            {{element.amps}}
          </div>
          <div *ngIf="!element.amps">
            N/A
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="5" style="color: grey;">Actions </mat-header-cell>
        <mat-cell *matCellDef="let element; index as i;" fxFlex="5">
          <button matTooltipPosition="left" matTooltip="View Menu" (click)="selectedElement = element" mat-icon-button
            class="mobile-hidden mat-light-green-700-bg" [matMenuTriggerFor]="unlinkedMeterMenu">
            <mat-icon style="font-size: x-large;color: black;">more_vert</mat-icon>
          </button>
        </mat-cell>
  
        <mat-menu #unlinkedMeterMenu="matMenu" xPosition="before" style="padding: 10px;">
          <div>
            <button (click)="editMeterDialog()" mat-menu-item>Update Meter</button>
            <mat-divider></mat-divider>
            <button (click)="creatNodeUnitLink(true)" mat-menu-item>Link Meter to Unit</button>
          </div>
        </mat-menu>
      </ng-container>

      <mat-header-row *matHeaderRowDef="unlinkedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: unlinkedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #Meters="matPaginator" [pageSize]="25"
    [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </sym-content-card>
  </div>

  
  