import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LayoutService } from '../layout.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../components/loading/loading.service';
import { UntypedFormControl } from '@angular/forms';
import { PropertiesService } from 'src/app/services/properties.service';
import { UtilitySupplierService } from 'src/app/services/utility-supplier.service';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { ShopsService } from 'src/app/services/shops.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { FloorplanService } from 'src/app/services/floor-plan.service';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() logo: string;
  @Input() fixed: boolean;
  @Input() buttonIcon: string;
  @Input() color: string;
  @Input() headerHeight: string;
  disableDates: boolean = false;
  selectedAdapter: any;
  adaptersLoaded = false;
  propertySelector: any[] = [];
  viewPropertyList: boolean = false;
  periodReadings: any;
  propertyLoaded: boolean = false;
  selectedProperty = new UntypedFormControl({value: ''});
  @Output() propertySelect = new EventEmitter();
  selectedMonth: any;
  supplierList: any[] = [];
  readingPeriod: any;
  selectedYear: any;
  approveAll: string;
  yearValueList = [];
  hideSelectors: boolean = false;
  @Output() periodReadingDates = new EventEmitter();
  @Output() openPropertyList = new EventEmitter();

  monthsValueList = [
    { month: "January", value: "01" },
    { month: "February", value: "02" },
    { month: "March", value: "03" },
    { month: "April", value: "04" },
    { month: "May", value: "05" },
    { month: "June", value: "06" },
    { month: "July", value: "07" },
    { month: "August", value: "08" },
    { month: "September", value: "09" },
    { month: "October", value: "10" },
    { month: "November", value: "11" },
    { month: "December", value: "12" },
  ];
  hasError: boolean = false;
  count: number = 0;
  tariffList: any[] = [];

  newCompany: any;

  constructor(
    public ls: LayoutService,
    public dialog: MatDialog,
    public userMessagesService: UserMessagesService,
    private loadingservice: LoadingService,
    private utilitySupplierService: UtilitySupplierService,
    private router: Router,
    private shopService: ShopsService, private tariffService: TariffsService, private floorPlanService: FloorplanService) { 
      console.log(new Date());
    }

  ngOnInit() {
    this.propertySelector = JSON.parse(sessionStorage.getItem('properties'));
    this.periodReadings = JSON.parse(sessionStorage.getItem('periodReadings'));
    if(this.propertySelector === null) {
      this.getCentres();
      this.getUtilitySupplierList();
    } else {
      this.setDates();
    }
  }

  closeLoader(val?) {
    sessionStorage.removeItem('approveAll');
    if((this.count === 2 && !this.hasError)) {
      this.loadingservice.closeBlockingLoader();
      if(val) {
        this.emitProperty(val);
      } else {
        this.emitProperty('TenantListCall')
      }

      if(this.propertyLoaded) {
        this.propertyLoaded = false;
        this.count = 0;
        for(let property of this.propertySelector) {
          property.supplierDetails = [];
          if(property.supplierIds != null) {
            for(let id of property.supplierIds) {
              property.supplierDetails.push(this.supplierList.find(s => s.id === id))
            }
          }
        }
        sessionStorage.setItem('supplierList', JSON.stringify(this.supplierList));
        sessionStorage.setItem('properties', JSON.stringify(this.propertySelector));
        sessionStorage.setItem('propertySelection', JSON.stringify(this.propertySelector[0]));
        sessionStorage.setItem('propertyLoaded', JSON.stringify(true));
        this.propertySelect.emit();
        this.setDates();
      }
    } else if(this.hasError){
      if(val.errors[0].message.includes('401')) {
        message_types.error.displaySupport = false;
        message_types.error.messageBody = 'Your session has expired. Please log in again';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      } else {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to retrieve all properties';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      }
    }
  }

  hideSelectorsToggle(shouldShow) {
    this.hideSelectors = shouldShow;
  }

  disableInputs(input) {
    if(input === 'All') {
      this.disableDates = true;
      this.selectedProperty.disable();
    } else if(input === 'Dates') {
      this.disableDates = true;
    } else {
      this.selectedProperty.disable();
    }
  }

  enableInputs() {
    this.disableDates = false;
    this.selectedProperty.enable();
  }

  propertyChange(property, propChanged) {
    this.readingPeriod = this.selectedYear + this.selectedMonth.value;
    if(property.id || property.value || this.viewPropertyList) {
      let prop = {
        property: property.value ? property.value : property,
        date: {
          year: this.selectedYear,
          month: this.selectedMonth
        }
      }

      if((propChanged && property.value != '') || this.viewPropertyList) {
        this.count = 0;
        this.getTariffs(property, prop)
        this.approveAll = sessionStorage.getItem('approveAll');

        if(!this.approveAll) {
          this.loadingservice.openBlockingLoader('Retrieving Additional Details', 'primary');
        } else {
          this.loadingservice.openBlockingLoader("Approving all readings", "primary");
        }
        
        if((this.selectedProperty && this.selectedProperty.value.name === 'View All') || this.viewPropertyList) {
          this.count = -1;
          from(this.floorPlanService.getAllPropertyMeterCounts(this.readingPeriod)).subscribe((properties: any) => {
            const propertyList = [...properties.data.getPeriodReadingLandingPageList.propertyList];
            for(let property of this.propertySelector) {
              if(property.name != 'View All') {
                property.meterCounts = propertyList.find(p => p.id === property.id).meterCounts;
              }
              sessionStorage.setItem('properties', JSON.stringify(this.propertySelector));
              sessionStorage.setItem('propertySelection', JSON.stringify(this.propertySelector[0]))
            }

            this.propertySelect.emit();
            this.count++;
            this.closeLoader();
          }, (error) => {
            this.hasError = true;
            this.closeLoader(error)
          })

          this.viewPropertyList = false;
        }
        from(this.shopService.getTargetDate(prop.property.id, this.readingPeriod)).subscribe((res:any) => {
          const targetDates = res.data.getTargetDate.targetDate;
          prop.property.minDate = targetDates.startDate;
          prop.property.maxDate = targetDates.endDate;
          this.count++;
          this.closeLoader(prop);
        }, (error) => {
          this.hasError = true;
          this.closeLoader(error)
        })
      } else {
        this.emitProperty(prop);
      }
    } else {
      sessionStorage.setItem('periodStatus', this.readingPeriod);
      this.emitProperty(property.value);
    }
  }

  changeToPropertyList() {
    this.viewPropertyList = true;
    this.selectedProperty.patchValue(this.propertySelector[0])
    this.propertyChange(this.propertySelector[0], true)
  }

  setProperty(prop) {
    this.selectedProperty.patchValue(this.propertySelector[this.propertySelector.findIndex(p => p.id === prop.id)]);
    this.propertyChange(prop, true);
  }

  getTariffs(property, prop) {
    const chosenProperty = property.value ? property.value : property;
    console.log(chosenProperty)
    from(this.tariffService.getUtilitySupplierDetails(chosenProperty.supplierIds)).subscribe((res:any) => {
      this.tariffList = [];
      let tariffs = [];
      for(let i = 0; i < chosenProperty.supplierDetails.length; i++) {
        tariffs = res.data['s'+i].tariffTypes;
        this.tariffList.push({
          supplierName: chosenProperty.supplierDetails[i].name,
          supplierId: chosenProperty.supplierDetails[i].id,
          tariffList: tariffs.map(t => {
            return {
              id: t.id ? t.id : '',
              name: t.name ? t.name : '',
              utilityType: t.tariffConstraint && t.tariffConstraint.utilityType ? t.tariffConstraint.utilityType : '',
              utilitySupplierId: t.utilitySupplierId
            }
          })
        })
      }
      sessionStorage.setItem('tariffs', JSON.stringify(this.tariffList));
      this.count++;
      this.closeLoader(prop);
    }, (error) => {
      this.hasError = true;
      this.closeLoader(error);
    })
  }

  emitProperty(prop) {
    if(prop && prop != 'TenantListCall') {
      sessionStorage.setItem('propertySelection', JSON.stringify(prop));
      this.propertySelect.emit(prop);
    } else {
      this.propertySelect.emit(prop);
    }
  }

  setDates() {
    const period = sessionStorage.getItem('periodStatus');
    let date = new Date();
    let currentYear = date.getFullYear();
    let previousYear = date.getFullYear() - 1;

    if(date.getDate() < 22) {
      this.selectedMonth = this.monthsValueList[date.getMonth() === 0 ? 11 : date.getMonth() - 1];
    } else {
      this.selectedMonth = this.monthsValueList[date.getMonth()];
    }

    this.selectedYear = date.getMonth() === 0 && date.getDate() < 16 ? currentYear - 1 : currentYear ;
    
    this.yearValueList = [];
    this.yearValueList.push(currentYear);
    this.yearValueList.push(previousYear);
    if(!period) {
      this.yearValueList = [];
      this.yearValueList.push(currentYear);
      this.yearValueList.push(previousYear);
      this.readingPeriod = this.selectedYear + this.selectedMonth.value;
      
    } else {
      this.selectedYear = new Date(period.slice(0, 4)).getFullYear();
      this.readingPeriod = period;
    }

    let currentPeriod = JSON.parse(localStorage.getItem('currentPeriod'));

    if(!currentPeriod) {
      localStorage.setItem('currentPeriod', JSON.stringify(this.readingPeriod));
    }

    const propertySelected: any = JSON.parse(sessionStorage.getItem('propertySelection'));
    
    if(propertySelected && propertySelected.name != 'View All' && propertySelected != 'TenantListCall') {
      this.selectedProperty.patchValue(this.propertySelector[this.propertySelector.findIndex(p => p.id === propertySelected.property.id)]);
      this.selectedYear = propertySelected.date.year;
      this.selectedMonth = this.monthsValueList[this.monthsValueList.findIndex(m => m.value === propertySelected.date.month.value)];
    } else if((propertySelected && propertySelected.name === 'View All') || propertySelected === 'TenantListCall') {
      this.selectedProperty.patchValue(this.propertySelector[0]);
    }
  }

  goToLogin() {
    this.router.navigateByUrl('/login')
  }

  getCentres() {
    this.loadingservice.openBlockingLoader("Retrieving Properties", "primary");
    this.setDates();
    this.readingPeriod = this.selectedYear + this.selectedMonth.value;
    from(this.floorPlanService.getAllProperties(this.readingPeriod)).subscribe((properties: any) => {
      this.propertySelector = [];
      let propertyList = [];
      
      if(properties.data.getPeriodReadingLandingPageList && properties.data.getPeriodReadingLandingPageList.propertyList && properties.data.getPeriodReadingLandingPageList.propertyList.length > 0) {
        propertyList = [...properties.data.getPeriodReadingLandingPageList.propertyList];
      }
      
      for(let property of propertyList ) {
        property.utilitySupplierIds = property.utilitySupplierIds.length === 1 ? property.utilitySupplierIds[0].split(',') : property.utilitySupplierIds;
        let editedProperties = {
          id: property.id,
          code: property.code,
          address: property.address,
          name: property.name,
          numberOfAreas: property.numberOfAreas,
          numberOfUnits: property.numberOfUnits,
          notSubmitted: property.notSubmitted,
          notApproved: property.notApproved,
          supplierIds: property.utilitySupplierIds.length > 0 ? property.utilitySupplierIds : [],
          meterCounts: property.meterCounts
        };
        this.propertySelector.push(editedProperties);
      }
      this.propertySelector.unshift({
        name: 'View All',
        id: undefined
      });
      this.selectedProperty.setValue(this.propertySelector[0]);
      this.propertyLoaded = true;
      this.count++;
      this.closeLoader();
    }, (error) => {
      this.hasError = true;
      this.closeLoader(error)
    }
    );
  }

  addProperty(property) {
    property.supplierDetails = [];
    const supplierList = JSON.parse(sessionStorage.getItem('supplierList'))
    for(let id of property.supplierIds) {
      property.supplierDetails.push(supplierList.find(s => s.id === id))
    }
    this.propertySelector.push(property);
    this.propertySelector.splice(0, 1);
    this.propertySelector.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)
    this.propertySelector.unshift({
      name: 'View All',
      id: undefined
    });
    this.selectedProperty.patchValue(this.propertySelector[this.propertySelector.findIndex(p => p.id === property.id)]);
    sessionStorage.setItem('properties', JSON.stringify(this.propertySelector));
    this.propertyChange(property, true)
  }

  getUtilitySupplierList() {
    from(this.utilitySupplierService.listUtilitySuppliersName()).subscribe((res:any) => {
      this.supplierList = res.data.listUtilitySuppliers.utilitySupplierList;
      this.count++;
      this.closeLoader();
    }, (error) => {
      this.hasError = true;
      this.closeLoader(error)
    })
  }
}
