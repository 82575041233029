<div class="container" *ngIf="messageInfo && messageInfo.messageBody">
    <div fxLayout="row" fxLayoutAlign="start center">
        <div >
            <!-- <mat-icon style="width: 32px;height: 32px;font-size: 32px;" [ngStyle]="{color: messageInfo ? messageInfo.iconColor : 'white'}">{{messageInfo.icon}}</mat-icon> -->
            <img src="{{messageInfo.img}}" alt="">
        </div>

        <div style="height: 54px;margin: 0px 12px;border: 1px solid white;">
        </div>

        <div fxLayout="column" style="width: 215px;padding-right: 12px;">
            <span style="font-weight: bold;padding-bottom: .5em;font-size: 16px;" fxLayout="row" fxLayoutAlign="space-between center">
                {{messageInfo.messageHeader}}
            </span>
            <span style="font-size: 12px;">{{messageInfo.messageBody}}</span>
            <div>
                <!-- <label *ngIf="messageInfo.displaySupport" style="font-size: 12px;padding-top: .25em;">If the issue continues to happen please
                    <span style="text-decoration: underline;cursor: pointer; color: #fff;font-size: 12px;" (click)="openHelp()">Contact Support</span>
                </label> -->
            </div>
        </div>

        <button style="border: 1px solid white !important;border-radius: 4px;" mat-stroked-button (click)="userMessagesService.closeMessage()">
            <!-- <mat-icon >close</mat-icon --> Close
        </button>

    </div>
    
</div>