<div class="input-container">
    <span class="charge-label">{{chargeLabel}}</span>
    <div *ngIf="seasons.length > 0" class="input-container">
      <div *ngFor="let season of seasons; let i = index" class="charge-input" style="width: 100%;">
        <mat-form-field appearance="outline" style="width: 100%;" >
          <mat-label>{{season.name}} Season</mat-label>
          <input matInput placeholder="Charge (per Month)" [(ngModel)]="tariffCharge[i]" type="number" />
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="seasons.length === 0" class="charge-input">
      <mat-form-field appearance="outline">
        <mat-label>Tariff Charge</mat-label>
        <input matInput placeholder="Charge (per Month)" [(ngModel)]="tariffCharge[0]" type="number" />
      </mat-form-field>
    </div>
  </div>