import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptureReadingComponent } from './capture-reading.component';
import { BaseModule } from 'src/app/core/base/base.module';
import { MaterialModule } from 'src/app/core/material/material.module';
import { SaCardModule } from '../../../components/sa-card/sa-card.module';
import { InfoCardModule } from '../../../components/info-card/info-card.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ClientDocumentUploaderModule } from '../clientDocumentUploader/clientDocument-uploader.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CardDialogModule } from 'src/app/shared/components/card-dialog/card-dialog.module';



@NgModule({
    declarations: [
        CaptureReadingComponent
    ],
    imports: [
        CommonModule,
        BaseModule,
        MaterialModule,
        SaCardModule,
        InfoCardModule,
        FileUploadModule,
        ClientDocumentUploaderModule,
        MatDialogModule,
        CardDialogModule
    ],
    exports: [CaptureReadingComponent]
})
export class CaptureReadingModule { }
