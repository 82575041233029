import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { FileLookupService } from 'src/app/core/services/file-lookup.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { ShopsService } from 'src/app/services/shops.service';
import { ImageViewComponent } from '../image-view/image-view.component';
import { UpdateReadingsComponent } from '../../../shared/components/update-readings/update-readings.component';
import { CaptureReadingComponent } from 'src/app/shared/components/capture-reading/capture-reading.component';
import { SendNotificationDialogComponent } from 'src/app/shared/components/send-notification-dialog/send-notification-dialog.component';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { ActivatedRoute } from '@angular/router';

const utilityTypes = [
  {
    type: 'ELEC_1P',
    num: 1,
    hasKva: false
  },
  {
    type: 'ELEC_1P_KVA',
    num: 2,
    hasKva: true
  },
  {
    type: 'ELEC_3P_1',
    num: 1,
    hasKva: false
  },
  {
    type: 'ELEC_3P_3',
    num: 3,
    hasKva: false
  },
  {
    type: 'ELEC_3P_KVA',
    num: 2,
    hasKva: true
  },
  {
    type: 'ELEC_3P_TOU',
    num: 4,
    hasKva: true
  },
  {
    type: 'WATER',
    num: 1,
    hasKva: false
  },
]

@Component({
  selector: 'sym-detailed-control',
  templateUrl: './detailed-control.component.html',
  styleUrls: ['./detailed-control.component.scss']
})
export class DetailedControlComponent implements OnInit {

  meterId;
  utilityType;
  readingHistory;
  propertyId;
  meterPeriodStatus;
  unitId;
  supplierIds;
  meterDetails;
  minDate;
  maxDate;
  hasImages: boolean = false;
  windowWidth: number = window.innerWidth;
  stopCapture: boolean = false;
  @Output() retrieveReadingDetails = new EventEmitter<any>();
  period: any;
  property: any;
  wasApproved: boolean = false;
  meterReadingColumns: string[] = ['date', 'reading','utilityType', 'status','captureType', 'capturedBy', 'flag', 'image', 'actions'];
  meterReadingDS = new MatTableDataSource();
  readingHistoryList: any[] = [];
  readingHistoryColumns: string[] = [ 'date', 'reading', 'capturedBy'];
  readingHistoryDS = new MatTableDataSource();
  selectedReading: any;
  selectedRow: any;
  readingList: any[] = [];
  kvaNum: {name: string, value: number}[] = [];
  readingValueList: any[] = [];
  imageLoaded: boolean[] = [];
  thumbnail: any[] = [];
  blobThumbNail: string[] = [];
  count = 0;
  startBigCall: boolean = false;
  hasError = false;
  selection = new SelectionModel(true, []);
  selectedIndex: number;
  outsideTDate: boolean = false;
  userRole: any;
  superUser: boolean = false;

  titleButton: any = {
    icon: '',
    text: 'Capture Reading',
    method: () => {
      this.captureNewReading()
    }
  }

  constructor(private propertyService: PropertiesService,
    private loadingService: LoadingService,
    private userMessagesService: UserMessagesService,
    private shopService: ShopsService,
    private sanitizer: DomSanitizer,
    private tenantService: FileLookupService,
    public dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit(): void { 
    this.period = this.route.snapshot.paramMap.get("period");
  }

  changeSelection(event, index) {
    this.selectedRow = this.readingHistoryList[index];
    this.selectedIndex = event.checked ? index : undefined;
  }

  getDetailedScreenDetails(reading, meterId, propertyId, meterPeriodStatus, unitId, supplierIds, meterDetails, minDate, maxDate, longCall) {
    this.readingHistory = reading.length > 0 ? reading[0] : [];
    console.log(this.readingHistory)
    this.meterId = meterId;    
    this.utilityType = meterPeriodStatus.utilityType;
    this.propertyId = propertyId;
    this.meterPeriodStatus = meterPeriodStatus;
    this.selectedReading = reading[0];
    
    if(unitId && unitId.length > 0) {
      this.unitId = unitId[0];
    }
    this.supplierIds = supplierIds;
    this.meterDetails = meterDetails;
    console.log(meterDetails)
    let currentDate = new Date();
    this.minDate = minDate;
    this.maxDate = maxDate;
    if(currentDate >= new Date(this.minDate) && currentDate <= new Date(this.maxDate)) {
      this.outsideTDate = false;
      this.meterReadingColumns = ['date', 'reading','utilityType', 'status','captureType', 'capturedBy', 'flag', 'image', 'actions'];
    } else {
      this.meterReadingColumns = ['date', 'reading','utilityType', 'status','captureType', 'capturedBy', 'flag', 'image'];
      this.outsideTDate = true;
    }

    if(currentDate < new Date(minDate) || currentDate > new Date(maxDate)) {
      this.stopCapture = true;
    } else {
      this.stopCapture = false;
    }

    if(this.readingHistory.approvalStatus) {
      this.readingHistory.status = this.readingHistory.approvalStatus;
    }

    this.userRole = JSON.parse(localStorage.getItem("userRole"));
    if (
      this.userRole === "ADMIN" ||
      this.userRole === "Admin" ||
      this.userRole === "SUPER_USER"
    ) {
      this.superUser = true;
    }
    
    if(this.readingHistory) {
      if(this.wasApproved && this.readingHistory.approvalStatus !== 'APPROVED' && this.superUser) {
        this.readingHistory.approvalStatus = 'APPROVED';
      } else if(this.wasApproved && this.readingHistory.approvalStatus !== 'APPROVED' && !this.superUser) {
        this.readingHistory.approvalStatus = 'SUBMITTED';
      }
  
      if(this.utilityType && !this.utilityType.type) {
        this.utilityType = utilityTypes.filter(x => x.type === this.utilityType)[0];
      }
      this.readingList = [];
      
  
      if(this.readingHistory && this.readingHistory.reading) {
        const loopLength = this.readingHistory.reading.readingValueList ? this.readingHistory.reading.readingValueList.length : this.utilityType.num;
        for(let i = 0; i < loopLength; i++) {
          if(this.readingHistory && this.readingHistory.reading && 
            this.readingHistory.reading.readingValueList && 
            this.readingHistory.reading.readingValueList[i] && 
            this.readingHistory.reading.readingValueList[i] != null) {
            if(this.readingHistory.reading.readingValueList[i].name && this.readingHistory.reading.readingValueList[i].name.includes('KVA')) {
              this.kvaNum.push({
                name: this.readingHistory.reading.readingValueList[i].name,
                value: this.readingHistory.reading.readingValueList[i].value
              });
            }
            this.readingList.push(this.readingHistory.reading.readingValueList[i]);
          } else {
            this.readingList.push({
              sequence: i+1,
              value: 0
            })
          }      
        }
        
        this.readingValueList = this.readingHistory.reading.readingValueList;
        for(let i = 0; i < this.readingValueList.length; i++) {
          this.readingValueList[i].readingTS = this.readingHistory.reading.readingTS;
          this.readingValueList[i].propertyId = this.propertyId;
          this.readingValueList[i].meterId = this.readingHistory.meterId;
          this.readingValueList[i].capturedBy = this.readingHistory.reading.readingBy;
          this.imageLoaded.push(false);
        }
        this.getReadingImage();
        this.property = JSON.parse(sessionStorage.getItem('propertySelection'));
        if(this.utilityType.type === 'WATER' || this.utilityType.type === 'ELEC_1P' || this.utilityType.type === 'ELEC_3P_1') {
          this.readingHistoryColumns = ['date', 'reading', 'capturedBy'];
        } else if(this.utilityType.type == 'ELEC_3P_TOU') {
          this.readingHistoryColumns = [ 'date', 'standard', 'offPeak', 'peak', 'kva', 'capturedBy'];
        } else if(this.utilityType.type === 'ELEC_3P_3') {
          this.readingHistoryColumns = [ 'date', 'reading', 'reading2', 'reading3', 'capturedBy'];
        } else {
          this.readingHistoryColumns = [ 'date', 'reading', 'kva', 'capturedBy'];
        }
        this.period = this.property.date.year + this.property.date.month.value;
        
        if(!longCall) {
          this.count = 0;
          if(this.superUser && this.readingHistory.approvalStatus !== 'APPROVED') {
            this.retrieveReadingHistory();
          } else {
            this.loadingService.openBlockingLoader('Retrieving Reading Details', 'primary');
            this.count++;
            this.closeLoader();
          }
        } else {
          if(!this.startBigCall) {
            this.startBigCall = true;
          } else {
            if(this.superUser && this.readingHistory.approvalStatus !== 'APPROVED') {
              this.retrieveReadingHistory();
            }
          }
        }
      }
    }
  }

  setFlagWarningText() {
    if((this.readingHistory.flagReason && this.readingHistory.flagReason !== 'NONE') && (!this.meterPeriodStatus.errorMessageList || (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length === 0))) {
      return this.readingHistory.flagReason;
    } else if((!this.readingHistory.flagReason || (this.readingHistory.flagReason && this.readingHistory.flagReason === 'NONE')) && (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length > 0)) {
      return this.meterPeriodStatus.errorMessageList[0];
    } else if((this.readingHistory.flagReason && this.readingHistory.flagReason !== 'NONE') && (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length > 0)){
      return `${this.readingHistory.flagReason}, ${this.meterPeriodStatus.errorMessageList[0]}`;
    } else {
      return '';
    }
  }

  rejectReading(notReject) {
    const reading = {
      meterId: this.meterId,
      unitId: this.unitId.unitId ? this.unitId.unitId : this.unitId,
      propertyId: this.propertyId,
      readingPeriod: this.period
    }
    const dialogRef = this.dialog.open(SendNotificationDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '60%',
      data: {
        notReject: notReject,
        title: notReject ? 'Submit ' : 'Reject ',
        reading: reading,
        period: this.period
      }
    });

    dialogRef.afterClosed().subscribe((res:any) => {
      if(res) {
        this.count = 0;
        sessionStorage.setItem('qualityControlGetCall', 'true');
        this.retrieveReadingDetails.emit();
      }
    })
  }

  retrieveReadingHistory() {
    if(!this.startBigCall) {
      this.loadingService.openBlockingLoader('Retrieving Previous Readings', 'primary');
    }
    console.log('kahwvfkhbawdhkfbadwhkfbhwladbflhawfhlwbh')
    from(this.propertyService.getPreviousPeriodReadingsInPeriod(this.property.property.id, this.minDate, this.maxDate, this.meterId, 5)).subscribe((res:any) => {
      this.readingHistoryList = res.data.getMeterReadingsPerMeterPerPeriod.readingList;
      console.log(this.readingHistoryList)
      this.readingHistoryDS = new MatTableDataSource(this.readingHistoryList);
      console.log(this.readingHistoryList);
      if(!this.startBigCall) {
        this.count++;
        this.closeLoader()
      }
    })
  }

  approveReading() {
    if(!this.superUser && this.readingHistory.flagReason.includes('NEGATIVE')) {
      this.readingHistory.propertyId = this.propertyId;
      this.rejectReading(true);
    } else {
      this.loadingService.openBlockingLoader(`${this.superUser ? 'Approving' : 'Submitting'} Reading`);

      if(this.meterDetails.meterType === 'UNIT') {
        from(this.propertyService.approvePerioReadingPerMeter(this.meterId, this.propertyId, this.period)).subscribe((res:any) => {
          this.loadingService.closeBlockingLoader();
    
          if(res.data.approvePeriodReadingsPerMeter.isSuccessful) {
            this.wasApproved = true;
            message_types.success.messageBody = `Successfully ${this.superUser ? 'Approved' : 'Submitted'} Reading`;
            this.userMessagesService.openMessage(message_types.success);
            this.count = 0;
            sessionStorage.setItem('qualityControlGetCall', 'true');
            this.retrieveReadingDetails.emit();
          } else {
            message_types.error.displaySupport = true;
            message_types.error.messageBody = `Failed to ${this.superUser ? 'Approve' : 'Submit'} Reading`;
            this.userMessagesService.openMessage(message_types.error);
            this.loadingService.closeBlockingLoader();
          }
          
        },
        (error) => {
          if(error.errors[0].message.includes('401')) {
            message_types.error.displaySupport = false;
            message_types.error.messageBody = 'Your session has expired. Please log in again';
            this.userMessagesService.openMessage(message_types.error);

            this.loadingService.closeBlockingLoader();
          } else {
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to Approve Reading';
            this.userMessagesService.openMessage(message_types.error);
            this.loadingService.closeBlockingLoader();
          }
        });
      } else {
        from(this.propertyService.approveBulkPeriodReading(this.meterId, this.propertyId, this.period)).subscribe((res:any) => {
          this.loadingService.closeBlockingLoader();
    
          if(res.data.approvePeriodReadingsPerMeter.isSuccessful) {
            this.wasApproved = true;
            message_types.success.messageBody = `Successfully ${this.superUser ? 'Approved' : 'Submitted'} Reading`;
            this.userMessagesService.openMessage(message_types.success);
            this.getDetailedScreenDetails(this.readingHistory, this.meterId, this.propertyId, this.meterPeriodStatus, this.unitId, this.supplierIds, this.meterDetails, this.minDate, this.maxDate, false);
          } else {
            message_types.error.displaySupport = true;
            message_types.error.messageBody = `Failed to ${this.superUser ? 'Approve' : 'Submit'} Reading`;
            this.userMessagesService.openMessage(message_types.error);
            this.loadingService.closeBlockingLoader();
          }
          
        },
        (error) => {
          if(error.errors[0].message.includes('401')) {
            message_types.error.displaySupport = false;
            message_types.error.messageBody = 'Your session has expired. Please log in again';
            this.userMessagesService.openMessage(message_types.error);
            this.loadingService.closeBlockingLoader();
          } else {
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to Approve Reading';
            this.userMessagesService.openMessage(message_types.error);
            this.loadingService.closeBlockingLoader();
          }
        });
      }
    }    
  }

  checkReadingText(i) {
    if(this.utilityType.type === 'ELEC_3P_TOU') {
      if(i === 1) {
        return 'Standard'
      } else if(i === 2) {
        return 'Off Peak'
      } else if(i === 3) {
        return 'Peak'
      } else {
        return 'KVA'
      }
    } else if(this.utilityType.type.includes('KVA')) {
      if(i === 1) {
        return 'Reading';
      } else {
        return 'KVA';
      }
    } else if(this.utilityType.type === 'ELEC_3P_3') {
      return 'Reading ' + i;
    } else {
      return 'Reading';
    }
  }

  
  closeLoader(error?) {
    if(this.count === 1 && !this.hasError) {
      this.loadingService.closeBlockingLoader();
      
      if(this.readingList[0].meterId) {
        this.readingList = this.readingList.filter(r => !r.name || (r.name && !r.name.includes('Reactive') && (!r.name.includes('KVA') || (r.name.includes('Max KVA') || r.name.includes('Netword Demand Charge')))))
        this.readingList.unshift({
          readingTS: this.readingValueList[0].readingTS
        })
        this.meterReadingDS = new MatTableDataSource(this.readingList);
        console.log(this.readingList);
      }
    } else if(this.hasError){
      if(error && error.errors[0].message.includes('401')) {
        message_types.error.displaySupport = false;
        message_types.error.messageBody = 'Your session has expired. Please log in again';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      } else {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = error;
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      }
    }
  }

  openUpdateReadings() {
    const dialogRef = this.dialog.open(UpdateReadingsComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '60%',
      data: {
        isUpdate: this.readingHistory.approvalStatus !== 'APPROVED',
        meter: {
          readingList: this.readingList,
          utilityType: this.utilityType,
          readingDetails: {
            meterId: this.meterId,
            approvalStatus: this.selectedReading.approvalStatus,
            reading: {
              readingType: this.selectedReading.reading.readingType
            }
          },
          thumbNail: this.blobThumbNail
        },
        hasImages: this.hasImages,
        period: this.period,
        propertyId: this.propertyId,
        supplierIds: this.supplierIds
      }
    })

    dialogRef.afterClosed().subscribe((updatedMeter:any) => {
      if(updatedMeter) {
        this.count = 0;
        for(let i = 1; i <= this.readingList.length - 1; i++) {
          this.readingList[i].value = updatedMeter.reading.readingValueList[i - 1].value;
        }

        this.loadingService.closeBlockingLoader();
        sessionStorage.setItem('qualityControlGetCall', 'true');
        this.retrieveReadingDetails.emit('update');
      }
    })
  }

  viewImage(index) {
    const dialogRef = this.dialog.open(ImageViewComponent, {
      panelClass: 'custom-view-image-container',
      data: {
        thumbnail: this.thumbnail[index - 1]
      }
    });
  }

  getReadingImage() {
    if(this.readingList.length > 0) {
      localStorage.setItem("image",JSON.stringify(true));
      for(let i = 0; i < this.readingList.length; i++) {
        if(this.readingList[i].imageKey) {
          from(this.shopService.getReadingImageFromKey(this.readingList[i].imageKey)).subscribe((res:any) => {
            localStorage.setItem("image",JSON.stringify(true));
            this.readingList[i].presignedURL = res.data.getReadingImageFromKey.presignedUrl;
            this.getImageFromS3(res.data.getReadingImageFromKey.presignedUrl, i);
          })
        } else {
          from(this.shopService.getReadingImagePresinged(this.readingList[i])).subscribe((res: any) => {
            localStorage.setItem("image",JSON.stringify(true));
            this.readingList[i].presignedURL = res.data.getReadingImage.presignedUrl;
            this.getImageFromS3(res.data.getReadingImage.presignedUrl, i);
          },(error) => {
            this.count++;
            this.closeLoader();
          })
        }
      }
    }
  }

  getImageFromS3(url, index) {
    
    this.tenantService.getImageReadingFromS3(url).subscribe((res: any) => {
      this.count++;
      console.log(url)
      console.log(res)
      this.blobThumbNail[index] = res;
      console.log(this.blobThumbNail[index])
      this.thumbnail[index] = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobThumbNail[index]);
      console.log(this.thumbnail[index])
      this.hasImages = true;
      this.closeLoader();
      this.imageLoaded[index] = true;
      //for when the index is equal to number of readings
      if(index === 4) {
        localStorage.removeItem("image");
      }
    },(error) => {
      this.count++;
      if(error.status === 404) {
        this.thumbnail[index] = ''
        this.imageLoaded[index] = false;
      }
      this.closeLoader();
      localStorage.removeItem("image");
    });
  }

  captureNewReading() {
    const newMeter = {
      meter: {
        utilityType: this.meterPeriodStatus.utilityType,
        id: this.meterId,
        serialNumber: this.meterPeriodStatus.meterNo
      },
      dates: {
        minDate: this.meterPeriodStatus.minDate,
        maxDate: this.meterPeriodStatus.maxDate
      },
      showDates: true,
      superUser: this.superUser,
      propertyId: this.propertyId,
      reload: true,
      tradingName: this.meterPeriodStatus.tradingName
    }

    const dialogRef = this.dialog.open(CaptureReadingComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '64%',
      width: '60%',
      disableClose: true,
      data: {
        meter: newMeter,
        readingPeriod: this.period,
        title: 'Capture New'
      }
    })

    dialogRef.afterClosed().subscribe((res:any) => {
      if(res) {
        this.loadingService.closeBlockingLoader();
        sessionStorage.setItem('qualityControlGetCall', 'true');
        this.retrieveReadingDetails.emit('long');
      }
    })
  }

  replacePeriodReading() {
    this.selectedRow.utilityType = this.utilityType;
    if(this.selectedRow) {
      const dialogRef = this.dialog.open(HistorySelectReviewDialogComponent, {
        panelClass: 'custom-user-dialog-container',
        width: '60%',
        data: {
          selectedRow: this.selectedRow,
          readingHistoryColumns: this.readingHistoryColumns.slice(1)
        }
      });

      dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.loadingService.openBlockingLoader('Replacing Period Reading', 'primary');
          from(this.propertyService.replacePeriodReading(this.meterId, this.selectedRow.readingTS, this.period)).subscribe((res:any) => {
            message_types.in_progress.messageBody = `Processing ${this.readingValueList.length > 1 ? 'readings' : 'reading'} and charges`;
            this.userMessagesService.openMessage(message_types.in_progress);
            this.loadingService.closeBlockingLoader();
            this.count = 0;
            sessionStorage.setItem('qualityControlGetCall', 'true');
            this.retrieveReadingDetails.emit('update');
          }, (error) => {
            this.hasError = true;
            this.closeLoader('Failed to Replace Period Reading');
          })
        }
      })
    }
  }

}

@Component({
  selector: 'history-select-review-dialog',
  templateUrl: './history-select-review-dialog.html',
})
export class HistorySelectReviewDialogComponent {

  readingHistoryColumns: string[];
  selectedRow;

  constructor(public dialogRef: MatDialogRef<HistorySelectReviewDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.selectedRow = new MatTableDataSource([data.selectedRow]);
    this.readingHistoryColumns = data.readingHistoryColumns;
  }

  closeDialog = (replaceReading?) => {
    this.dialogRef.close(replaceReading);
  }
}