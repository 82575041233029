import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import gql from 'graphql-tag';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilitySupplierService {

  chargeCalculationEndPoint = environment.api.chargeCalculation;
  chargeCalculationAPI = {
    "aws_appsync_graphqlEndpoint" : this.chargeCalculationEndPoint
  }

  constructor() { }

  listUtilitySuppliers() {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      query MyQuery {
        listUtilitySuppliers {
          errorMessage
          isSuccessful
          utilitySupplierList {
            description
            id
            name
            province
            shortName
          }
        }
      }
      `
    })
  }

  getAllTariffTypesWithSupplierId(supplierId) {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query:`
      query MyQuery($utilitySupplierId: ID = "") {
        getAllTariffTypesByUtilitySupplierId(utilitySupplierId: $utilitySupplierId) {
          errorMessage
          isSuccessful
          tariffTypes {
            description
            id
            name
            tariffCategory
            tariffConstraint {
              utilityType
            }
          }
        }
      }      
      `,
      variables: {
        utilitySupplierId: supplierId
      }
    })
  }

  listUtilitySuppliersName() {
    API.configure(this.chargeCalculationAPI);
    return API.graphql({
      query: `
      query MyQuery {
        listUtilitySuppliers {
          errorMessage
          isSuccessful
          utilitySupplierList {
            id
            name
            tariffSetList {
              tariffList {
                tariffType
              }
            }
          }
        }
      }
      `
    })
  }
  
}
