import { Injectable } from '@angular/core';
import gql from 'graphql-tag'
import { API } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: any;
  result: any;
  userManagementEndPoint = environment.api.userManagement;
  meterReadingEndPoint = environment.api.meterReading;
  userManagementAPI = {
    "aws_appsync_graphqlEndpoint" : this.userManagementEndPoint
  }
  meterReadingAPI = {
    "aws_appsync_graphqlEndpoint" : this.meterReadingEndPoint
  }

  constructor(private http: HttpClient) { 
  }

  setUserDetails() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    localStorage.setItem("accessToken", this.currentUser.token);
    localStorage.setItem("refreshToken",this.currentUser.refreshToken);
    localStorage.setItem("userRole", JSON.stringify(this.currentUser.roles[0]));
    localStorage.setItem("email", JSON.stringify(this.currentUser.email));
  }

  signIn(email,password) {
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($email: String = "", $password: String = "") {
        loginUser(email: $email, password: $password) {
          accessToken
          email
          firstname
          lastname
          loginState
          message
          refreshToken
          role
          token
          userId
        }
      }
      `,
      variables: {
        email: email.trim().toLowerCase(),
        password: password
      },
    })
  }

  refreshToken(refreshToken) {
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($refreshToken: String = "") {
        refreshToken(refreshToken: $refreshToken) {
          accessToken
          errorMessage
          isSuccessful
          loginState
          refreshToken
          token
          code
          expiry
        }
      }
      `,
      variables: {
        refreshToken: refreshToken
      }
    })
  }

  registerUser(user){
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($roles: [String] = "", $lastName: String = "", $firstName: String = "", $email: String = "", $contactNumber: String = "", $password: String = "") {
        registerUser(password: $password, userEntity: {contactNumber: $contactNumber, email: $email, firstName: $firstName, lastName: $lastName, roles: $roles}) {
          user {
            email
            lastName
            firstName
            roles
            userId
            contactNumber
          }
          isSuccessful
          errorMessage
        }
      }
      
      `,
      variables: {
        contactNumber: user.contactNumber,
        email: user.email.trim().toLowerCase(),
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        roles: user.roles,
      },
    })
  }

  superRegisterUser(user){
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($roles: [String] = "", $lastName: String = "", $firstName: String = "", $email: String = "", $contactNumber: String = "", $agentCode: String = "", $password: String = "") {
        registerUser(password: $password, userEntity: {contactNumber: $contactNumber, email: $email, firstName: $firstName, lastName: $lastName, roles: $roles, agentCode: $agentCode}) {
          user {
            email
            lastName
            firstName
            roles
            userId
            contactNumber
          }
          isSuccessful
          errorMessage
        }
      }               
      `,
      variables: {
        contactNumber: user.contactNumber,
        email: user.email.trim().toLowerCase(),
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        roles: user.roles,
        agentCode: user.agentCode
      },
    })
  }

  updateUser(user, userId){
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($contactNumber: String = "", $email: String = "", $firstName: String = "", $lastName: String = "", $roles: [String] = "", $userId: String = "", $agentCode: String = "") {
        updateUser(userEntity: {contactNumber: $contactNumber, email: $email, firstName: $firstName, lastName: $lastName, roles: $roles, userId: $userId, agentCode: $agentCode}) {
          errorMessage
          isSuccessful
          user {
            userId
          }
        }
      }           
      `,
      variables: {
        contactNumber: user.contactNumber,
        email: user.email.trim().toLowerCase(),
        firstName: user.firstName,
        lastName: user.lastName,
        userId: userId,
        roles: user.roles
      },
    })
  }

  registerPropertyToUser(propertyList, userId) {
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: `
      mutation MyMutation($userId: ID = "", $assignedProperties: [AssignedPropertyInput] = {propertyId: "", propertyName: ""}) {
        assignUserToProperties(assignedProperties: $assignedProperties, userId: $userId) {
          isSuccessful
          errorMessage
        }
      }                 
      `,
      variables: {
        assignedProperties: propertyList,
        userId: userId
      }
    })
  }

  deleteUser(id) {
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($userId: String = "") {
        deleteUser(userId: $userId) {
          isSuccessful
        }
      }
      `,
      variables: {
        userId: id
      },
    })
  }

  loginUser(email, password, authToken){
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: `mutation MyMutation {
      loginUser(email: "${email.trim().toLowerCase()}", password: "${password}", agentCode: "SAC") {
        accessToken
        email
        errorMessage
        expiry
        firstName
        flowCode
        isSuccessful
        lastName
        loginState
        message
        refreshToken
        roles
        session
        token
        userId
      }
    }`}, {'Authorization': authToken})
  }

  logOut(username){
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: `
      mutation MyMutation($username: String = "") {
        logoutUser(, username: $username) {
          errorMessage
          isSuccessful
        }
      }      
    `,
    variables: {
      username: username.trim().toLowerCase()
    }}, {'Authorization': localStorage.getItem('accessToken')})
  }
  

  getAllUsers(){
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery {
        getUsers(limit: 100) {
          userList {
            _deleted
            assignedProperties {
              propertyId
              propertyName
            }
            contactNumber
            createDate
            createdBy
            email
            firstName
            lastName
            roles
            updateDate
            updatedBy
            userId
          }
          isSuccessful
          errorMessage
        }
      }     
      `
    })
  }

  forgotPassworRequest(username, authToken) {
    API.configure(this.userManagementAPI);
    return API.graphql({
      query:`
      mutation MyMutation($email: String = "") {
        forgotPasswordRequest(email: $email) {
          isSuccessful
          statusCode
          errorMessage
        }
      }
      `,
      variables:{
        email: username.trim().toLowerCase()
      }
    },
    {'Authorization': authToken})
  }

  firstTimeForgot(username, sessionToken, authToken, newPassword) {
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: `
      mutation MyMutation($agentCode: String = "", $email: String = "", $newPassword: String = "", $session: String = "") {
        changePassword(email: $email, newPassword: $newPassword, session: $session, agentCode: $agentCode) {
          errorMessage
          isSuccessful
          status
        }
      }           
      `,
      variables: {
        email: username.trim().toLowerCase(),
        newPassword: newPassword,
        agentCode: 'SAC',
        session: sessionToken
      }
    }, {'Authorization': authToken})
  }

  forgotPasswordConfirm(username, code, authToken, newPassword) {
    API.configure(this.userManagementAPI);
    return API.graphql({
      query: `
      mutation MyMutation($confirmationCode: String = "", $email: String = "", $newPassword: String = "") {
        forgotPasswordConfirm(confirmationCode: $confirmationCode, email: $email, newPassword: $newPassword) {
          errorMessage
          isSuccessful
          statusCode
        }
      }
      `,
      variables: {
        confirmationCode: code,
        email: username.trim().toLowerCase(),
        newPassword: newPassword
      }
    }, {'Authorization': authToken})
  }
}
