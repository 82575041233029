import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile.component';
import { BaseModule } from '../../core/base/base.module';
import { MaterialModule } from '../../core/material/material.module';
import { LoadingModule } from '../loading/loading.module';
import { FabButtonModule } from 'src/app/shared/micro-components/fab-button/fab-button.module';
@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    BaseModule,
    MaterialModule,
    LoadingModule,
    FabButtonModule
  ],
  exports: [
    UserProfileComponent
  ]
})
export class UserProfileModule { }
