import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { UserMessagesService } from '../../micro-components/user-messages/user-messages.service';
import { message_types } from '../../micro-components/user-messages/message_types';

@Component({
  selector: 'app-add-meter-node-dialog',
  templateUrl: './add-meter-node-dialog.component.html',
  styleUrls: ['./add-meter-node-dialog.component.scss']
})
export class AddMeterNodeDialogComponent implements OnInit, AfterViewInit {

  title;
  propertyId;
  tariffList;
  bulkMeters: any[];
  meter:any;
  timeout: any = null;
  checkExists: boolean = false;
  meterNoExists: boolean = false;
  captureType = [{name: 'Online', value: 'ONLINE'}, {name: 'Manual', value: 'MANUAL'}, {name: 'Online Manual', value: 'ONLINE_MANUAL'}]
  displayCommission2 = false;
  displayCommission3 = false;
  displayCommission4 = false;
  tariffShow = false;
  waterList: any[] = [];
  elecList: any[] = [];
  buttonProperties = {
    text: 'Save',
    method: () => {
      this.saveMeter()
    }
  }
  closeMethod = () => {
    this.closeDialog({})
  }
  showWater: boolean = false;

  meterDetails = new UntypedFormGroup({
    amps: new UntypedFormControl(1, Validators.min(1)),
    utilityType: new UntypedFormControl(''),
    readingMethod: new UntypedFormControl(''),
    meterNo: new UntypedFormControl(''),
    commissionDate: new UntypedFormControl(''),
    noOfPhotos: new UntypedFormControl({
      value: '',
      disabled: true
    }),
    commissionReading: new UntypedFormControl(''),
    commissionReading2: new UntypedFormControl(''),
    commissionReading3: new UntypedFormControl(''),
    commissionReading4: new UntypedFormControl(''),
    captureType: new UntypedFormControl(''),
    tariff: new UntypedFormControl(''),
    isManual: new UntypedFormControl(''),
  });
  meterUtility = [{
    name: 'ELEC_1P',
    image: 1
  },
  {
    name : 'ELEC_1P_KVA',
    image: 2
  },
  {
    name: 'ELEC_3P_KVA',
    image: 2
  },
  {
    name: 'ELEC_3P_1',
    image: 1
  },
  {
    name: 'ELEC_3P_3',
    image: 3
  },
  {
    name: 'ELEC_3P_TOU',
    image: 4
  },
  {
    name: 'WATER',
    image: 1
  }];
  readingMethods = ["Manual", "Smart"];

  constructor(
    public loadingservice: LoadingService, 
    private userMessagesService: UserMessagesService, 
    public dialogRef: MatDialogRef<AddMeterNodeDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data, 
    private meterManagementService: MeterManagementService,
    private cd: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
      this.cd.detectChanges();
  }

    ngOnInit() {
      this.bulkMeters = [];
      this.bulkMeters = this.data.bulkMeters;
      this.meter = {};
      this.title = this.data.title;
      this.propertyId = this.data.propertyId;
      this.tariffList = this.data.tariffList;
      if(this.tariffList.length != 0) {
        console.log(this.tariffList)
        this.waterList = this.sortList(this.tariffList, true)
        this.elecList = this.sortList(this.tariffList, false);
      }
    }

    closeDialog(meter) {
      this.dialogRef.close(meter);
    }
    
    meterChange() {
      console.log(this.meterDetails.get('utilityType').value)

      if(this.meterDetails.get('utilityType').value.name === 'WATER') {
        this.showWater = true;
      } else {
        this.showWater = false;
      }
      console.log(this.showWater)
      const meterUtility = this.meterDetails.value.utilityType;
      this.meterDetails.patchValue({
        noOfPhotos: meterUtility.image
      })
      if(this.tariffList.length != 0) {
        this.checkUtilityType();
      }

      if(meterUtility.name === 'ELEC_1P'){
        this.displayCommission2 = false;
        this.displayCommission3 = false;
        this.displayCommission4 = false;
        this.meterDetails.patchValue({
          commissionReading2: '',
          commissionReading3: '',
          commissionReading4: ''
        });
      }else if(meterUtility.name === 'ELEC_1P_KVA'){
        this.displayCommission2 = true;
        this.displayCommission3 = false;
        this.displayCommission4 = false;
        this.meterDetails.patchValue({
          commissionReading3: '',
          commissionReading4: ''
        });
      }else if(meterUtility.name === 'ELEC_3P_1'){
        this.displayCommission2 = false;
        this.displayCommission3 = false;
        this.displayCommission4 = false;
      }else if(meterUtility.name === 'ELEC_3P_3'){
        this.displayCommission2 = true;
        this.displayCommission3 = true;
        this.displayCommission4 = false;
        this.meterDetails.patchValue({
          commissionReading4: ''
        });
      }else if(meterUtility.name === 'ELEC_3P_KVA'){
        this.displayCommission2 = true;
        this.displayCommission3 = false;
        this.displayCommission4 = false;
        this.meterDetails.patchValue({
          commissionReading3: '',
          commissionReading4: ''
        });
      }else if(meterUtility.name === 'ELEC_3P_TOU'){
        this.displayCommission2 = true;
        this.displayCommission3 = true;
        this.displayCommission4 = true;
      }else if(meterUtility.name === 'WATER'){
        this.displayCommission2 = false;
        this.displayCommission3 = false;
        this.displayCommission4 = false;
        this.meterDetails.patchValue({
          commissionReading2: '',
          commissionReading3: '',
          commissionReading4: ''
        });
      }

      this.checkIfNumberExists();

      this.checkCaptureType();
    }

    sortList(tariffList, isWater) {
      let tariffType = []
      if(isWater) {
        tariffType = ['Water', 'Sanitation'];
      } else {
        tariffType = ['Electricity']
      }
      return tariffList.map(t => {
        if(t.tariffList && t.tariffList.length > 0) {
          return t.tariffList.filter(tariff => tariff.utilityType && tariffType.includes(tariff.utilityType))
        }
      });
    }

    checkCaptureType() {
      const utilityType = this.meterDetails.value.utilityType;
      const readingMethod = this.meterDetails.value.isManual;
  
      if(readingMethod === 'Manual') {
        this.meterDetails.controls['captureType'].disable();
        this.meterDetails.controls['captureType'].setValue('MANUAL');
      } else {
        this.meterDetails.controls['captureType'].enable();
      }
  
    }

    checkIfNumberExists() {
      if(this.meterDetails.value.utilityType && this.meterDetails.value.meterNo) {
        clearTimeout(this.timeout);
        this.meterNoExists = true;
        this.checkExists = true;
        this.timeout = setTimeout(() => {
          from(this.meterManagementService.checkIfMeterNumberExists(this.propertyId, this.meterDetails.value.utilityType.name, this.meterDetails.value.meterNo)).subscribe((res:any) => {
            this.meterNoExists = res.data.checkIfMeterNumberExists.meterNoExists;
            this.checkExists = false;
          })
        }, 1000)
      } else {
        this.meterNoExists = false;
        this.checkExists = false;
      }
    }

    checkUtilityType() {
      let meterUtility = this.meterDetails.value.utilityType.name;
      if(meterUtility && meterUtility === 'WATER') {
        this.meterDetails.setControl('amps', new UntypedFormControl(0));
        this.tariffShow = true;
        for(let i = 0; i < this.tariffList.length; i++) {
          this.tariffList[i].tariffList = this.waterList[i];
        }
      } else if(!meterUtility || meterUtility === null) {
        this.tariffShow = false;
      } else {
        this.tariffShow = true;
        const amps = this.meterDetails.value.amps;
        this.meterDetails.setControl('amps', new UntypedFormControl(amps, Validators.min(1)));
        for(let i = 0; i < this.tariffList.length; i++) {
          this.tariffList[i].tariffList = this.elecList[i];
        }
      }
    }

    saveMeter() {
      let commissionReadings = [];
      this.meter = this.meterDetails.value;
      this.meter.captureType = this.meterDetails.controls['captureType'].value;
        if(this.meter.isManual === "Manual") {
          this.meter.isManual = true;
        }else {
          this.meter.isManual = false;
        }

        if(this.meter.commissionReading === "" || this.meter.commissionReading === undefined || this.meter.commissionReading === null) {
          commissionReadings.push({
            'value': 0,
            'sequence': "1"
          });
        } else {
          commissionReadings.push({
            'value': parseFloat(this.meter.commissionReading),
            'sequence': "1"
          });
        }
        
        if(this.displayCommission2) {
          commissionReadings.push({
            'value': this.meter.commissionReading2 ? parseFloat(this.meter.commissionReading2) : 0,
            'sequence': "2"
          });
        }

        if(this.displayCommission3) {
          commissionReadings.push({
            'value': this.meter.commissionReading3 ? parseFloat(this.meter.commissionReading3) : 0,
            'sequence': "3"
          });
        }

        if(this.displayCommission4) {
          commissionReadings.push({
            'value': this.meter.commissionReading4 ? parseFloat(this.meter.commissionReading4) : 0,
            'sequence': "4"
          });
        }

        this.meter.commissionReadings = commissionReadings;

        if(this.meter.isManual === "" || this.meter.isManual === undefined || this.meter.isManual === null) {this.meter.isManual = true};
        this.meter.commissionDate = new Date(this.meter.commissionDate).toISOString();
        if(this.tariffList.length != 0) {
          this.meter.tariffId = this.meter.tariff.map(t => t.id);
        }
        this.meter.meterReadingType = this.meter.utilityType.name.includes('TOU') ? 'UTILIZATION' : 'READING';
        this.meter.isCommonArea = false;
        this.meter.bulkMeter = this.meter.bulkMeter === '' ? null : this.meter.bulkMeter;
        
        this.loadingservice.openBlockingLoader("Adding New Meter","primary");
        from(this.meterManagementService.createMeterNode(this.propertyId, this.meter)).subscribe((res:any) => {
          this.loadingservice.closeBlockingLoader();
          message_types.success.messageBody = 'Successfully Added Meter';
          this.userMessagesService.openMessage(message_types.success);
          if(res.data.createMeterNode.isSuccessful) {
            this.meter.id = res.data.createMeterNode.meterNodeId;
            this.meter.meterNo = this.meter.meterNo;
            this.meter.linkStatus = 'UNLINKED';
            this.meter.utilityType = this.meter.utilityType.name;
            this.meter.status = 'ACTIVE';
            this.closeDialog(this.meter);
          } else {
            this.loadingservice.closeBlockingLoader();
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Error Creating Meter';
            this.userMessagesService.openMessage(message_types.error);
          }
          
        }, (error) => {
          this.loadingservice.closeBlockingLoader();
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Error Creating Meter';
          this.userMessagesService.openMessage(message_types.error);
        })
    }

}
