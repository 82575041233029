<sym-card-dialog [title]="'Confirmation'" [closeMethod]="closeDialog">
  <span style="font-size: 16px;margin: 1em 0">The current period reading will be replaced with the one below:</span>
    <mat-table [dataSource]="selectedRow">
  
          <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef fxFlex="35" style="height: 80%;" class="table-header">Date
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="35" style="color: black;">
                <div *ngIf="element.readingTS">
                  {{element.readingTS | date:'yyyy-MM-dd'}}
                </div>
                <div *ngIf="!element.readingTS">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="reading">
              <mat-header-cell *matHeaderCellDef fxFlex="25" style="height: 80%;" class="table-header">Reading
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="25" style="color: black;">
                <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[0].value">
                  {{element.readingValueList[0].value | number:'1.0-4'}}
                </div>
                <div *ngIf="!element.readingValueList || (element.readingValueList && element.readingValueList.length <= 0)  || (element.readingValueList && element.readingValueList.length > 0 && !element.readingValueList[0].value)">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="standard">
              <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Standard
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
                <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[1].value">
                  {{element.readingValueList[0].value | number:'1.0-4'}}
                </div>
                <div *ngIf="!element.readingValueList || (element.readingValueList && element.readingValueList.length <= 0)  || (element.readingValueList && element.readingValueList.length > 0 && !element.readingValueList[1].value)">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="offPeak">
              <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Off Peak
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
                <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[2].value">
                  {{element.readingValueList[1].value | number:'1.0-4'}}
                </div>
                <div *ngIf="!element.readingValueList || (element.readingValueList && element.readingValueList.length <= 0)  || (element.readingValueList && element.readingValueList.length > 0 && !element.readingValueList[2].value)">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="peak">
              <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Peak
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
                <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[0].value">
                  {{element.readingValueList[2].value | number:'1.0-4'}}
                </div>
                <div *ngIf="!element.readingValueList || (element.readingValueList && element.readingValueList.length <= 0)  || (element.readingValueList && element.readingValueList.length > 0 && !element.readingValueList[0].value)">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="reading2">
              <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Reading 2
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
                <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[1].value">
                  {{element.readingValueList[1].value | number:'1.0-4'}}
                </div>
                <div *ngIf="!element.readingValueList || (element.readingValueList && element.readingValueList.length <= 0)  || (element.readingValueList && element.readingValueList.length > 0 && !element.readingValueList[1].value)">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="reading3">
              <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">Reading 3
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
                <div *ngIf="element.readingValueList && element.readingValueList.length > 0 && element.readingValueList[2].value">
                  {{element.readingValueList[2].value | number:'1.0-4'}}
                </div>
                <div *ngIf="!element.readingValueList || (element.readingValueList && element.readingValueList.length <= 0)  || (element.readingValueList && element.readingValueList.length > 0 && !element.readingValueList[2].value)">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="kva">
              <mat-header-cell *matHeaderCellDef fxFlex="15" style="height: 80%;" class="table-header">KVA
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="15" style="color: black;">
                <div *ngIf="element.readingValueList && element.readingValueList.length > 3 && element.utilityType.type === 'ELEC_3P_TOU'">
                  {{element.readingValueList[3].value | number:'1.0-4'}}
                </div>
                <div *ngIf="element.readingValueList && element.readingValueList.length > 1 && element.utilityType.type != 'ELEC_3P_TOU'">
                  {{element.readingValueList[1].value | number:'1.0-4'}}
                </div>
                <div *ngIf="!element.readingValueList || (element.readingValueList && element.readingValueList.length <= 0) || (element.readingValueList && element.readingValueList.length > 0 && element.utilityType.type === 'ELEC_3P_TOU' && !element.readingValueList[3].value) || (element.readingValueList && element.readingValueList.length > 0 && element.utilityType.type != 'ELEC_3P_TOU' && !element.readingValueList[1].value)">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="capturedBy">
              <mat-header-cell *matHeaderCellDef fxFlex="40" style="height: 80%;" class="table-header">Captured By
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="40" style="color: black;">
                <div *ngIf="element.createdBy">
                  {{element.createdBy}}
                </div>
                <div *ngIf="!element.createdBy">
                  -
                </div>
              </mat-cell>
            </ng-container>
  
        <mat-header-row *matHeaderRowDef="readingHistoryColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: readingHistoryColumns;"></mat-row>
      </mat-table>
      <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 2em;">
        <button mat-button style="color: #41535D;border-radius: 100px;border: 1px solid #E7E7E7;" (click)="closeDialog(false)">Cancel</button>
        <button (click)="closeDialog(true)" mat-button style="color: white;background-color: #41535D;border-radius: 100px;margin-left: .5em;">Confirm</button>
      </div>
</sym-card-dialog>