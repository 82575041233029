import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

@Component({
  selector: 'app-delink-allocation',
  templateUrl: './delink-allocation.component.html',
  styleUrls: ['./delink-allocation.component.scss']
})
export class DelinkAllocationComponent implements OnInit {

  unitColumns: string[] = [
    'unit', 'split', 'allocationRule', 'remove'
  ];
  totalSplit = 0;
  unitDatasource = new MatTableDataSource();
  closeMethod = () => {
    this.closeDialog()
  }
  selectedLink: any;
  linkedUnits: any[];
  
  constructor(public loadingservice: LoadingService, public dialogRef: MatDialogRef<DelinkAllocationComponent>, @Inject(MAT_DIALOG_DATA) public data, private meterManagementService: MeterManagementService, private userMessagesService: UserMessagesService) { }

  ngOnInit(): void {
    this.selectedLink = this.data.selectedLinkNode;

    this.loadingservice.openBlockingLoader('Retrieving additional details', 'primary');
    from(this.meterManagementService.getLinkForNode(this.selectedLink.id)).subscribe((res:any) => {
      this.linkedUnits = [];
      const nodes: any[] = res.data.getLinksPerNode.nodeUnitLinkList;
      
      this.loadingservice.closeBlockingLoader();
    }, (error) => {
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to retrieve additional details';
      this.userMessagesService.openMessage(message_types.error);
      this.loadingservice.closeBlockingLoader();
    })
  }

  addToTable() {
    let unitList = this.unitDatasource.data;
    unitList.push({
      unit: '',
      split: 0,
      allocationRule: ''
    })
    this.unitDatasource = new MatTableDataSource(unitList);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getTotalSplit() {
    this.totalSplit = this.unitDatasource.data.map((s:any) => s.split).reduce((s, val) => s + val, 0);
    return this.totalSplit;
  }

}
