<sym-card-dialog [title]="'Display Readings and Charges'" [closeMethod]="closeMethod">
  <mat-dialog-content>
    <mat-tab-group>
      <mat-tab *ngIf="hasReadingsList(selectedReading)" label="{{(meter.meterReadingType && meter.meterReadingType === 'UTILIZATION') ? 'Utilizations' : 'Meter Readings'}}" >
          <sym-charge-readings [meter]="data.meter" [utilityType]="data.utilityType" [selectedReading]="selectedReading" [utilizationList]="utilizationList" [isMultiple]="isMultiple"></sym-charge-readings>
      </mat-tab>

      <mat-tab label="Charge Calculations">
        <mat-table *ngIf="readingCharges.length > 1" [dataSource]="readingCharges" style="height: 42vh;">
          <ng-container matColumnDef="chargeType">
            <mat-header-cell style="flex: 2;" *matHeaderCellDef>
              Charge Type
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="flex: 2;color: black;">
              <div *ngIf="element.title" style="font-weight: bold;color: #9B9020;">
                {{element.title}}
              </div>
              <div *ngIf="element.tou" style="font-weight: bold;">
                {{element.tou}}
              </div>
              <div *ngIf="!element.title && !element.tou">
                {{element.label | chargeDisplay}}
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="flex: 2;"></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="unitType">
            <mat-header-cell style="flex: 1;" *matHeaderCellDef>
              Unit Type
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="flex: 1;color: black;">
                <div *ngIf="!element.title && !element.tou">
                  <div *ngIf="element.units === 'amps'; else otherUnits">
                    {{element.units | titlecase}}
                  </div>
                  <ng-template #otherUnits>
                    {{element.units}}
                  </ng-template>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="flex: 1;"></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="units">
            <mat-header-cell style="flex: 1;" *matHeaderCellDef>
              Units
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="flex: 1;color: black;">
              <div *ngIf="!element.title && !element.tou">
                {{element.quantity| number:'1.0-4'}}
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="flex: 1;"></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="rate">
            <mat-header-cell style="flex: 1;" *matHeaderCellDef>
              Rate (R)
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="flex: 1;color: black;">
              <div *ngIf="!element.title && !element.tou">
                {{element.rate| number:'1.2-4'}}
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="flex: 1;"><div style="font-weight: bold;">Total Charge:</div></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
            <mat-header-cell style="flex: 1;" *matHeaderCellDef>
              Amount (R)
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="flex: 1;color: black;">
              <div *ngIf="!element.title && !element.tou">
                {{element.amount| number:'1.2'}}
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="flex: 1;">{{totalCharge|number:'1.2-2'}}</mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="chargeColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: chargeColumns"></mat-row>
          <mat-footer-row *matFooterRowDef="chargeColumns;"></mat-footer-row>
        </mat-table>

        <mat-table *ngIf="readingCharges.length <= 1 && selectedReading.unitCalculatedChargeList.length != 0" [dataSource]="calculatedChargeList.calculatedChargeList" style="height: 42vh;">
          <ng-container matColumnDef="tariff">
            <mat-header-cell *matHeaderCellDef fxFlex="40" style="color: grey;">
              Tariff
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="40" style="color: black;font-weight: bold;">
                {{element.chargeType}}
            </mat-cell>
              <mat-footer-cell *matFooterCellDef fxFlex="40" style="color: black;">
              </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="units">
            <mat-header-cell *matHeaderCellDef fxFlex="20" style="color: grey;">
              Units
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;">
              <div fxLayout="column" *ngIf="element.utilization">
                <!-- {{element.utilization + ' ' + element.unitOfMeasurement}} -->
                {{element.utilization}}
              </div>
              <div *ngIf="!element.utilization">
                  -
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="20" style="color: black;">

              </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="rate">
            <mat-header-cell *matHeaderCellDef fxFlex="20" style="color: grey;">
              Rate
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;">
                {{element.rate}}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="20" style="color: black;font-weight: bold;">
              Total:
          </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
              <mat-header-cell *matHeaderCellDef fxFlex="20" style="color: grey;">
                Amount
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;">
                 R {{element.utilitySupplierCharge | number:'1.2-2'}}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef fxFlex="20" style="color: black;font-weight: bold;">
                 R {{calculatedChargeList.totalCharge | number:'1.2-2'}}
              </mat-footer-cell>
            </ng-container>

          <mat-header-row *matHeaderRowDef="olderColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: olderColumns"></mat-row>
          <mat-footer-row *matFooterRowDef="olderColumns"></mat-footer-row>
        </mat-table>

      </mat-tab>
  </mat-tab-group>
  </mat-dialog-content>
    
</sym-card-dialog>
