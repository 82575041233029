import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardDialogComponent } from './card-dialog.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    CardDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule
  ],
  exports: [
    CardDialogComponent
  ]
})
export class CardDialogModule { }
