import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CaptureReadingComponent } from './capture-reading.component';

@Injectable({
  providedIn: 'root'
})
export class CaptureReadingService {

  constructor(private http: HttpClient, public dialog: MatDialog) { }

  uploadImage(image,url){
    return this.http.put(url,image);
  }
}
