import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import gql from 'graphql-tag'
import { API, Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class UploadServiceService {
  @Output() startUpload = new EventEmitter<string>();
  constructor() { }

  meterReadingEndPoint = environment.api.meterReading;
  meterReadingAPI = {
    "aws_appsync_graphqlEndpoint" : this.meterReadingEndPoint
  }

  getPresignedURL(fileName, documentType, supplierId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($documentType: String = "", $filename: String = "", $utilitySupplierIds: [String] = "") {
        fileUpload(file: {documentType: $documentType, filename: $filename, utilitySupplierIds: $utilitySupplierIds})
      }
        `,
      variables: {
        filename: fileName,
        documentType: documentType,
        utilitySupplierIds: supplierId
      }
    })
  }

  updateS3DB(fileName, documentType) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation2($documentType: String = "", $filename: String = "") {
        fileUpdateDB(file: {documentType: $documentType, filename: $filename})
      }
      
      `,
      variables: {
        filename: fileName,
        documentType: documentType
      },

    })
  }
  StartUpload(data: any) {
    this.startUpload.emit(data);
  }

  getStatus(documentType) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($documentType: String = "") {
        getUploadStatus(documentType: $documentType) {
          status
          isSuccessful
        }
      }
      `,
      variables: {
        documentType: documentType,
      },

    })
  }
}
