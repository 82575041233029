<div class="page-layout blank" fxLayout="column">
    <div fxLayout="row">
      <div fxFlex="100">
        <sym-content-card>
          <div fxFlexAlign="end center">
            <div fxLayout="row" fxLayoutAlign="end center"  fxLayoutGap="15px">
              <mat-form-field appearance="outline" fxFlex="20" class="input-fields">
                <mat-label>Year</mat-label>
                <mat-select [(ngModel)]="selectedYear" placeholder="Property">
                 <mat-option *ngFor="let year of yearList" [value]="year">
                   {{year}}
                 </mat-option>
               </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAling="center center">
              <sym-primary-button [primaryBtn]="primaryBtn" style="padding-bottom: .5em;" [disabled]="!selectedProperty || !selectedYear"></sym-primary-button>
            </div>
          </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-divider style="width: 100%;"></mat-divider>
          </div>
          <div style="width: 100%;margin: 2em 0;" fxLayout="row" fxFlex="100" *ngIf="modelArray.length != 0">
            <span fxFlex="20"></span>
            <span fxFlex="20" style="font-size: 16px;font-weight: bold;">Start Date</span>
            <span fxFlex="20" style="font-size: 16px;font-weight: bold;">Target Date</span>
            <span fxFlex="20" style="font-size: 16px;font-weight: bold;">Deadline Date</span>
          </div>
          <div style="width: 100%; margin-bottom: 1em;" *ngFor="let dateModel of modelArray; let i = index" fxLayout="row" fxLayoutAlign="center center">
            <span fxFlex="15" style="font-size: 16px;margin-left: 1em;">{{monthNames[i]}}</span>
            <div fxLayout="row" style="width: 100%">
              <!-- <mat-icon style="margin-right: 1em;cursor: pointer;" (click)="setMultipleDates(i)">event</mat-icon> -->
              <div fxLayout="column" fxFlex="100">
                <mat-chip-list #chipList aria-label="Choose a date" fxFlex="100">
                  <mat-chip style="width: 25%;height: 34px;" fxLayout="row" fxLayoutAlign="space-between center"
                    (click)="setSingleDate(dateModel.startDate, i, 'startDate')"
                    [selectable]="false" [ngStyle]="checkChanges(i, 'startDate')"
                  >
                    {{ dateModel.startDate | date }}
                    <mat-icon>date_range</mat-icon>
                  </mat-chip>
                  <mat-chip fxLayoutAlign="space-between center" style="width: 25%;height: 34px;" [ngStyle]="checkChanges(i, 'targetDate')"
                    (click)="setSingleDate(dateModel.targetDate, i, 'targetDate')"
                    [selectable]="false"
                  >
                    {{ dateModel.targetDate | date }}
                    <mat-icon>date_range</mat-icon>
                  </mat-chip>
                  <mat-chip fxLayoutAlign="space-between center" style="width: 25%;height: 34px;" [ngStyle]="checkChanges(i, 'endDate')"
                    (click)="setSingleDate(dateModel.endDate, i, 'endDate')"
                    [selectable]="false"
                  >
                    {{ dateModel.endDate | date }}
                    <mat-icon>date_range</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <mat-form-field style="visibility: hidden;"><input
                  [value]="selectedDate"
                  matInput
                  [matDatepicker]="picker"
                  (dateChange)="dateChanged($event)"
                  [min]="minDate"
                  [max]="maxDate"
                  hidden
                  #dateInput
                /><mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker
                    touchUi
                    #picker
                    [startAt]="init"
                    [dateClass]="dateClass"
                    [calendarHeaderComponent]="exampleHeader"
                  ></mat-datepicker></mat-form-field>

                  <div *ngIf="modelArray.length != 0" fxLayout="row" fxLayoutAlign="center center">
                    <mat-divider style="width: 95%;margin-left: 2%;padding-bottom: 2em;"></mat-divider>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="end center" *ngIf="modelArray.length != 0">
                    <button  class="bottomButton" matTooltipPosition="above" style="margin-bottom: 1em;"
                    matTooltip="Cancle the Update for the target dates" type="submit" mat-raised-button style="color: #384952;" (click)="cancelUpdate()">
                      Cancel Update
                    </button>
                    <button  class="white-fg ml-24 bottomButton" matTooltipPosition="above" style="margin-bottom: 1em;"
                    matTooltip="Updates the target dates" type="submit" mat-raised-button style="background-color: #41535D;color: white;" (click)="updateTargetDates()">
                      Update Target Dates
                    </button>
                  </div>
        </sym-content-card>
      </div>
    </div>
    
</div>
