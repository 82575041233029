import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'sym-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() primaryBtn: any;
  @Input() menuBtn: any;
  @Input() breadcrumbText: string;
  @Input() routerNav: any;
  @Input() showSearch: any;
  @Input() showFilters: boolean = false;
  selectedProperty = new UntypedFormControl('');
  utilitySupplierList: any[] = [];
  @Input() requireProperties: boolean = false;
  @Input() propertySelector: any[] = [];
  periodReadings: any;
  @Output() propertySelect = new EventEmitter();
  @Output() searchFunction = new EventEmitter();
  filterValues = {
    readingCaptureType: '',
    flag: '',
    status: '',
    type: 'Filter'
  }
  readingTypes = [{
      name: 'None',
      val: ''
    },
    {
    name: 'Manual',
    val: 'MANUAL'
  },
  {
    name: 'Online',
    val: 'ONLINE'
  },
  ];
  statuses = [
    {
      name: 'None',
      val: ''
    },
    {
      name: 'Approved',
      val: 'APPROVED'
    },
    {
      name: 'Captured',
      val: 'CAPTURED'
    },
    {
      name: 'Decommissioned',
      val: 'DECOMMISSIONED'
    },
    {
      name: 'Faulty',
      val: 'FAULTY'
    },
    {
      name: 'Modified',
      val: 'MODIFIED'
    },
    {
      name: 'Not Captured',
      val: 'NOT_CAPTURED'
    }, 
    {
      name: 'Overwritten',
      val: 'OVERWRITTEN'
    },
    {
      name: 'Rejected',
      val: 'REJECTED'
    },
    {
      name: 'Submitted',
      val: 'SUBMITTED'
    }];
  flags = [
    {
      name: 'None',
      val: ''
    },
    {
      name: 'Faulty Meter',
      val: 'FAULTY METER'
    },
    {
      name: 'Negative Consumption',
      val: 'NEGATIVE CONSUMPTION'
    },
    {
      name: 'No Flag',
      val: '-'
    },
    {
      name: 'No SQM on Unit',
      val: 'NO SQM ON UNIT'
    },
    {
      name: 'Over Consumption',
      val: 'OVER CONSUMPTION'
    },
    {
      name: 'Under Consumption',
      val: 'UNDER CONSUMPTION'
    },
    {
      name: 'Zero Consumption',
      val: 'ZERO CONSUMPTION'
    }
  ]
  searchText;
  searchParam: any;
  filterText: string = 'tradingName: "", meterNo: "",'
  searched: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  search(event) {
    this.searchFunction.emit(event);
  }

  startFilter(filterType) {
    if(filterType === 'Reading' && this.filterValues.readingCaptureType !== '' && !this.filterText.includes('readingCaptureType')) {
      this.filterText += 'readingCaptureType: MANUAL,';
      this.searched = true;
    } else if(this.filterValues.readingCaptureType === '') {
      this.searched = false;
      this.filterText = this.filterText.replace('readingCaptureType: MANUAL,', '')
    }

    if(filterType === 'Status' && this.filterValues.status !== '' && !this.filterText.includes('status')) {
      this.filterText += 'status: "",';
      this.searched = true;
    } else if(this.filterValues.status === '') {
      this.searched = false;
      this.filterText = this.filterText.replace('status: "",', '')
    }

    if(filterType === 'Flag' && this.filterValues.flag !== '' && !this.filterText.includes('flag')) {
      this.filterText += 'flag: "",';
      this.searched = true;
    } else if(this.filterValues.flag === '') {
      this.searched = false;
      this.filterText = this.filterText.replace('flag: "",', '')
    }

    this.filterContent();
  }

  filterContent() {
    this.searchParam = {
      searched: this.searched,
      searchText: this.searchText,
      filterText: this.filterText,
      filterValues: this.filterValues,
      type: 'Filter'
    }
    this.search(this.searchParam);
  }

  checkIfEmpty(event) {
    this.search(event);
  }

  clearSearch() {
    this.searched = false; 
    this.searchText = '';
    this.filterContent();
  }

  propertyChange(property) {
    this.propertySelect.emit(property.value);
  }

}
