<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>Decomission Meter</h2>
  <button [mat-dialog-close]="'close'" style="margin-bottom: 20px;" mat-icon-button><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content class="mat-typography">
    <h3>Would you like to create a replacement meter?</h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="'yes'" cdkFocusInitial>Yes</button>
  <button mat-button [mat-dialog-close]="'no'">No</button>
</mat-dialog-actions>
