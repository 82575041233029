<sym-card-dialog [title]="title" [closeMethod]="closeMethod">
    <mat-dialog-content>
        <div>
            <div  fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
                <div fxLayout="column" *ngIf="createType === 'Property'">
                    <form [formGroup]="propertyDetails">
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Property Name</mat-label>
                                <input matInput formControlName="name" required>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Property Address</mat-label>
                                <input matInput formControlName="address" required>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Number of Areas</mat-label>
                                <input matInput formControlName="numberofAreas" required>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Number of Units</mat-label>
                                <input matInput formControlName="numberOfUnits" required>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Total SQM</mat-label>
                                <input matInput [(ngModel)]="totalSQM">
                            </mat-form-field>
                        </div>
                    </form>
                    <mat-divider style="margin: 34px 12px"></mat-divider>
                    <!-- <div fxLayout="row" fxLayoutAlign="end center">
                        <button mat-raised-button id="btnClass" (click)="createNode()" [disabled]="!propertyDetails.valid">
                            <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>Save
                        </button>
                    </div> -->
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <button mat-raised-button id="btnClass" (click)="createNode()">
                            <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>Save
                        </button>
                    </div>          
                </div>
        
                <div fxLayout="column" *ngIf="createType === 'Area'">
                    <form [formGroup]="areaDetails">
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Area Name</mat-label>
                                <input matInput formControlName="name" required>
                            </mat-form-field>
                        </div>
                    </form>
                    <mat-divider style="margin: 34px 12px"></mat-divider>
                    <!-- <div fxLayout="row" fxLayoutAlign="end center">
                        <button mat-raised-button id="btnClass" (click)="createNode()" [disabled]="!propertyDetails.valid">
                            <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>Save
                        </button>
                    </div> -->
                    <div  fxLayout="row" fxLayoutAlign="end center">
                        <button *ngIf="!isUpdate" mat-raised-button id="btnClass" (click)="createNode()">
                            <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>Save
                        </button>
                        <button *ngIf="isUpdate" mat-raised-button id="btnClass" (click)="updateNode()">
                            <mat-icon style="color: white;margin-right: 5px;">build</mat-icon>Update
                        </button>
                    </div>            
                </div>
        
                <div fxLayout="column" *ngIf="createType === 'Unit'">
                    <form [formGroup]="unitDetails">
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Unit Name</mat-label>
                                <input matInput formControlName="name" required>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Unit Type</mat-label>
                                <mat-select style="color: black;" formControlName="unitType" required>
                                    <mat-option *ngFor="let unit of unitType" style="color: black;" [value]="unit.value">
                                    {{unit.view}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="20px">
                            <mat-form-field appearance="outline" style="width: 100%;">
                                <mat-label>Unit Code</mat-label>
                                <input matInput formControlName="code" required>
                            </mat-form-field>
                        </div>
                        
                    </form>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Total SQM</mat-label>
                            <input matInput [(ngModel)]="totalSQM" required>
                        </mat-form-field>
                    </div>
                    <mat-divider style="margin: 34px 12px"></mat-divider>
                    <!-- <div fxLayout="row" fxLayoutAlign="end center">
                        <button mat-raised-button id="btnClass" (click)="createNode()" [disabled]="!propertyDetails.valid">
                            <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>Save
                        </button>
                    </div> -->
                    <div  fxLayout="row" fxLayoutAlign="end center">
                        <button *ngIf="!isUpdate" mat-raised-button id="btnClass" (click)="createNode()">
                            <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>Save
                        </button>
                        <button *ngIf="isUpdate" mat-raised-button id="btnClass" (click)="updateNode()">
                            <mat-icon style="color: white;margin-right: 5px;">build</mat-icon>Update
                        </button>
                    </div>           
                </div>
            </div>
        </div>
    </mat-dialog-content>
</sym-card-dialog>
