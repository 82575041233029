import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent } from './comment-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CommentDialogService {

  constructor(public dialog: MatDialog
  ) { }

  openCommentDialog(e, commentType, propertyName) {

    const dialogRef = this.dialog.open(CommentDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: { propertyList: e, type: commentType, property: propertyName }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
