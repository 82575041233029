import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {Auth} from 'aws-amplify';
import {JwtHelperService} from '@auth0/angular-jwt';
import { ShopsService } from 'src/app/services/shops.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  token: any;
  jwtHelper = new JwtHelperService();
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  authCount: string = '';

  constructor(public router: Router, private http: HttpClient, public shopService: ShopsService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.shopService.getAuth();
    if(auth && auth !== '') {
      request = request.clone({
        setHeaders: {
          Signature: auth,
          'Content-Type': 'application/octet-stream',
        }
      });
    } else if (this.checkRequestWhitelist(request.url)) {
      return next.handle(request);
    } else {
      if (JSON.parse(localStorage.getItem("qr")) === true || JSON.parse(localStorage.getItem("periodReading")) === true || JSON.parse(localStorage.getItem("chargeCalculation")) === true || JSON.parse(localStorage.getItem("qs")) === true || JSON.parse(localStorage.getItem("image")) === true) {
        request = request.clone({
          setHeaders: {}
        });
      } else if (JSON.parse(localStorage.getItem("callToken"))) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        });
      }
    }

    this.shopService.setAuth('');

    localStorage.removeItem("callToken");
    localStorage.removeItem("qr");
    localStorage.removeItem("image")
    localStorage.removeItem("qs");
    localStorage.removeItem("image");
    return next.handle(request);
  }
  checkRequestWhitelist(url) {
    if (
      url.includes('login') ||
      url.includes('password') ||
      url.includes('refreshtoken') ||
      url.includes('auth') ||
      url.includes('register')
    ) {
      return true;
    } else {
      return false;
    }
  }
  setUrlsForLater(req) {
    let requestedURLS = [];
    if (localStorage.getItem('requestedURLS')) {
      requestedURLS = JSON.parse(localStorage.getItem('requestedURLS'));
    }
    requestedURLS.push({
      url: req.url,
      method: req.method,
      body: req.body
    });
    this.saverequestedUrls(requestedURLS);
  }

  saverequestedUrls(urls) {
    localStorage.setItem('requestedURLS', JSON.stringify(urls));
  }

  reRunServices() {
    const requestedURLS = JSON.parse(localStorage.getItem('requestedURLS'));
    requestedURLS.forEach((url, i) => {
      if (url.method === 'GET') {
        this.http.get(url.url);
      }
      if (url.method === 'DELETE') {
        this.http.delete(url.url);
      }
      if (url.method === 'POST') {
        this.http.post(url.url, url.body);
      }
      if (url.method === 'PUT') {
        this.http.put(url.url, url.body);
      }
      requestedURLS.splice(i, 1);
      this.saverequestedUrls(requestedURLS);
    });
  }
}