import { Injectable } from '@angular/core';
import gql from 'graphql-tag'
import { API } from 'aws-amplify';
import { cpuUsage } from 'process';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopsService {

  auth: string;

  meterReadingEndPoint = environment.api.meterReading;
  meterReadingAPI = {
    "aws_appsync_graphqlEndpoint" : this.meterReadingEndPoint
  };
  meterManagementEndPoint = environment.api.meterManagement;
  meterManagementAPI = {
    "aws_appsync_graphqlEndpoint" : this.meterManagementEndPoint
  }

  constructor() { }

  setAuth(auth) {
    this.auth = auth;
  }

  getAuth() {
    return this.auth;
  }

  updateSerialNumber(propertyId, meter, oldMeterNumber, utilityType) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      mutation MyMutation($generalUtilityType: GeneralUtilityType = ELEC, $meterId: String = "", $newMeterNumber: String = "", $oldMeterNumber: String = "", $propertyId: String = "") {
        updateMeterNumber(input: {generalUtilityType: $generalUtilityType, meterId: $meterId, newMeterNumber: $newMeterNumber, oldMeterNumber: $oldMeterNumber, propertyId: $propertyId}) {
          errorMessage
          isSuccessful
        }
      }
      
      `,
      variables: {
        propertyId: propertyId,
        meterId: meter.id,
        generalUtilityType: utilityType,
        oldMeterNumber: oldMeterNumber,
        newMeterNumber: meter.serialNumber
      }
    })
  }

  checkIfSerialNumberExists(propertyId, meter, serialNumber) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      query MyQuery($meterNo: String = "", $propertyId: String = "", $utilityType: UtilityType = ELEC_1P) {
        checkIfMeterNumberExists(input: {meterNo: $meterNo, propertyId: $propertyId, utilityType: $utilityType}) {
          isSuccessful
          meterNoExists
          errorMessage
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        utilityType: meter.utilityType,
        meterNo: serialNumber
      }
    })
  }

  getMeterList(cid) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $limit1: Int = 400) {
        getRentableUnitListPerProperty(limit: $limit1, propertyId: $propertyId) {
          rentableUnitList {
            monthlyStatus {
              metersCaptured
              metersOutstanding
              readingPeriod
            }
            tenantOccupation {
              tenant {
                id
              }
            }
            unitId
          }
        }
      }
      
      
      `,
      variables: {
        propertyId: cid,
      },
    })
  }

  getPropertyMeterPeriodStatuses(propertyId, period, limit) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $readingPeriod: String = "", $limit: Int = 50) {
        getPropertyMeterPeriodStatusListV2(limit: $limit, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          errorMessage
          LastEvaluatedKey
          meterPeriodStatusList {
            flag
            meterId
            meterNo
            propertyId
            readingPeriod
            readingTS
            status
            totalCharge
            tradingName
            utilityType
            readingCaptureType
            errorMessageList
            readingList {
              sequence
              value
              imageKey
              name
              category
            }
          }
          captureCount
          outstandingCount
        }
        getPropertyMeterPeriodStatusListCount(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          errorMessage
          count
        }
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            targetDate
            startDate
            endDate
          }
          errorMessage
        }
      }      
                  
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: period,
        limit: limit
      }
    })
  }

  getCaptureCount(propertyId, readingPeriod) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $readingPeriod: String = "", $limit: Int = 1) {
        getPropertyMeterPeriodStatusListV2(limit: $limit, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          errorMessage
          captureCount
        }
      }            
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: readingPeriod,
        limit: 1
      }
    })
  }

  getMeterPeriodStatus(meterId, propertyId, readingPeriod) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        getUnitMeterQualityControlDetails(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          errorMessage
          isSuccessful
          qualityControlScreenDetails {
            meterPeriodStatus {
              errorMessageList
              flag
              meterId
              meterNo
              propertyId
              readingCaptureType
              readingList {
                imageKey
                sequence
                value
              }
              readingPeriod
              readingTS
              status
              totalCharge
              tradingName
              unitNo
              utilityType
            }
          }
        }
      }      
      `,
      variables: {
        propertyId: propertyId,
        meterId: meterId,
        readingPeriod: readingPeriod
      }
    })
  }

  searchPropertyMeterPeriodWithFilter(propertyId, period, limit, meter, filterText) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $readingPeriod: String = "", $limit: Int = 50, $filter: StatusFilterInput = {${filterText}}) {
        getPropertyMeterPeriodStatusListV2(limit: $limit, propertyId: $propertyId, readingPeriod: $readingPeriod, filter: $filter) {
          isSuccessful
          errorMessage
          meterPeriodStatusList {
            flag
            meterId
            meterNo
            propertyId
            readingPeriod
            readingTS
            status
            totalCharge
            tradingName
            utilityType
            readingCaptureType
            errorMessageList
           readingList {
              sequence
              value
              imageKey
              name
              category
            }
          }
          totalCount
          LastEvaluatedKey
          captureCount
          outstandingCount
        }
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            targetDate
            startDate
            endDate
          }
          errorMessage
        }
      }
      `,
      variables: {
        filter: meter,
        propertyId: propertyId,
        readingPeriod: period,
        limit: limit
      }
    })
  }

  filterPropertyMeterToken(propertyId, period, limit, meter, token, filter) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $readingPeriod: String = "", $limit: Int = 50, $filter: StatusFilterInput = {${filter}}, $exclusiveStartKey: String = "") {
        getPropertyMeterPeriodStatusListV2(limit: $limit, propertyId: $propertyId, readingPeriod: $readingPeriod, filter: $filter, exclusiveStartKey: $exclusiveStartKey) {
          isSuccessful
          errorMessage
          meterPeriodStatusList {
            flag
            meterId
            meterNo
            propertyId
            readingPeriod
            readingTS
            status
            totalCharge
            tradingName
            utilityType
            readingCaptureType
            errorMessageList
           readingList {
              sequence
              value
              imageKey
              name
              category
            }
          }
          totalCount
          LastEvaluatedKey
          captureCount
          outstandingCount
        }
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            targetDate
            startDate
            endDate
          }
          errorMessage
        }
      }         
      `,
      variables: {
        filter: meter,
        propertyId: propertyId,
        readingPeriod: period,
        limit: limit,
        exclusiveStartKey: token
      }
    })
  }

  getPropertyMeterPeriodStatusesWithToken(propertyId, period, token, limit) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $readingPeriod: String = "", $limit: Int = 50, $exclusiveStartKey: String = "") {
        getPropertyMeterPeriodStatusListV2(limit: $limit, propertyId: $propertyId, readingPeriod: $readingPeriod, exclusiveStartKey: $exclusiveStartKey) {
          isSuccessful
          errorMessage
          LastEvaluatedKey
          meterPeriodStatusList {
            flag
            meterId
            meterNo
            propertyId
            readingPeriod
            readingTS
            status
            totalCharge
            tradingName
            utilityType
            readingCaptureType
            errorMessageList
           readingList {
              sequence
              value
              imageKey
              name
              category
            }
          }
          captureCount
          outstandingCount
        }
        getPropertyMeterPeriodStatusListCount(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          errorMessage
          count
        }
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            targetDate
            startDate
            endDate
          }
          errorMessage
        }
      }
           
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: period,
        exclusiveStartKey: token,
        limit: limit
      }
    })
  }

  getReadingsForMeter(meterId, period, propertyId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        getUnitMeterQualityControlDetails(propertyId: $propertyId, readingPeriod: $readingPeriod, meterId: $meterId) {
          isSuccessful
          errorMessage
          qualityControlScreenDetails {
            previousPeriodReadingList {
              approvalStatus
              flagReason
              meterId
              meterNo
              readingPeriod
              readingType
              tenantCode
              tradingName
              type
              utilityType
              reading {
                readingValueList {
                  sequence
                  value
                }
                readingBy
                readingType
                readingTS
              }
              prevReading {
                readingValueList {
                  sequence
                  value
                }
              }
              unitCalculatedChargeList {
                calculatedChargeList {
                  utilization
                  rate
                  chargeType
                  utilitySupplierCharge
                  readingPeriod
                  utilitySupplierId
                  tariffId
                  id
                  unitId
                }
                totalCharge
              }
              utilizationList {
                value
                sequence
              }
              unitId
            }
            faultyReading {
              approvalStatus
              meterId
              meterNo
              notificationImagePresignedUrl
              propertyCode
              propertyId
              readingPeriod
              resolved
              type
              utilityType
              meterNotification {
                message
                meterId
                meterNo
                notificationDate
                notificationId
                notificationType
                sentBy
              }
            }
            meterPeriodStatus {
              flag
              meterId
              meterNo
              propertyId
              readingPeriod
              readingTS
              status
              totalCharge
              tradingName
              unitNo
              utilityType
              readingCaptureType
              errorMessageList
            }
            meterPeriodStatusReadingList {
              unitCalculatedChargeList {
                calculatedChargeList {
                  utilization
                  rate
                  chargeType
                  utilitySupplierCharge
                  readingPeriod
                  utilitySupplierId
                  tariffId
                  id
                }
                totalCharge
              }
              flagReason
              meterId
              meterNo
              prevReading {
                createdBy
                readingValueList {
                  imageKey
                  sequence
                  value
                  name
                  category
                }
                status
                statusReason
                readingType
                readingTS
                readingBy
              }
              approvalStatus
              reading {
                status
                statusReason
                readingValueList {
                  imageKey
                  sequence
                  value
                  name
                  category
                }
                readingTS
                createdBy
                readingBy
                readingType
              }
              tradingName
              unitId
              utilizationList {
                sequence
                value
              }
            }
          }
        }
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            targetDate
            startDate
            endDate
          }
          errorMessage
        }
      }                              
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period
      }
    })
  }

  getOnlyCurrentReadingsForMeter(meterId, period, propertyId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        getUnitMeterQualityControlDetails(propertyId: $propertyId, readingPeriod: $readingPeriod, meterId: $meterId) {
          isSuccessful
          errorMessage
          qualityControlScreenDetails {
            meterPeriodStatusReadingList {
              unitCalculatedChargeList {
                calculatedChargeList {
                  utilization
                  rate
                  chargeType
                  utilitySupplierCharge
                  readingPeriod
                  tariffId
                  id
                  utilitySupplierId
                }
                totalCharge
              }
              flagReason
              meterId
              meterNo
              prevReading {
                createdBy
                readingValueList {
                  imageKey
                  sequence
                  value
                  name
                  category
                }
                status
                statusReason
                readingType
                readingTS
                readingBy
              }
              approvalStatus
              reading {
                status
                statusReason
                readingValueList {
                  imageKey
                  sequence
                  value
                  name
                  category
                }
                readingTS
                createdBy
                readingBy
                readingType
              }
              tradingName
              unitId
              utilizationList {
                sequence
                value
              }
            }
            meterPeriodStatus {
              totalCharge
              errorMessageList
              flag
            }
          }
        }
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            targetDate
            startDate
            endDate
          }
          errorMessage
        }
      }                                                
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period
      }
    })
  }

  getReadingTypeForModifyReading(propertyId, readingPeriod, meterId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query getReadingTypeForModifyReading($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        getUnitMeterQualityControlDetails(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          errorMessage
          qualityControlScreenDetails {
            meterPeriodStatusReadingList {
              readingType
            }
          }
          isSuccessful
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        meterId: meterId,
        readingPeriod: readingPeriod
      }
    })
  }

  getOnlyCurrentReadingsAndStatusForMeter(meterId, period, propertyId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        getUnitMeterQualityControlDetails(propertyId: $propertyId, readingPeriod: $readingPeriod, meterId: $meterId) {
          isSuccessful
          errorMessage
          qualityControlScreenDetails {
            meterPeriodStatus {
              flag
              meterId
              meterNo
              propertyId
              readingPeriod
              readingTS
              status
              totalCharge
              tradingName
              unitNo
              utilityType
              readingCaptureType
              errorMessageList
            }
            meterPeriodStatusReadingList {
              unitCalculatedChargeList {
                calculatedChargeList {
                  utilization
                  rate
                  chargeType
                  utilitySupplierCharge
                  readingPeriod
                  utilitySupplierId
                  tariffId
                  id
                }
                totalCharge
              }
              flagReason
              meterId
              meterNo
              prevReading {
                createdBy
                readingValueList {
                  imageKey
                  sequence
                  value
                  name
                  category
                }
                status
                statusReason
                readingType
                readingTS
                readingBy
              }
              approvalStatus
              reading {
                status
                statusReason
                readingValueList {
                  imageKey
                  sequence
                  value
                  name
                  category
                }
                readingTS
                createdBy
                readingBy
                readingType
              }
              tradingName
              unitId
              utilizationList {
                sequence
                value
              }
            }
          }
        }
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            targetDate
            startDate
            endDate
          }
          errorMessage
        }
      }     
                              
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period
      }
    })
  }

  approveMeterPeriod(meterId, propertyId, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        approvePeriodReadingsPerMeter(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period
      }
    })
  }

  rejectMeterPeriod(meterId, propertyId, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        rejectPeriodReadingsPerMeter(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period
      }
    })
  }

  getAllShopsByPropertyId(cid) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $limit: Int = 400) {
        getRentableUnitListPerProperty(limit: $limit, propertyId: $propertyId) {
          rentableUnitList {
            isVacant
            propertyId
            unitNo
            comments {
              comment
              commentDate
            }
            tenantOccupation {
              rentableUnit {
                accomodationType
                isVacant
                unitId
                unitName
                unitNo
                unitCode
                tenantId
                propertyId
              }
              leaseEnd
              leaseStart
              propertyId
              tenant {
                code
                contactDetails {
                  contactEmails
                  contactName
                  contactTelephone
                }
                tradingName
                propertyId
                id
              }
              isExpired
              leaseType
            }
            monthlyStatus {
              metersCaptured
              metersOutstanding
              readingPeriod
            }
            unitId
            unitCode
          }
        }
      }
      `,
      variables: {
        propertyId: cid,
      },
    })
  }

  getTargetDatesForProperty(pId, year) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $year: String = "") {
        getAllTargetDatesByPropertyAndYear(propertyId: $propertyId, year: $year) {
          isSuccessful
          targetDateList {
            endDate
            targetDate
            startDate
          }
          errorMessage
        }
      }
      `,
      variables: {
        propertyId: pId.id,
        year: year
      }
    })
  }

  updateTargetDatesForProperty(targetDates) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($targetDates: [CreateTargetDateInput!] = {endDate: "1930-01-01T16:00:00-07:00", propertyId: "", readingPeriod: "", startDate: "1930-01-01T16:00:00-07:00", targetDate: "1930-01-01T16:00:00-07:00"}) {
        updateTargetDateList(targetDates: $targetDates) {
          isSuccessful
          errorMessage
        }
      }
      `,
      variables: {
        targetDates: targetDates
      }
    })
  }

  updateTenantOccupation(tenantId, unitId, tenantOccupation) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($leaseStart: AWSDateTime!, $leaseType: LeaseType = FIXED, $tenantId: String = "", $unitId: String = "", $leaseEnd: AWSDateTime!) {
        updateTenantOccupation(leaseEnd: $leaseEnd, leaseStart: $leaseStart, leaseType: $leaseType, tenantId: $tenantId, unitId: $unitId) {
          isSuccessful
        }
      }
      `,
      variables: {
        unitId: unitId,
        tenantId: tenantId,
        leaseType: tenantOccupation.leaseType,
        leaseStart: tenantOccupation.leaseStart,
        leaseEnd: tenantOccupation.leaseEnd
      },
    })
  }
  

  getAllVacantShopsByPropertyId(propertyId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "") {
        getVacantRentableUnitListPerProperty(limit: 50, propertyId: $propertyId) {
          rentableUnitList {
            isVacant
            propertyId
            unitNo
            tenant {
              leaseEnd
              leaseStart
              tenantId
              tradingName
              createDate
              contactDetails {
                contactName
                contactTelephone
              }
              unitId
            }
            monthlyStatus {
              metersCaptured
              metersOutstanding
              readingPeriod
            }
            unitId
          }
        }
      }
      `,
      variables: {
        propertyId: propertyId,
      },
    })
  }

  getTargetDate(propertyId, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $readingPeriod: String = "") {
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            endDate
            startDate
            targetDate
          }
          errorMessage
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: period
      }
    })
  }

  //TODO: The call made on init for tenant details
  getTenantByShopId(unitId, tenantId, propertyId, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($unitId: String = "", $propertyId: String = "", $limit: Int = 100, $tenantId: String = "", $readingPeriod: String = "") {
        getTargetDate(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          targetDate {
            targetDate
            startDate
            endDate
          }
          errorMessage
        }
        getRentableUnit(propertyId: $propertyId, unitId: $unitId) {
          rentableUnit {
            unitNo
            accomodationType
            isVacant
            unitCode
            propertyId
            unitName
            unitNo
            unitId
            tenantId
            tenantOccupation {
              isExpired
              leaseEnd
              leaseStart
              leaseType
              tenant {
                code
                contactDetails {
                  contactEmails
                  contactName
                  contactTelephone
                }
                tradingName
                id
              }
            }
          }
        }
        getUnitMeterListPerUnit(propertyId: $propertyId, unitId: $unitId, limit: $limit) {
          meterList {
            meter {
              amps
              commissionDate
              commissionReading
              ctMultiplier
              id
              indexes
              isCommonArea
              isManual
              name
              phases
              meterNo
              tariff
              status
              statusUpdateDate
              tariffId
              utilityType
              noOfPhotos
              commissionReading2
              commissionReading3
              commissionReading4
              voltageClassId
              meterType
              meterReadingType
              captureType
            }
          }
        }
        getCurrentTenantOccupation(tenantId: $tenantId, propertyId: $propertyId, unitId: $unitId) {
          isSuccessful
          tenantOccupation {
            leaseEnd
            leaseStart
            tenant {
              code
              contactDetails {
                contactEmails
                contactName
                contactTelephone
              }
              id
              tradingName
            }
            isExpired
            leaseType
          }
        }
      }      
                         
      `,
      variables: {
        unitId: unitId,
        propertyId: propertyId,
        tenantId: tenantId,
        readingPeriod: period,
        limit: 100
      },
    })
  }

  addTenant(tenant) {
    API.configure(this.meterReadingAPI);
      return API.graphql({
        query: gql`
        mutation MyMutation($code: String = "", $contactTelephone: String = "", $contactName: String = "", $contactEmails: [String] = "", $tradingName: String = "") {
          createTenant(tenant: {contactDetails: {contactTelephone: $contactTelephone, contactName: $contactName, contactEmails: $contactEmails}, code: $code, tradingName: $tradingName}) {
            errorMessage
            isSuccessful
            tenantId
          }
        }
               
        `,
        variables: {
          tradingName: tenant.tradingName,
          contactEmails: tenant.contactEmails,
          code: tenant.code,
          contactName: tenant.contactName,
          contactTelephone: tenant.contactTelephone
        },
      })
  }

  createTenantOccupation(tenant, propertyId, tenantId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($leaseEnd: AWSDateTime!, $leaseStart: AWSDateTime!, $propertyId: String = "", $tenantId: String = "", $unitId: String = "", $leaseType: LeaseType = FIXED) {
        addTenantOccupation(leaseEnd: $leaseEnd, leaseStart: $leaseStart, propertyId: $propertyId, tenantId: $tenantId, unitId: $unitId, leaseType: $leaseType) {
          isSuccessful
        }
      }      
      `,
      variables: {
        leaseEnd: tenant.leaseEnd,
        leaseStart: tenant.leaseStart,
        propertyId: propertyId,
        unitId: tenant.unitId,
        tenantId: tenantId,
        leaseType: tenant.leaseType
      }
    })
  }

  updateRentableUnit(unit, tenantId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($accomodationType: String = "", $agentCode: String = "", $isVacant: Boolean = false, $propertyId: String = "", $tenantId: String = "", $unitCode: String = "", $unitName: String = "", $unitNo: String = "", $unitId: String = "") {
        updateRentableUnit(rentableUnit: {accomodationType: $accomodationType, agentCode: $agentCode, isVacant: $isVacant, propertyId: $propertyId, tenantId: $tenantId, unitCode: $unitCode, unitName: $unitName, unitNo: $unitNo}, unitId: $unitId) {
          isSuccessful
        }
      }
         
      `,
      variables: {
        accomodationType: unit.accomodationType,
        isVacant: unit.isVacant,
        propertyId: unit.propertyId,
        tenantId: tenantId,
        unitCode: unit.unitCode,
        unitName: unit.unitName,
        unitNo: unit.unitNo,
        unitId: unit.unitId
      },
    })
  }

  deleteShopById(propertyId, unitId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($propertyId: String = "", $unitId: String = "") {
        deleteRentableUnit(propertyId: $propertyId, unitId: $unitId)
      }
      `,
      variables: {
        propertyId: propertyId,
        unitId: unitId,
      },
    })
  }

  addNewMeter(meter, propertyId, unitId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($propertyId: String = "", $unitId: String = "", $name: String = "", $utilityType: UtilityType = ELEC_1P, $isManual: Boolean = false, $meterNo: String = "", $commissionDate: AWSDateTime, $commissionReading: String = "", $noOfPhotos: Int = 10, $commissionReading2: String = "", $commissionReading3: String = "", $commissionReading4: String = "", $tariffId: String = "", $tariff1: String = "", $voltageClassId: String = "", $amps: String = "", $captureType: CaptureType = ONLINE, $meterReadingType: MeterReadingType = READING) {
        createUnitMeter(meter: {name: $name, utilityType: $utilityType, isManual: $isManual, meterNo: $meterNo, commissionDate: $commissionDate, commissionReading: $commissionReading, noOfPhotos: $noOfPhotos, commissionReading2: $commissionReading2, commissionReading3: $commissionReading3, commissionReading4: $commissionReading4, tariff: $tariff1, tariffId: $tariffId, voltageClassId: $voltageClassId, amps: $amps, captureType: $captureType, meterReadingType: $meterReadingType}, propertyId: $propertyId, unitId: $unitId) {
          isSuccessful
        }
      }
                  
      `,
      variables: {
        name: meter.name,
        utilityType: meter.utilityType.name,
        propertyId: propertyId,
        unitId: unitId,
        isManual: meter.isManual,
        meterNo: meter.serialNumber,
        commissionDate: meter.commissionDate,
        commissionReading: meter.commissionReading,
        commissionReading2: meter.commissionReading2,
        commissionReading3: meter.commissionReading3,
        commissionReading4: meter.commissionReading4,
        noOfPhotos: meter.utilityType.image,
        tariff1: meter.tariff,
        tariffId: meter.tariffId,
        voltageClassId: meter.voltageId,
        amps: meter.amps,
        captureType: meter.captureType,
        meterReadingType: meter.meterReadingType
      },
    })
  }

  getMeterReadingListByUnitId(propertyId,unitId){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $unitId: String = "", $limit: Int = 500) {
        getMeterReadingsPerUnit(propertyId: $propertyId, unitId: $unitId, limit: $limit) {
          unitMeterList {
            readingList {
              readingBy
              readingTS
              meter {
                utilityType
                id
                name
                comments {
                  comment
                }
                isCommonArea
                isManual
                meterNo
                commissionDate
                commissionReading
                status
                noOfPhotos
              }
              readingValueList {
                sequence
                value
              }
              comments {
                comment
              }
              comment
              utilityType
            }
          }
        }
      }        
      `,
      variables: {
        unitId: unitId,
        propertyId: propertyId,
        limit: 500
      },
    })
  }

  updateTenant(tenant) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($tenantId: String = "", $tradingName: String = "", $contactTelephone: String = "", $contactName: String = "", $contactEmails: [String] = "", $code: String = "") {
        updateTenant(tenant: {contactDetails: {contactTelephone: $contactTelephone, contactName: $contactName, contactEmails: $contactEmails}, tradingName: $tradingName, code: $code}, tenantId: $tenantId) {
          isSuccessful
        }
      }
                       
      `,
      variables: {
        tenantId : tenant.tenantId,
        code: tenant.code,
        contactName: tenant.contactName,
        contactTelephone: tenant.contactTelephone,
        contactEmails: tenant.contactEmails,
        tradingName: tenant.tradingName
      },
    })
  }

  captureNewReading(meter, isDecommissioned?) {
    
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($comment: String = "", $meterId: String = "", $propertyId: String = "", $readingBy: String = "", $readingTS: AWSDateTime!, $readingValueList: [ReadingValueInput] = {sequence: "", value: ""}, $readingType: ReadingType = PERIOD_READING, $isDecommissionMeterReading: Boolean = false) {
        captureMeterReadingSync(reading: {comment: $comment, meterId: $meterId, propertyId: $propertyId, readingBy: $readingBy, readingTS: $readingTS, readingValueList: $readingValueList, readingType: $readingType, isDecommissionMeterReading: $isDecommissionMeterReading}) {
          isSuccessful
          flag
          errorMessage
        }
      } 
      `,
      variables: {
        // applicationType: 'ADMIN',
        isDecommissionMeterReading: isDecommissioned ? isDecommissioned : false,
        meterId: meter.meterId,  
        propertyId: meter.propertyId,
        readingBy : meter.readingBy,
        readingTS : meter.readingTS,
        readingValueList: meter.readingValueList,
        // unitId: meter.unitId,
        comment: meter.comment,
        readingType: meter.readingType
      }
    })
  }

  updateMeter(meter, propertyId, unitId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterId: String = "", $propertyId: String = "", $unitId: String = "", $utilityType: UtilityType = ELEC_1P, $isManual: Boolean = false, $name: String = "", $meterNo: String = "", $commissionReading: String = "", $commissionDate: AWSDateTime!, $noOfPhotos: Int = 10, $commissionReading2: String = "", $commissionReading3: String = "", $commissionReading4: String = "", $tariff: String = "", $tariffId: String = "", $voltageClassId: String = "", $amps: String = "", $captureType: CaptureType = ONLINE, $meterReadingType: MeterReadingType = READING) {
        updateMeter(meter: {utilityType: $utilityType, isManual: $isManual, name: $name, meterNo: $meterNo, commissionReading: $commissionReading, commissionDate: $commissionDate, noOfPhotos: $noOfPhotos, commissionReading2: $commissionReading2, commissionReading3: $commissionReading3, commissionReading4: $commissionReading4, tariffId: $tariffId, tariff: $tariff, voltageClassId: $voltageClassId, amps: $amps, captureType: $captureType, meterReadingType: $meterReadingType}, meterId: $meterId, propertyId: $propertyId, unitId: $unitId) {
          isSuccessful
        }
      }
                      
      `,
      variables: {
        meterId : meter.id,
        propertyId : propertyId, 
        unitId : unitId, 
        name : meter.name,
        utilityType : meter.utilityType.name,
        commissionDate : meter.commissionDate,
        commissionReading : meter.commissionReading,
        commissionReading2 : meter.commissionReading2,
        commissionReading3 : meter.commissionReading3,
        commissionReading4 : meter.commissionReading4,
        isManual : meter.isManual,
        meterNo : meter.serialNumber,
        noOfPhotos : meter.utilityType.image,
        tariff: meter.tariff,
        tariffId: meter.tariffId,
        voltageClassId: meter.voltageId,
        amps: meter.amps,
        meterReadingType: meter.meterReadingType,
        captureType: meter.captureType
      }
    })
  }

  deleteMeter(propertyId, meterId, meterNo) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterId: String = "", $propertyId: String = "") {
        deleteMeter(meterId: $meterId, propertyId: $propertyId) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId : meterId,
        propertyId : propertyId, 
      }
    })
  }

  decomissionMeterOnly(meter,meterId,unitId,propertyId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($decommissionReading: String = "", $decommissionReason: String = "", $meterId: String = "", $propertyId: String = "") {
        decommissionMeter(decommissionReading: $decommissionReading, decommissionReason: $decommissionReason, meterId: $meterId, propertyId: $propertyId) {
          isSuccessful
          replacementMeterId
        }
      }             
      `,
      variables: {
        decommissionReading: meter.decommissionReading,
        decommissionReason: meter.decommissionReason,
        meterId: meterId,
        propertyId: propertyId,
        unitId: unitId,
      }
    })
  }

  decommissionMeter(meter,meterId,unitId,propertyId){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($decommissionReading: String = "", $decommissionReason: String = "", $meterId: String = "", $propertyId: String = "", $utilityType: UtilityType = ELEC_1P, $noOfPhotos: Int = 10, $name: String = "", $meterNo: String = "", $isManual: Boolean = false, $commissionReading: String = "", $commissionDate: AWSDateTime, $commissionReading2: String = "", $commissionReading3: String = "", $commissionReading4: String = "", $tariffId: String = "", $voltageClassId: String = "", $tariff: String = "", $amps: String = "", $captureType: CaptureType = ONLINE, $meterReadingType: MeterReadingType = READING) {
        decommissionMeter(decommissionReading: $decommissionReading, decommissionReason: $decommissionReason, meterId: $meterId, propertyId: $propertyId, replacementMeter: {utilityType: $utilityType, noOfPhotos: $noOfPhotos, name: $name, meterNo: $meterNo, isManual: $isManual, commissionReading: $commissionReading, commissionDate: $commissionDate, commissionReading2: $commissionReading2, commissionReading3: $commissionReading3, commissionReading4: $commissionReading4, tariffId: $tariffId, voltageClassId: $voltageClassId, tariff: $tariff, amps: $amps, captureType: $captureType, meterReadingType: $meterReadingType}) {
          isSuccessful
          replacementMeterId
        }
      }           
              
      `,
      variables: {
        decommissionReading: meter.decommissionReading,
        decommissionReason: meter.decommissionReason,
        meterId: meterId,
        propertyId: propertyId,
        unitId: unitId,
        name: meter.name,
        utilityType: meter.utilityType.name,
        meterNo: meter.serialNumber,
        isManual: meter.isManual,
        commissionDate: meter.commissionDate,
        commissionReading: meter.commissionReading,
        commissionReading2: meter.commissionReading2,
        commissionReading3: meter.commissionReading4,
        commissionReading4: meter.commissionReading4,
        noOfPhotos: meter.utilityType.image,
        tariffId: meter.tariff.id,
        tariff: meter.tariff.name,
        voltageClassId: meter.voltageId,
        amps: meter.amps,
        captureType: meter.captureType,
        meterReadingType: meter.meterReadingType
      }
    })  
  }

  updatePeriodReading(reading,reason,period, supplierId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($utilitySupplierId: String = "", $meterId: String = "", $propertyId: String = "", $readingPeriod: String = "", $meterId1: String = "", $readingTS: AWSDateTime!, $propertyId1: String = "", $comment: String = "", $readingBy: String = "", $approvalStatus: ApprovalStatus = NEW, $readingType: ReadingType = PERIOD_READING, $readingValueList: [ReadingValueInput] = {sequence: "", value: "", category: "", imageKey: "", name: ""}) {
        updatePeriodReading(utilitySupplierId: $utilitySupplierId, meterId: $meterId, propertyId: $propertyId, reading: {meterId: $meterId1, readingTS: $readingTS, propertyId: $propertyId1, comment: $comment, readingBy: $readingBy, readingType: $readingType, readingValueList: $readingValueList}, readingPeriod: $readingPeriod, approvalStatus: $approvalStatus) {
          isSuccessful
        }
      }                      
      `,
      variables: {    
        approvalStatus: reading.approvalStatus,
        meterId: reading.meterId,
        meterId1: reading.meterId,
        propertyId: reading.reading.propertyId,
        propertyId1: reading.reading.propertyId,
        readingBy: reading.reading.readingBy,
        readingTS: reading.reading.readingTS,
        readingValueList: reading.reading.readingValueList,
        comment: reason,
        unitId: reading.unitId,
        readingPeriod: period,
        utilitySupplierId: supplierId,
        readingType: reading.reading.readingType
      }
    })   
  }

  addRentableUnitComment(comment) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($message: String = "", $propertyId: String = "", $unitId: String = "") {
        addUnitNotification(message: $message, propertyId: $propertyId, unitId: $unitId) {
          isSuccessful
        }
      }      
      `,
      variables: {    
        unitId: comment.unitId,
        message: comment.comment,
        propertyId: comment.propertyId
      }
    })   
  }

  addPeriodReadingComment(comment) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($message: String = "", $meterId: String = "", $propertyId: String = "", $readingPeriod: String = "", $unitId: String = "") {
        addPeriodReadingNotification(message: $message, meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod, unitId: $unitId) {
          isSuccessful
          notificationId
        }
      }          
      `,
      variables: {    
        message: comment.comment,
        meterId: comment.meterId,
        propertyId: comment.propertyId,
        readingPeriod: comment.readingPeriod,
        unitId: comment.unitId,
      }
    })  
  }

  addPeriodReadingCommentWithoutUnitId(comment) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation addPeriodReadingCommentWithoutUnitId($message: String = "", $meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        addPeriodReadingNotification(message: $message, meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          notificationId
        }
      }          
      `,
      variables: {    
        message: comment.comment,
        meterId: comment.meterId,
        propertyId: comment.propertyId,
        readingPeriod: comment.readingPeriod,
      }
    })  
  }

  getPresignedURL(capture, sequence, readingPeriod) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($filename: String = "", $meterId: String = "", $propertyId: String = "", $readingBy: String = "", $readingTS: AWSDateTime!, $agentCode: String = "", $sequence: String = "", $readingPeriod: String = "") {
        captureImageReading(reading: {filename: $filename, meterId: $meterId, propertyId: $propertyId, readingBy: $readingBy, readingTS: $readingTS, agentCode: $agentCode, sequence: $sequence, readingPeriod: $readingPeriod}) {
          presignedURL
          isSuccessful
        }
      }      
      `,
      variables: {
        filename: capture.fileName,
        meterId: capture.meterId,  
        propertyId: capture.propertyId,
        readingBy : capture.readingBy,
        readingTS : capture.readingTS,
        unitId: capture.unitId,
        agentCode: '0000',
        sequence: sequence,
        readingPeriod: readingPeriod
      }
    })
  }

  getQRPresignedURL(meter){
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($meterId: String = "", $propertyId: String = "") {
        getMeterQrCodePresignedUrl(meterId: $meterId, propertyId: $propertyId) {
          presignedUrl
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meter.meterId,  
        propertyId: meter.propertyId,
      }
    })  
  }

  getReadingImageFromKey(key) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      query MyQuery($key: String = "") {
        getReadingImageFromKey(key: $key) {
          errorMessage
          isSuccessful
          presignedUrl
          status
        }
      }
      `,
      variables: {
        key: key
      }
    })
  }

  getReadingImagePresinged(meter) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($meterId: String = "", $propertyId: String = "", $readingTS: AWSDateTime!, $sequence: String = "") {
        getReadingImage(meterId: $meterId, propertyId: $propertyId, readingTS: $readingTS, sequence: $sequence) {
          presignedUrl
        }
      }
      `,
      variables: {
        meterId: meter.meterId,  
        propertyId: meter.propertyId,
        readingTS: meter.readingTS,
        sequence: meter.sequence
      }
    })
  }

  getCommentImage(id) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($commentId: String = "") {
        getCommentImage(commentId: $commentId) {
          presignedUrl
          isSuccessful
        }
      }
      
      `,
      variables: {
        commentId: id
      }
    })
  }

  getNotificationImage(id){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($notificationId: String = "") {
        getNotificationImage(notificationId: $notificationId) {
          presignedUrl
        }
      }
      `,
      variables: {
        notificationId: id
      }
    })
  }
}
