import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { ClientDocumentUploaderComponent } from 'src/app/shared/components/clientDocumentUploader/clientDocument-uploader.component';
import { ConfirmDialogService } from 'src/app/shared/components/confirm-dialog/confirm-dialog.service';
import { DeleteDialogService } from 'src/app/shared/components/delete-dialog/delete-dialog.service';
import { DisplayImageDialogService } from 'src/app/shared/components/display-image-dialog/display-image-dialog.service';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { ReadingDownloadDialogService } from 'src/app/components/reading-download-dialog/reading-download-dialog.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { ShopsService } from 'src/app/services/shops.service';
import { CaptureReadingComponent } from 'src/app/shared/components/capture-reading/capture-reading.component';
import { UpdateReadingsComponent } from '../../shared/components/update-readings/update-readings.component';
import { SendNotificationDialogComponent } from 'src/app/shared/components/send-notification-dialog/send-notification-dialog.component';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { FileLookupService } from 'src/app/core/services/file-lookup.service';
import { DomSanitizer } from '@angular/platform-browser';

export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
@Component({
  selector: 'app-period-readings',
  templateUrl: './period-readings.component.html',
  styleUrls: ['./period-readings.component.scss']
})
export class PeriodReadingsComponent implements OnInit {

  @ViewChild("Captures", { static: true }) capturesPaginator: MatPaginator;
  @ViewChild("Sort", { static: true }) sort: MatSort;
  recentCaptures = new MatTableDataSource();
  tenantList: any;
  vacantList: any;
  unitList: any;
  cid: any;
  firstRefresh = true;
  tableLength = 0;
  showButtonExport: any;
  downloadOption: any;
  recentCaptureList: any;
  periodURL: any;
  selectedMonth: any;
  selectedYear: any;
  readingPeriod: any;
  canApproveAll: any = true;
  currentMonth: any;
  thumbnails: any[] = [];
  propertyName: any;
  selectedReading: any;
  meterReading: any;
  userRole: any;
  readingListLength = 0;
  capturedReadings = 0;
  outstandingReadings = 0;
  showUpload = false;
  searchText: any;
  loadRentableUnits = false;
  loadVacantUnits = false;
  showAddTenant = false;
  showExport = false;
  displayApproveAll = false;
  pictureLoaded = false;
  showRejectPeriodReading = false;
  superUser = false;
  selectedProperty;
  urlList = [];
  unitId: string = '';
  searchedTenants: boolean = false;
  tabIndex = 0;
  fromTenantView = 0;
  supplierId;
  supplierList: any [] = [];
  searchForm: UntypedFormGroup;
  placeHolder: any;
  addRentableUnitChange: boolean = true;
  unitCodeList: any[] = [];
  currentDate: Date = new Date();
  pageSize: number = 100;
  nextToken: any = '';
  previousToken: any[] = [];
  meterPeriods: any[] = [];
  searched: boolean = false;
  meterList: any[] = [];
  gotOtherCaptures: boolean = false;
  fiveDaysStart;
  endDate;
  outsideTDate: boolean = false;
  retryCallCount: number = 0;
  isCalculating: boolean = false;
  filterText: string = '';
  /*TODO: Look at ANY fix for filterValues*/
  filterValues: any;
  meterPeriodStatus: any = {
    meterId: ''
  };
  imgHasError: boolean = false;
  oldTotalCharge: number = 0;
  finalCheck: boolean = false;
  blobThumbNail: string[] = [];
  count = 0;

  targetDateList: any = {}

  primaryBtn: any = {
    text: 'Approve All Readings',
    method: () => {
      this.approveAllPeriodReadings()
    }
  }

  primaryBtnProperty: any = {
    text: 'Submit All Readings',
    method: () => {
      this.approveAllPeriodReadings()
    }
  }

  menuBtnShow: boolean = false;

  menuBtn: any = {
    text: 'More Actions',
    methods: [
      {
        text: 'Approve All Readings',
        method: () => {
          this.approveAllPeriodReadings()
        },
        icon: 'check',
        show: true   
      },
      {
        text: 'Refresh Current Period',
        method: () => {
          this.getRecentCaptures(this.pageSize)
        },
        icon: 'refresh',
        show: false   
      },
      {
        text: 'Download Period Readings',
        method: () => {
          this.openReadingDownloadDialog()
        },
        icon: 'cloud_download',
        show: false   
      },
      
    ]
  }

  menuManagerBtn: any = {
    text: 'More Actions',
    methods: [
      // {
      //   text: 'Submit All Readings',
      //   method: () => {
      //     this.approveAllPeriodReadings()
      //   },
      //   icon: 'check',
      //   show: true   
      // },
      {
        text: 'Refresh Current Period',
        method: () => {
          this.getRecentCaptures(this.pageSize)
        },
        icon: 'refresh',
        show: true   
      }      
    ]
  }

  meterFlags = ['FAULTY METER', 'NEGATIVE CONSUMPTION', 'ZERO CONSUMPTION', 'OVER CONSUMPTION', 'UNDER CONSUMPTION'];
  @Output() chooseProperty: EventEmitter<any> = new EventEmitter();

  
  yearValueList = [];
  meterColumns: string[] = [
    "date",
    "meterNo",
    "tenant",
    "readingType",
    "readings",
    "charge",
    "target",
    "status",
    "flag",
    "actions",
  ];
  propertyDS = new MatTableDataSource();
  meterPeriodDS = new MatTableDataSource();
  propertyColumns: string[] = ['name', 'numberOfUnits', 'meters', 'captured', 'notCaptured', 'notSubmitted', 'notApproved']

  constructor(
    public router: Router,
    public approveService: ConfirmDialogService,
    public deleteService: DeleteDialogService,
    private shopsService: ShopsService,
    public loadingservice: LoadingService,
    private fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    public propertyService: PropertiesService,
    public imageDialog: DisplayImageDialogService,
    public dialog: MatDialog,
    private readingDialogService: ReadingDownloadDialogService,
    private userMessagesService: UserMessagesService,
    private fileLookupService: FileLookupService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.currentDate.setHours(0, 0, 0, 0);
    this.userRole = JSON.parse(localStorage.getItem("userRole"));
    if (
      this.userRole === "ADMIN" ||
      this.userRole === "Admin" ||
      this.userRole === "SUPER_USER"
    ) {
      this.superUser = true;
    }
    this.unitList = [];
    this.searchForm = this.fb.group({
      monthToSearch: [],
    });
    this.clearSearch();

    const properties = JSON.parse(sessionStorage.getItem('properties'));
    const selectedProperty = JSON.parse(sessionStorage.getItem('propertySelection'));
    if(properties && ((selectedProperty && selectedProperty.property && selectedProperty.property.name === 'View All') || (selectedProperty && selectedProperty.name === 'View All'))) {
      this.selectedProperty = selectedProperty;
      this.setPropertyTable(properties);

      if(selectedProperty.maxDate) {
        this.endDate = new Date(selectedProperty.maxDate);
      } else {
        if(this.currentDate.getDate() > 17 && this.currentDate.getMonth() !== 11) {
          this.endDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 10);
        } else {
          this.endDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 10);
        }
      }

      this.fiveDaysStart = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate() - 5);
    }
  }

  setPropertyTable(prop) {
    this.propertyDS = new MatTableDataSource(prop.filter(p => p.name != 'View All'));
  }

  setChosenProperty(prop) {
    // message_types.success.messageBody = "Reading has been successfully " + (this.superUser ? 'Approved' : 'Submitted');
    // this.userMessagesService.openMessage(message_types.success);
    this.chooseProperty.emit(prop);
  }

  checkApprovalStatusSuper(capture) {
    return capture.status === 'APPROVED' && capture.status === 'NOT_CAPTURED'
  }

  checkApprovalStatusManager(capture) {
    return capture.status === 'SUBMITTED' || capture.status === 'APPROVED'
  }

  openReadingDownloadDialog() {
    const data = {
      cid: this.selectedProperty.id,
      readingPeriod: this.readingPeriod,
      propertyName: this.selectedProperty.name
    }
    this.readingDialogService.openReadingDownload(data);
  }

  setProperty(property, date) {
    this.selectedProperty = property;
    if(this.selectedProperty.name === 'View All') {
      const properties = JSON.parse(sessionStorage.getItem('properties'));
      this.setPropertyTable(properties);
    } else {
      this.selectedMonth = date.month;
      this.selectedYear = date.year;
      this.readingPeriod = this.selectedYear + this.selectedMonth.value;
      this.getRecentCaptures(this.pageSize);
    }
  }

  captureNewReading() {
    const newMeter = {
      meter: {
        utilityType: this.selectedReading.utilityType,
        id: this.selectedReading.meterId,
        serialNumber: this.selectedReading.meterNo
      },
      dates: {
        minDate: this.targetDateList.startDate,
        maxDate: this.targetDateList.endDate
      },
      showDates: true,
      superUser: this.superUser,
      propertyId: this.selectedProperty.id,
      reload: true,
      tradingName: this.selectedReading.tradingName
    }

    const dialogRef = this.dialog.open(CaptureReadingComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '64%',
      width: '60%',
      disableClose: true,
      data: {
        meter: newMeter,
        readingPeriod: this.readingPeriod,
        title: 'Capture New'
      }
    });

    dialogRef.afterClosed().subscribe((res:any) => {
      this.loadingservice.closeBlockingLoader();
      if(res) {
        this.isCalculating = true;
        this.retryCallCount = 0;
        this.getMeterPeriodStatus(this.selectedReading.meterId);
      }
    })
  }

  getMeterPeriodStatus(meterId) {
    from(this.shopsService.getMeterPeriodStatus(meterId, this.selectedProperty.id, this.readingPeriod)).subscribe((res:any) => {
      this.meterPeriodStatus = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatus;

      if(this.retryCallCount !== 8 && ((!this.meterPeriodStatus.errorMessageList || (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length === 0)) && (!this.meterPeriodStatus.flagReason || (this.meterPeriodStatus.flagReason && this.meterPeriodStatus.flagReason === '-')) && (this.meterPeriodStatus.totalCharge === 0 || this.meterPeriodStatus.totalCharge === this.oldTotalCharge))) {
        this.isCalculating = true;
        this.retryCallCount++;
        this.getMeterPeriodStatus(meterId);
      } else {

        if(!this.finalCheck) {
          this.finalCheck = true;
          this.getMeterPeriodStatus(meterId);
        } else {
          this.finalCheck = false;
          let i = this.meterPeriods.findIndex(m => m.meterId === this.meterPeriodStatus.meterId);
          this.meterPeriods[i] = this.meterPeriodStatus;
          this.meterPeriodDS = new MatTableDataSource(this.meterPeriods);
          this.meterPeriodStatus = {meterId: ''};
          this.isCalculating = false;
          message_types.success.messageBody = 'Successfully processed reading and charge';
          this.userMessagesService.openMessage(message_types.success);
        }
        
      }
    });
  }

  modifyReading() {
    this.loadingservice.openBlockingLoader('Retrieving Additional Reading Details');
    from(this.shopsService.getReadingTypeForModifyReading(this.selectedProperty.id, this.readingPeriod, this.selectedReading.meterId)).subscribe((res:any) => {
      let readingList = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatusReadingList;
      this.loadingservice.closeBlockingLoader();

      const dialogRef = this.dialog.open(UpdateReadingsComponent, {
        panelClass: 'custom-user-dialog-container',
        width: '60%',
        data: {
          isUpdate: this.selectedReading.status !== 'APPROVED',
          meter: {
            readingList: this.selectedReading.readingList,
            utilityType: {
              type: this.selectedReading.utilityType,
              num: this.selectedReading.readingList.length
            },
            readingDetails: {
              meterId: this.selectedReading.meterId,
              approvalStatus: this.selectedReading.status,
              reading: {
                readingType: readingList.length > 0 ? readingList[0].readingType : 'PERIOD_READING'
              }
            }
          },
          period: this.readingPeriod,
          propertyId: this.selectedProperty.id,
          supplierIds: this.selectedProperty.supplierIds
        }
      })
  
      dialogRef.afterClosed().subscribe((updatedMeter:any) => {
        if(updatedMeter) {
          this.loadingservice.closeBlockingLoader();
          this.oldTotalCharge = this.selectedReading.totalCharge;
          this.getMeterPeriodStatus(this.selectedReading.meterId)
        }
      })
    })
    
  }

  setFlagWarningText(element) {
    if((element.flag && element.flag != '-') && (!element.errorMessageList || (element.errorMessageList && element.errorMessageList.length === 0))) {
      return element.flag;
    } else if(!element.flag || (element.flag && element.flag === '-') && (element.errorMessageList && element.errorMessageList.length > 0)) {
      return element.errorMessageList[0];
    } else if((element.flag && element.flag != '-') && (element.errorMessageList && element.errorMessageList.length > 0)){
      return `${element.flag}, ${element.errorMessageList[0]}`;
    } else {
      return '';
    }
  }

  changePeriodReading() {
    this.searchText = '';
    this.searched = false;
    if (!this.selectedYear) {
      this.selectedYear = new Date().getFullYear();
    }
    if (this.selectedMonth) {
      this.readingPeriod = this.selectedYear + this.selectedMonth.value;
      this.getRecentCaptures(this.pageSize);
    }
    localStorage.setItem('periodStatus', this.readingPeriod);
    // this.applyFilter('');
    this.searchText = '';
  }

  changeTab(event) {
    this.tabIndex = event.index;
    this.setChosenProperty(this.tabIndex)
  }

  clearSearch() {
    const cleanSearch = {
      monthToSearch: "",
    };
    this.searchForm.setValue(cleanSearch);
  }

  clearCriteria() {
    const cleanSearch = {
      monthToSearch: "",
    };
    this.searchForm.setValue(cleanSearch);
    this.getRecentCaptures(this.pageSize);
  }

  sortTenants(tenants) {
    this.unitCodeList = []
    for(let i = 0; i < tenants.length; i++) {
      if(tenants[i].tenantOccupation.tenant === null) {
        tenants[i].tenantOccupation.tenant = {tradingName: null};
      }
    }
    this.tenantList = tenants;
    this.tenantList.sort((a, b) =>
      a.tenantOccupation.tenant.tradingName > b.tenantOccupation.tenant.tradingName ? 1 : -1
    );

    this.unitCodeList = this.unitCodeList.concat(tenants.map(x => x.unitCode));

    for (let shop of this.tenantList) {
      if (shop.tenantOccupation.tenant) {
        let shops = {
          name: shop.tenantOccupation.tenant.tradingName,
          id: shop.unitId,
          unitNo: shop.unitNo,
          propertyName: this.selectedProperty.name,
          propertyId: this.selectedProperty.id,
        };
        this.unitList.push(shops);
      }
    }
  }

  sortVacantShops(vacantUnits) {
    this.vacantList = vacantUnits;
    this.vacantList.sort((a, b) => (a.unitNo > b.unitNo ? 1 : -1));
    this.loadRentableUnits = true;
    this.loadingservice.closeBlockingLoader();
    this.unitCodeList = this.unitCodeList.concat(vacantUnits.map(x => x.unitCode));
  }

  onPageChangeEvent(event) {
    if(this.pageSize != event.pageSize) {
      this.pageSize = event.pageSize;
      this.getRecentCaptures(this.pageSize);
      this.capturesPaginator.pageIndex = 0;
    }else if(event.pageIndex > event.previousPageIndex) {
      this.previousToken.push(this.nextToken);
      this.getOtherCaptures(this.nextToken, this.pageSize)
    } else if(event.pageIndex != event.previousPageIndex) {
      if(event.pageIndex === 0) {
        this.getRecentCaptures(this.pageSize);
        this.previousToken = [];
        this.gotOtherCaptures = false;
      } else {
        this.getOtherCaptures(this.previousToken[event.pageIndex-1], this.pageSize);
        this.previousToken.pop();
      }
    }
  }

  filterContent() {
    if((this.searchText && this.searchText.length != 0) || (this.filterValues && this.filterValues.readingCaptureType !== '')  || (this.filterValues && this.filterValues.flag !== '')  || (this.filterValues && this.filterValues.status !== '')) {
      this.previousToken = [];
      this.loadingservice.openBlockingLoader('Searching for Meters');
      const meter: any = {
        tradingName: this.searchText,
        meterNo: this.searchText,
      }

      if(this.filterValues) {
        if(this.filterValues.readingCaptureType != '') {
          meter.readingCaptureType = this.filterValues.readingCaptureType;
        }

        if(this.filterValues.flag != '') {
          meter.flag = this.filterValues.flag;
        }

        if(this.filterValues.status != '') {
          meter.status = this.filterValues.status;
        }
      }

      from(this.shopsService.searchPropertyMeterPeriodWithFilter(this.selectedProperty.id, this.readingPeriod, this.pageSize, meter, this.filterText)).subscribe((res:any) => {
        this.capturesPaginator.pageIndex = 0;
        this.searched = true;
        this.loadingservice.closeBlockingLoader();
        this.meterPeriods = res.data.getPropertyMeterPeriodStatusListV2.meterPeriodStatusList;
        this.meterPeriodDS = new MatTableDataSource(this.meterPeriods);
        this.tableLength = res.data.getPropertyMeterPeriodStatusListV2.totalCount;
        this.setTargetDates(res.data.getTargetDate);
        this.nextToken = res.data.getPropertyMeterPeriodStatusListV2.LastEvaluatedKey;
        this.outstandingReadings = res.data.getPropertyMeterPeriodStatusListV2.outstandingCount;
        this.capturedReadings = res.data.getPropertyMeterPeriodStatusListV2.captureCount;
      }, (error) => {
        this.loadingservice.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Could Not Retrieve Meters';
        this.userMessagesService.openMessage(message_types.error);
      })
    } else if(this.filterValues && this.filterValues.readingCaptureType === '' &&  this.filterValues.flag === '' && this.filterValues.status === '') {
      this.searched = false;
      this.getRecentCaptures(this.pageSize);
    }
  }

  closeLoader(error?) {
    if(this.count === this.selectedReading.readingList.length && !error) {
      this.loadingservice.closeBlockingLoader();
    } else if(error) {
      this.loadingservice.closeBlockingLoader();
      message_types.error.displaySupport = false;
      message_types.info.messageBody = 'No Images uploaded for current Reading';
      this.userMessagesService.openMessage(message_types.info);
    }
  }

  getReadingImage() {
    this.blobThumbNail = Array(this.selectedReading.readingList.length).fill("");
    this.thumbnails = Array(this.selectedReading.readingList.length).fill({});
    this.imgHasError = true;
    if(this.selectedReading.readingList.length > 0) {
      this.loadingservice.openBlockingLoader('Retrieving Images', 'primary');
      this.count = 0;
      localStorage.setItem("image",JSON.stringify(true));
      for(let i = 0; i < this.selectedReading.readingList.length; i++) {
        if(this.selectedReading.readingList[i].imageKey) {
          from(this.shopsService.getReadingImageFromKey(this.selectedReading.readingList[i].imageKey)).subscribe((res:any) => {
            localStorage.setItem("image",JSON.stringify(true));
            this.selectedReading.readingList[i].presignedURL = res.data.getReadingImageFromKey.presignedUrl;
            this.getImageFromS3(res.data.getReadingImageFromKey.presignedUrl, i);
          })
        } else {
          let readingObject = {
            propertyId: this.selectedReading.propertyId,
            meterId: this.selectedReading.meterId,
            readingTS: this.selectedReading.readingTS,
            sequence: this.selectedReading.readingList[i].sequence
          }
          from(this.shopsService.getReadingImagePresinged(readingObject)).subscribe((res: any) => {
            localStorage.setItem("image",JSON.stringify(true));
            this.selectedReading.readingList[i].presignedURL = res.data.getReadingImage.presignedUrl;
            this.getImageFromS3(res.data.getReadingImage.presignedUrl, i);
          },(error) => {
            this.count++;
            this.closeLoader();
          })
        }
        
      }
    }
  }

  getImageFromS3(url, index) {
    this.fileLookupService.getImageReadingFromS3(url).subscribe((res: any) => {
      this.count++;
      this.blobThumbNail[index] = res;
      this.thumbnails[index] = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobThumbNail[index]);
      this.closeLoader();

      if(this.count >= this.selectedReading.readingList.length) {
        localStorage.removeItem("image");
        this.imgHasError = false;
        this.imageDialog.openImageDialog(this.selectedReading, this.thumbnails)
      }
    },(error) => {
      this.count++;
      console.log(this.count)
      if(this.count >= this.selectedReading.readingList.length) {
        if(this.imgHasError) {
          if(error.status === 404) {
            this.thumbnails[index] = ''
          }
          this.closeLoader(error);
        } else {
          this.imageDialog.openImageDialog(this.selectedReading, this.thumbnails)
        }
        localStorage.removeItem("image");
      }
      
    });
  }

  checkMeterLength() {
    if(this.meterPeriods.length != 0) {
      this.menuBtn = {
        text: 'More Actions',
        methods: [
          {
            text: 'Approve All Readings',
            method: () => {
              this.approveAllPeriodReadings()
            },
            icon: 'check',
            show: true  
          },
          {
            text: 'Refresh Current Period',
            method: () => {
              this.getRecentCaptures(this.pageSize)
            },
            icon: 'refresh',
            show: true   
          },
          {
            text: 'Download Period Readings',
            method: () => {
              this.openReadingDownloadDialog()
            },
            icon: 'cloud_download',
            show: true   
          },
          
        ]
      }
    
      this.menuManagerBtn = {
        text: 'More Actions',
        methods: [
          // {
          //   text: 'Submit All Readings',
          //   method: () => {
          //     this.approveAllPeriodReadings()
          //   },
          //   icon: 'check',
          //   show: true   
          // },
          {
            text: 'Refresh Current Period',
            method: () => {
              this.getRecentCaptures(this.pageSize)
            },
            icon: 'refresh',
            show: true   
          }      
        ]
      }
    }  else {
      this.menuBtnShow = false;
      this.menuBtn = null;
      this.menuManagerBtn = null;
    }
  }

  getOtherCaptures(token, limit) {
    this.loadingservice.openBlockingLoader('Retrieving Other Captures');
    if(this.searched) {
      let meter: any = {}
      if((this.searchText && this.searchText.length != 0) || (this.filterValues && this.filterValues.readingCaptureType !== '')  || (this.filterValues && this.filterValues.flag !== '')  || (this.filterValues && this.filterValues.status !== '')) {
        meter = {
          tradingName: this.searchText,
          meterNo: this.searchText,
        }
  
        if(this.filterValues) {
          if(this.filterValues.readingCaptureType != '') {
            meter.readingCaptureType = this.filterValues.readingCaptureType;
          }
  
          if(this.filterValues.flag != '') {
            meter.flag = this.filterValues.flag;
          }
  
          if(this.filterValues.status != '') {
            meter.status = this.filterValues.status;
          }
        }
      }

      from(this.shopsService.filterPropertyMeterToken(this.selectedProperty.id, this.readingPeriod, this.pageSize, meter, token, this.filterText)).subscribe((res:any) => {
        this.loadingservice.closeBlockingLoader();
        this.meterPeriods = res.data.getPropertyMeterPeriodStatusListV2.meterPeriodStatusList;
        this.meterPeriodDS = new MatTableDataSource(this.meterPeriods);
        this.setTargetDates(res.data.getTargetDate);
        this.nextToken = res.data.getPropertyMeterPeriodStatusListV2.LastEvaluatedKey;
        this.outstandingReadings = res.data.getPropertyMeterPeriodStatusListV2.outstandingCount;
        this.capturedReadings = res.data.getPropertyMeterPeriodStatusListV2.captureCount;
      }, (error) => {
        this.loadingservice.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Could not load meter statuses';
        this.userMessagesService.openMessage(message_types.error);
      });
    } else {
      from(this.shopsService.getPropertyMeterPeriodStatusesWithToken(this.selectedProperty.id, this.readingPeriod, token, limit)).subscribe((res:any) => {
        this.gotOtherCaptures = true;
        this.loadingservice.closeBlockingLoader();
        this.meterPeriods = res.data.getPropertyMeterPeriodStatusListV2.meterPeriodStatusList;
        this.meterPeriodDS = new MatTableDataSource(this.meterPeriods);
        this.setTargetDates(res.data.getTargetDate);
        this.outstandingReadings = res.data.getPropertyMeterPeriodStatusListV2.outstandingCount;
        this.capturedReadings = res.data.getPropertyMeterPeriodStatusListV2.captureCount;
        this.tableLength = res.data.getPropertyMeterPeriodStatusListCount.count;
        this.nextToken = res.data.getPropertyMeterPeriodStatusListV2.LastEvaluatedKey;
      }, (error) => {
        this.loadingservice.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Could not load meter statuses';
        this.userMessagesService.openMessage(message_types.error);
      })
    }
  }

  getScreenWidth() {
    if(window.innerWidth < 1400) {
      return 75
    }
    return 60
  }

  checkSearch(value) {
    if(value.type === 'Clear') {
      this.searched = false;
      this.searchText = '';
      this.getRecentCaptures(0);
    } else if(value.type === 'Filter') {
      this.searchText = value.searchText;
      this.filterText = value.filterText;
      this.filterValues = value.filterValues;
      this.filterContent();
    } else {
      this.checkIfEmpty(value)
    }
  }

  checkIfEmpty(event) {
    if(event.length === 0 && this.searched) {
      this.searched = false;
      this.getRecentCaptures(this.pageSize);
    }
  }

  setTargetDates(targetDatesList) {
    this.targetDateList = targetDatesList;
    if(this.targetDateList && this.targetDateList != null) {
      let currDate = new Date();
      this.targetDateList = this.targetDateList.targetDate;
      this.targetDateList.startDate = new Date(this.targetDateList.startDate);
      this.targetDateList.targetDate = new Date(this.targetDateList.targetDate);
      this.targetDateList.endDate = new Date(this.targetDateList.endDate);

      if(currDate >= this.targetDateList.startDate && currDate <= this.targetDateList.endDate) {
        this.outsideTDate = false;
      } else {
        this.outsideTDate = true;
      }

      this.targetDateList.endDate.setHours(0, 0, 0, 0);
      this.targetDateList.startDate.setHours(0, 0, 0, 0);
      this.targetDateList.targetDate.setHours(0, 0, 0, 0);
    }
    this.checkMeterLength();
  }

  getRecentCaptures(pageLimit) {
    if(!this.searched) {
      pageLimit = pageLimit === 0 ? this.pageSize : pageLimit;
      this.loadingservice.openBlockingLoader("Loading Recent Captures","primary");
      this.searched = false;
      from(this.shopsService.getPropertyMeterPeriodStatuses(this.selectedProperty.id, this.readingPeriod, pageLimit)).subscribe((res:any) => {
        this.capturesPaginator.pageIndex = 0;
        this.gotOtherCaptures = false;
        this.loadingservice.closeBlockingLoader();
        this.tableLength = res.data.getPropertyMeterPeriodStatusListCount.count;
        this.meterPeriods = res.data.getPropertyMeterPeriodStatusListV2.meterPeriodStatusList;
        this.meterPeriodDS = new MatTableDataSource(this.meterPeriods);
        this.outstandingReadings = res.data.getPropertyMeterPeriodStatusListV2.outstandingCount;
        this.capturedReadings = res.data.getPropertyMeterPeriodStatusListV2.captureCount;
        this.setTargetDates(res.data.getTargetDate);
        if(this.superUser) {
          this.canApproveAll = this.meterPeriods.every(this.checkApprovalStatusSuper);
        } else {
          this.canApproveAll = this.meterPeriods.every(this.checkApprovalStatusManager);
        }
        this.nextToken = res.data.getPropertyMeterPeriodStatusListV2.LastEvaluatedKey;
      }, (error) => {
        this.loadingservice.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Could not load meter statuses';
        this.userMessagesService.openMessage(message_types.error);
      });
    } else {
      this.filterContent();
    }
    if(!this.searchedTenants) {
      this.getMeterList();
    }
  }

  approveAllPeriodReadings() {
    this.loadingservice.openBlockingLoader('Retrieving Meter Count Details', 'primary');

    let captureCount = 0;

    from(this.shopsService.getCaptureCount(this.selectedProperty.id, this.readingPeriod)).subscribe((res:any) => {
      this.loadingservice.closeBlockingLoader();
      captureCount = res.data.getPropertyMeterPeriodStatusListV2.captureCount;
      const dialogRef = this.dialog.open(ApproveAllConfirmationComponent, {
        panelClass: 'custom-user-dialog-container',
        width: '45%',
        data: {
          month: new Date(this.readingPeriod.substring(0, 3) ,this.readingPeriod.slice(4)).getMonth() - 1,
          captureCount: captureCount
        }
      })
  
      dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.loadingservice.openBlockingLoader("Approving all readings", "primary");
          from(
            this.propertyService.approveAllPeriodReadings(
              this.selectedProperty.id,
              this.readingPeriod
            )
          ).subscribe(
            () => {
              message_types.success.messageBody = `Period Readings are being approved for ${this.selectedProperty.name}. Please allow for a few minutes to process and update`;
              this.userMessagesService.openMessage(message_types.success);
              sessionStorage.setItem('approveAll', 'true');
              this.loadingservice.closeBlockingLoader();
              const propertyList = JSON.parse(sessionStorage.getItem('properties'));
              this.setChosenProperty(propertyList[0]);
            },
            (error) => {
              this.loadingservice.closeBlockingLoader();
              message_types.error.displaySupport = true;
              message_types.error.messageBody = `Could not approve all period readings`;
              this.userMessagesService.openMessage(message_types.error);
            }
          );
        }
      })
    })
    
  }

  getMeterList() {
    this.capturedReadings = 0;
    this.outstandingReadings = 0;
    // this.loadingservice.openBlockingLoader(
    //   "Retrieving Meter Information",
    //   "primary"
    // );


  }

  sendNotification() {
    let date = new Date();
    let commentDate = new Date(Date.parse(date.toISOString()));
    let commentBy = JSON.parse(localStorage.getItem("email"));
    let comment;

    if (this.superUser) {
      comment = this.selectedMonth.month + " " + "Period Readings Approved";
    } else {
      comment = this.selectedMonth.month + " " + "Period Reading Submitted";
    }
    const object = {
      propertyId: this.selectedProperty.id,
      comment: comment,
      commentBy: commentBy || "Not Available",
      commentDate: commentDate,
      propertyName: this.selectedProperty.name,
    };
    from(this.propertyService.addPropertyComment(object)).subscribe(
      (res: any) => {
        this.propertyService.pingNotifications(true);
      },
      (error) => {
        console.log("failed to submit");
      }
    );
  }

  approvePeriodReading(reading) {
    if(!this.superUser && reading.flag.includes('NEGATIVE')) {
      this.selectedReading.propertyId = this.selectedProperty.id;
      this.openRejectPeriodReading(true);
    } else {
      this.loadingservice.openBlockingLoader(`${this.superUser ? 'Approving' : 'Submitting'} Reading`, "primary");
      from(this.shopsService.approveMeterPeriod(reading.meterId,this.selectedProperty.id, this.readingPeriod)).subscribe(
        (res: any) => {
          message_types.success.messageBody = "Reading has been successfully " + (this.superUser ? 'Approved' : 'Submitted');
          this.userMessagesService.openMessage(message_types.success);
          this.loadingservice.closeBlockingLoader();
          if(this.gotOtherCaptures) {
            this.getOtherCaptures(this.previousToken[this.previousToken.length - 1], this.pageSize)
          } else {
            this.getRecentCaptures(this.pageSize);
          }
        },
        (error) => {
          message_types.error.displaySupport = true;
          message_types.error.messageBody = "Could not approve reading";
          this.userMessagesService.openMessage(message_types.success);
          this.loadingservice.closeBlockingLoader();
        }
      );
    }
  }

  openRejectPeriodReading(notReject) {
    const dialogRef = this.dialog.open(SendNotificationDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '60%',
      data: {
        notReject: notReject,
        title: notReject ? 'Submit ' : 'Reject ',
        reading: this.selectedReading,
      }
    });
    dialogRef.afterClosed().subscribe(()=> {
      if(this.gotOtherCaptures) {
        this.getOtherCaptures(this.previousToken[this.previousToken.length - 1], this.pageSize)
      } else {
        this.getRecentCaptures(this.pageSize);
      }
    })
  }

  uploadFile() {
    this.showAddTenant = true;
    let supplierId: any = this.route.snapshot.paramMap.get("sid");
    if(supplierId && supplierId != null && !supplierId.includes('null')) {
      supplierId = this.route.snapshot.paramMap.get("sid").split(',');
    } else {
      supplierId = ['']
    }
    const dialogRef = this.dialog.open(ClientDocumentUploaderComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '60%',
      height: 'Upload Tenancy Schedule',
      data: {
        meterDetails: '',
        uploadType: 'PROPERTY_WEBILL',
        suppliers: '',
        importMeters: true,
        supplierId: supplierId,
        title: 'Upload Meter History'
      },
      disableClose: true
    });
  }

  gotoCentres() {
    localStorage.removeItem('periodStatus')
    sessionStorage.removeItem('tenantsView');
    this.router.navigate(["centres"]);
  }

  goToQualityScreen(element) {
    sessionStorage.setItem('periodReadings', JSON.stringify(this.selectedProperty))
    localStorage.setItem('periodStatus', this.readingPeriod);
    sessionStorage.setItem('centreUrl', this.router.url);
    this.router.navigate(['quality-control', {cid: this.selectedProperty.id, mNo: element.meterNo, period: this.readingPeriod, sid: this.selectedProperty.supplierIds, cName: this.selectedProperty.name, mId: element.meterId}]);
  }

  exportPeriodReadings() {
    this.showExport = true;
  }

  setPeriodData(element) {
    this.selectedReading = element;
    this.selectedReading.supplierId = this.supplierId;
  }

  getApprovalColor() {
    return !this.canApproveAll && !this.searched ? '1' : '.5'
  }

}


@Component({
  templateUrl: './approve-all-confirmation-dialog.html',
})
export class ApproveAllConfirmationComponent {
  month: string;
  captureCount: number = 0;

  constructor(public dialogRef: MatDialogRef<ApproveAllConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.month = MONTH_NAMES[data.month];
    this.captureCount = data.captureCount;
  }

  closeDialog = (approveReadings?) => {
    this.dialogRef.close(approveReadings);
  }
}