import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { BaseModule } from '../../../core/base/base.module';
import { MaterialModule } from '../../../core/material/material.module';
import { ClientDocumentUploaderComponent } from './clientDocument-uploader.component';
import { LoadingModule } from '../../../components/loading/loading.module';
import { CardDialogModule } from 'src/app/shared/components/card-dialog/card-dialog.module';


@NgModule({
  declarations: [ClientDocumentUploaderComponent],
  imports: [
    BaseModule,
    MaterialModule,
    FileUploadModule,
    LoadingModule,
    CardDialogModule
  ], exports: [
    ClientDocumentUploaderComponent
  ]
})
export class ClientDocumentUploaderModule { }
