<sym-card-dialog [title]="'Add ' +  commentTypeDisplay + ' Comment'" [closeMethod]="btnClose">
  <div class="p-12 m-0">
    <div fxLayout="column">
      <div fxLayout="column" fxFlex="100" novalidate class="full-width">
        <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;" *ngIf="displayPropertyDropDown">
          <mat-label>Property</mat-label>
          <mat-select style="color: black;" [(ngModel)]="selectedProperty" (ngModelChange)="selectedProperty">
            <mat-option *ngFor="let property of propertyList" style="color: black;" [value]="property.id">
              {{property.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" style="margin-top: 5px;width: 100%;" *ngIf="displayRentableUnitProperty">
          <mat-label>Unit</mat-label>
          <mat-select style="color: black;" [(ngModel)]="selectedUnit" (ngModelChange)="selectedUnit">
            <mat-option *ngFor="let unit of unitsList" style="color: black;" [value]="unit.id">
                {{unit.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div fxFlex class="full-width">
          <mat-form-field appearance="outline" color="primary" class="full-width">
            <mat-label>Comment</mat-label>
            <input matInput [(ngModel)]="comment">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" style="margin-top: 10px;">
        <button matTooltip="Submit Comment" [disabled]="checkInfo()" class="submitButton" mat-raised-button type="button"
          (click)="submitComment()">Submit
          Comment</button>
      </div>
    </div>
  </div>
</sym-card-dialog>