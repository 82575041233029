import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommentDialogService } from 'src/app/shared/components/comment-dialog/comment-dialog.service';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { ShopsService } from 'src/app/services/shops.service';
import { DisplayMeterChargesComponent } from './display-meter-charges/display-meter-charges.component';
import { from } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FileLookupService } from 'src/app/core/services/file-lookup.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { SendNotificationDialogComponent } from 'src/app/shared/components/send-notification-dialog/send-notification-dialog.component';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import { TariffDetailsService } from '../tariff-details/tariff-details.service';

const utilityTypes = [
  {
    type: 'ELEC_1P',
    num: 1,
    hasKva: false
  },
  {
    type: 'ELEC_1P_KVA',
    num: 2,
    hasKva: true
  },
  {
    type: 'ELEC_3P_1',
    num: 1,
    hasKva: false
  },
  {
    type: 'ELEC_3P_3',
    num: 3,
    hasKva: false
  },
  {
    type: 'ELEC_3P_KVA',
    num: 2,
    hasKva: true
  },
  {
    type: 'ELEC_3P_TOU',
    num: 4,
    hasKva: true
  },
  {
    type: 'WATER',
    num: 1,
    hasKva: false
  },
]

@Component({
  selector: 'app-quality-control',
  templateUrl: './quality-control.component.html',
  styleUrls: ['./quality-control.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: "*"})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class QualityControlComponent implements OnInit {

  @ViewChild('detailedControl') detailedControl;
  panelOpenState = false;
  readingType: string = '';
  pictureLoaded: boolean = false;
  totalCharge: any = 0;
  readingVal: number = 0;
  selectedReadingValue: any = {
    approvalStatus: ''
  };
  selectedIndex: number = 0;
  readingCharges: any[] = [];
  prevCharges: any[] = [];
  unitList: any;
  cellFlexArray: any[] = [{ date: 11, tenant: 15, capturedBy: 14, reading: 10, charge: 11, readingType: 14, warning: 10, status: 11, actions: 5}, {date: 12, tenant: 11, capturedBy: 12, reading: 9, reading2: 8, charge: 11, readingType: 13, warning: 8, status: 11, actions: 5}, {date: 12, tenant: 10, capturedBy: 10, reading: 8, reading2: 7, reading3: 7, charge: 11, readingType: 14, warning: 7, status: 11, actions: 5}, {date: 10, tenant: 8, capturedBy: 9, reading: 8, reading2: 10, reading3: 9, kva: 8, charge: 11, readingType: 11, warning: 5, status: 6, actions: 5}];
  imageView: boolean = false;
  thumbnail: any;
  readingList: any[] = [];
  readingsList: any[] = [];
  showReading: boolean = false;
  addColumnNum: number = 0;
  minDate: any;
  maxDate: any;
  cid: any;
  sid: any;
  meterNo: any;
  allowTargetDate: boolean = false;
  period: any;
  meter: any = {
    isManual: false
  };
  readingCount: number = 0;
  headerObject = {};
  meterId: any;
  chargeList: any[] = [];
  isPrevRead: boolean = false;
  propertyName: string = '';
  userRole: string = '';
  meterPeriodStatus: any = {status: 'NOT_CAPTURED'};
  meterUnits: any = [];
  superUser: boolean = false;
  faultyReading: any;
  tariffs: string[] = [];
  meterColumns: string[] = [];
  readingCaptureType: any;
  windowSize = window.innerWidth;
  noReadings: boolean = true;
  tabIndex = 0;
  reading: any[] = [];
  meterDetails: any;
  supplierDetails: any;
  qualityControlGetCall: any;
  hasPrevReading: boolean = false;
  hasFaultyReading: boolean = false;
  readingLoaded: boolean = false;
  prevReading: any[] = [];
  hasStatus: boolean = false;
  callCount: number = 0;
  isCalculating: boolean = false;
  outsideTDate: boolean = false;
  oldTotalCharge: number = 0;
  finalCheck: boolean = false;
  utilityType: any;

  isDownloaded: boolean = false;
  
  constructor(private loadingService: LoadingService,
    private shopService: ShopsService,
    private sanitizer: DomSanitizer,
    public matDialog: MatDialog,
    private route: ActivatedRoute,
    public commentService: CommentDialogService,
    private propertiesService: PropertiesService,
    private tenantService: FileLookupService,
    public dialog: MatDialog,
    private meterManagementService: MeterManagementService,
    private userMessagesService: UserMessagesService,
    private tariffService: TariffsService,
    private tariffDetailsService: TariffDetailsService) { }
  
  expandedElement: any | null;
  readingHistory: any[] = [];
  readingSelected: any = '';
  unitIds: any[] = [];
  dataSource = new MatTableDataSource(this.readingHistory);
  selection = new SelectionModel(true, []);
  retryCallCount: number = 0;
  hasError: boolean = false;

  titleButton: any = {
    icon: 'refresh',
    method: () => {
      this.ngOnInit()
    }
  }

  changeTab(event) {
    this.tabIndex = event.index;
    
    if(this.tabIndex === 0) {
      setTimeout(() => {
        this.detailedControl.getDetailedScreenDetails(this.reading, this.meterId, this.cid, this.meterPeriodStatus, this.unitIds, this.sid, this.meterDetails, this.minDate, this.maxDate, false);
      }, 50)
    }
  }

  ngOnInit(): void {
    this.selection = new SelectionModel(true, []);
    this.userRole = JSON.parse(localStorage.getItem("userRole"));
    if (
      this.userRole === "ADMIN" ||
      this.userRole === "Admin" ||
      this.userRole === "SUPER_USER"
    ) {
      this.superUser = true;
    }
    this.totalCharge = 0;
    this.chargeList = [];
    
    this.addColumnNum = 6 - Math.round((1920 - window.innerWidth) * 0.00677966101694915254237288135593);

    this.cid = this.route.snapshot.paramMap.get("cid");
    this.sid = this.route.snapshot.paramMap.get("sid");
    this.meterNo = this.route.snapshot.paramMap.get("mNo");
    this.period = this.route.snapshot.paramMap.get("period");
    this.propertyName = this.route.snapshot.paramMap.get("cName");
    this.meterId = this.route.snapshot.paramMap.get("mId");

    this.loadingService.openBlockingLoader('Retrieving Period Reading Details');
    from(this.meterManagementService.getTariffIdForMeter(this.meterId)).subscribe((res:any) => {
      this.tariffs = [];
      if(res.data.getUtilityNode.isSuccessful) {
        this.callCount++;
        this.meterDetails = res.data.getUtilityNode.utilityNode.meterNode;
        const unitIdList = res.data.getLinksPerNode.nodeUnitLinkList;
        const tariffIds = this.meterDetails.tariffIds;
        let count = 0;

        const supplierDetailsList = JSON.parse(sessionStorage.getItem('tariffs'));

        for(let id of tariffIds) {
          for(let supplier of supplierDetailsList) {
            let tariff = supplier.tariffList.find(t => t.id === id);

            if(tariff) {
              this.tariffs.push(tariff.name);
              this.supplierDetails = supplier;
              count++;

              if(count === tariffIds.length) {
                break;
              }
            } else {
              continue;
            }
          }
        }

        if(unitIdList.length > 0) {
          this.unitIds = unitIdList;
        }

        this.loadingService.closeBlockingLoader();
        this.redirectToCall(false);
        
      }
    }, (error) => {
      this.hasError = true;
      this.closeLoader(error);
    });

    
  }

  retrieveMeterDetails() {
    from(this.meterManagementService.getMeterDetails(this.meterId)).subscribe((res:any) => {
      if(res.data.getUtilityNode.isSuccessful) {
        this.meterDetails = res.data.getUtilityNode.utilityNode.meterNode;
      }
    });
  }

  redirectToCall(event) {
    if(event) {
      this.retryCallCount = 0;
      this.isCalculating = true;
      this.oldTotalCharge = this.meterPeriodStatus.totalCharge;
    }
    if(this.hasStatus) {
      this.getCurrentMeterReadings(event);
    } else {
      if(!this.superUser) {
        this.getCurrentMeterReadingsWithStatus();
      } else {
        this.getQualityControlDetails();
      }
    }
  }

  closeLoader(error?, longCall?) {
    if(this.callCount === 2 && !this.hasError) {

      if(!longCall) {
        this.loadingService.closeBlockingLoader();
      }

      if((this.superUser && this.tabIndex === 0) || (!this.superUser && this.tabIndex === 0)) {
        if((!this.superUser && this.detailedControl) || (!longCall && this.superUser && this.detailedControl)) {
          this.detailedControl.getDetailedScreenDetails(this.reading, this.meterId, this.cid, this.meterPeriodStatus, this.unitIds, this.sid, this.meterDetails, this.minDate, this.maxDate, longCall);
        } else {
          if(this.qualityControlGetCall || longCall) {
            this.qualityControlGetCall = null;
            this.detailedControl.getDetailedScreenDetails(this.reading, this.meterId, this.cid, this.meterPeriodStatus, this.unitIds, this.sid, this.meterDetails, this.minDate, this.maxDate, longCall);
          }
        }
      }      
    } else if(this.hasError){
      if(error && error.errors[0].message.includes('401')) {
        message_types.error.displaySupport = false;
        message_types.error.messageBody = 'Your session has expired. Please log in again';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      } else {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to retrieve period reading details';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      }
    }
  }

  getCurrentMeterReadings(longCall) {
    this.qualityControlGetCall = JSON.parse(sessionStorage.getItem('qualityControlGetCall'));

    if(this.qualityControlGetCall) {
      this.callCount = 1;
      sessionStorage.removeItem('qualityControlGetCall');
    }

    if(!longCall) {
      this.loadingService.openBlockingLoader('Retrieving Period Reading Details');
    }
    
    from(this.shopService.getOnlyCurrentReadingsForMeter(this.meterId, this.period, this.cid)).subscribe((res:any) => {
      this.retrieveMeterDetails();
      this.minDate = res.data.getTargetDate.targetDate.startDate;
      this.maxDate = res.data.getTargetDate.targetDate.endDate;
      if(this.minDate && this.maxDate) {
        let currentDate = new Date();

        if(currentDate >= new Date(this.minDate) && currentDate <= new Date(this.maxDate)) {
          this.allowTargetDate = true;
          this.outsideTDate = false;
        } else {
          this.outsideTDate = true;
          this.allowTargetDate = false;
        }
      }

      this.readingHistory = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatusReadingList;
      this.reading = [... res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatusReadingList];

      if(this.reading.length === 0) {
        this.reading.push({
          captureType: '',
          status: ''
        })
      }

      this.utilityType = utilityTypes.find(u => u.type === this.meterPeriodStatus.utilityType);
      this.reading[0].captureType = this.meterPeriodStatus.readingCaptureType;
      this.reading[0].status = this.meterPeriodStatus.status;
      if(this.readingHistory.length == 0) {
        this.noReadings = true;
      } else {
        this.noReadings = false;
      }

      let unitDetails = this.readingHistory.find(r => r.unitId && r.unitId != '');

      if(!unitDetails || unitDetails && !unitDetails.unitId) {
        unitDetails = this.prevReading.find(r => r.unitId && r.unitId != '');
      }

      if(unitDetails && unitDetails.unitId && this.unitIds.length === 0) {
        this.unitIds = [{unitId: unitDetails.unitId}];
      }

      if(this.prevReading && this.prevReading.length > 0) {
        this.readingHistory = [...this.prevReading, ...this.readingHistory];
      }
      this.dataSource = new MatTableDataSource(this.readingHistory);
      this.checkColumns();
      let meterPeriodStatus = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatus;
      this.meterPeriodStatus.minDate = this.minDate;
      this.meterPeriodStatus.maxDate = this.maxDate;
      this.meterPeriodStatus.errorMessageList = meterPeriodStatus.errorMessageList;
      this.meterPeriodStatus.flagReason = meterPeriodStatus.flagReason;

      this.loadingService.closeBlockingLoader();
      this.isDownloaded = true;
      this.callCount++;
      this.retrieveTariffCharges();
      this.readingLoaded = true;
      this.closeLoader(null, longCall);

      if(( longCall && longCall === 'long' && this.retryCallCount !== 7 && 
      (((!this.meterPeriodStatus.errorMessageList || (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length === 0)) && (!this.meterPeriodStatus.flagReason || (this.meterPeriodStatus.flagReason && this.meterPeriodStatus.flagReason === '-')) && (meterPeriodStatus.totalCharge === 0 || this.oldTotalCharge === meterPeriodStatus.totalCharge)))) || ( longCall && longCall === 'update' && this.retryCallCount !== 9)) {
        this.isCalculating = true;
        this.callCount = 1;
        this.getCurrentMeterReadings(longCall);
        this.retryCallCount++;
      } else {

        if(!this.finalCheck) {
          this.getCurrentMeterReadings(longCall);
          this.finalCheck = true;
        } else {
          if(this.isCalculating && this.superUser) {
            message_types.success.messageBody = 'Successfully processed reading and charge';
            this.userMessagesService.openMessage(message_types.success);
          }
          if((this.tabIndex === 0 && this.superUser) || (!this.superUser && this.tabIndex === 0)) {
            this.callCount = 2;
            this.closeLoader(null, false)
          }
          this.isCalculating = false;
        }
        
      }
    },
    (error) => {
      this.isDownloaded = true;
      this.hasError = true;
      this.closeLoader();
    });
  }

  getCurrentMeterReadingsWithStatus() {
    this.qualityControlGetCall = JSON.parse(sessionStorage.getItem('qualityControlGetCall'));

    if(this.qualityControlGetCall) {
      this.callCount = 1;
      sessionStorage.removeItem('qualityControlGetCall');
    }

    this.loadingService.openBlockingLoader('Retrieving Period Reading Details');
    from(this.shopService.getOnlyCurrentReadingsAndStatusForMeter(this.meterId, this.period, this.cid)).subscribe((res:any) => {
      this.retrieveMeterDetails();
      this.minDate = res.data.getTargetDate.targetDate.startDate;
      this.maxDate = res.data.getTargetDate.targetDate.endDate;
      if(this.minDate && this.maxDate) {
        let currentDate = new Date();
        if(currentDate > new Date(this.maxDate)) {
          this.outsideTDate = true;
        } else {
          this.outsideTDate = false;
        }
        
        if(currentDate >= new Date(this.minDate) && currentDate <= new Date(this.maxDate)) {
          this.allowTargetDate = true;
        } else {
          this.allowTargetDate = false;
        }
      }
      this.hasStatus = true;

      this.meterPeriodStatus = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatus;
      this.readingHistory = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatusReadingList;
      this.reading = [... res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatusReadingList];

      if(this.reading.length === 0) {
        this.reading.push({
          captureType: '',
          status: ''
        })
      }
      
      this.utilityType = utilityTypes.find(u => u.type === this.meterPeriodStatus.utilityType);
      this.reading[0].captureType = this.meterPeriodStatus.readingCaptureType;
      this.reading[0].status = this.meterPeriodStatus.status;
      if(this.readingHistory.length == 0) {
        this.noReadings = true;
      } else {
        this.noReadings = false;
      }

      let unitDetails = this.readingHistory.find(r => r.unitId && r.unitId != '');

      if(!unitDetails || unitDetails && !unitDetails.unitId) {
        unitDetails = this.prevReading.find(r => r.unitId && r.unitId != '');
      }

      if(unitDetails && unitDetails.unitId && this.unitIds.length === 0) {
        this.unitIds = [{unitId: unitDetails.unitId}];
      }

      if(this.prevReading && this.prevReading.length > 0) {
        this.readingHistory = [...this.prevReading, ...this.readingHistory];
      }
      this.dataSource = new MatTableDataSource(this.readingHistory);
      this.checkColumns();
      this.meterPeriodStatus.minDate = this.minDate;
      this.meterPeriodStatus.maxDate = this.maxDate;

      this.loadingService.closeBlockingLoader();
      this.isDownloaded = true;
      this.callCount++;
      this.retrieveTariffCharges();
      this.readingLoaded = true;
      this.closeLoader();
    },
    (error) => {
      this.isDownloaded = true;
      this.hasError = true;
      this.closeLoader();
    });
  }

  getQualityControlDetails() {
    this.qualityControlGetCall = JSON.parse(sessionStorage.getItem('qualityControlGetCall'));

    if(this.qualityControlGetCall) {
      this.callCount = 1;
      sessionStorage.removeItem('qualityControlGetCall');
    }

    this.loadingService.openBlockingLoader('Retrieving Period Reading Details');
    from(this.shopService.getReadingsForMeter(this.meterId, this.period, this.cid)).subscribe((res:any) => {
      this.retrieveMeterDetails();
      this.minDate = res.data.getTargetDate.targetDate.startDate;
      this.maxDate = res.data.getTargetDate.targetDate.endDate;
      if(this.minDate && this.maxDate) {
        let currentDate = new Date();
        if(currentDate > new Date(this.maxDate)) {
          this.outsideTDate = true;
        } else {
          this.outsideTDate = false;
        }
        
        if(currentDate >= new Date(this.minDate) && currentDate <= new Date(this.maxDate)) {
          this.allowTargetDate = true;
        } else {
          this.allowTargetDate = false;
        }
      }
      this.hasStatus = true;
      this.faultyReading = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.faultyReading;
      this.meterPeriodStatus = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatus;
      this.readingCaptureType = this.meterPeriodStatus.readingCaptureType;

      this.readingType = this.meterPeriodStatus.utilityType;
      this.meterUnits = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterUnits;
      this.readingHistory = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatusReadingList;
      this.reading = [... res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.meterPeriodStatusReadingList];

      if(this.reading.length === 0) {
        this.reading.push({
          captureType: '',
          status: ''
        })
      }

      this.utilityType = utilityTypes.find(u => u.type === this.meterPeriodStatus.utilityType);
      this.reading[0].captureType = this.meterPeriodStatus.readingCaptureType;
      this.reading[0].status = this.meterPeriodStatus.status;
      if(this.readingHistory.length == 0) {
        this.noReadings = true;
      } else {
        this.noReadings = false;
      }

      this.prevReading = res.data.getUnitMeterQualityControlDetails.qualityControlScreenDetails.previousPeriodReadingList;
      if(this.prevReading.length > 0) {
        this.hasPrevReading = true;
        this.prevReading.map(p => p.isPrev = true);
        // this.readingHistory = this.readingHistory.sort((a, b) => new Date(a.reading.readingTS).getTime() - new Date(b.reading.readingTS).getTime());
      }

      if(this.faultyReading) {
        this.hasFaultyReading = true;
        this.faultyReading.reading = {};
        this.faultyReading.isPrev = true;
        this.faultyReading.reading.readingTS = this.faultyReading.meterNotification.notificationDate;
        this.faultyReading.reading.readingBy = this.faultyReading.meterNotification.sentBy;
        this.faultyReading.reading.readingType = this.faultyReading.meterNotification.notificationType;
        this.faultyReading.flagReason = 'FAULTY_READING';
        if(this.prevReading) {
          this.prevReading.splice(this.prevReading.length - 1, 0, this.faultyReading);
        } else {
          this.readingHistory.splice(0, 0, this.faultyReading);
        }
      }

      let unitDetails = this.readingHistory.find(r => r.unitId && r.unitId != '');

      if(!unitDetails || unitDetails && !unitDetails.unitId) {
        unitDetails = this.prevReading.find(r => r.unitId && r.unitId != '');
      }

      if(unitDetails && unitDetails.unitId && this.unitIds.length === 0) {
        this.unitIds = [{unitId: unitDetails.unitId}];
      }

      if(this.prevReading) {
        this.readingHistory = [...this.prevReading, ...this.readingHistory];
      }
      this.dataSource = new MatTableDataSource(this.readingHistory);
      this.checkColumns();
      this.meterPeriodStatus.minDate = this.minDate;
      this.meterPeriodStatus.maxDate = this.maxDate;

      this.loadingService.closeBlockingLoader();
      this.isDownloaded = true;
      this.callCount++;
      this.retrieveTariffCharges();
      this.readingLoaded = true;
      this.closeLoader();
    },
    (error) => {
      this.isDownloaded = true;
      this.hasError = true;
      this.closeLoader();
    });
  }

  setFlagWarningText(element) {
    if((element.flagReason && element.flagReason !== 'NONE') && (!this.meterPeriodStatus.errorMessageList || (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length === 0))) {
      return element.flagReason;
    } else if((!element.flagReason || (element.flagReason && element.flagReason === 'NONE')) && (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length > 0)) {
      return this.meterPeriodStatus.errorMessageList[0];
    } else if((element.flagReason && element.flagReason !== 'NONE') && (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length > 0)){
      return `${element.flagReason}, ${this.meterPeriodStatus.errorMessageList[0]}`;
    } else {
      return '';
    }
  }

  checkHeader() {
    if(((this.meterPeriodStatus.status === 'NOT_CAPTURED' || this.meterPeriodStatus.status === 'CAPTURED' || this.noReadings) && this.superUser && ((!this.meter.isManual && this.meter.captureType != 'ONLINE') || this.meter.isManual)) || (!this.superUser && this.allowTargetDate && (this.meterPeriodStatus.status === 'NOT_CAPTURED' || this.meterPeriodStatus.status === 'CAPTURED' || this.noReadings) && ((!this.meter.isManual && this.meter.captureType != 'ONLINE') || this.meter.isManual))) {
      return true;
    } else {
      return false;
    }
  }

  checkReadings(type: string) {
    const selectedReadings = this.selection.selected.filter(r => !r.readingPeriod);

    if(type === 'S') {
      if(selectedReadings.length === 0 || (!this.superUser && (selectedReadings.filter(r => r.approvalStatus === 'APPROVED').length > 0 || selectedReadings.filter(r => r.approvalStatus === 'SUBMITTED').length > 0))) {
        return false;
      }
    } else if(type === 'A') {
      if((selectedReadings.filter(r => r.approvalStatus === 'APPROVED').length > 0 && this.superUser) || (!this.superUser && (selectedReadings.filter(r => r.approvalStatus === 'APPROVED').length > 0 || selectedReadings.filter(r => r.approvalStatus === 'SUBMITTED').length > 0))) {
        return false;
      }
    } else if(type === 'R') {
      if(selectedReadings.filter(r => r.approvalStatus === 'REJECTED').length > 0) {
        return false;
      }
    }
    
    return true;
  }

  getImageFromS3(url) {
    this.tenantService.getImageReadingFromS3(url).subscribe((res: any) => {
      this.thumbnail = this.sanitizer.bypassSecurityTrustResourceUrl(res);
      this.pictureLoaded = true;
      // this.getImageSize(res)
      this.loadingService.closeBlockingLoader();
      // localStorage.setItem("image",JSON.stringify(false));
    },(error) => {
      this.pictureLoaded = false;
      this.loadingService.closeBlockingLoader();
    });
  }
  

  approveMeterPeriod() {
    this.loadingService.openBlockingLoader('Approving reading');
    from(this.propertiesService.approvePeriodReading(this.selectedReadingValue.meterId, this.cid, this.period, this.selectedReadingValue.unitId)).subscribe((res:any) => {
      this.loadingService.closeBlockingLoader();
      this.ngOnInit();
    },
    (error) => {
      if(error.errors[0].message.includes('401')) {
        message_types.error.displaySupport = false;
        message_types.error.messageBody = 'Your session has expired. Please log in again';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      } else {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to Approve Reading';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      }
    });
  }

  rejectMultipleReadings() {
    const selectedReadings = this.selection.selected.filter(r => !r.isPrev);
    const unitIds: any[] = selectedReadings.map(r => r.unitId);
    this.loadingService.openBlockingLoader('Rejecting reading');
    from(this.propertiesService.rejectMultiplePeriodReadings(selectedReadings[0].meterId, this.cid, this.period, unitIds)).subscribe((res:any) => {
      this.loadingService.closeBlockingLoader();
      this.ngOnInit();
    },
    (error) => {
      if(error.errors[0].message.includes('401')) {
        message_types.error.displaySupport = false;
        message_types.error.messageBody = 'Your session has expired. Please log in again';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      } else {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to Reject Readings';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingService.closeBlockingLoader();
      }
    });
  }

  rejectMeterPeriod() {
    this.selectedReadingValue.propertyId = this.cid;
    this.selectedReadingValue.readingPeriod = this.period;
    const dialogRef = this.matDialog.open(SendNotificationDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '60%',
      data: {
        title: 'Reject ',
        reading: this.selectedReadingValue,
        period: this.period,
      }
    });
    dialogRef.afterClosed().subscribe(()=> {
      this.ngOnInit();
    })
  }

  checkColumns() {
    if(this.readingType === 'ELEC_1P_KVA' || this.readingType === 'ELEC_3P_KVA') {
      this.readingVal = 1;
      this.readingsList = [
        {
          name: 'Reading 1',
          val: 'reading'
        },{
          name: 'Reading 2',
          val: 'reading2'
        }];
      this.meterColumns = [
      "date",
      "tenant",
      "capturedBy",
      "reading",
      "reading2",
      "charge",
      "readingType",
      "warning",
      "status",
      "actions",];
    } else if(this.readingType === 'ELEC_3P_3') {
      this.readingVal = 2;
      this.readingsList = [
        {
          name: 'Reading 1',
          val: 'reading'
        },{
          name: 'Reading 2',
          val: 'reading2'
        },{
          name: 'Reading 3',
          val: 'reading3'
        }];
      this.meterColumns = [
        "date",
        "tenant",
        "capturedBy",
        "reading",
        "reading2",
        "reading3",
        "charge",
        "readingType",
        "warning",
        "status",
        "actions",];
    } else if(this.readingType === 'ELEC_3P_TOU') {
      this.readingVal = 3;
      this.readingsList = [
        {
          name: 'Reading 1',
          val: 'reading'
        },{
          name: 'Reading 2',
          val: 'reading2'
        },{
          name: 'Reading 3',
          val: 'reading3'
        },{
          name: 'KVA',
          val: 'kva'
        }];
      this.meterColumns = [
      "date",
      "tenant",
      "capturedBy",
      "reading",
      "reading2",
      "reading3",
      "kva",
      "charge",
      "readingType",
      "warning",
      "status",
      "actions",];
    } else {
      this.readingVal = 0;
      this.readingsList = [
        {
          name: 'Reading 1',
          val: 'reading'
        }];
      this.meterColumns = [
        "date",
        "tenant",
        "capturedBy",
        "reading",
        "charge",
        "readingType",
        "warning",
        "status",
        "actions",];
    }

    this.headerObject = this.cellFlexArray[this.readingVal];
  }

  setReadingElement(element, index) {
    this.selectedIndex = index;
    this.meterId = element.meterId;
    if(element.isPrev) {
      this.isPrevRead = element.isPrev;
    } else {
      this.isPrevRead = false;
    }
    this.selectedReadingValue = element;
  }

  checkCharge(reading, isPrev, index) {
    if(this.readingCharges.length > 0 && this.readingCharges[index] && this.readingCharges[index].total && this.readingCharges[index].total > 0) {
      return 'R ' +  this.readingCharges[index].total.toFixed(2);
    } else if(this.readingCharges.length > 0 && this.readingCharges[index] && this.readingCharges[index][0] && this.readingCharges[index][0].total && this.readingCharges[index][0].total > 0) {
      return 'R ' +  this.readingCharges[index][0].total.toFixed(2);
    } else if(reading.calculatedChargeListV1 && reading.calculatedChargeListV1.totalCharge) {
      return 'R ' +  reading.calculatedChargeListV1.totalCharge.toFixed(2);
    } else if(reading.unitCalculatedChargeList && reading.unitCalculatedChargeList.totalCharge) {
      return 'R ' + reading.unitCalculatedChargeList.totalCharge.toFixed(2);
    } else {
      return '-';
    }
  }

  retrieveTariffCharges() {
    this.readingCharges = [];
    if(this.unitIds && this.unitIds.length > 0 && this.unitIds[0] && this.superUser) {
      from(this.tariffService.getBillingPeriodCharges(this.period, this.unitIds, this.meterId, this.hasPrevReading)).subscribe((res:any) => {
        this.readingCount = 0;
        let periodCharges = []

        if(this.hasPrevReading) {
          periodCharges = this.setPeriodCharges(res.data, true);
          this.readingCharges = periodCharges;
        }

        periodCharges = this.setPeriodCharges(res.data, false);
        this.readingCharges = this.readingCharges.concat(periodCharges);
      });
    }
  }

  setPeriodCharges(callArray, isPrev) {
    this.totalCharge = 0;
    let total = 0;
    let periodCharges = [];
    let callString = !isPrev || !this.hasPrevReading ? 'a' : 'b';

    for(let i = 0; i < this.unitIds.length; i++) {
      let readingCharges = [];
      total = 0;
      let billingPeriodCharges = callArray[callString+i].billingPeriodCharges;
      
      let tariffCharges = {
        'utilisation': {
          'display': '',
          'charges': []
        },
        'fixed': {
          'display': '',
          'charges': []
        },
        'demand': {
          'display': '',
          'charges': []
        },
        'water': {
          'display': '',
          'charges': []
        },
        'sanitation': {
          'display': '',
          'charges': []
        },
      }
  
      for(let i = 0; i < billingPeriodCharges.length; i++) {
        let periodCharges = billingPeriodCharges[i].periodCharges;
  
        total += periodCharges[0].total;
  
        if(periodCharges.length > 0) {
  
          if(this.utilityType.type === 'WATER') {
  
            if(billingPeriodCharges[i].periodDetails && billingPeriodCharges[i].periodDetails.tariffName) {
              if(periodCharges[0].utilisationCharges.length > 0 || periodCharges[0].fixedCharges.length > 0) {
                if(billingPeriodCharges[i].periodDetails.tariffName.toLowerCase().includes('sanitation')) {
                  tariffCharges.sanitation.charges = [...periodCharges[0].fixedCharges, ...periodCharges[0].utilisationCharges.filter(t => !t.label.includes('Basic'))];
                  tariffCharges.sanitation.display = 'Sanitation Charges';
                } else {
                  tariffCharges.water.charges = [...periodCharges[0].fixedCharges, ...periodCharges[0].utilisationCharges.filter(t => !t.label.includes('Basic'))];
                  tariffCharges.water.display = 'Water Charges';
                }
              }
            }
  
            
          } else {
  
            if(periodCharges[0].fixedCharges.length > 0) {
              tariffCharges.fixed.charges = periodCharges[0].fixedCharges;
              tariffCharges.fixed.display = 'Fixed Charges';
            }
  
            if(periodCharges[0].demandCharges.length > 0) {
              tariffCharges.demand.charges = periodCharges[0].demandCharges;
              tariffCharges.demand.display = 'Demand Charges';
            }
  
            if(periodCharges[0].utilisationCharges.length > 0) {
              tariffCharges.utilisation.charges = periodCharges[0].utilisationCharges;
              tariffCharges.utilisation.display = 'Energy Charges';  
            }            
          }
        
        }
      }

      readingCharges = [{total: total}];

      if(!isPrev) {
        this.totalCharge += total;
      }
  
      if(tariffCharges.fixed.display !== '') {
        this.tariffDetailsService.sortCharges(tariffCharges.fixed.charges, 'fixed');
        readingCharges = [...readingCharges, {title: tariffCharges.fixed.display}, ...tariffCharges.fixed.charges];
      }
  
      if(tariffCharges.water.display !== '') {
        readingCharges = [...readingCharges, {title: tariffCharges.water.display}, ...tariffCharges.water.charges];
      }
  
      if(tariffCharges.sanitation.display !== '') {
        tariffCharges.sanitation.charges.sort((a, b) => a.label.includes('Surcharge') ? 1 : -1);
        readingCharges = [...readingCharges, {title: tariffCharges.sanitation.display}, ...tariffCharges.sanitation.charges];
      }
  
      if(tariffCharges.demand.display !== '') {
        this.tariffDetailsService.sortCharges(tariffCharges.demand.charges, 'demand');
        readingCharges = [...readingCharges, {title: tariffCharges.demand.display}, ...tariffCharges.demand.charges];
      }
  
      if(tariffCharges.utilisation.display !== '') {
        let newChargeList = [], peakCharges = [], offPeakCharges = [], standardCharges = [];
    
        for(let charge of tariffCharges.utilisation.charges) {
          if(charge.label.includes('Off-Peak')) {
            charge.label = charge.label.replace('- Off-Peak', '');
            offPeakCharges.push(charge);
          } else if(charge.label.includes('Peak')) {
            charge.label = charge.label.replace('- Peak', '');
            peakCharges.push(charge);
          } else if(charge.label.includes('Standard')) {
            charge.label = charge.label.replace('- Standard', '');
            standardCharges.push(charge);
          } else {
            newChargeList.push(charge)
          }
        }
        this.tariffDetailsService.sortCharges(newChargeList, 'energy');
        readingCharges = [...readingCharges, {title: tariffCharges.utilisation.display}, ...newChargeList];
  
        if(peakCharges.length > 0) {
          readingCharges = [...readingCharges, 
            {tou: 'Standard'}, ...standardCharges, {tou: 'Off-Peak'}, ...offPeakCharges, {tou: 'Peak'}, ...peakCharges,]
        }
      }

     

      periodCharges.push(readingCharges);
      this.readingCount++;
    }

    return periodCharges;
  }

  openMeterChargeDialog() {
    this.matDialog.open(DisplayMeterChargesComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '60vw',
      height: '70vh',
      data: {
        selectedReading: this.selectedReadingValue,
        utilityType: this.readingType,
        meter: this.meter,
        utility: this.utilityType,
        isMultiple: this.meterPeriodStatus.tradingName === 'MULTIPLE' ? true : false,
        readingCharges: this.readingCharges[this.selectedIndex],
        totalCharge: this.totalCharge
      }
    })
  }

}
