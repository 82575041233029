import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ClientDocumentUploaderComponent } from 'src/app/shared/components/clientDocumentUploader/clientDocument-uploader.component';
import { AddPropertyComponent } from './add-property/add-property.component';

@Component({
  selector: 'sym-property-management',
  templateUrl: './property-management.component.html',
  styleUrls: ['./property-management.component.scss']
})
export class PropertyManagementComponent implements OnInit {

  @ViewChild('propertyStructure') propertyStructure;
  @ViewChild('dateComp') targetDateComp;
  @ViewChild('qrBooklet') qrBookletComp;
  @ViewChild('metersComp') metersComp;
  @Output() hasSearched: EventEmitter<any> = new EventEmitter();
  @Output() addNewProperty: EventEmitter<any> = new EventEmitter();
  @Output() chooseProperty: EventEmitter<any> = new EventEmitter();
  @Output() fetchTariffs = new EventEmitter<any>();
  selectedProperty: any;
  selectedCentre: any;
  allProperties: any;
  property: any;
  tabIndex = 0;
  firstTimeFloorCall: boolean = false;
  firstTimeMeterCall: boolean = false;
  firstTimeDateCall: boolean = false;
  propertyList: any;
  propertyName: any;
  userRole: any;
  showAddCentre = false;
  showEditCenter = false;
  showUpload = false;
  superUser = false;

  primaryBtn: any = {
    
  }
  propertyDS = new MatTableDataSource();
  propertyColumns: string[] = ['name', 'code', 'address', 'numberOfAreas', 'numberOfUnits']

  menuBtn: any = {
    text: 'More Actions',
    methods: [
      {
        text: 'Add Property',
        method: () => {
          this.addProperty()
        },
        icon: 'add',
        show: true   
      },
      
    ]
  }

  constructor(
    public router: Router,
    // public commentService: CommentDialogService,
    public dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.userRole = JSON.parse(localStorage.getItem('userRole'));
    if(this.userRole === "ADMIN" || this.userRole === "Admin" || this.userRole === "SUPER_USER"){this.superUser = true};
    this.propertyList = [];
    const properties = JSON.parse(sessionStorage.getItem('properties'));
    this.selectedProperty = JSON.parse(sessionStorage.getItem('propertySelection'));
    if(properties && ((this.selectedProperty && this.selectedProperty.property && this.selectedProperty.property.name === 'View All') || (this.selectedProperty && this.selectedProperty.name === 'View All')) ) {
      this.setPropertyTable(properties)
    }
    this.tabIndex = JSON.parse(sessionStorage.getItem('managePropertyTabIndex'));

    if(!this.tabIndex) {
      this.tabIndex = 0;
    }
  }

  setChosenProperty(prop) {
    this.chooseProperty.emit(prop);
  }

  uploadFile() {
    const dialogRef = this.dialog.open(ClientDocumentUploaderComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        meterDetails: '',
        uploadType: 'TENANCY_SCHEDULE',
        importMeters: false,
        title: 'Upload Tenancy Schedule'
      },
      disableClose: true
    });
  }

  setPropertyTable(prop) {
    sessionStorage.setItem('managePropertyTabIndex', '0')
    this.propertyDS = new MatTableDataSource(prop.filter(p => p.name != 'View All'));
  }

  useProperty(property) {
    if(property === undefined) {
      this.selectedProperty = JSON.parse(sessionStorage.getItem('propertySelection'))
    } else {
      this.selectedProperty = property;
    }

    if(this.selectedProperty.name === 'View All') {
      const properties = JSON.parse(sessionStorage.getItem('properties'));
      this.setPropertyTable(properties);
    } else {
      if(this.tabIndex === 0 && this.selectedProperty != undefined) {
        setTimeout(() => {
          this.propertyStructure.getPropertyDetails(this.selectedProperty);
        }, 50) 
      } else if(this.tabIndex === 1  && this.selectedProperty != undefined) {
        setTimeout(() => {
          this.metersComp.retrieveDetails(this.selectedProperty)
          this.fetchTariffs.emit();
        }, 50);
      } else if(this.tabIndex === 2) {
        setTimeout(() => {
          this.qrBookletComp.selectedProperty(this.selectedProperty)
        }, 50)
      } else if(this.tabIndex === 3) {
        setTimeout(() => {
          this.targetDateComp.setProperty(this.selectedProperty);
        }, 50)
      }
    }
    
  }

  changeTab(event) {
    this.tabIndex = event.index;
    sessionStorage.setItem('managePropertyTabIndex', this.tabIndex.toString())
    this.useProperty(this.selectedProperty)
  }

  setData(element) {
    this.property = element;
    this.propertyName = element.name
  }
  
  addProperty() {
    const dialogRef = this.dialog.open(AddPropertyComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        title: 'Add New ',
        centre: {address:{}, supplier: ['']},
        displayAgentCode: true,
        suppliers: JSON.parse(sessionStorage.getItem('supplierList'))
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((data:any)=>{
      if(data) {
        sessionStorage.setItem('newProperty', JSON.stringify(data))
        this.addNewProperty.emit(data);
      }
      this.ngOnInit();
    });
  }

  editCentre(centre) {
    this.selectedCentre = centre;
    const dialogRef = this.dialog.open(AddPropertyComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        title: 'Edit ',
        centre: centre,
        displayAgentCode: false
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(()=>{
      this.ngOnInit();
    });
  }
  viewComments(comments) {
  }

}
