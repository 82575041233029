<mat-card fxLayout="column" style="box-shadow: 1px 1px 30px rgba(53, 53, 53, 0.05);">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <span style="font-size: 22px;margin-bottom: 17px;">{{title}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign=" center center" fxLayoutGap="8px">
        <div *ngIf=searchBar>
          <mat-form-field class="searchInput" style="font-size: small;width: 232px;"
            appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Ex. Meter #" [(ngModel)]="searchText" (keyup)="search($event)" #input>
            <button [ngStyle]="{'visibility': searchText ? '' : 'hidden'}" matSuffix mat-icon-button (click)="clearSearch()"><mat-icon class="clear-icon">close</mat-icon></button>
          </mat-form-field>
        </div>
        <button style="color: white;background-color: #41535D;" *ngIf="titleButton && titleButton.text" matTooltip="{{titleButton.toolTip}}" matTooltipPosition="above" (click)="titleButton.method()" mat-button><mat-icon *ngIf="titleButton.icon">{{titleButton.icon}}</mat-icon>{{titleButton.text}}</button>
        <button style="color: white;background-color: #41535D;" *ngIf="titleButton && !titleButton.text" matTooltip="{{titleButton.toolTip}}" matTooltipPosition="above" (click)="titleButton.method()" mat-mini-fab><mat-icon *ngIf="titleButton.icon">{{titleButton.icon}}</mat-icon></button>
      </div>
    </div>
    <div *ngIf="title" fxFlex style="padding: 12px 0px;">
      <mat-divider fxFlex></mat-divider>
    </div>
    <span>{{subTitle}}</span>
    <ng-content></ng-content>
  </mat-card>