import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs'
import { LoadingService } from 'src/app/components/loading/loading.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { ShopsService } from 'src/app/services/shops.service';
import { UserMessagesService } from '../../micro-components/user-messages/user-messages.service';
import { message_types } from '../../micro-components/user-messages/message_types';

@Component({
  selector: 'app-send-notification-dialog',
  templateUrl: './send-notification-dialog.component.html',
  styleUrls: ['./send-notification-dialog.component.scss']
})
export class SendNotificationDialogComponent implements OnInit {

  title;
  reading;
  notReject: boolean = false;
  comment: any;
  readingPeriod:any;
  flagReason: string = '';

  closeMethod = () => {
    this.closeDialog(false)
  }

  constructor(public route: ActivatedRoute, private shopService: ShopsService, public userMessagesService: UserMessagesService,
     public loadingservice: LoadingService,public propertyService: PropertiesService, private rentableUnitService: ShopsService,
     @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<SendNotificationDialogComponent>
    ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.reading = this.data.reading;
    this.notReject = this.data.notReject;
    this.flagReason = this.reading.flagReason ? this.reading.flagReason : this.reading.flag;
  }

  approvePeriodReading() {
    this.loadingservice.openBlockingLoader("Submitting Comment And Reading", "primary");
    from(this.shopService.approveMeterPeriod(this.reading.meterId, this.reading.propertyId, this.reading.readingPeriod)).subscribe(
      (res: any) => {
        this.submitComment();
      },
      (error) => {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to approve reading';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      }
    );
  }

  rejectPeriodReading() {
    this.setReadingPeriod();
    this.loadingservice.openBlockingLoader("Rejecting reading", "primary");
    from(this.shopService.rejectMeterPeriod(this.reading.meterId, this.reading.propertyId,this.reading.readingPeriod)).subscribe(
      (res: any) => {
        this.submitComment();
      },
      (error) => {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to reject reading';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      }
    );
  }

  submitComment() {
    const commentObject = {
      comment: this.comment,
      meterId: this.reading.meterId,
      propertyId: this.reading.propertyId,
      readingPeriod: this.reading.readingPeriod,
    }
    from(this.rentableUnitService.addPeriodReadingComment(commentObject)).subscribe((res: any) => {
      message_types.success.messageBody = `Reading ${this.notReject ? 'and Comment Submitted Successfully' : 'Rejected and Comment Submitted Successfully'}`;
      this.userMessagesService.openMessage(message_types.success);
      this.loadingservice.closeBlockingLoader();
      this.closeDialog(true);
    },(error) => {
      this.loadingservice.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to submit comment';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

  setReadingPeriod() {
    let date = new Date();
    let readingDate = new Date(Date.parse(date.toISOString()));
    let readingYear = readingDate.getFullYear();
    let readingMonthValue = readingDate.getMonth() + 1;
    let readingMonth = `00`;
    
    if(readingDate.getDate() <= 10) {
        readingMonthValue -= 1;
    }

    if (readingMonthValue === 0) {
        readingYear = readingYear - 1;
        readingMonthValue = 12;
    }

    if (readingMonthValue < 10) {
        readingMonth = `0${readingMonthValue.toString()}`;
    } else {
        readingMonth = readingMonthValue.toString();
    }
    this.readingPeriod = `${readingYear}${readingMonth}`;
  }

  closeDialog(hasSubmitted) {
    this.dialogRef.close(hasSubmitted);
  }

}
