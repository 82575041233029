<sym-card-dialog [title]="title + 'Meter'" [isValid]="meterDetails.valid && !meterNoExists" [closeMethod]="closeMethod" [buttonProperties]="buttonProperties">
  <mat-dialog-content>
    <div>
      <form [formGroup]="meterDetails" >
      <div fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
          <div fxLayout="column" fxLayoutAlign="center center">
      
            <div fxLayout="row wrap" fxLayoutGap="14px">
  
              <!-- <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Meter Type</mat-label>
                <mat-select data-cy="meterType" style="color: black;" formControlName="meterType" required>
                  <mat-option [value]="'BULK'">Bulk Meter</mat-option>
                  <mat-option [value]="'UNIT'">Unit Meter</mat-option>
                </mat-select>
              </mat-form-field> -->
  
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Utility Type</mat-label>
                <mat-select style="color: black;" formControlName="utilityType" (value)="meter.utilityType.name" (ngModelChange)="meterChange()" required>
                  <mat-option *ngFor="let utilityType of meterUtility" style="color: black;" [value]="utilityType">
                    {{utilityType.name}}</mat-option>
                </mat-select>
              </mat-form-field>
  
              <div fxLayout="column" style="margin-top: 5px;" fxFlex="45">
                <mat-form-field appearance="outline" >
                  <mat-label>Meter Number</mat-label>
                  <input matInput style="color: black;"  formControlName="meterNo" (ngModelChange)="checkIfNumberExists($event)">
                  <mat-hint *ngIf="checkExists">Checking if number exists</mat-hint>
                </mat-form-field>
                <mat-error *ngIf="!checkExists && meterNoExists" style="margin-top: -1em;padding-bottom: 1.5em; font-size: 75%;">Meter Number already exists</mat-error>
              </div>
    
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Reading Method</mat-label>
                <mat-select style="color: black;" (ngModelChange)="checkCaptureType()" formControlName="isManual" [(value)] ="meter.isManual" required>
                <mat-option *ngFor="let readingMethod of readingMethods" style="color: black;" [value]="readingMethod.value">
                  {{readingMethod.name}}</mat-option>
                </mat-select>
              </mat-form-field>
        
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Capture Type</mat-label>
                <mat-select data-cy="captureType" style="color: black;" formControlName="captureType" [(value)] ="meter.captureType" required>
                  <mat-option *ngFor="let type of captureType" [value]="type.value" >
                    {{type.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="tariffList.length != 0 && !showWater">
                <mat-label>Tariff Type</mat-label>
                <mat-select data-cy="tariffType" style="color: black;" formControlName="tariff" required>
                  <mat-optgroup *ngFor="let supplier of tariffList" [label]="supplier.supplierName">
                    <mat-option *ngFor="let tariff of supplier.tariffList" [value]="tariff.id" >
                      {{tariff.name}}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="tariffList.length != 0 && showWater">
                <mat-label>Tariff Type</mat-label>
                <mat-select data-cy="tariffType" style="color: black;" formControlName="tariff" multiple required>
                  <mat-optgroup *ngFor="let supplier of tariffList" [label]="supplier.supplierName">
                    <mat-option *ngFor="let tariff of supplier.tariffList" [value]="tariff.id" >
                      {{tariff.name}}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
      
  
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45" *ngIf="!showWater">
                <mat-label>Amps</mat-label>
                <input matInput style="color: black;" formControlName="amps" type="number" required>
              </mat-form-field> 
  
              <mat-form-field appearance="outline" style="margin-top: 5px; color: black;" fxFlex="45">
                <mat-label>Commission date</mat-label>
                <input matInput [matDatepicker]="picker"  formControlName="commissionDate" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
  
              <!-- <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="45">
                <mat-label>Commission Reading 1</mat-label>
                <input matInput data-cy="commissionReading" style="color: black;" formControlName="commissionReading" type="number">
              </mat-form-field>
      
              <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission2"
                fxFlex="45">
                <mat-label>Commission Reading 2</mat-label>
                <input matInput data-cy="commissionReading2" style="color: black;" formControlName="commissionReading2" type="number">
              </mat-form-field>
      
              <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission3"
                fxFlex="45">
                <mat-label>Commission Reading 3</mat-label>
                <input matInput data-cy="commissionReading3" style="color: black;" formControlName="commissionReading3" type="number">
              </mat-form-field>
      
              <mat-form-field appearance="outline" style="margin-top: 5px;" *ngIf="displayCommission4"
                fxFlex="45">
                <mat-label>Commission Reading 4</mat-label>
                <input matInput data-cy="commissionReading4" style="color: black;" formControlName="commissionReading4" type="number">
              </mat-form-field> -->
        
              
        
             
            </div>
          </div>
        </div>
      </form>
      <!-- <mat-divider style="margin: 34px 0px;"></mat-divider>
      <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button [ngStyle]="{'opacity': (meterDetails.valid && !meterNoExists ? '1' : '.5')}" style="margin-bottom: 10px; background-color: #41535D;color: white;"
            id="btnClass" [disabled]="!meterDetails.valid || meterNoExists" (click)="checkMeterNo()">
            Update
        </button>
      </div> -->
    </div>
  </mat-dialog-content>
    
  </sym-card-dialog>