<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      View Comments
    </div>
    <div>
      <button mat-icon-button (click)="dialogRef.close()" matTooltip="Close dialog">
        <mat-icon style="color: white;">
          close
        </mat-icon>
      </button>
    </div>
  </div>
  <div class="p-12 m-0">
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8 m-12">
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef fxFlex="30" style="color: grey;">Date</mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="30" style="color: black;">
          <div *ngIf="element.commentDate">
            {{ element.commentDate | date:'dd/MM/yyyy'}}
          </div>
          <div *ngIf="!element.commentDate || element.commentDate === null">
            -
          </div>
        </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef fxFlex="70" style="color: grey;">Comment</mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="70" style="color: black;">
          {{ element.comment}}
          <div *ngIf="!element.comment || element.comment === null">
            -
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator  [pageSize]="5" [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
  </div>