<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    Approve All Units
  </div>
  <div>
    <button mat-icon-button (click)="dialogRef.close()" matTooltip="Close dialog">
      <mat-icon style="color: white;">
        close
      </mat-icon>
    </button>
  </div>
</div>
<div class="p-12 m-0">
  <div fxLayout="column">
    <div>Would you like to submit all the readings captured for this month?</div>
    <div fxLayout="row" fxLayoutAlign="center center" class="p-10" style="margin-top: 100px;">
      <button matTooltip="Approve all units" class="submitButton" mat-raised-button type="button"
        (click)="submitReadings()">Submit
        Readings</button>
    </div>
  </div>
</div>