import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kva'
})
export class KvaPipe implements PipeTransform {

  transform(utilityType: UtilityType, readingList: any[]): unknown {
    let reading: any = {};
    
    if(utilityType) {
      if(utilityType.type.includes('KVA')) {
        if(readingList.length === 2) {
          reading = readingList[1];
        } else if(readingList.length > 2) {
          reading = readingList.find(r => r.name && (r.name === 'Max KVA' || r.name === 'Netword Demand Charge'))
        }
      } else if(utilityType.type.includes('TOU')) {
        if(readingList.length === 4) {
          reading = readingList[3];
        } else if(readingList.length > 4) {
          reading = readingList.find(r => r.name && (r.name === 'Max KVA' || r.name === 'Netword Demand Charge'))
        }
      }
    }
    
    return reading && reading.value && reading.value != 'NaN' ? reading.value : '';
  }

}

export interface UtilityType {
  hasKva: boolean;
  num: number;
  type: string;
}