import { Component, OnInit, Inject } from '@angular/core';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';

@Component({
  selector: 'app-migrate-meter',
  templateUrl: './migrate-meter.component.html',
  styleUrls: ['./migrate-meter.component.scss']
})
export class MigrateMeterComponent implements OnInit {

  closeMethod = () => {
    this.closeDialog()
  }

  propertyId: any;
  propertyList: any[] = [];
  newProperty: any;
  meterNo: any;
  utilityType: any;
  migrationReason: any;
  migrationReading: any;
  showError: boolean = false;

  constructor(public loadingservice: LoadingService, public userMessagesService: UserMessagesService, public dialogRef: MatDialogRef<MigrateMeterComponent>, @Inject(MAT_DIALOG_DATA) public data, private meterManagementService: MeterManagementService) { }

  ngOnInit(): void {
    this.propertyId = this.data.propertyId;
    this.propertyList = JSON.parse(sessionStorage.getItem('properties'));
    if(this.propertyList.length > 0) {
      this.propertyList = this.propertyList.filter(p => p.name != 'View All')
    }
    this.meterNo = this.data.meter.meterNo;
    this.utilityType = this.data.meter.utilityType;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  migrateMeter() {
    const meter = {
      utilityType: this.utilityType,
      meterNo: this.meterNo,
      id: this.data.meter.id,
      reading: this.migrationReading,
      reason: this.migrationReason
    }

    if(this.newProperty.id != this.propertyId) {
      this.loadingservice.openBlockingLoader(`Migrating Meter to ${this.newProperty.name}`, 'primary');
      from(this.meterManagementService.migrateMeter(this.propertyId, this.newProperty.id, meter)).subscribe((res:any) => {
        message_types.success.messageBody = 'Successfully Migrated Meter';
        this.userMessagesService.openMessage(message_types.success);
        this.loadingservice.closeBlockingLoader();
        this.closeDialog();
      })
    }
  }

  checkError() {
    if(this.newProperty && this.newProperty.id === this.propertyId) {
      return true
    } else {
      return false
    }
  }

}
