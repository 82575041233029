<sym-card-dialog [title]="title + 'Period Reading'" [closeMethod]="closeMethod">
    <div fxLayout="column" style="margin-top: 15px">
        <div fxLayout="column">
            <span *ngIf="notReject" style="color: red;padding-bottom: 1em;">This reading is flagged with Negative Consumption. Please provide a comment and submit</span>
            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field appearance="outline" style="width: 100%;" class="input-field">
                    <mat-label>Reason</mat-label>
                    <input matInput [(ngModel)]="comment">
                </mat-form-field>
            </div>

            <mat-divider style="margin: 12px 0;"></mat-divider>

            <div fxLayout="row" fxLayoutAlign="end center">
              <button mat-raised-button [ngStyle]="{'opacity': comment ? '1' : '.5'}"
              style="margin-bottom: 10px; background-color: #41535D;color: white;" id="btnClass"
              (click)="notReject ? approvePeriodReading() : rejectPeriodReading()">
              {{notReject ? 'Submit' : 'Reject'}}
          </button>
          </div>
        </div>
    </div>
</sym-card-dialog>