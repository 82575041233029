<sym-card-dialog [title]="'Download Reading Details'" [closeMethod]="closeMethod">
  <div fxLayout="column">
    <div fxLayoutAlign="center center">
      <mat-form-field appearance="outline" style="width: 50%;">
        <mat-label>Select Download Option</mat-label>
        <mat-select [(ngModel)]="downloadOption" placeholder="Download">
          <mat-option *ngFor="let option of downloadOptions" [disabled]="option.value === 'charge' && stopChargeDownload ? true : null" [value]="option.value">
            {{option.id}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-divider style="margin:16px 0"></mat-divider>

    <div fxLayoutAlign="end center">
      <button mat-raised-button style="margin-bottom: 10px; background-color: #41535D;color: white;" [disabled]="!downloadOption" (click)="exportReading()">
        Download
      </button>
    </div>
  </div>
</sym-card-dialog>