
export class Navigation {
    public navItems: any[];
    loginRoute = '/login';
    constructor(
    ) {
        // const userGroup = localStorage.getItem('userGroup');
        const userGroup = "METER_READING";

        const userRole: string = localStorage.getItem('userRole');
        if(userGroup === "METER_READING" && userRole) {
            if(userRole.includes('PROPERTY_MANAGER')) {
                this.navItems = [
                    { name: 'Properties', link: '/centres', icon: 'home' },
                    { name: 'Log out', link: '/login', icon: 'logout' }
                ]
            } else if(userRole.includes('ADMIN')) {
                this.navItems = [
                    { name: 'Properties', link: '/centres', icon: 'home' },
                    // { name: 'Property Charges', link: '/property-charges', icon: 'account_balance_wallet' },
                    { name: 'Utility Suppliers', link: '/utility-suppliers', icon: 'location_city' },
                    {name: 'Analytics Dashboard', link: '/dashboard', icon: 'timeline'},
                    // { name: 'Work Items', link: '/work-items', icon: 'developer_board' },
                    { name: 'Users', link: '/users', icon: 'face' },
                    { name: 'Reading History', link: '/reading-history', icon: 'event'},
                    // { name: 'Reports', link: '/reports', icon: 'source' },
                    {name: 'Booklet', link: '/booklet', icon: 'qr_code'},
                    { name: 'Log out', link: '/login', icon: 'logout' },
                ];
            }
            
        }
        //  else if(userGroup === "PAYMENTS") {
        //     this.navItems = [
        //         { name: 'Utility Suppliers', link: '/utility-suppliers', icon: 'location_city' },
        //         { name: 'Users', link: '/users', icon: 'face' }
        //     ];
        // }
        
    }
}
