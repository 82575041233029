<div style="width: 100%;background-color: white;box-shadow: 2px 0px 22px rgba(0, 0, 0, 0.05);min-height: 98px;" fxLayoutAlign="space-between center">
    <div style="width: 100%;"fxLayout="column">
        <div style="margin-left: 24px;">
            <sym-breadcrumb [breadcrumbText]="breadcrumbText" *ngIf="breadcrumbText" [routerNav]="routerNav" style="margin-left: 24px;"></sym-breadcrumb>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" [ngStyle]="{'margin': breadcrumbText ? '0px 24px 12px 24px' : '12px 24px'}">
            <div>
                <span style="font-size: 24px;" [ngStyle]="{'margin-bottom': showSearch ? '14px 0' : ''}">{{title}}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">

                <div fxLayout="row" fxLayoutAlign="center center" *ngIf="showSearch" fxLayoutGap="8px">
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
                        <mat-form-field appearance="outline" class="searchInput" *ngIf="showFilters" style="width: 124px;font-size: small;">
                            <mat-label>Reading Type</mat-label>
                            <mat-select style="color: black;" [(ngModel)]="filterValues.readingCaptureType" (ngModelChange)="startFilter('Reading')">
                                <mat-option *ngFor="let readingType of readingTypes" style="color: black;" [value]="readingType.val">
                                    {{readingType.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="searchInput" *ngIf="showFilters" style="width: 124px;font-size: small;">
                            <mat-label>Status</mat-label>
                            <mat-select style="color: black;" [(ngModel)]="filterValues.status" (ngModelChange)="startFilter('Status')">
                                <mat-option *ngFor="let status of statuses" style="color: black;" [value]="status.val">
                                    {{status.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="searchInput" *ngIf="showFilters" style="width: 124px;font-size: small;">
                            <mat-label>Flag</mat-label>
                            <mat-select style="color: black;" [(ngModel)]="filterValues.flag" (ngModelChange)="startFilter('Flag')">
                                <mat-option *ngFor="let flag of flags" style="color: black;" [value]="flag.val">
                                    {{flag.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field  class="searchInput" appearance="outline" style="width: 100%;font-size: small;">
                            <mat-label>Search Meters</mat-label>
                            <input matInput (ngModelChange)="checkIfEmpty($event)" [(ngModel)]="searchText" (keydown.enter)="filterContent()" #input>
                            <button [ngStyle]="{'visibility': searchText ? '' : 'hidden'}" matSuffix mat-icon-button (click)="clearSearch()"><mat-icon class="clear-icon">close</mat-icon></button>
                          </mat-form-field>
                    </div>
                      <button matTooltip="Download Period Readings" mat-raised-button style="background-color: #41535D;color: white;border-radius: 100px;"aria-label="Add user button" [ngStyle]="{'opacity': (searchText ? '1' : '.5')}" [ngStyle]="{'margin-bottom': showSearch ? '14px' : ''}" [disabled]="!searchText" (click)="filterContent()">Search<mat-icon style="font-size: x-large;margin-left: 10px;">search</mat-icon></button>
                </div>
                
                <div *ngIf="!primaryBtn && !menuBtn" style="height: 36px;"></div>
                
                <sym-primary-button *ngIf="primaryBtn && primaryBtn.method" [primaryBtn]="primaryBtn"></sym-primary-button>
                <button *ngIf="menuBtn" mat-mini-fab [matMenuTriggerFor]="buttonMenu" style="background-color: white;color: #383A41;border: 1px solid #E7E7E7;margin-left:8px" [ngStyle]="{'margin-bottom': showSearch ? '14px' : ''}"><mat-icon>
                    more_vert
                  </mat-icon></button>
                  <mat-menu  #buttonMenu="matMenu" yPosition="below" fxLayout="column" fxLayoutAlign="center center">
                    <div *ngIf="menuBtn">
                        <div *ngFor="let btn of menuBtn.methods">
                            <button  *ngIf="btn.show" mat-menu-item style="color: black" (click)="btn.method()"><mat-icon style="color: black">{{btn.icon}}</mat-icon>{{btn.text}}</button>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>        
    </div>
</div>