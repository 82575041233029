import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LoadingService } from '../../../components/loading/loading.service';
import { from } from "rxjs";
import { ShopsService } from 'src/app/services/shops.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilitySupplierService } from 'src/app/services/utility-supplier.service';
import { UserMessagesService } from '../../micro-components/user-messages/user-messages.service';
import { message_types } from '../../micro-components/user-messages/message_types';

@Component({
  selector: 'sym-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  public message: string;
  identifier: any;
  name: any;
  id: any;

  closeMethod = () => {
    this.dialogRef.close();
  }
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loadingService: LoadingService,
    public route: ActivatedRoute,
    public authService: AuthService,
    private shopService: ShopsService,
    public userMessagesService: UserMessagesService,
    private propertyService: PropertiesService,
    private supplierService: UtilitySupplierService
  ) { }

  ngOnInit(){
    this.identifier = this.data.type.type;
    this.name = this.data.type.name;
  }

  delete(){
    switch (this.identifier) {
      case 'User':
        this.deleteUser();
        break;
      case 'Tenant':
        this.deleteTenant();
        break;
      case 'Unit':
        this.deleteUnit();
        break;
      case 'Property':
        this.deleteProperty();
        break;
      default: break;
    }
  }

  deleteUser() {
    this.loadingService.openBlockingLoader("Deleting User","primary");
    const id = this.data.type.data.userId;
    from(this.authService.deleteUser(id)).subscribe((res:any) => {
      this.loadingService.closeBlockingLoader();
      this.dialogRef.close();
      message_types.success.messageBody = 'User has been successfully deleted';
      this.userMessagesService.openMessage(message_types.success);
    },(error) => {
      this.loadingService.closeBlockingLoader();
      this.dialogRef.close();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to delete user';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

  deleteTenant() {
    this.loadingService.openBlockingLoader("Deleting Rentable Unit","primary");
    this.loadingService.closeBlockingLoader();
  }

  deleteProperty() {
    this.loadingService.openBlockingLoader("Deleting Property","primary");
    from(this.propertyService.deleteProperty(this.data.type.data.id)).subscribe((res:any) => {
      message_types.success.messageBody = 'Successfully deleted property';
      this.userMessagesService.openMessage(message_types.success);
      this.loadingService.closeBlockingLoader();
      this.dialogRef.close();
    },(error) => {
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Faild to delete property';
      this.userMessagesService.openMessage(message_types.error);
      this.loadingService.closeBlockingLoader();
    })
  }

  deleteUnit() {
    const propertyId = this.data.type.data.propertyId;
    const unitId = this.data.type.data.unitId;
    this.dialogRef.close();
    this.loadingService.openBlockingLoader("Deleting unit","primary");
    from(this.shopService.deleteShopById(propertyId,unitId)).subscribe((res:any) => {
      this.loadingService.closeBlockingLoader();
      message_types.success.messageBody = 'Successfully deleted unit';
      this.userMessagesService.openMessage(message_types.success);
    },(error) => {
      this.loadingService.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Could not delete unit';
      this.userMessagesService.openMessage(message_types.error);
    })
  }
}
