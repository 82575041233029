import { Component, OnInit, Input, Output, EventEmitter, Inject } from "@angular/core";
import { FileUploader, FileItem } from "ng2-file-upload";
import { UploadServiceService } from "src/app/core/services/upload-service.service";
import { LoadingService } from "../../../components/loading/loading.service";
import { from } from "rxjs";
import { DocumentService } from "./clientDocument-uploader.service";
import { ActivatedRoute } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserMessagesService } from "../../micro-components/user-messages/user-messages.service";
import { message_types } from "../../micro-components/user-messages/message_types";
import { ShopsService } from "src/app/services/shops.service";

/**
 * Custom file uploader component
 */
@Component({
  selector: "client-file-uploader",
  templateUrl: "./clientDocument-uploader.component.html",
  styleUrls: ["./clientDocument-uploader.component.scss"],
})
export class ClientDocumentUploaderComponent implements OnInit {
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;
  uploadType;
  meterDetails;
  suppliers;
  importMeters;
  title: any;
  supplierId: any = [];
  uploadUrl: any;
  uploader: any;
  fileToUpload: any;
  fileType: any;
  id: any;
  fileName: any;
  loggedInUser: any;
  documentUrl: any;
  documentExtension: any;
  closeMethod = () => {
    this.closeDialog()
  }
  meterUploads: any[] =  [
    {
      id: 'I would like to Import Meters', value: 'PROPERTY_WEBILL'
    },
    {
      id: 'I would like to Import Meter Reading History', value: 'METER_READING_HISTORY'
    }
  ];
  companyName: any;
  constructor(
    public loadingService: LoadingService,
    public uploadService: UploadServiceService,
    public loadingservice: LoadingService,
    public userMessagesService: UserMessagesService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private shopService: ShopsService,
    public dialogRef: MatDialogRef<ClientDocumentUploaderComponent>, @Inject(MAT_DIALOG_DATA) public data
  ) { }

  /**
   * Initialization
   */
  ngOnInit() {
    this.title = this.data.title;
    this.suppliers = this.data.suppliers;
    this.meterDetails = this.data.meterDetails;
    this.importMeters = this.data.importMeters;
    this.uploadType = this.data.uploadType;
    this.supplierId = this.data.supplierId;

    this.fileType = this.uploadType;
    this.uploadUrl = "assets/";
    this.uploader = new FileUploader({
      url: this.uploadUrl,
      disableMultipart: true,
    });
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  ngAfterViewInit() {
    this.uploader.onAfterAddingFile = (item: FileItem) => {
      const objectKey = "folder_to_upload/" + item.file.name;
    };
  }

  addFile(event) {
    console.log('this.uploader')
    console.log(this.uploader)
    const selectedFiles = event.target.files;
    this.fileName = selectedFiles[0].name;
    const newFile = new File([selectedFiles[0]], this.fileName);
    this.fileToUpload = newFile;
    this.uploader.queue.push(newFile)
    this.documentExtension = selectedFiles[0].name.substr(
      selectedFiles[0].name.lastIndexOf(".") + 1
    );
  }

  /**
   * File process function
   */
  processFile() {
    if(this.fileType || this.importMeters) {
      this.fileType = this.uploadType;
    }
    switch (this.fileType) {
      case 'TENANCY_SCHEDULE':
        //For the properties
        this.processTenancy('TENANCY_SCHEDULE');
        break;
      case 'UPDATE_TENANCY_SCHEDULE':
        //For the tenants
        this.processTenancy('UPDATE_TENANCY_SCHEDULE');
        break;
      case 'PROPERTY_WEBILL':
        //For the meters
        this.processMeters();
        break;
      case 'METER_READING_HISTORY':
        //For the Meter Reading History
        this.processMeterHistory();
        break;
      case 'METER_IMAGE':
        this.processImageUpload();
      default: break;
    }
  }

  processTenancy(fileType) {
    this.loadingservice.openBlockingLoader("Uploading Tenancy Schedule", "primary");
    if(this.fileName == undefined) {
      this.loadingService.closeBlockingLoader();
      message_types.info.messageBody = 'Please select a file for upload';
      this.userMessagesService.openMessage(message_types.info);
    } else {
      from(this.uploadService.updateS3DB(this.fileName, fileType)).subscribe((res: any) => {
        from(this.uploadService.getPresignedURL(this.fileName, fileType, this.supplierId)).subscribe(
          (response: any) => {
              const url = response.data.fileUpload;
              if(!url || url === null || url === undefined) {
                this.loadingService.closeBlockingLoader();
                message_types.error.displaySupport = true;
                message_types.error.messageBody = 'There was a problem uploading your file';
                this.userMessagesService.openMessage(message_types.error);
              } else {
                  const splitUrl = url.substring(
                  url.lastIndexOf("Signature="),
                  url.lastIndexOf("x-amz-security-token=")
                );
                const split = splitUrl.split('Signature=');
                this.shopService.setAuth(split[1]);
                this.documentService.uploadDocumentToS3(url, this.fileToUpload).subscribe((res: any) => {
                  if(fileType === 'TENANCY_SCHEDULE') {
                    localStorage.setItem('fileName', JSON.stringify(this.fileName));
                    localStorage.setItem('uploadType',JSON.stringify(this.fileType));
                    message_types.in_progress.messageBody = 'File Upload has started';
                    this.userMessagesService.openMessage(message_types.in_progress);
                    this.uploadService.StartUpload('true');
                  } else {
                    message_types.success.messageBody = 'File has been uploaded';
                    this.userMessagesService.openMessage(message_types.success);
                  }
                  this.loadingService.closeBlockingLoader();
                  this.closeDialog();
                }, error => {
                  this.loadingService.closeBlockingLoader();
                  message_types.error.displaySupport = true;
                  message_types.error.messageBody = 'There was a problem uploading your file';
                  this.userMessagesService.openMessage(message_types.error);
                })
              }
          },
          (error) => {
            this.loadingservice.closeBlockingLoader();
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to retrieve all properties';
            this.userMessagesService.openMessage(message_types.error);
          }
        );
      }, (error) => {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'There was a problem uploading your file';
        this.userMessagesService.openMessage(message_types.error);
      })
    }
    
  }

  processMeters() {
    this.loadingservice.openBlockingLoader("Uploading Meter History", "primary");
    from(this.uploadService.updateS3DB(this.fileName, 'PROPERTY_WEBILL')).subscribe((res: any) => {
      from(this.uploadService.getPresignedURL(this.fileName, 'PROPERTY_WEBILL', this.supplierId)).subscribe(
        (response: any) => {
          const url = response.data.fileUpload;
          const splitUrl = url.substring(
            url.lastIndexOf("Signature="),
            url.lastIndexOf("x-amz-security-token=")
          );
          const split = splitUrl.split('Signature=');
          this.shopService.setAuth(split[1]);
          this.documentService.uploadDocumentToS3(url, this.fileToUpload).subscribe((res: any) => {
            localStorage.setItem('fileName', JSON.stringify(this.fileName));
            localStorage.setItem('uploadType',JSON.stringify(this.fileType));
            message_types.in_progress.messageBody = 'File Upload has started';
            this.userMessagesService.openMessage(message_types.in_progress);
            this.uploadService.StartUpload('true');
            this.loadingService.closeBlockingLoader();
            this.closeDialog();
          }, error => {
            this.loadingService.closeBlockingLoader();
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'There was a problem uploading your file';
            this.userMessagesService.openMessage(message_types.error);
          })
        },
        (error) => {
          this.loadingservice.closeBlockingLoader();
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to retrieve all properties';
          this.userMessagesService.openMessage(message_types.error);
        }
      );
    }, (error) => {
      this.loadingService.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'There was a problem uploading your file';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

  processMeterHistory() {
    this.loadingservice.openBlockingLoader("Uploading Meter History", "primary");
    from(this.uploadService.updateS3DB(this.fileName, 'METER_READING_HISTORY')).subscribe((res: any) => {
      from(this.uploadService.getPresignedURL(this.fileName, 'METER_READING_HISTORY', this.supplierId)).subscribe(
        (response: any) => {
          const url = response.data.fileUpload;
          const splitUrl = url.substring(
            url.lastIndexOf("Signature="),
            url.lastIndexOf("x-amz-security-token=")
          );
          const split = splitUrl.split('Signature=');
          this.shopService.setAuth(split[1]);
          this.documentService.uploadDocumentToS3(url, this.fileToUpload).subscribe((res: any) => {
            localStorage.setItem('fileName', JSON.stringify(this.fileName));
            localStorage.setItem('uploadType',JSON.stringify(this.fileType));
            message_types.in_progress.messageBody = 'File Upload has started';
            this.userMessagesService.openMessage(message_types.in_progress);
            this.uploadService.StartUpload('true');
            this.loadingService.closeBlockingLoader();
            this.closeDialog();
          }, error => {
            this.loadingService.closeBlockingLoader();
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'There was a problem uploading your file';
            this.userMessagesService.openMessage(message_types.error);
          })
        },
        (error) => {
          this.loadingservice.closeBlockingLoader();
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to retrieve all properties';
          this.userMessagesService.openMessage(message_types.error);
        }
      );
    }, (error) => {
      this.loadingService.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'There was a problem uploading your file';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

  processImageUpload(){
    this.loadingService.openBlockingLoader("Uploading Image","primary");
    
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
