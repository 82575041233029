<div class="page-layout blank" fxLayout="column">
    <sym-page-header [title]="'Manage Tenants'" [primaryBtn]="primaryBtn" *ngIf="!showUpload"></sym-page-header>

    <sym-page-header [title]="'Manage Tenants'" [primaryBtn]="refreshButton" *ngIf="showUpload"></sym-page-header>

    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="changeTab($event)">
      <mat-tab label="Tenant List">
        <sym-content-card>
          <mat-table [dataSource]="dataSource" #Sort="matSort" matSort style="margin: 10px;">
            <ng-container matColumnDef="tradingName">
                <mat-header-cell *matHeaderCellDef fxFlex="27" style="color: grey;">
                  Trading Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="27" style="color: black;">
                  <div fxLayout="column">
                    {{element.tradingName}}
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="tenantCode">
                <mat-header-cell *matHeaderCellDef fxFlex="11" style="color: grey;">
                  Tenant Code
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="11" style="color: black;">
                  <div fxLayout="column">
                    {{element.code}}
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="contact">
                <mat-header-cell *matHeaderCellDef fxFlex="13" style="color: grey;">
                  Contact #
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="13" style="color: black;">
                  <div fxLayout="column">
                    <div *ngIf="element.contactDetails.contactTelephone">
                      {{element.contactDetails.contactTelephone}}
                    </div>
                    <div *ngIf="!element.contactDetails.contactTelephone">
                      -
                    </div>
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="contactName">
                <mat-header-cell *matHeaderCellDef fxFlex="20" style="color: grey;">
                  Contact Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;">
                  <div fxLayout="column">
                    <div *ngIf="element.contactDetails.contactName">
                      {{element.contactDetails.contactName}}
                    </div>
                    <div *ngIf="!element.contactDetails.contactName">
                      -
                    </div>
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef fxFlex="25" style="color: grey;">
                  Contact Email
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="25" style="color: black;">
                  <div fxLayout="column">
                    <div *ngIf="element.contactDetails.contactEmails && element.contactDetails.contactEmails.length != 0 && element.contactDetails.contactEmails[0]">
                      {{element.contactDetails.contactEmails[0]}}
                    </div>
                    <div *ngIf="!element.contactDetails || !element.contactDetails.contactEmails ">
                      -
                    </div>
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="leaseNum">
                <mat-header-cell *matHeaderCellDef fxFlex="5" style="color: grey;">
                  # Leases
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="5" style="color: black;">
                  <div fxLayout="column">
                    {{element.tenantOccupationList.length}}
                  </div>
                </mat-cell>
              </ng-container>
    
    
    
            <mat-header-row *matHeaderRowDef="tenantColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: tenantColumns"></mat-row>
          </mat-table>
    
          <mat-paginator *ngIf="!retrievedTenantsForProperty" #Captures="matPaginator" (page)="onPageChangeEvent($event)" [length]="tenantCount" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
          <mat-paginator *ngIf="retrievedTenantsForProperty" #PropTenants="matPaginator" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
        </sym-content-card>
      </mat-tab>
      <mat-tab label="Processed Files">
        <sym-content-card>
          <mat-table [dataSource]="uploadSource" #Sort="matSort" matSort style="margin: 10px;" fxFlex="100">
            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef fxFlex="20" style="color: grey;">
                  Upload Date
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;cursor: pointer;" (click)="goToReviewTenantScreen(element)">
                  <div fxLayout="column">
                    {{element.uploadedAt | date:'dd/MM/yyyy'}}
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="fileName">
                <mat-header-cell *matHeaderCellDef fxFlex="35" style="color: grey;">
                  File Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="35" style="color: black;cursor: pointer;" (click)="goToReviewTenantScreen(element)">
                  <div fxLayout="column">
                    {{element.fileName}}
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="fileType">
                <mat-header-cell *matHeaderCellDef fxFlex="20" style="color: grey;">
                  File Type
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;cursor: pointer;" (click)="goToReviewTenantScreen(element)">
                  <div fxLayout="column">
                    {{element.fileType}}
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef fxFlex="25" style="color: grey;">
                  Status
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="25" style="color: black;cursor: pointer;" (click)="goToReviewTenantScreen(element)">
                  <div fxLayout="column">
                    {{element.uploadStatus}}
                  </div>
                </mat-cell>
              </ng-container>
    
    
    
            <mat-header-row *matHeaderRowDef="documentColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: documentColumns"></mat-row>
          </mat-table>
    
          <mat-paginator #Documents="matPaginator" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </sym-content-card>
        <div fxFlex="100" fxLayout="column">
          
        </div>
        
      </mat-tab>
    </mat-tab-group>
      
</div>