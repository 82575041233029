import { AfterViewInit, Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { FloorplanService } from 'src/app/services/floor-plan.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { AddNodeComponent } from '../add-node/add-node.component';
import { DelinkAllocationComponent } from '../delink-allocation/delink-allocation.component';
import { UnitLinkComponent } from '../unit-link/unit-link.component';
import { UpdateMeterNodeDialogComponent } from 'src/app/shared/components/update-meter-node-dialog/update-meter-node-dialog.component';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';

@Component({
  selector: 'sym-area-tables',
  templateUrl: './area-tables.component.html',
  styleUrls: ['./area-tables.component.scss']
})
export class AreaTablesComponent implements OnInit {

  @Input() areaDS = new MatTableDataSource();
  @Input() unitDS = new MatTableDataSource();
  @Input() treeNode: any;
  @Input() unit: any;
  @Input() propertyId;
  @Input() unitList: any[];
  @Input() meterList: any[];
  @Input() linkDS = new MatTableDataSource();
  @Input() allocationList: any[];
  @Output() openCreateNodeEventEmitter = new EventEmitter<any>();
  @Output() refreshNode = new EventEmitter<any>();
  @Output() refreshUnlinkedTable = new EventEmitter<any>();
  selectedLinkNode: any;
  @ViewChild('unitListPaginator', { static: true }) unitListPaginator: MatPaginator;
  @ViewChild("Meters", { static: true }) metersPaginator: MatPaginator;
  unitType: string[] = ['Common Area', 'Rentable Unit'];
  tariffList = [];
  supplierDetails;

  meterCardButton: any = {
    method: () => {
      this.openCreateNodeDialog()
    },
    icon: 'add'      
  }

  displayedAreaColumns: string[] = [
    "areaName",
    "areaSize",
    "numberAreas",
    "numberUnits",
    "actions",
  ];
  displayedUnitColumns: string[] = [
    "unitName",
    "unitType",
    "unitSqm",
    "actions",
  ];
  linkColumns: string[] = [
    "meterNo",
    "meterType",
    "utilityType",
    "status",
    "amps",
    "actions"
  ];

  unlinkedColumns: string[] = [
    "meterNo",
    "meterType",
    "utilityType",
    "status",
    "amps",
    "actions"
  ];

  titleButton: any = {
    method: () => {
      this.creatNodeUnitLink(false)
    },
    toolTip: 'Link a Meter to Unit',
    icon: 'add'
  }
  selectedElement: any
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AddNodeComponent>, private floorPlanService: FloorplanService, private userMessagesService: UserMessagesService, private loadingService: LoadingService, private meterManagementService: MeterManagementService) { }

  ngOnInit(): void {
    this.tariffList = JSON.parse(sessionStorage.getItem('tariffs'));
    const selectedProperty = JSON.parse(sessionStorage.getItem('propertySelection'));

    if(selectedProperty) {
      this.supplierDetails = selectedProperty.supplierDetails;
    }
  }

  editMeterDialog() {
    const dialogRef = this.dialog.open(UpdateMeterNodeDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '62%',
      width: '60%',
      data: {
        supplier: this.supplierDetails,
        propertyId: this.propertyId,
        tariffList: this.tariffList,
        meter: this.selectedElement,
        title: 'Update '
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        let meterArray:any[] = this.linkDS.data;
        let index = meterArray.findIndex(m => m.id === res.id);
        let status = meterArray[index].status;
        let meterType = meterArray[index].meterType;
        meterArray[index] = res;
        meterArray[index].utilityType = res.utilityType.name;
        meterArray[index].status = status;
        meterArray[index].tariffId = res.tariff;
        meterArray[index].meterType = meterType;
        this.linkDS = new MatTableDataSource(meterArray);
        this.linkDS.paginator = this.metersPaginator;
      }
    });
  }

  setupUnlinkedMetersDatasource(unlinkedMeters) {
    this.linkDS = new MatTableDataSource(unlinkedMeters);
    this.linkDS.paginator = this.metersPaginator;
  }

  updateUnit() {
    const unit = {
      unitType: this.selectedElement.unitType === 'Rentable Unit' ? 'RENTABLE_UNIT' : 'COMMON_AREA',
      name: this.selectedElement.name,
      id: this.selectedElement.id,
      code: this.selectedElement.code
    }
    this.loadingService.openBlockingLoader('Updating Unit Values', 'primary');
    from(this.floorPlanService.updateUnit(this.propertyId, unit, this.selectedElement.sqm)).subscribe((res: any) => {
      this.loadingService.closeBlockingLoader();
      message_types.success.messageBody = 'Successfully Updated Unit Values';
      this.userMessagesService.openMessage(message_types.success);
      this.refreshNode.emit();
    })
  }

  openCreateNodeDialog() {
    this.openCreateNodeEventEmitter.emit();
  }

  creatNodeUnitLink(nodeToUnitLink) {
    const dialogRef = this.dialog.open(UnitLinkComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '64%',
      width: '60%',
      data: {
        node: this.treeNode,
        unitList: this.unitList,
        meterList: this.meterList,
        allocationList: this.allocationList,
        propertyId: this.propertyId,
        nodeToUnitLink: nodeToUnitLink,
        meter: this.selectedElement
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((res)=>{
      if(res === 'unit') {
        this.refreshUnlinkedTable.emit();
      } else if(res) {
        this.refreshTable();
      }
    });
  }

  updateArea() {
    this.loadingService.openBlockingLoader('Updating Area Values', 'primary');
    from(this.floorPlanService.updateArea(this.selectedElement.id, this.selectedElement.name)).subscribe((res: any) => {
      this.loadingService.closeBlockingLoader();
      message_types.success.messageBody = 'Successfully Updated Area Values';
      this.userMessagesService.openMessage(message_types.success);
    })
  }

  delinkNode() {
    const dialogRef = this.dialog.open(DelinkNodeDialog, {
      width: '400px'
    });
    dialogRef.afterClosed().subscribe((res:boolean) => {
      if(res) {
        if(this.selectedLinkNode.linkType != 'PERCENTAGE') {
          this.loadingService.openBlockingLoader('Unlinking Unit from Meter', 'primary');
          from(this.meterManagementService.deleteNodeUnitLink(this.treeNode.id, this.selectedLinkNode.id)).subscribe((res:any) => {
            if(res.data.deleteNodeUnitLink.isSuccessful) {
              this.loadingService.closeBlockingLoader();
              message_types.success.messageBody = 'Successfully unlinked unit';
              this.userMessagesService.openMessage(message_types.success);
            } else {
              this.loadingService.closeBlockingLoader();
              message_types.error.displaySupport = true;
              message_types.error.messageBody = 'Failed to unlink unit';
              this.userMessagesService.openMessage(message_types.error);
            }
          }, (error) => {
            this.loadingService.closeBlockingLoader();
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to unlink unit';
            this.userMessagesService.openMessage(message_types.error);
          })
        } else {
          const dialogRef = this.dialog.open(DelinkAllocationComponent, {
            panelClass: 'custom-user-dialog-container',
            height: '70%',
            width: '60%',
            data: {
              selectedLinkNode: this.selectedLinkNode,
              unitList: this.unitList,
              propertyId: this.propertyId
            },
            disableClose: true
          });
        }
      }
    })
  }

  refreshTable() {
    this.loadingService.openBlockingLoader('Retrieving Unit Details', 'primary');
    from(this.meterManagementService.getLinksForUnit(this.treeNode.id)).subscribe((res:any) => {
      let unitPath = [];
      let unitLinkList = [...res.data.getLinksForUnit.nodeUnitLinkList];
      for(let i = 0; i < unitLinkList.length; i++) {
        if(unitLinkList[i].utilityNode.meterNode != null) {
            unitPath.push(unitLinkList[i].utilityNode.id);
            unitLinkList[i] = {
              id: unitLinkList[i].utilityNode.id,
              meterNo: unitLinkList[i].utilityNode.meterNode.meterNo,
              type: 'Meter - ' + unitLinkList[i].utilityNode.meterNode.generalUtilityType,
              amps: unitLinkList[i].utilityNode.meterNode.amps,
              utilityType: unitLinkList[i].utilityNode.meterNode.utilityType,
              status: unitLinkList[i].utilityNode.meterNode.status,
              meterType: unitLinkList[i].utilityNode.meterNode.meterType,
              commissionDate: unitLinkList[i].utilityNode.meterNode.commissionDate,
              tariffId: unitLinkList[i].utilityNode.meterNode.tariffId,
              capturedStatus: unitLinkList[i].utilityNode.meterNode.capturedStatus,
              isManual: unitLinkList[i].utilityNode.meterNode.isManual,
              captureType: unitLinkList[i].utilityNode.meterNode.captureType
            }
          }
        }

        this.linkDS = new MatTableDataSource(unitLinkList);
        this.loadingService.closeBlockingLoader();
      }, (error) => {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to Retrieve Unit Details';
        this.userMessagesService.openMessage(message_types.error);
    })
  }

  updateItem() {
    const title = 'Update  ' +  this.selectedElement.name
    const dialogRef = this.dialog.open(AddNodeComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        title: title,
        item: this.selectedElement,
        propertyId: this.propertyId,
        isUpdate: true
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(()=>{
      this.ngOnInit();
    });
  }

  resetDataSources(unitList, areaList) {
    this.unitDS = new MatTableDataSource(unitList)
    this.unitDS.paginator = this.unitListPaginator;
    this.areaDS = new MatTableDataSource(areaList);
  }

}

@Component({
  selector: 'delink-node-dialog',
  templateUrl: './delink-node-dialog.html',
})
export class DelinkNodeDialog {
  constructor(public dialogRef: MatDialogRef<DelinkNodeDialog>) {}

  closeDialog(delinkNode: boolean) {
    this.dialogRef.close(delinkNode)
  }
}
