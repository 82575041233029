import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../../components/loading/loading.service';

@Component({
  selector: 'sym-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public message: string;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loadingService: LoadingService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  submitReadings(){
    this.dialogRef.close();
    }
  }
