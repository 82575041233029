import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Navigation } from '../../navigation.model';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  /**
   * @property {Navigation} navigation Property for navigation items
   */
  public navigation: Navigation;
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.navigation = new Navigation();
  }

  /**
   * 
   * @param item Object containing navigation properties
   * @returns {Navigation} Navigation item
   */
  getNavigation() {
    if(this.navigation.loginRoute){
      return this.getNavItems();
    } else 
    return this.getNavItems();
  }

  getNavItems() {
    const userGroup = "METER_READING";

    let userRole: string = localStorage.getItem('userRole');
    if(userRole === undefined || userRole === null) {
      this.authService.setUserDetails();
      userRole = localStorage.getItem('userRole');
    }
    let navItems: any[] = [];
    if(userGroup === "METER_READING") {
      if(userRole.includes('PROPERTY_MANAGER') || userRole.includes('METER_READER')) {
        navItems = [
          {name: 'Period Readings', link: '/period-readings', icon:'phonelink_ring'},
        ]
      } else if(userRole.includes('ADMIN')) {
        navItems =  [
          {name: 'Period Readings', link: '/period-readings', icon:'phonelink_ring'},
          {name: 'Properties', link: '/centres', icon: 'home' },
          {name: 'Tenants', link: '/tenant-list', icon: 'accessibility_new'},
          {name: 'Users', link: '/users', icon: 'face'},
          {name: 'Utility Suppliers', link: '/utility-suppliers', icon: 'location_city'},
        ];
      }     
    } else if(userGroup === "PAYMENTS") {

      navItems =  [
                { name: 'Utility Suppliers', link: '/utility-suppliers', icon: 'location_city' },
                { name: 'Users', link: '/users', icon: 'face' }
            ];
        }

        return navItems;
  }
}
