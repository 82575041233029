import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chargeDisplay'
})
export class ChargeDisplayPipe implements PipeTransform {

  transform(charge: string): unknown {
    if(charge.toLocaleLowerCase().includes('surcharge') && !charge.toLocaleLowerCase().includes('network surcharge')) {
      return 'Surcharge';
    } else {
      console.log(charge)
      let index = charge.toLocaleLowerCase().indexOf('(per');

      if(index === -1) {
        index = charge.toLocaleLowerCase().indexOf('- per');
        if(index === -1) {
          index = charge.toLocaleLowerCase().indexOf(' per');
          
          if(index === -1) {
            index = charge.toLocaleLowerCase().indexOf('(r');

            if(index === -1) {
              index = charge.toLocaleLowerCase().indexOf('- total');

              if(index === -1 || charge.toLocaleLowerCase().includes('percentage')) {
                return charge;
              }

            }
          }
        } 
      }
      
      return charge.substring(0, index);
    }
    
  }

}