import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sym-electricity-charges',
  templateUrl: './electricity-charges.component.html',
  styleUrls: ['./electricity-charges.component.scss']
})
export class ElectricityChargesComponent implements OnInit {

  @Input() tariffElecCharge: any;
  @Input() isTou: boolean;
  @Input() seasons: any;
  // demandUnits: string[] = ['Month', 'Day', 'kWh', 'kVA', 'amps', 'Rand'];
  demandUnits: string[] = ['Month', 'Day'];
  selectedCharge: number = 0;
  elecCharge = {
    fixedCharges: [],
    demandCharges: [],
    utilisationCharges: []
  };
  showNotifiedFields: boolean = false;

  units: string[] = ['Month', 'Day', 'kWh', 'kVA'];

  constructor() { }

  ngOnInit(): void {
  }

  changeNotifiedValues() {
    this.showNotifiedFields = !this.showNotifiedFields;

    this.tariffElecCharge.demandCharge.excessNetworkCharge.forEach((el, i) => {
      this.tariffElecCharge.demandCharge.excessNetworkCharge[i] = null;
    });
    
    this.tariffElecCharge.demandCharge.networkAccessCharge.forEach((el, i) => {
      this.tariffElecCharge.demandCharge.networkAccessCharge[i] = null;
    });
  }

  addCharge(chargeType: string) {
    this.elecCharge[chargeType].push({
      name: '',
      charges: [],
      units: ''
    })
  }

  removeCharge(index: number, chargeType: string) {
    this.elecCharge[chargeType].splice(index, 1);
  }

}
