<div fxLayout="row" style="width: 100%;">
    <div class="container">
        <div style="background-color: white;width: 300px;overflow: scroll;height: 100vh;">
            <!-- <div fxLayoutAlign="center center" style="padding-top:1em; padding-bottom: .7em;">
                <span style="color: #A9A9A9;">AREAS</span>
            </div>
            <div fxLayoutAlign="center center" fxLayout="column">
                <mat-divider style="width: 90%;"></mat-divider>
            </div> -->
            <div fxLayout="column" style="padding: 15px 20px;" *ngIf="property" (mousedown)="onMouseDown($event)" (click)="openNode($event, {type: 'Property'})" (contextmenu)="onRightClick($event, {content: {type: 'Property'}})">
              <span *ngIf="property && property.name" style="color: #A9A9A9;">{{property.name}}</span>
              <mat-divider style="margin-top: .5em"></mat-divider>
            </div>
            <div cdkDropList (cdkDropListDropped)="drop($event)">
              <ng-template #tmplNode let-node="node">
                <div class="node-item" [attr.data-id]="node.id" [attr.id]="'node-'+node.id">
                  
                  <div class="node-title" [ngStyle]="checkNodeStyle(node)"  (click)="getNodesForArea($event,node)">
                    <span (mousedown)="onMouseDown($event)" style="font-size: 14px;" (contextmenu)="onRightClick($event, {content: node})" fxLayout="row" fxLayoutAlign="start center"> <mat-icon [ngClass]="selectedNode === node ? 'expanded-icon-selected' : node.isExpanded ? 'expanded-icon' : 'not-expanded-icon'">{{node.type === 'Area' ? 'map' : node.type === 'Unit' ? 'store' : ''}}</mat-icon>{{node.name + (node.type === 'Unit' ? ' (' + node.code + ')' : '')}}</span>
                  </div>
              
                  <div *ngIf="node.isExpanded && node.children.length" class="node-children"
                       cdkDropList [cdkDropListData]="node.children"
                       [id]="node.id" [cdkDropListConnectedTo]="dropTargetIds"
                       (cdkDropListDropped)="drop($event)"
                       [cdkDropListSortingDisabled]="true">
              
                    <div *ngFor="let child of node.children" cdkDrag [cdkDragData]="child" (cdkDragMoved)="dragMoved($event, child)">
                      <ng-container *ngTemplateOutlet="tmplNode,context:{node:child}"></ng-container>  
                    </div>
              
                  </div>
              
                </div>
              </ng-template>
              
              <div cdkDropList [cdkDropListData]="propertyList"
                   [id]="'main'" [cdkDropListConnectedTo]="dropTargetIds"
                   (cdkDropListDropped)="drop($event)"
                   [cdkDropListSortingDisabled]="true">
                   
                <div *ngFor="let node of propertyList" cdkDrag [cdkDragData]="node"      
                   (cdkDragMoved)="dragMoved($event, node)">
              
                  <ng-container *ngTemplateOutlet="tmplNode,context:{node:node}"></ng-container>
              
                </div>
              </div>
            </div>
              <div style="visibility: hidden; position: fixed;"
                  [style.left.px]="menuTopLeftPosition.x"
                  [style.top.px]="menuTopLeftPosition.y"
                  [matMenuTriggerFor]="rightMenu"></div>

                  <mat-menu #rightMenu="matMenu" style="visibility: hidden;">
                    <ng-template matMenuContent let-item="item" style="visibility: hidden;">
                      <div *ngIf="item.content.type === 'Area' || item.content.type === 'Property'">
                        <button  mat-menu-item class="mobile-hidden mat-light-green-700-bg" (mousedown)="onMouseDown($event)"  (click)="addNode('Area')">
                          <mat-icon style="font-size: large;color: black">add</mat-icon>{{item.content.type === 'Property' ? 'Add New Area' : 'Add New Sub-Area'}}
                        </button>
                        <mat-divider></mat-divider>
                        <button (mousedown)="onMouseDown($event)"  (click)="addNode('Unit')" mat-menu-item class="mobile-hidden mat-light-green-700-bg">
                          <mat-icon style="font-size: large;color: black">add</mat-icon>Add New Unit
                        </button>
                        <div *ngIf="item.content.type === 'Property'">
                          <mat-divider></mat-divider>
                          <button (mousedown)="onMouseDown($event)" (click)="updateProperty(item.content)" mat-menu-item class="mobile-hidden mat-light-green-700-bg">
                            <mat-icon style="font-size: large;color: black">build</mat-icon>Update Property Details
                          </button>
                        </div>
                        <div *ngIf="item.content.type === 'Area'">
                          <mat-divider></mat-divider>
                          <button (mousedown)="onMouseDown($event)" (click)="updateNode(item.content)" mat-menu-item class="mobile-hidden mat-light-green-700-bg">
                            <mat-icon style="font-size: large;color: black">build</mat-icon>Update Area Details
                          </button>
                        </div>
                      </div>
                      <div *ngIf="item.content.type === 'Unit'">
                        <button  mat-menu-item class="mobile-hidden mat-light-green-700-bg" (mousedown)="onMouseDown($event)"  (click)="creatNodeUnitLink()">
                          <mat-icon style="font-size: large;color: black">add</mat-icon>Link Meter
                        </button>
                        <mat-divider></mat-divider>
                        <button  mat-menu-item class="mobile-hidden mat-light-green-700-bg" (mousedown)="onMouseDown($event)" (click)="updateNode(item.content)">
                          <mat-icon style="font-size: large;color: black">build</mat-icon>Update Unit Details
                        </button>
                        <mat-divider></mat-divider>
                        <button  mat-menu-item class="mobile-hidden mat-light-green-700-bg" (mousedown)="onMouseDown($event)" (click)="showDeleteDialog = true; getNodesForArea($event, item.content)">
                          <mat-icon style="font-size: large;color: black">delete_forever</mat-icon>Delete Unit
                        </button>
                      </div>
                    </ng-template>
                  </mat-menu>
        </div>
    </div>
    <div style="width: 100%;">
      <sym-area-tables #areaTables (openCreateNodeEventEmitter)="openCreateNodeDialog()" (refreshNode)="updatedNode = true; getNodesForArea({}, selectedNode)" (refreshUnlinkedTable)="refreshUnlinkedTable()" [areaDS]="areaDS" [linkDS]="linkDS" [unitList]="unitList" [propertyId]="propertyId"
      [unitDS]="unitDS" [allocationList]="allocationList" [meterList]="meterList" [unit]="unit" [treeNode]="selectedNode"></sym-area-tables>
    </div>
</div>
