import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { ShopsService } from 'src/app/services/shops.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

@Component({
  selector: 'app-decommission-meter-node',
  templateUrl: './decommission-meter-node.component.html',
  styleUrls: ['./decommission-meter-node.component.scss']
})
export class DecommissionMeterNodeComponent implements OnInit, AfterViewInit {

  title;
  meterId;
  propertyId;
  tariffList;
  tariffShow = false;
  meter: any;
  displayCommission2 = false;
  displayCommission3 = false;
  displayCommission4 = false;
  selectedMeter: any;
  waterList: any[] = [];
  elecList: any[] = [];
  closeMethod = () => {
    this.closeDialog(false)
  }
  utilityTypeNumber: number;
  oldMeterId: any;
  timeout: any = null;
  checkExists: boolean = false;
  meterNoExists: boolean = false;

  decomMeterButtomProp = {
    text: 'Decommission Meter And Create New Meter',
    type: 'primary',
    method: () => {
      this.submitReading()
    }
  }

  decomButtomProp = {
    text: 'Decommission Meter',
    type: 'primary',
    method: () => {
      this.submitReading()
    }
  }

  captureType = [{ name: 'Online', value: 'ONLINE' }, { name: 'Manual', value: 'MANUAL' }, { name: 'Online Manual', value: 'ONLINE_MANUAL' }];

  meterUtility = [{
    name: 'ELEC_1P',
    image: 1
  },
  {
    name: 'ELEC_1P_KVA',
    image: 2
  },
  {
    name: 'ELEC_3P_KVA',
    image: 2
  },
  {
    name: 'ELEC_3P_1',
    image: 1
  },
  {
    name: 'ELEC_3P_3',
    image: 3
  },
  {
    name: 'ELEC_3P_TOU',
    image: 4
  },
  {
    name: 'WATER',
    image: 1
  }];
  readingMethods = ["Manual", "Smart"];

  decomissionGroup = new UntypedFormGroup({
    decommissionReason: new UntypedFormControl(''),
    decommissionReading: new UntypedFormControl(''),
    decommissionReading2: new UntypedFormControl(''),
    decommissionReading3: new UntypedFormControl(''),
    decommissionReading4: new UntypedFormControl(''),
  });

  meterDetails = new UntypedFormGroup({
    amps: new UntypedFormControl(0, Validators.min(1)),
    utilityType: new UntypedFormControl(''),
    readingMethod: new UntypedFormControl(''),
    meterNo: new UntypedFormControl(''),
    commissionDate: new UntypedFormControl(''),
    noOfPhotos: new UntypedFormControl({
      value: '',
      disabled: true
    }),
    commissionReading: new UntypedFormControl(''),
    // meterType: new UntypedFormControl(''),
    commissionReading2: new UntypedFormControl(''),
    commissionReading3: new UntypedFormControl(''),
    commissionReading4: new UntypedFormControl(''),
    tariff: new UntypedFormControl(''),
    isManual: new UntypedFormControl(''),
    captureType: new UntypedFormControl('')
  });
  showWater: boolean = false;

  constructor(public loadingservice: LoadingService,
    public userMessagesService: UserMessagesService,
    public dialogRef: MatDialogRef<DecommissionMeterNodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private meterManagementService: MeterManagementService,
    private cd: ChangeDetectorRef,
    private shopsService: ShopsService) {
      console.log(this.data)
     }
phase = 0
  ngOnInit() {
    this.meter = {};
    this.title = this.data.title;
    this.meterId = this.data.meterId;
 
    this.utilityTypeNumber  =  Number(this.data.meter.phases);
    let phaseLength = []
    let count = 0 
    console.log(this.phase)
    while (count !== this.phase) {
      count++
      phaseLength.push(count)
      console.log(count)
    }
    this.propertyId = this.data.propertyId;
    this.tariffList = JSON.parse(sessionStorage.getItem('tariffs'));
    if (this.tariffList.length != 0) {
      this.waterList = this.sortList([...this.tariffList], true);
      this.elecList = this.sortList([...this.tariffList], false);
    }

    this.meterDetails.setValue({
      amps: this.data.meter.amps,
      utilityType: this.meterUtility.find(m => m.name === this.data.meter.utilityType),
      readingMethod: '',
      meterNo: '',
      commissionDate: '',
      noOfPhotos: '',
      commissionReading: 0,
      commissionReading2: 0,
      commissionReading3: 0,
      commissionReading4: 0,
      // meterType: this.data.meter.meterType ? this.data.meter.meterType : '', 
      tariff: this.data.meter.tariffIds,
      isManual: '',
      captureType: ''
    })

      if(this.data.meter.utilityType === "WATER" || this.data.meter.utilityType === "ELEC_1P" || this.data.meter.utilityType === "ELEC_3P_1") {
        this.utilityTypeNumber = 1;
      }else if(this.data.meter.utilityType === "ELEC_3P_KVA" || this.data.meter.utilityType === "ELEC_1P_KVA"){
        this.utilityTypeNumber = 2;
      } else if (this.data.meter.utilityType === "ELEC_3P_TOU") {
        this.utilityTypeNumber = 4;
      } else {
        this.utilityTypeNumber = 3;
      }
  //  = this.meterUtility.find(m => m.name === this.data.meter.utilityType).image;

    console.log(this.utilityTypeNumber)
    this.oldMeterId = this.data.meter.id;
    this.meterChange();
  }
  closeDialog(isDecommisioned) {
    this.dialogRef.close(isDecommisioned);
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  checkIfNumberExists() {
    if (this.meterDetails.value.utilityType && this.meterDetails.value.meterNo) {
      clearTimeout(this.timeout);
      this.meterNoExists = true;
      this.checkExists = true;
      this.timeout = setTimeout(() => {
        from(this.meterManagementService.checkIfMeterNumberExists(this.propertyId, this.meterDetails.value.utilityType.name, this.meterDetails.value.meterNo)).subscribe((res: any) => {
          this.meterNoExists = res.data.checkIfMeterNumberExists.meterNoExists;
          this.checkExists = false;
        })
      }, 1000)
    } else {
      this.meterNoExists = false;
      this.checkExists = false;
    }
  }

  checkCaptureType() {
    const utilityType = this.meterDetails.value.utilityType;
    const readingMethod = this.meterDetails.value.readingMethod;

    if (readingMethod === 'Manual') {
      this.meterDetails.controls['captureType'].disable();
      this.meterDetails.controls['captureType'].setValue('MANUAL');
    } else {
      this.meterDetails.controls['captureType'].enable();
    }

  }

  meterChange() {
    if (this.meterDetails.get('utilityType').value.name === 'WATER') {
      this.showWater = true;
    } else {
      this.showWater = false;
    }

    const meterUtility = this.meterDetails.value.utilityType;
    this.meterDetails.patchValue({
      noOfPhotos: meterUtility.image
    })
    if (this.tariffList.length != 0) {
      this.checkUtilityType();
    }

    if (meterUtility.name === 'ELEC_1P') {
      this.displayCommission2 = false;
      this.displayCommission3 = false;
      this.displayCommission4 = false;
      this.meterDetails.patchValue({
        commissionReading2: '',
        commissionReading3: '',
        commissionReading4: ''
      });
    } else if (meterUtility.name === 'ELEC_1P_KVA') {
      this.displayCommission2 = true;
      this.displayCommission3 = false;
      this.displayCommission4 = false;
      this.meterDetails.patchValue({
        commissionReading3: '',
        commissionReading4: ''
      });
    } else if (meterUtility.name === 'ELEC_3P_1') {
      this.displayCommission2 = false;
      this.displayCommission3 = false;
      this.displayCommission4 = false;
    } else if (meterUtility.name === 'ELEC_3P_3') {
      this.displayCommission2 = true;
      this.displayCommission3 = true;
      this.displayCommission4 = false;
      this.meterDetails.patchValue({
        commissionReading4: ''
      });
    } else if (meterUtility.name === 'ELEC_3P_KVA') {
      this.displayCommission2 = true;
      this.displayCommission3 = false;
      this.displayCommission4 = false;
      this.meterDetails.patchValue({
        commissionReading3: '',
        commissionReading4: ''
      });
    } else if (meterUtility.name === 'ELEC_3P_TOU') {
      this.displayCommission2 = true;
      this.displayCommission3 = true;
      this.displayCommission4 = true;
    } else if (meterUtility.name === 'WATER') {
      this.displayCommission2 = false;
      this.displayCommission3 = false;
      this.displayCommission4 = false;
      this.meterDetails.patchValue({
        commissionReading2: '',
        commissionReading3: '',
        commissionReading4: ''
      });
    }

    this.checkIfNumberExists();

    this.checkCaptureType();
  }

  sortList(tariffList, isWater) {
    let tariffType = []
    if (isWater) {
      tariffType = ['Water', 'Sanitation'];
    } else {
      tariffType = ['Electricity']
    }
    return tariffList.map(t => {
      if (t.tariffList && t.tariffList.length > 0) {
        return t.tariffList.filter(tariff => tariff.utilityType && tariffType.includes(tariff.utilityType))
      }
    });
  }

  checkUtilityType() {
    let meterUtility = this.meterDetails.value.utilityType.name;
    if (meterUtility && meterUtility === 'WATER') {
      this.meterDetails.setControl('amps', new UntypedFormControl(0));
      this.tariffShow = true;
      for (let i = 0; i < this.tariffList.length; i++) {
        this.tariffList[i].tariffList = this.waterList[i];
      }
    } else if (!meterUtility || meterUtility === null) {
      this.tariffShow = false;
    } else {
      this.tariffShow = true;
      const amps = this.meterDetails.value.amps;
      this.meterDetails.setControl('amps', new UntypedFormControl(amps, Validators.min(1)));
      for (let i = 0; i < this.tariffList.length; i++) {
        this.tariffList[i].tariffList = this.elecList[i];
      }
    }
  }

  saveMeter() {
    let commissionReadings = [];
    this.meter = this.meterDetails.value;
    this.meter.captureType = this.meterDetails.controls['captureType'].value;
    this.meter.decommissionReason = this.decomissionGroup.value.decommissionReason;

    if (this.meter.readingMethod === "Manual") {
      this.meter.isManual = "true";
    } else {
      this.meter.isManual = "false";
    }

    if (this.meter.commissionReading === "" || this.meter.commissionReading === undefined || this.meter.commissionReading === null) {
      commissionReadings.push({
        'value': 0,
        'sequence': "1"
      });
    } else {
      commissionReadings.push({
        'value': parseFloat(this.meter.commissionReading),
        'sequence': "1"
      });
    }

    if (this.displayCommission2) {
      commissionReadings.push({
        'value': this.meter.commissionReading2 ? parseFloat(this.meter.commissionReading2) : 0,
        'sequence': "2"
      });
    }

    if (this.displayCommission3) {
      commissionReadings.push({
        'value': this.meter.commissionReading3 ? parseFloat(this.meter.commissionReading3) : 0,
        'sequence': "3"
      });
    }

    if (this.displayCommission4) {
      commissionReadings.push({
        'value': this.meter.commissionReading4 ? parseFloat(this.meter.commissionReading4) : 0,
        'sequence': "4"
      });
    }

    this.meter.commissionReadings = commissionReadings;

    if (this.meter.isManual === "" || this.meter.isManual === undefined || this.meter.isManual === null) { this.meter.isManual = true };

    if (this.meter.commissionDate) {
      this.meter.commissionDate = new Date(this.meter.commissionDate).toISOString();
    }
    this.meter.meterReadingType = this.meter.utilityType.name.includes('TOU') ? 'UTILIZATION' : 'READING';
    this.decomissionAndCreateMeter();
  }

  decomissionAndCreateMeter() {
    this.meter.decommissionReason = this.decomissionGroup.value.decommissionReason;

    from(this.meterManagementService.decommissionAndCreateMeterNode(this.meter, this.propertyId, this.oldMeterId)).subscribe((res: any) => {
      this.checkDecommission(res)
    }, (error) => {
      this.showError(error)
    })
  }

  showError(error) {
    this.loadingservice.closeBlockingLoader();
    message_types.error.displaySupport = true;
    message_types.error.messageBody = 'Failed to decommision meter';
    this.userMessagesService.openMessage(message_types.error);
  }

  checkDecommission(res) {
    this.closeDialog(true);
    this.loadingservice.closeBlockingLoader();
    message_types.success.messageBody = 'Successfully decommissioned meter';
    this.userMessagesService.openMessage(message_types.success);
    // if (res.data.decommissionMeterNode.isSuccessful) {
      
    //   this.loadingservice.closeBlockingLoader();
    //   message_types.success.messageBody = 'Successfully decommisioned meter';
    //   this.userMessagesService.openMessage(message_types.success);
    // } else {
    //   if (res.data.decommissionMeterNode.errorMessage) {
    //     this.loadingservice.closeBlockingLoader();
    //     message_types.error.displaySupport = true;
    //     message_types.error.messageBody = res.data.decommissionMeterNode.errorMessage;
    //     this.userMessagesService.openMessage(message_types.error);
    //   } else {
    //     this.loadingservice.closeBlockingLoader();
    //     message_types.error.displaySupport = true;
    //     message_types.error.messageBody = 'Failed to decommision meter';
    //     this.userMessagesService.openMessage(message_types.error);
    //   }
    // }
  }

  decomissionMeter() {
    const decomissionDetails = {
      decommissionReason: this.decomissionGroup.value.decommissionReason
    }

    from(this.meterManagementService.decommissionMeterNode(decomissionDetails, this.oldMeterId, this.propertyId)).subscribe((res: any) => {
      this.checkDecommission(res);
    }, (error) => {
      this.showError(error)
    })
  }

  submitReading() {
    this.loadingservice.openBlockingLoader("Decommissioning Meter");
    const readingDate = new Date().toISOString()

    const username = JSON.parse(localStorage.getItem('email'));

    this.selectedMeter = {
      meterId: this.oldMeterId,
      propertyId: this.propertyId,
      readingBy: username,
      readingTS: readingDate,
      comment: '',
      readingValueList: [
        {
          sequence: 1,
          value: this.decomissionGroup.value.decommissionReading,
        },
      ],
    };

    if (this.utilityTypeNumber > 3) {
      this.selectedMeter.readingValueList = [
        {
          sequence: 1,
          value: this.decomissionGroup.value.decommissionReading,
        },
        {
          sequence: 2,
          value: this.decomissionGroup.value.decommissionReading2,
        },
        {
          sequence: 3,
          value: this.decomissionGroup.value.decommissionReading3,
        },
        {
          sequence: 4,
          value: this.decomissionGroup.value.decommissionReading4,
        },
      ];
    } else if (this.utilityTypeNumber > 2) {
      this.selectedMeter.readingValueList = [
        {
          sequence: 1,
          value: this.decomissionGroup.value.decommissionReading,
        },
        {
          sequence: 2,
          value: this.decomissionGroup.value.decommissionReading2,
        },
        {
          sequence: 3,
          value: this.decomissionGroup.value.decommissionReading3,
        }
      ];
    } else if (this.utilityTypeNumber > 1) {
      this.selectedMeter.readingValueList = [
        {
          sequence: 1,
          value: this.decomissionGroup.value.decommissionReading,
        },
        {
          sequence: 2,
          value: this.decomissionGroup.value.decommissionReading2,
        }
      ];
    }




    this.selectedMeter.readingType = "PERIOD_READING";
    console.log(this.selectedMeter);

    from(this.shopsService.captureNewReading(this.selectedMeter, true)).subscribe(
      (res: any) => {
        console.log(res)
        setTimeout(() => {
          this.decomissionMeter();
        }, 2500);
        
        // if (this.data.addMeter) {
        //   this.saveMeter();
        // } else {
      
        // }
       // this.decomissionMeter()
      },
      (error) => {
        message_types.error.messageBody = 'Failed to capture new reading';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      }
    );
  }
}
