<div *ngIf="utilizationList.length === 0" fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;" >
    <div fxLayout="row" fxFlex="100" style="padding-bottom: 2em;" >
        <span fxFlex="25" style="font-weight: bold;"></span>
        <span fxFlex="25" style="font-weight: bold;">Previous</span>
        <span fxFlex="25" style="font-weight: bold;">Current</span>
        <span fxFlex="25" style="font-weight: bold;" *ngIf="(meter.meterReadingType && meter.meterReadingType != 'UTILIZATION') || !meter.meterReadingType">Utilization</span>
    </div>

    <div *ngFor="let number of loopNumber">
        <span fxFlex="25" style="font-weight: bold;">
            <div *ngIf="utilityType.includes('TOU') && number < 3"> {{ touList[number] }}: </div>
            <div *ngIf="!utilityType.includes('TOU')"> {{meter.meterReadingType && meter.meterReadingType === 'UTILIZATION' ? 'Consumption ' + (number+1) : 'Reading ' + (number+1)}}:
            </div></span>
        <span fxFlex="25">{{selectedReading.prevReading.readingValueList[number].value}}</span>
        <span fxFlex="25">{{selectedReading.reading.readingValueList[number].value}}</span>
        <span fxFlex="25" *ngIf="(meter.meterReadingType && meter.meterReadingType != 'UTILIZATION') || !meter.meterReadingType">{{selectedReading.reading.readingValueList[number].value - selectedReading.prevReading.readingValueList[number].value}}</span>
    </div>
    <div *ngIf="kva && selectedReading.prevReading.readingValueList[loopNumber.length]">
        <span fxFlex="25" style="font-weight: bold;">KVA:</span>
        <span fxFlex="25">{{selectedReading.prevReading.readingValueList[loopNumber.length].value}}</span>
        <span fxFlex="25">{{selectedReading.reading.readingValueList[loopNumber.length].value}}</span>
    </div>
</div>

<div *ngIf="utilizationList.length > 0">
  <div *ngIf="!isMultiple" fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;" >
    <div fxLayout="row" fxFlex="100" style="padding-bottom: 2em;">
        <span fxFlex="25" style="font-weight: bold;"></span>
        <span fxFlex="25" style="font-weight: bold;">Previous</span>
        <span fxFlex="25" style="font-weight: bold;">Current</span>
        <span fxFlex="25" style="font-weight: bold;" *ngIf="(meter.meterReadingType && meter.meterReadingType != 'UTILIZATION') || !meter.meterReadingType">Utilization</span>
    </div>

    <div *ngFor="let number of loopNumber">
        <span fxFlex="25" style="font-weight: bold;">
            <div *ngIf="utilityType.includes('TOU') && number < 3"> {{ touList[number] }}: </div>
            <div *ngIf="!utilityType.includes('TOU')"> {{meter.meterReadingType && meter.meterReadingType === 'UTILIZATION' ? 'Consumption ' + (number+1) : 'Reading ' + (number+1)}}: </div>
        </span>
        <span fxFlex="25">{{selectedReading.prevReading.readingValueList[number].value}}</span>
        <span fxFlex="25">{{selectedReading.reading.readingValueList[number].value}}</span>
        <span fxFlex="25" *ngIf="(meter.meterReadingType && meter.meterReadingType != 'UTILIZATION') || !meter.meterReadingType">{{utilizationList[number].value}}</span>
    </div>
    <div *ngIf="kva">
        <span fxFlex="25" style="font-weight: bold;">KVA:</span>
        <span fxFlex="25">{{utility | kva: selectedReading.prevReading.readingValueList}}</span>
        <span fxFlex="25">{{utility | kva: selectedReading.reading.readingValueList}}</span>
    </div>
  </div>

  <div *ngIf="isMultiple" fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;" >
    <div fxLayout="row" fxFlex="100" style="padding-bottom: 2em;">
        <span fxFlex="15" style="font-weight: bold;"></span>
        <span fxFlex="21" style="font-weight: bold;">Previous</span>
        <span fxFlex="21" style="font-weight: bold;">Current</span>
        <span fxFlex="21" style="font-weight: bold;" *ngIf="(meter.meterReadingType && meter.meterReadingType != 'UTILIZATION') || !meter.meterReadingType">Meter Utilization</span>
        <span fxFlex="22" style="font-weight: bold;" *ngIf="(meter.meterReadingType && meter.meterReadingType != 'UTILIZATION') || !meter.meterReadingType">Tenant Utilization</span>
    </div>

    <div *ngFor="let number of loopNumber">
        <span fxFlex="21" style="font-weight: bold;">
            <div *ngIf="utilityType.includes('TOU') && number < 3"> {{ touList[number] }}: </div>
            <div *ngIf="!utilityType.includes('TOU')"> {{meter.meterReadingType && meter.meterReadingType === 'UTILIZATION' ? 'Consumption ' + (number+1) : 'Reading ' + (number+1)}}:
            </div></span>
        <span fxFlex="21">{{selectedReading.prevReading.readingValueList[number].value}}</span>
        <span fxFlex="21">{{selectedReading.reading.readingValueList[number].value}}</span>
        <span fxFlex="21" *ngIf="(meter.meterReadingType && meter.meterReadingType != 'UTILIZATION') || !meter.meterReadingType">{{selectedReading.reading.readingValueList[number].value - selectedReading.prevReading.readingValueList[number].value}}</span>
        <span fxFlex="21" *ngIf="(meter.meterReadingType && meter.meterReadingType != 'UTILIZATION') || !meter.meterReadingType">{{utilizationList[number].value}}</span>
    </div>
    <div *ngIf="kva">
        <span fxFlex="20" style="font-weight: bold;">KVA:</span>
        <span fxFlex="20">{{utility | kva: selectedReading.prevReading.readingValueList}}</span>
        <span fxFlex="20">{{utility | kva: selectedReading.reading.readingValueList}}</span>
    </div>
  </div>
</div>