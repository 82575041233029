<div fxLayout="row">
  <div>
    <button matTooltip="User options" mat-button class="user-button" matBadgePosition="before" matBadgeColor="warn"
      style="margin-top: 10px;" *ngIf="showUploadStatus">
      <div>
        <span>Upload Status: {{notifications}}</span>
        <mat-progress-bar mode="query" *ngIf="showLoader"></mat-progress-bar>
      </div>
    </button>
  </div>
  <div *ngIf="!showUploadStatus">
    <button mat-mini-fab [icon]="'notifications'" style="background-color: #384952;color: white;" (click)="gotoNotifications()" matBadge="{{notificationAmount}}" matBadgeColor="warn"><mat-icon style="color: white;">notifications</mat-icon></button>
  </div>
  <div *ngIf="closeUploadStatus">
    <mat-icon (click)="hideUploadStatus()" style="color: white;">close</mat-icon>
  </div>
</div>
