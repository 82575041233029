import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { TariffEditService } from './tariff-edit.service';
import { Router } from '@angular/router';
import { TariffConstraint, TariffWaterCharge } from './tariff-charge.model';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_DATE_FORMATS } from 'src/app/features/quality-control/event-history/event-history.component';
@Component({
  selector: 'app-add-tariff',
  templateUrl: './add-tariff.component.html',
  styleUrls: ['./add-tariff.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class AddTariffComponent implements OnInit, OnDestroy {

  @ViewChild('select') select: MatSelect;
  @ViewChild('waterSelect') waterSelect: MatSelect;
  tariffDetailForm = new UntypedFormGroup({
    validityDates: new UntypedFormControl(null, Validators.required),
    utilityType: new UntypedFormControl(null, Validators.required),
    tariffName: new UntypedFormControl(null, Validators.required),
    tariffDetail: new UntypedFormControl(null, Validators.required),
    tariffCategory: new UntypedFormControl(null, Validators.required),
    seasons: new UntypedFormArray([])
  });
  tariffConstraintForm = new UntypedFormGroup({});
  tabIndex: number = 0;
  supplierName: string;
  tariffTypes: string[] = ['Electricity', 'Water', 'Sanitation'];
  tariffValidityDates: any[] = [];
  tariffName: string;
  showMoreFields: boolean = false;
  isNewPeriodTariff: boolean = false;

  maxDemandWindow = {
    percentageOver: 0,
    type: '',
    units: 'Month',
    value: 0
  };
  tariffElecCharge: any = {
    minUnits: 0,
    surchargePercentage: 0,
    fixedCharge: {
      basicCharge: [],
      serviceCharge: [],
      adminCharge: [],
      capacityCharge: [],
      capacityAmpCharge: [],
      networkCapacityCharge: []
    },
    demandCharge: {
      networkDemandCharge: [],
      networkAccessCharge: [],
      excessNetworkCharge: [],
      reactiveEnergyCharge: [],
      subsidyCharge: [],
      demandEnergyCharge: [],
      transNetworkCharge: []
    },
    energyCharge: {
      activeCharge: [],
      networkSurcharge: [],
      ancillaryCharge: [],
      elecRuralCharge: [],
      affordabilityCharge: [],
      networkDemandCharge: [],
      touCharges: {
        lowSeason: {},
        highSeason: {}
      },
      distributionCharge: {
        lowSeason: {},
        highSeason: {}
      }
    }
  };

  isUpdate: boolean = false;

  waterRestrictions: {value: string, name: string}[] = [
    {
      value: 'level-0',
      name: 'Level 0'
    },
    {
      value: 'level-1',
      name: 'Level 1'
    },
    {
      value: 'level-2',
      name: 'Level 2'
    }
  ];

  tariffBoolObject: any = {
    'keyCustomer': false,
    'tou': false,
    'availableNewCustomers': false,
    'availablePrepaymentCustomers': false,
  };
  seasonNames: string[] = ['High', 'Low'];
  selectedWaterRestrictions: string[] = [];
  tariffWaterCharge: TariffWaterCharge = {
    blockCharges: [],
    basicCharge: 0,
    surchargePercentage: 0,
    variableCharge: 1,
    standardCharge: 0,
    demandMangementLevy: 0,
    demandManagementUnit: 'Day',
    waterConsCharge: 0,
    level0: 0,
    level1: 0,
    level2: 0,
    blockType: 'Step',
    finalBlock: 0,
    percentageQty: 0
  };
  tariffCategories: string[] = ['Commercial & Business', 'Industrial'];
  clearValidators: boolean = false;
  supplierId: string;
  selectedAllConstraints: boolean = false;
  selectedAllRestrictions: boolean = false;
  selectedConstraints: string[] = [];
  tariffConstraints: TariffConstraint[] = [];
  minDate: Date;
  maxDate: Date;
  updateTariff: any;
  validityStartDate: Date;
  validityEndDate: Date;
  periodTariffId: string;
  periodTariffName: string;
  hasTou: boolean;

  // closeMethod = () => {
  //   this.closeDialog()
  // }

  constructor(
    private tariffService: TariffsService,
    private loadingService: LoadingService,
    private userMessagesService: UserMessagesService,
    private tariffEditService: TariffEditService,
    private el: ElementRef,
    private router: Router) { }

  ngOnInit(): void {
    let currentDate = new Date();
    this.tariffEditService.setTariffChargeObjects();

    // let displayYear = currentDate.getMonth() < 3 ? currentDate.getFullYear() - 1 : currentDate.getFullYear()
    let displayYear = JSON.parse(localStorage.getItem('selectedTariffDate'));
    this.tariffValidityDates = [{
      display: `${displayYear}/04/01 - ${displayYear + 1}/03/31`,
      value: {
        startDate: new Date(displayYear, 3, 1),
        endDate: new Date(displayYear + 1, 2, 31, 23, 59, 59, 999),
      }
    }];

    // displayYear = currentDate.getMonth() < 6 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    this.tariffValidityDates.push({
      display: `${displayYear}/07/01 - ${displayYear + 1}/06/30`,
      value: {
        startDate: new Date(displayYear, 6, 1),
        endDate: new Date(displayYear + 1, 5, 30, 23, 59, 59, 999),
      }
    });

    console.log(this.tariffValidityDates)

    if(localStorage.getItem('tariffDetailRoute') != null) {
      this.updateTariff = JSON.parse(localStorage.getItem('updateTariff'));
      this.router.config[0].children.find(c => c.path === 'add-tariff').data = {
        breadcrumbStart: '/tariff-detail'
      }

      if(this.updateTariff) {
        console.log('Update Tariff', this.updateTariff)
        this.isNewPeriodTariff = this.updateTariff.isNewPeriodTariff;
        if(this.updateTariff.periodTariff) {
          this.periodTariffId = this.updateTariff.periodTariff.id;
          if(this.updateTariff.periodTariff.maxDemandWindow) {
            this.maxDemandWindow = this.updateTariff.periodTariff.maxDemandWindow;
          }
          this.isUpdate = true;
  
          let periodDate: string = this.updateTariff.periodTariff.startDate;
          let validityDateIndex = 0;

          if(periodDate.charAt(6) === '4') {
            validityDateIndex = 0;
          } else {
            validityDateIndex = 1;
          }
          this.validityStartDate = this.tariffValidityDates[validityDateIndex].value.startDate;
          this.validityEndDate = this.tariffValidityDates[validityDateIndex].value.endDate;

          this.periodTariffName = `${this.validityStartDate.getFullYear()} / ${this.validityEndDate.getFullYear()}`;
          this.tariffDetailForm.setValue({
            validityDates: this.tariffValidityDates[validityDateIndex].value,
            utilityType: this.updateTariff.tariffConstraint.utilityType,
            tariffName: this.updateTariff.tariffType.name,
            tariffDetail: this.updateTariff.tariffType.description,
            tariffCategory: this.updateTariff.tariffType.tariffCategory,
            seasons: []
          });
          
        } else {
          this.tariffDetailForm.setValue({
            validityDates: '',
            utilityType: this.updateTariff.tariffConstraint.utilityType,
            tariffName: this.updateTariff.tariffType.name,
            tariffDetail: this.updateTariff.tariffType.description,
            tariffCategory: this.updateTariff.tariffType.tariffCategory,
            seasons: []
          });
        }
        

        

        this.setConstraintFields();

        this.tariffDetailForm.get('utilityType').disable();

        this.tariffName = this.updateTariff.tariffType.name;

        

        if(this.updateTariff.tariffType.seasons && ((this.updateTariff.tariffType.seasons.length === 1 && this.updateTariff.tariffType.seasons[0].name !== 'All') || this.updateTariff.tariffType.seasons.length > 1)) {
          let seasonNames: {name: string, index: number}[] = [], count: number = 0;
          for(let season of this.updateTariff.tariffType.seasons) {
            let sDate = seasonNames.findIndex(s => s.name === season.name);
            let endDate = new Date(season.endDate);
            if(sDate > -1) {
              this.getSeasonDates(seasonNames[sDate].index).push(new UntypedFormGroup({
                startDate: new UntypedFormControl(season.startDate, Validators.required),
                endDate: new UntypedFormControl(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1, 23, 59, 59, 999), Validators.required)
              }));
            } else {
              this.addSeason(season);
              seasonNames.push({name: season.name, index: count});
              this.getSeasonDates(count).push(new UntypedFormGroup({
                startDate: new UntypedFormControl(season.startDate, Validators.required),
                endDate: new UntypedFormControl(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1, 23, 59, 59, 999), Validators.required)
              }));
              count++;
            }
          }
        }

        let tariffConstraint = this.updateTariff.tariffConstraint;
        
        for(let constraint in tariffConstraint) {
          let selectedTariffVal = this.tariffConstraints.find(c => c.value === constraint);
          if(selectedTariffVal) {
            if((tariffConstraint[constraint] && typeof(tariffConstraint[constraint]) !== 'object') || (tariffConstraint[constraint] && (tariffConstraint[constraint].min || tariffConstraint[constraint].max))) {
              this.selectedConstraints.push(selectedTariffVal.value);
              if(!selectedTariffVal.isBoole) {
                setTimeout(() => {
                  this.tariffConstraintForm.get(selectedTariffVal.value).setValue(tariffConstraint[constraint]);
                }, 100)
              }
            }
          }
        }

        if(this.updateTariff.tariffConstraint.utilityType === 'Electricity') {
          this.tariffElecCharge = this.tariffEditService.setupUpdateCharges(this.updateTariff.tariffConstraint.utilityType, this.updateTariff.tariffCharges);
          if((this.tariffElecCharge.energyCharge.touCharges.lowSeason && this.tariffElecCharge.energyCharge.touCharges.lowSeason.peak) || this.tariffElecCharge.energyCharge.distributionCharge.highSeason.peak) {
            this.selectedConstraints.push('tou');
          }
        } else {
          this.tariffWaterCharge = this.tariffEditService.setupUpdateCharges(this.updateTariff.tariffConstraint.utilityType, this.updateTariff.tariffCharges);
          let demandManagementLevyCharge = this.updateTariff.tariffCharges.find(charge => charge.display.includes('Demand Management Levy'));
          if(demandManagementLevyCharge && demandManagementLevyCharge.units) {
            this.tariffWaterCharge.demandManagementUnit = demandManagementLevyCharge.units;
          }

          if(this.updateTariff.tariffCharges.find(charge => charge.display.includes('Standard')))  {
            this.tariffWaterCharge.variableCharge = 1;
          }
        }
        this.clearValidators = false;
      } else {
        this.tariffDetailForm.get('utilityType').enable();
      }
    }
    const supplier: any = JSON.parse(localStorage.getItem('supplier'));
    this.supplierName = supplier.name;
    this.supplierId = supplier.id;
  }

  ngOnDestroy(): void {
    localStorage.removeItem('tariffDetailRoute');
  }

  setSeasonDate(validityDate) {
    this.validityStartDate = new Date(validityDate.value.startDate);
    this.validityEndDate = new Date(validityDate.value.endDate);

    this.periodTariffName = `${this.validityStartDate.getFullYear()} / ${this.validityEndDate.getFullYear()}`;

    const tariffSeasons = this.getTariffSeasons().value;

    if(tariffSeasons && tariffSeasons.length > 0) {
      for(let i = 0; i < tariffSeasons.length; i++) {
        if(tariffSeasons[i] && tariffSeasons[i].name) {
          this.setSeasonalDate(tariffSeasons[i].name, i);
        }
      }
    }
  }

  setSeasonalDate(event: MatSelectChange, index: number) {
    let name = event.value ? event.value : event;

    this.getSeasonDates(index).clear();
    if(name === 'High') {
      if(this.validityStartDate.getMonth() === 3) {
        this.getSeasonDates(index).push(new UntypedFormGroup({
          startDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear(), 5, 1), Validators.required),
          endDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear(), 7, 31, 23, 59, 59, 999), Validators.required)
        }))
      } else {
        this.getSeasonDates(index).push(new UntypedFormGroup({
          startDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear(), 6, 1), Validators.required),
          endDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear(), 7, 31, 23, 59, 59, 999), Validators.required)
        }));

        this.getSeasonDates(index).push(new UntypedFormGroup({
          startDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear() + 1, 5, 1), Validators.required),
          endDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear() + 1, 5, 30, 23, 59, 59, 999), Validators.required)
        }));
      }
    } else {
      if(this.validityStartDate.getMonth() === 6) {
        this.getSeasonDates(index).push(new UntypedFormGroup({
          startDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear(), 8, 1), Validators.required),
          endDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear() + 1, 4, 31, 23, 59, 59, 999), Validators.required)
        }))
      } else {
        this.getSeasonDates(index).push(new UntypedFormGroup({
          startDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear(), 3, 1), Validators.required),
          endDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear(), 4, 31, 23, 59, 59, 999), Validators.required)
        }));

        this.getSeasonDates(index).push(new UntypedFormGroup({
          startDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear(), 8, 1), Validators.required),
          endDate: new UntypedFormControl(new Date(this.validityStartDate.getFullYear() + 1, 2, 31, 23, 59, 59, 999), Validators.required)
        }));
      }
    }
  }

  addSeason(season?: {name: string, startDate: Date, endDate: Date}) {
    this.getTariffSeasons().push(new UntypedFormGroup({
      name: new UntypedFormControl(season && season.name ? season.name : null, Validators.required),
      seasonDates: new UntypedFormArray([])
    }));
  }

  getTariffSeasons(): UntypedFormArray {
    return <UntypedFormArray>this.tariffDetailForm.get('seasons');
  }

  getSeasonDates(index: number): UntypedFormArray {
    return <UntypedFormArray>this.getTariffSeasons().at(index).get('seasonDates');
  }

  addSeasonDates(index: number) {
    this.getSeasonDates(index).push(new UntypedFormGroup({
      startDate: new UntypedFormControl(null, Validators.required),
      endDate: new UntypedFormControl(null, Validators.required)
    }))
  }

  removeSeasonDate(dateIndex: number, index: number) {
    this.getSeasonDates(dateIndex).removeAt(index);
  }

  removeSeason(index: number) {
    this.getTariffSeasons().removeAt(index);
  }

  setConstraintFields() {
    this.clearValidators = true;
    if(this.tariffDetailForm.get('utilityType') && this.tariffDetailForm.get('utilityType').value !== 'Electricity') {
      (<UntypedFormArray>this.tariffDetailForm.get('seasons')).clear();

      if(this.tariffDetailForm.get('utilityType').value === 'Water') {
        this.tariffWaterCharge.percentageQty = 0;
      }

      this.tariffConstraints = [
        {
          name: 'Unavailable New Customers',
          value: 'availableNewCustomers',
          isBoole: true
        },
        {
          name: 'Unavailable Prepayment Customers',
          value: 'availablePrepaymentCustomers',
          isBoole: true
        },
        {
          name: 'Connection Pipe Diameter',
          value: 'pipeSizeDiameter',
          minMax: false
        },
        {
          name: 'Key Customer',
          value: 'keyCustomer',
          isBoole: true
        },
        {
          name: 'Transmission Zone (Max km)',
          value: 'transmissionZone',
          minMax: true
        },
        {
          name: 'Connection Point',
          value: 'connectionPoint',
          minMax: false
        },
      ];
    } else {
      this.tariffConstraints = [
        {
          name: 'Unavailable New Customers',
          value: 'availableNewCustomers',
          isBoole: true
        },
        {
          name: 'Unavailable Prepayment Customers',
          value: 'availablePrepaymentCustomers',
          isBoole: true
        },
        {
          name: 'Connection Point',
          value: 'connectionPoint',
          minMax: false
        },
        {
          name: 'Key Customer',
          value: 'keyCustomer',
          isBoole: true
        },
        {
          name: 'Min & Max Circuit Breaker (A)',
          value: 'circuitBreaker',
          minMax: true
        },
        {
          name: 'Min & Max Demand (kVA) Rolling 12M',
          value: 'demand',
          minMax: true
        },
        {
          name: 'Min & Max Energy Utilization',
          value: 'utilisation',
          minMax: true,
          hasDesc: true
        },
        {
          name: 'Min & Max Supply (V)',
          value: 'supplyVoltage',
          minMax: true
        },
        {
          name: 'Phases',
          value: 'phases',
          minMax: false
        },
        {
          name: 'Transmission Zone (Max km)',
          value: 'transmissionZone',
          minMax: true
        },
        {
          name: 'TOU',
          value: 'tou',
          isBoole: true
        },
      ];
    }
  }

  setConstraints(constraint: TariffConstraint) {
    const selectedName = constraint;

    if(selectedName.value === 'all') {
      this.selectAll(false);
      this.tariffConstraintForm.clearValidators();
    } else {
      if(!selectedName.isBoole) {
        if(selectedName.minMax) {
          if(!this.tariffConstraintForm.get(selectedName.value)) {
            let minMaxGroup: UntypedFormGroup = new UntypedFormGroup({
              min: new UntypedFormControl(null),
              max: new UntypedFormControl(null)
            })

            if(selectedName.hasDesc) {
              minMaxGroup.addControl('description', new UntypedFormControl(null))
            }
            this.tariffConstraintForm.addControl(selectedName.value, minMaxGroup);
          } else {
            this.tariffConstraintForm.removeControl(selectedName.value);
          }
        } else {
          if(!this.tariffConstraintForm.get(selectedName.value)) {
            this.tariffConstraintForm.addControl(selectedName.value, new UntypedFormControl(null))
          } else {
            this.tariffConstraintForm.removeControl(selectedName.value);
          }
        } 
      } else {
        this.tariffBoolObject[selectedName.value] = !this.tariffBoolObject[selectedName.value];

        if(constraint.name === 'TOU') {
          this.hasTou = this.tariffBoolObject[selectedName.value];
        }
      }
    }
  }

  selectChange(isWaterRestriction) {
    let newStatus = true;
    if(isWaterRestriction) {
      this.waterSelect.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
        }
      });
      this.selectedAllRestrictions = newStatus;
    } else {
      this.select.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus = false;
        }
      });
      this.selectedAllConstraints = newStatus;
    }
    
  }

  selectAll(isWaterRestriction) {
    if(isWaterRestriction) {
      if(this.selectedAllRestrictions) {
        this.waterSelect.options.forEach((item: MatOption) => item.select());
      } else {
        this.waterSelect.options.forEach((item: MatOption) => item.deselect());
      }
    } else {
      if(this.selectedAllConstraints) {
        this.select.options.forEach((item: MatOption) => item.select());
      } else {
        this.select.options.forEach((item: MatOption) => item.deselect());
      }
    }
  }

  changeTab(event: MatTabChangeEvent) {
    this.tabIndex = event.index;

    if(this.tabIndex === 1 && this.clearValidators) {
      for(const key in this.tariffConstraintForm.controls) {
        this.tariffConstraintForm.removeControl(key);
      }
      this.clearValidators = false;
    }
  }

  // closeDialog(tariffDetails?: any) {
  //   this.dialogRef.close(tariffDetails);
  // }

  checkControls() {
    if(this.tariffDetailForm.invalid) {
      this.tabIndex = 0;
      this.tariffDetailForm.markAllAsTouched();
    } else {
      this.saveTariff();
    }

    setTimeout(() => {
      const invalidControl = this.el.nativeElement.querySelector('form .ng-invalid');
      if(invalidControl) {
        invalidControl.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } ,100)
  }

  saveTariff() {
    let periodTariffObject = {...this.tariffDetailForm.value,
      startDate: this.tariffDetailForm.value.validityDates.startDate,
      endDate: this.tariffDetailForm.value.validityDates.endDate,
    };

    const startDate = new Date(periodTariffObject.startDate);
    const endDate = new Date(periodTariffObject.endDate);

    let seasons = [];

    if(this.tariffDetailForm.get('seasons').value.length > 0) {
      let startDate, endDate;
      for(let season of this.tariffDetailForm.get('seasons').value) {
        for(let seasonDate of season.seasonDates) {
          startDate = new Date(seasonDate.startDate);
          endDate = new Date(seasonDate.endDate);
          seasons.push({
            name: season.name,
            startDate: startDate.getFullYear() + '-' + (startDate.getMonth() + 1 < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)) + '-' + (startDate.getDate() < 10 ? '0' : '') + startDate.getDate() + 'T00:00:00.000Z',
            endDate: endDate.getFullYear() + '-' + (endDate.getMonth() + 1 < 10 ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)) + '-' + (endDate.getDate() < 10 ? '0' : '') + endDate.getDate() + 'T23:59:59.999Z'
          })
        }
      }
    } else {
      seasons = [
        {
          name: 'All',
          startDate: startDate.getFullYear() + '-' + (startDate.getMonth() + 1 < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)) + '-' + (startDate.getDate() < 10 ? '0' : '') + startDate.getDate() + 'T00:00:00.000Z',
          endDate: endDate.getFullYear() + '-' + (endDate.getMonth() + 1 < 10 ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)) + '-' + (endDate.getDate() < 10 ? '0' : '') + endDate.getDate() + 'T23:59:59.999Z'
        }
      ]
    }

    periodTariffObject = {...periodTariffObject, 
      seasons: seasons,
      status: 'Active'
    }

    periodTariffObject.startDate = startDate.getFullYear() + '-' + (startDate.getMonth() + 1 < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1)) + '-' + (startDate.getDate() < 10 ? '0' : '') + startDate.getDate() + 'T00:00:00.000Z';
    periodTariffObject.endDate = endDate.getFullYear() + '-' + (endDate.getMonth() + 1 < 10 ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1)) + '-' + (endDate.getDate() < 10 ? '0' : '') + endDate.getDate() + 'T23:59:59.999Z';
    const utilityType: string = this.tariffDetailForm.get('utilityType').value;

    if(utilityType === 'Electricity') {
      if(this.hasTou) {
        this.tariffElecCharge.energyCharge.activeCharge = [];
      } else {
        this.tariffElecCharge.energyCharge.touCharges = {
          lowSeason: {},
          highSeason: {}
        };
        this.tariffElecCharge.energyCharge.distributionCharge = {
          lowSeason: {},
          highSeason: {}
        };
      }
    }

    let tariffCharge = this.tariffEditService.setupTariffCharges(utilityType, utilityType === 'Electricity' ? this.tariffElecCharge : this.tariffWaterCharge, this.tariffDetailForm.get('seasons').value, this.hasTou);

    let constraintObject = {...this.tariffConstraintForm.value, ...this.tariffBoolObject,
      'utilityType': periodTariffObject.utilityType
    }

    for(let constraint of this.tariffConstraints) {
      if(constraintObject[constraint.value]) {
        if(constraintObject[constraint.value].min) {
          constraintObject[constraint.value].min = +constraintObject[constraint.value].min;
          constraintObject[constraint.value].max = +constraintObject[constraint.value].max;
        } else if(constraint.value === 'phases') {
          constraintObject[constraint.value] = +constraintObject[constraint.value];
        }
      }
    }

    if(this.maxDemandWindow.percentageOver) {
      if(this.maxDemandWindow.percentageOver >= 1) {
        this.maxDemandWindow.percentageOver = this.maxDemandWindow.percentageOver / 100;
      }
    }

    console.log('Tariff Charge', tariffCharge);
    console.log('Period Object', periodTariffObject);
    console.log('Max Demand Window', this.maxDemandWindow);
    console.log('Period Tariff Name', this.periodTariffName)

    this.loadingService.openBlockingLoader((this.isUpdate && !this.isNewPeriodTariff ? 'Updating' : 'Creating New') + ' Tariff');
    if(this.isUpdate  && !this.isNewPeriodTariff) {
      let updateCharges = [], newCharges = [];
      for(let tariff of tariffCharge) {
        let updateTariff = this.updateTariff.tariffCharges.find(t => {
          return t.type === tariff.type && tariff.display.includes(t.display)
        })

        if(updateTariff && updateTariff.id) {
          updateCharges.push({
            tariffChargeId: updateTariff.id,
            periodTariffId: this.periodTariffId,
            updateTariffChargeInput: tariff
          })
        } else {
          newCharges.push(tariff)
        }
        
      }
      let deleteCharges = [];

      for(let charge of this.updateTariff.tariffCharges) {
        let index = updateCharges.findIndex(t => t.tariffChargeId === charge.id) 

        if(index === -1) {
          deleteCharges.push(charge.id)
        }
      }

      let tariffType = {
        ...periodTariffObject,
        id: this.updateTariff.tariffType.id
      };

      periodTariffObject = {
        ...periodTariffObject,
        id: this.updateTariff.periodTariff.id
      }

      if(deleteCharges.length > 0) {
        from(this.tariffService.deleteTariffCharges(deleteCharges, periodTariffObject.id)).subscribe();
      }

      from(this.tariffService.updateAllOfTariffType(tariffType, constraintObject, periodTariffObject, this.supplierId, this.maxDemandWindow, updateCharges, this.periodTariffName)).subscribe((res:any) => {
        this.loadingService.closeBlockingLoader();

        if(newCharges.length > 0) {
          this.createTariffCharge(periodTariffObject.id, newCharges, tariffType.id);
        } else {
          localStorage.setItem('tariffTypeId', JSON.stringify(tariffType.id));
          message_types.success.messageBody = 'Successfully updated tariff';
          this.userMessagesService.openMessage(message_types.success);
          this.router.navigate(['tariff-detail', {tId: tariffType.id, sId: this.supplierId, sName: this.supplierName}]);
        }
      }, (error) => {
        this.loadingService.closeBlockingLoader();
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to update period tariff';
        this.userMessagesService.openMessage(message_types.error);
      });
    } else {
      if(this.isNewPeriodTariff) {
        periodTariffObject = {
          ...periodTariffObject,
          id: this.updateTariff.tariffType.id
        }
        from(this.tariffService.createPeriodTariff(periodTariffObject.id, periodTariffObject, this.maxDemandWindow, this.periodTariffName)).subscribe((res:any) => {
          const periodTariffId = res.data.createPeriodTariff.periodTariff.id;
          this.createTariffCharge(periodTariffId, tariffCharge, periodTariffObject.id, periodTariffObject.tariffName);          
  
        }, (error) => {
          this.loadingService.closeBlockingLoader();
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to create period tariff';
          this.userMessagesService.openMessage(message_types.error);
        });
  
      } else {
        from(this.tariffService.createTariffType(periodTariffObject, this.supplierId)).subscribe((res:any) => {
          const tariffTypeId = res.data.createTariffType.tariffType.id;
          periodTariffObject = {...periodTariffObject, 
            'id': tariffTypeId,
            'description': periodTariffObject.tariffDetail
          };
    
          localStorage.setItem('tariffType', JSON.stringify(periodTariffObject));
          localStorage.setItem('tariffTypeId', JSON.stringify(tariffTypeId));
    
          from(this.tariffService.createTariffConstraintAndPeriod(this.supplierId, tariffTypeId, constraintObject, periodTariffObject, this.maxDemandWindow, this.periodTariffName)).subscribe((res:any) => {
            const periodTariffId = res.data.createPeriodTariff.periodTariff.id;
            this.createTariffCharge(periodTariffId, tariffCharge, tariffTypeId, periodTariffObject.tariffName);          
    
          }, (error) => {
            this.loadingService.closeBlockingLoader();
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to create period tariff';
            this.userMessagesService.openMessage(message_types.error);
          });
    
        }, (error) => {
          this.loadingService.closeBlockingLoader();
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to create tariff type';
          this.userMessagesService.openMessage(message_types.error);
        });
      }
    }
  }

  createTariffCharge(periodTariffId, tariffCharges, tariffTypeId?, tariffName?) {
    from(this.tariffService.createTariffCharges(periodTariffId, tariffCharges)).subscribe((res:any) => {
      if(!this.isUpdate) {
        this.loadingService.closeBlockingLoader();
        message_types.success.messageBody = 'Successfully created new tariff';
        this.userMessagesService.openMessage(message_types.success);
        this.router.navigate(['tariff-detail', {tId: tariffTypeId, sId: this.supplierId, sName: this.supplierName, tName: tariffName}]);
      } else {
        localStorage.setItem('tariffTypeId', JSON.stringify(tariffTypeId));
        message_types.success.messageBody = 'Successfully updated tariff';
        this.userMessagesService.openMessage(message_types.success);
        this.router.navigate(['tariff-detail', {tId: tariffTypeId, sId: this.supplierId, sName: this.supplierName}]);
      }
    }, (error) => {
      this.loadingService.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to create tariff charges';
      this.userMessagesService.openMessage(message_types.error);
    });
  }

}
