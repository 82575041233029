import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import {from} from 'rxjs';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { TariffDetailsService } from './tariff-details.service';

@Component({
  selector: 'app-tariff-details',
  templateUrl: './tariff-details.component.html',
  styleUrls: ['./tariff-details.component.scss']
})
export class TariffDetailsComponent implements OnInit {

  tariffTypeId: any;
  periodTariff: any;
  supplierName: string;
  tariffType: any
  noChargeName: string;
  tariffConstraints: any;
  fixedCharges: any[] = [];
  blockCharges: any[] = [];
  utilisationCharges: any[] = [];
  hasBlock: boolean = false;
  demandCharges: any[] = []
  selectedCharge: number = 0;
  tariffCharges: any[] = [];
  supplierId: string;
  hasStandard: boolean = false;
  seasonalDate: any[] = [];
  maxDemandWindow: {
    type?: string;
    percentageOver?: number;
    value?: number;
    units?: string
  }
  minUnits: number;
  noCharges: boolean = false;
  percentageQty: number;
  surcharge: number;
  seasonsList = [];
  selectedTariffDate: string = '';
  constraintArr: any[] = [];
  constraintName =  {
    circuitBreaker: 'Circuit Breaker (A)',
    connectionPoint: 'Connection Point',
    phases: 'Phases',
    supplyVoltage: 'Supply (V)',
    utilisation: 'Energy Utilization',
    demand: 'Demand (KVA) Rolling 12M',
    availableNewCustomers: 'Unavailable New Customers',
    availablePrepaymentCustomers: 'Unavailable Prepayment Customers',
    transmissionZone: 'Transmission Zone (km)',
    keyCustomer: 'Key Customer',
    pipeSizeDiameter: 'Connection Pipe Diameter (mm)'
  }
  touList: any[] = [];
  constraintCount: number = -1;

  primaryBtn: any = {
    text: 'Update Tariff',
    method: () => {
      this.goToUpdateTariff()
    }
  }

  constructor(private route: ActivatedRoute, 
    private tariffService: TariffsService, 
    private loadingservice: LoadingService, 
    private userMessagesService: UserMessagesService,
    private router: Router,
    private tariffDetailsService: TariffDetailsService) { }

  setTariffConstraints() {
    this.constraintArr = [];
    for(let con in this.tariffConstraints) {
      if(con !== 'tariffTypeId' && con !== 'utilityType') {
        if(this.tariffConstraints[con]) {
          if(typeof this.tariffConstraints[con] !== 'object') {
            this.constraintArr.push({
              name: this.constraintName[con],
              value: this.tariffConstraints[con]
            });
            this.constraintCount++;
          } else if(typeof this.tariffConstraints[con] === 'object') {
            let description = this.tariffConstraints[con].description ? ' ' + this.tariffConstraints[con].description : '';
            if(this.tariffConstraints[con].min) {
              this.constraintArr.push({
                name: 'Min ' + [this.constraintName[con]],
                value: this.tariffConstraints[con].min + description
              });
              this.constraintCount++;
            }
            
            if(this.tariffConstraints[con].max) {
              this.constraintArr.push({
                name: 'Max ' + [this.constraintName[con]],
                value: this.tariffConstraints[con].max + description
              });
              this.constraintCount++;
            }
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.selectedTariffDate = JSON.parse(localStorage.getItem('selectedTariffDate'));
    this.tariffTypeId = JSON.parse(localStorage.getItem('tariffTypeId'));
    this.supplierName = this.route.snapshot.paramMap.get("sName");
    this.supplierId = this.route.snapshot.paramMap.get("sId");
    this.loadingservice.openBlockingLoader('Retrieving Tariff Details', 'primary');

    from(this.tariffService.getTariffType(this.tariffTypeId)).subscribe((res: any) => {

      const periodTariffs = res.data.getPeriodTariffs.periodTariffs;
      this.periodTariff = periodTariffs.find(p => {
        console.log(new Date(p.startDate).getFullYear())
        console.log(Number(this.selectedTariffDate))
        return new Date(p.startDate).getFullYear() === Number(this.selectedTariffDate);
      })
      
      console.log('Period Tariff', this.periodTariff)
      this.tariffType = res.data.getTariffType.tariffType;
      this.tariffConstraints = res.data.getTariffType.tariffType.tariffConstraint;

      if(this.tariffConstraints && this.tariffConstraints.utilityType !== 'Electricity') {
        from(this.tariffService.getWaterPipeSize(this.tariffTypeId)).subscribe((res:any) => {
          let pipeSizeDiameter = res.data.getTariffType.tariffType.tariffConstraint;

          if(pipeSizeDiameter && pipeSizeDiameter.pipeSizeDiameter) {
            this.tariffConstraints.pipeSizeDiameter = pipeSizeDiameter.pipeSizeDiameter;
          }
          this.setTariffConstraints();
        });
      }
      this.setTariffConstraints();

      if(this.periodTariff) {

        if(this.periodTariff.seasons && this.periodTariff.seasons.length > 0) {
          let seasonStoreObject = {}, index = 0;
          for(let season of this.periodTariff.seasons) {
            if(seasonStoreObject[season.name] || (seasonStoreObject[season.name] === 0)) {
              this.seasonsList[seasonStoreObject[season.name]].push(season);
            } else {
              seasonStoreObject[season.name] = index;
              this.seasonsList.push([season]);
              index++;
            }
          }
        }
        this.maxDemandWindow = this.periodTariff.maxDemandWindow;
        from(this.tariffService.getTariffCharges(this.periodTariff.id)).subscribe((res:any) => {
          this.tariffCharges = res.data.getTariffChargesForPeriodTariff.tariffCharges;
          console.log(this.tariffCharges)
  
          let count = 0, arrCount = 0;
          for(let charge of this.tariffCharges) {
            if(charge.minUnits && !this.minUnits) {
              this.minUnits = charge.minUnits;
            }
            if(charge && charge.type) {
              if(charge.type === 'Fixed') {

                if(charge.display.includes('Management Levy') || (charge.display.includes('Basic Charge') && this.tariffConstraints.utilityType !== 'Electricity')) {
                  this.utilisationCharges.push(charge);
                } else {
                  this.fixedCharges.push(charge);
                }
              } else if(charge.type === 'Utilisation') {
                if(charge.display.includes('Standard')) {
                  this.hasStandard = true;
                }

                if(charge.display === 'Active Energy Charge' || charge.display.includes('Distribution Network Demand Charge')) {
                  this.touList.push(charge);
                } else {
                  this.utilisationCharges.push(charge);
                }

                if(charge.display.includes('Block')) {
                  count = arrCount;
                  this.hasBlock = true;
                }                
              } else {
                this.demandCharges.push(charge);
              }
              arrCount++;
              if(charge.surcharge) {
                this.surcharge = charge.surcharge;
              }

              console.log(this.surcharge)

              if(charge.percentageQty) {
                this.percentageQty = charge.percentageQty;
              }
            }
          }
          console.log(this.touList)
          console.log(this.utilisationCharges)

          if(this.touList.length > 0) {
            let pIndex = 0, sIndex = 0, oIndex = 0;

            for(let charge of this.touList) {
              for(let rates of charge.rates) {
                if(rates.tou && rates.tou.length > 0) {
                  sIndex = rates.tou.findIndex(t => t.display === 'Standard');
                  oIndex = rates.tou.findIndex(t => t.display === 'Off-Peak');
                  pIndex = rates.tou.findIndex(t => t.display === 'Peak'); 
  
                  let tou = [
                    rates.tou[sIndex],
                    rates.tou[oIndex],
                    rates.tou[pIndex],
                  ];
                  
                  rates.tou = tou;
                }
              }
            }
          }

          if(this.hasBlock) {
            this.blockCharges = this.utilisationCharges.splice(count, 1);
          }

          if(this.tariffCharges.length === 0) {
            this.noCharges = true;
          } else {
            this.noCharges = false;
            
            if((this.tariffConstraints.utilityType !== 'Electricity' && ((this.utilisationCharges.length > 0 && !this.hasStandard) || (this.utilisationCharges.length > 1 && this.hasStandard)))) {
              this.selectedCharge = 0;
            } else if((this.fixedCharges.length === 0 && this.demandCharges.length > 0) || (this.tariffConstraints.utilityType !== 'Electricity' && (this.surcharge || this.percentageQty))) {
              this.selectedCharge = 1;
            } else if((this.fixedCharges.length === 0 && this.demandCharges.length === 0 && (this.tariffConstraints.utilityType !== 'Electricity' && !this.surcharge && !this.percentageQty) && (this.utilisationCharges.length > 0 || this.hasStandard || this.touList.length > 0)) || this.hasBlock) {
              this.selectedCharge = 2;
            } 
          }

          if(this.fixedCharges.length > 0) {
            this.tariffDetailsService.sortCharges(this.fixedCharges, 'fixed');
          }

          if(this.demandCharges.length > 0) {
            this.tariffDetailsService.sortCharges(this.demandCharges, 'demand');
          }

          if(this.utilisationCharges.length > 0 && this.tariffConstraints.utilityType === 'Electricity') {
            this.tariffDetailsService.sortCharges(this.utilisationCharges, 'energy');
          }

          this.loadingservice.closeBlockingLoader();
        }, (error) => {
          this.loadingservice.closeBlockingLoader();
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to retrieve tariff details';
          this.userMessagesService.openMessage(message_types.error);          
        })
      } else {
        this.noCharges = true;
        this.loadingservice.closeBlockingLoader();
        message_types.info.displaySupport = true;
        message_types.info.messageHeader = 'No Tariff Charges Found'
        message_types.info.messageBody = 'Please add charges for current tariff type';
        this.userMessagesService.openMessage(message_types.info);
      }
      
    }, (error) => {
      this.loadingservice.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to retrieve tariff details';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

  goToUpdateTariff() {
    
    let isUpdate = this.periodTariff ? true : false;
    for(let charge of this.tariffCharges) {
      charge.periodTariffId = this.periodTariff.id;
    }

    let tariff = {};

    if(isUpdate) {
      tariff = {
        'tariffCharges': this.tariffCharges,
        'periodTariff': this.periodTariff,
        'tariffType': {
          ...this.tariffType,
          id: this.tariffTypeId,
          'seasons': this.periodTariff.seasons
        },
        'isNewPeriodTariff': false,
        'isUpdate': true,
        'supplierDetails': {
          'id': this.supplierId,
          'name': this.supplierName
        },
        'tariffConstraint': this.tariffConstraints
      }
    } else {
      tariff = {
        'tariffCharges': this.tariffCharges,
        'tariffType': {
          ...this.tariffType,
          id: this.tariffTypeId,
        },
        'selectedTariffDate': this.selectedTariffDate,
        'isNewPeriodTariff': true,
        'isUpdate': false,
        'supplierDetails': {
          'id': this.supplierId,
          'name': this.supplierName
        },
        'tariffConstraint': this.tariffConstraints
      }
    }
    localStorage.setItem('updateTariff', JSON.stringify(tariff))

    localStorage.setItem('tariffDetailRoute', JSON.stringify(this.route.snapshot.params));
    this.router.navigateByUrl('/add-tariff');
  }

}
