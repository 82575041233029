<sym-card-dialog [title]="title + 'Property'" [closeMethod]="closeMethod">
    <mat-dialog-content>
        <div style="height: 50vh;">
            <mat-tab-group mat-align-tabs="start" #tabGroup animationDuration="200ms" [selectedIndex]="selectedTab" (selectedTabChange)="changeTabIndex($event)">
                <mat-tab label="Property Details">
                    <div  fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
                        <div fxLayout="column">
                            <form [formGroup]="propertyDetails">
                                <div fxLayout="row" fxLayoutGap="20px">
                                    <mat-form-field appearance="outline" style="width: 100%;">
                                        <mat-label>Property Name</mat-label>
                                        <input matInput formControlName="name" required>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutGap="20px">
                                    <mat-form-field appearance="outline" style="width: 100%;">
                                        <mat-label>Property Code</mat-label>
                                        <input matInput formControlName="code" required>
                                        <mat-error *ngIf="propertyDetails.controls['code'].hasError('pattern')">*Only numbers are allowed</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayoutGap="20px">
                                    <mat-form-field appearance="outline" style="width: 100%;">
                                        <mat-label>Property Address</mat-label>
                                        <input matInput formControlName="address" required>
                                        <mat-error *ngIf="propertyDetails.controls['address'].hasError('pattern')">*Only numbers are allowed</mat-error>
                                    </mat-form-field>
                                </div>
                            </form>
                            <!-- <div fxLayout="row" fxLayoutGap="20px" *ngIf="isSuperUser && displayAgentCode">
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>Agent Code</mat-label>
                                    <input matInput [(ngModel)]="centre.agentCode">
                                </mat-form-field>
                            </div> -->
                            <mat-divider style="margin: 34px 12px"></mat-divider>
                            <div fxLayout="row" fxLayoutAlign="end center">
                                <button mat-raised-button id="btnClass" (click)="selectedTab = 1">
                                    Next<mat-icon style="color: white;margin-left: 5px;">arrow_forward</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                  <div>
                  </div>
                </mat-tab>
                <mat-tab label="Supplier Details">
                    <div fxLayout="row" fxLayoutAlign="space-evenly" fxFlex="100" style="margin-top: 1em;">
                        <div fxFlex="5"></div>
                        <div fxLayout="column" fxFlex="45">
                            <span>Electricity</span>
                            <div style="margin-top: 15px">
                                <div *ngFor="let suppliers of selectedElectricitySuppliers; let i = index">
                                    <div fxLayout="row" fxLayoutGap="10px">
                                        <mat-form-field appearance="outline" style="width: 80%;">
                                            <mat-label>Supplier</mat-label>
                                            <mat-select [disabled]="disableSuppliers"
                                                  placeholder="Supplier"
                                                  [(ngModel)]="selectedElectricitySuppliers[i]"
                                                  (selectionChange)="checkSupplier($event, false)">
                                              <mat-option *ngFor="let supplier of electricitySuppliers" [value]="supplier.id" [disabled]="supplier.disabled">
                                                {{supplier.name + ' - Electricity'}}
                                              </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                              
                                        <button matTooltip="Delete Supplier" *ngIf="!disableSuppliers" mat-icon-button aria-label="Delete Item button"
                                        class="mobile-hidden mat-light-green-700-bg" style="padding-right: 10px;" matTooltip="Delete Supplier">
                                        <mat-icon style="color: red;font-size: large;padding-top: 10px;cursor: pointer;" (click)="deleteSupplier(suppliers, false, true)">delete
                                        </mat-icon>
                                      </button>
                                    </div>
                                </div>
                                  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 10px;" *ngIf="!disableSuppliers">
                                    <button mat-fab
                                    style="background-color: #41535D;" 
                                    (click)="addSupplier(false)" matTooltip="Add new Supplier">
                                    <mat-icon style="color: white;">add</mat-icon>
                                  </button>
                                  </div>
                            </div>
                        </div>
                        <div fxFlex="5"></div>
                        <div fxLayout="column" fxFlex="45">
                            <span>Water/Sewerage</span>
        
                            <div style="margin-top: 15px">
                                <div *ngFor="let suppliers of selectedWaterSuppliers; let i = index">
                                    <div fxLayout="row" fxLayoutGap="10px">
                                        <mat-form-field appearance="outline" style="width: 80%;">
                                            <mat-label>Supplier</mat-label>
                                            <mat-select [disabled]="disableSuppliers"
                                                  placeholder="Supplier"
                                                  [(ngModel)]="selectedWaterSuppliers[i]"
                                                  (selectionChange)="checkSupplier($event, true)">
                                              <mat-option *ngFor="let supplier of waterSuppliers" [value]="supplier.id" [disabled]="supplier.disabled">
                                                {{supplier.name + ' - Water/Sewerage'}}
                                              </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                              
                                        <button matTooltip="Delete Supplier" *ngIf="!disableSuppliers" mat-icon-button aria-label="Delete Item button"
                                        class="mobile-hidden mat-light-green-700-bg" style="padding-right: 10px;" matTooltip="Delete Supplier">
                                        <mat-icon style="color: red;font-size: large;padding-top: 10px;cursor: pointer;" (click)="deleteSupplier(suppliers, true, true)">delete
                                        </mat-icon>
                                      </button>
                                    </div>
                                </div>
                                  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 10px;" *ngIf="!disableSuppliers">
                                    <button mat-fab
                                    style="background-color: #41535D;color: white;" 
                                    (click)="addSupplier(true)" matTooltip="Add new Supplier">
                                    <mat-icon style="color: white;">add</mat-icon>
                                  </button>
                                  </div>
                            </div>
                        </div>
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
                        <div fxLayout="column">
                            <mat-divider style="margin: 34px 12px"></mat-divider>
                                <div fxLayout="row" fxLayoutAlign="end center">
                                    <button mat-raised-button *ngIf="!isUpdate" id="btnClass"
                                    [ngStyle]="{'opacity': (propertyDetails.valid && ((selectedWaterSuppliers.length > 0 && selectedWaterSuppliers[0] !== '') || (selectedElectricitySuppliers.length > 0 && selectedElectricitySuppliers[0] !== '')) ? '1' : '.5')}"
                                    (click)="addCenter(centre)"
                                    [disabled]="!propertyDetails.valid ||
                                        ((selectedWaterSuppliers.length === 0 || (selectedWaterSuppliers.length > 0 && selectedWaterSuppliers[0] === '')) && 
                                        (selectedElectricitySuppliers.length === 0 || (selectedElectricitySuppliers.length > 0 && selectedElectricitySuppliers[0] === '')))">
                                        <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>Save
                                    </button>
                                    <button mat-raised-button *ngIf="isUpdate" id="btnClass" [ngStyle]="{'opacity': (propertyDetails.valid ? '1' : '.5')}" (click)="updateCentre()" [disabled]="!propertyDetails.valid">
                                        <mat-icon style="color: white;margin-right: 5px;">build</mat-icon>Save
                                    </button>
                                </div>
                        </div>
                    </div>
                </mat-tab>
                <!-- <mat-tab label="Property Address">
                    <form [formGroup]="addressDetails">
                        <div  fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 14px;">
                            <div fxLayout="column">
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>Street Address 1</mat-label>
                                    <input matInput formControlName="line1" required>
                                </mat-form-field>
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>Street Address 2</mat-label>
                                    <input matInput formControlName="line2">
                                </mat-form-field>
                                <div fxLayout="row" fxFlex fxLayoutGap="15px">
                                    <div fxFlex="50">
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>Suburb</mat-label>
                                            <input matInput formControlName="suburb" required>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50">
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>City</mat-label>
                                            <input matInput formControlName="city" required>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" fxFlex>
                                    <div fxFlex="49">
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>Post Code</mat-label>
                                            <input matInput formControlName="postcode" required>
                                            <mat-error *ngIf="addressDetails.controls['postcode'].hasError('pattern')">*Only numbers are allowed</mat-error>
                                        </mat-form-field>
                                </div>
                                </div>
                                  </div>
                                  <mat-divider style="margin: 34px 12px"></mat-divider>
                                <div fxLayout="row" fxLayoutAlign="end center">
                                    <button mat-raised-button id="btnClass" [ngStyle]="{'opacity': (propertyDetails.valid && addressDetails.valid ? '1' : '.5')}" (click)="addCenter(centre)" [disabled]="!propertyDetails.valid || !addressDetails.valid">
                                        <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>Save
                                    </button>
                                </div>
                        </div>
                    </form>
                </mat-tab> -->
            </mat-tab-group> 
        </div>
    </mat-dialog-content>
</sym-card-dialog>
