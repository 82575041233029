<div [@onSideNavChange]="sideNavState ? 'open' : 'close'" *ngIf="getScreenType('Medium')">

    <mat-nav-list fxLayout="column" fxLayoutAlign="center center">
        <a mat-list-item (click)="openSideNav()" style="color: white;margin-bottom: 14px;" fxLayoutAlign="center"><mat-icon>{{sideNavState? 'first_page' : 'last_page'}}</mat-icon></a>
        <div fxLayout="column" fxLayoutAlign="center center">
            <a mat-list-item *ngFor="let page of nav" [routerLink]="[page.link]" style="padding: 32px 12px" routerLinkActive="list-item-active" fxLayout="column"  fxLayoutAlign="center">
                <div fxLayout="row" fxLayoutAlign=" center">
                    <mat-icon class="pr-4" [ngStyle]="{'color': checkRoute(page.link) ? '#A59A00' : 'white'}">{{page?.icon}}</mat-icon>
                    <span [ngStyle]="{'color': checkRoute(page.link) ? '#A59A00' : 'white'}" [@animateText]="sideNavState ? 'show' : 'hide'">{{ page?.name }} </span>
                </div>
            </a>
            
        </div>
    </mat-nav-list>
</div>

<div *ngIf="getScreenType('Large') || getScreenType('XLarge')">

    <mat-nav-list fxLayout="column" fxLayoutAlign="center center" style="padding: 2px 12px 32px 12px;">
        <div fxLayout="column" fxLayoutAlign="center center">
            <a mat-list-item *ngFor="let page of nav" [routerLink]="[page.link]" style="padding: 14px 0px 14px 0px; border-radius: 8px;" routerLinkActive="list-item-active" fxLayout="column"  fxLayoutAlign="center" [ngStyle]="{'background-color': checkRoute(page.link) ? '#576B76' : ''}">
                <div fxLayout="row" fxLayoutAlign=" center" >
                    <mat-icon class="pr-12" style="color: white;">{{page?.icon}}</mat-icon>
                    <span [ngStyle]="checkRoute(page.link) ? {'color': 'white', 'font-weight': 550} : {'color': 'white'}" style="letter-spacing: 0.03em;">{{ page?.name }} </span>
                </div>
            </a>
            
        </div>
    </mat-nav-list>
</div>