<sym-page-header [breadcrumbText]="'Reading Details: ' + ' - ' + meterPeriodStatus.meterNo"
  [title]="'Reading Details - ' + meterNo"></sym-page-header>

<mat-tab-group mat-align-tabs="start" (selectedTabChange)="changeTab($event)">
  <mat-tab label="Quality Control">
    <sym-detailed-control #detailedControl (retrieveReadingDetails)="redirectToCall($event)"></sym-detailed-control>
  </mat-tab>
  <mat-tab label="Utilization" *ngIf="superUser">
    <div class="page-layout blank" fxLayout="column">
      <sym-content-card [title]="'Meter Period Readings'" [titleButton]="titleButton">
        <mat-table [dataSource]="readingHistory" #Sort="matSort" matSort style="margin-top: 15px;" multiTemplateDataRows
          *ngIf="readingHistory.length != 0">
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['date']}}" style="color: grey;" mat-sort-header
              sortActionDescription="Sort by date">
              Reading Date
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="{{headerObject['date']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}">
              <div fxLayout="column">
                <div *ngIf="element.reading.readingTS">
                  {{element.reading.readingTS | date:'yyyy-MM-dd'}}
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['date']}}"></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="tenant">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['tenant']}}" style="color: grey;" mat-sort-header
              sortActionDescription="Sort by name">
              Tenant
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="{{headerObject['tenant']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}" matTooltip="{{element.tradingName}}"
              matTooltipPosition="above">
              <div fxLayout="column">
                <div *ngIf="element.tradingName">
                  {{element.tradingName.length > (headerObject['tenant'] + addColumnNum) ? (element.tradingName | slice:
                  0:(headerObject['tenant'] + addColumnNum)) + "..." : element.tradingName}}
                </div>
                <div *ngIf="!element.tradingName">
                  -
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['tenant']}}"></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="capturedBy">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['capturedBy']}}" style="color: grey;">
              Captured By
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="{{headerObject['capturedBy']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}" matTooltip="{{element.reading.readingBy}}"
              matTooltipPosition="above">
              <div fxLayout="column">
                <div *ngIf="element.reading && element.reading.readingBy">
                  {{element.reading.readingBy.length > (headerObject['capturedBy'] + addColumnNum)?
                  (element.reading.readingBy | slice: 0:(headerObject['capturedBy'] + addColumnNum)) + "..." :
                  element.reading.readingBy}}
                </div>
                <div *ngIf="!element.reading || (element.reading && !element.reading.readingBy)">
                  -
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['capturedBy']}}"></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="reading">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['reading']}}" style="color: grey;">
              {{meterPeriodStatus.utilityType.includes('TOU') ? 'Standard' : meterPeriodStatus.utilityType ===
              'ELEC_3P_1' || meterPeriodStatus.utilityType === 'ELEC_3P_KVA' ? 'Total' : 'Reading 1'}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="{{headerObject['reading']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}">
              <div fxLayout="column">
                <div
                  *ngIf="element.reading && element.reading.readingValueList && element.reading.readingValueList.length != 0 && element.reading.readingValueList[0].value">
                  {{element.reading.readingValueList[0].value != 'NaN' ? (element.reading.readingValueList[0].value |
                  number:'1.0-4') : ''}}
                </div>
                <div
                  *ngIf="!element.reading || !element.reading.readingValueList || element.reading.readingValueList.length === 0">
                  -
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['reading']}}">
              <div
                *ngIf="readingVal === 0 && ((faultyReading && readingHistory.length > 1) || (!faultyReading && readingHistory.length != 0))"
                style="font-weight: bold;">Total Charge:</div>
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="reading2">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['reading2']}}" style="color: grey;"
              mat-sort-header sortActionDescription="Sort by date">
              {{meterPeriodStatus.utilityType.includes('TOU') ? 'Off Peak' :
              meterPeriodStatus.utilityType.includes('KVA') ? 'KVA' : 'Reading 2'}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="{{headerObject['reading2']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}">
              <div fxLayout="column">
                <div
                  *ngIf="element.reading && element.reading.readingValueList && element.reading.readingValueList.length != 0 && element.reading.readingValueList[1]">
                  {{element.reading.readingValueList[1].value != 'NaN' ? (element.reading.readingValueList[1].value |
                  number:'1.0-4') : ''}}
                </div>
                <div
                  *ngIf="!element.reading || !element.reading.readingValueList || element.reading.readingValueList.length === 0 || !element.reading.readingValueList[1]">
                  -
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['reading2']}}">
              <div
                *ngIf="readingVal === 1 && ((faultyReading && readingHistory.length > 1) || (!faultyReading && readingHistory.length != 0))"
                style="font-weight: bold;">Total Charge:</div>
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="reading3">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['reading3']}}" style="color: grey;"
              mat-sort-header sortActionDescription="Sort by date">
              {{meterPeriodStatus.utilityType.includes('TOU') ? 'Peak' : ' Reading 3'}}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="{{headerObject['reading3']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}">
              <div fxLayout="column">
                <div
                  *ngIf="element.reading && element.reading.readingValueList && element.reading.readingValueList.length != 0 && element.reading.readingValueList[2]">
                  {{element.reading.readingValueList[2].value != 'NaN' ? (element.reading.readingValueList[2].value |
                  number:'1.0-4') : ''}}
                </div>
                <div
                  *ngIf="!element.reading || !element.reading.readingValueList || element.reading.readingValueList.length === 0 || !element.reading.readingValueList[2]">
                  -
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['reading3']}}">
              <div
                *ngIf="readingVal === 2 && ((faultyReading && readingHistory.length > 1) || (!faultyReading && readingHistory.length != 0))"
                style="font-weight: bold;">Total Charge:</div>
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="kva">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['kva']}}" style="color: grey;" mat-sort-header
              sortActionDescription="Sort by date">
              KVA
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="{{headerObject['kva']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}">
              <div fxLayout="column">
                <div>
                  {{utilityType | kva: element.reading.readingValueList | number:'1.0-4'}}
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['kva']}}">
              <div
                *ngIf="readingVal === 3 && ((faultyReading && readingHistory.length > 1) || (!faultyReading && readingHistory.length != 0))"
                style="font-weight: bold;">Total Charge:</div>
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="charge">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['charge']}}" style="color: grey;">
              Charge
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = dataIndex" fxFlex="{{headerObject['charge']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}">
              <div *ngIf="!element.isPrev && isCalculating">
                Calculating...
              </div>
              <div *ngIf="element.isPrev || !isCalculating">
                {{checkCharge(element, element.isPrev, i)}}
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['charge']}}">
              <div
                *ngIf="((faultyReading && readingHistory.length > 1) || (!faultyReading && readingHistory.length != 0)) && !isCalculating">
                <div *ngIf="totalCharge">
                  R {{totalCharge|number:'1.2-2'}}
                </div>
                <div *ngIf="!totalCharge">
                  R {{meterPeriodStatus.totalCharge|number:'1.2-2'}}
                </div>
              </div>
              <div *ngIf="isCalculating">Calculating...</div>
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="readingType">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['readingType']}}" style="color: grey;">
              Reading Type
            </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{element.reading.readingType}}" matTooltipPosition="above"
              fxFlex="{{headerObject['readingType']}}" [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}">
              <div fxLayout="column" *ngIf="readingVal === 3 || windowSize < 1400">
                {{element.reading.readingType.length > headerObject['readingType'] ? (element.reading.readingType |
                slice: 0:(headerObject['readingType'])) + "..." : element.reading.readingType}}
              </div>
              <div fxLayout="column" *ngIf="windowSize > 1400 && readingVal != 3">
                {{element.reading.readingType}}
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['readingType']}}"></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="warning">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['warning']}}" style="color: grey;">
              Warning
            </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{setFlagWarningText(element)}}"
              fxFlex="{{headerObject['warning']}}" matTooltipPosition="above"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}">
              <div fxLayout="column">
                <div
                  *ngIf="(element.flagReason && element.flagReason !== 'NONE') || (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length > 0)">
                  <mat-icon style="color: red;"
                    [ngStyle]="{'font-size': element.flagReason.includes('NEGATIVE') || element.flagReason.includes('ZERO') ? 'large' : 'x-large'}">{{element.flagReason.includes('OVER')
                    ? 'warning' : element.flagReason.includes('UNDER') ? 'trending_down' :
                    element.flagReason.includes('NEGATIVE') ? 'exposure_neg_1' : element.flagReason.includes('ZERO') ?
                    'exposure_zero' : 'notification_important'}}</mat-icon>
                </div>
                <div
                  *ngIf="(!element.flagReason || (element.flagReason && element.flagReason === 'NONE')) && (!this.meterPeriodStatus.errorMessageList || (this.meterPeriodStatus.errorMessageList && this.meterPeriodStatus.errorMessageList.length === 0))">
                  -
                </div>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['warning']}}"></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['status']}}" style="color: grey;">
              Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="{{headerObject['status']}}"
              [ngStyle]="{'color': element.isPrev ? 'grey' : 'black'}" matTooltip="{{element.approvalStatus}}"
              matTooltipPosition="above">
              <div fxLayout="column" *ngIf="element.approvalStatus">
                {{element.approvalStatus.length > headerObject['warning'] ? (element.approvalStatus | slice:
                0:(headerObject['warning'])) + "..." : element.approvalStatus}}
              </div>
              <div *ngIf="!element.approvalStatus">
                -
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['status']}}"></mat-footer-cell>
          </ng-container>

          <ng-container fxLayoutAllign="end center" matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef fxFlex="{{headerObject['actions']}}" style="color: grey;">Action
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = dataIndex;" fxFlex="{{headerObject['actions']}}">
              <button *ngIf="!isCalculating" matTooltipPosition="left" (click)="setReadingElement(element, i)"
                matTooltip="View Menu" mat-icon-button class="mobile-hidden mat-light-green-700-bg"
                [matMenuTriggerFor]="meterMenu">
                <mat-icon style="font-size: x-large;color: black;">more_vert</mat-icon>
              </button>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef fxFlex="{{headerObject['actions']}}"></mat-footer-cell>
            <mat-menu #meterMenu="matMenu" xPosition="before" style="padding: 10px;">
              <div class="menu">
                <div
                  *ngIf="((!isPrevRead && selectedReadingValue.approvalStatus === 'SUBMITTED' && superUser) || (!superUser && !isPrevRead && (selectedReadingValue.approvalStatus === 'REJECTED' || selectedReadingValue.approvalStatus === 'CAPTURED'))) && selectedReadingValue.approvalStatus !== 'OVERWRITTEN' && !outsideTDate">
                  <button matTooltipPosition="left" matTooltip="Approve Reading" mat-menu-item
                    (click)="approveMeterPeriod()" class="mobile-hidden mat-light-green-700-bg">
                    <mat-icon style="font-size: large;color: green">check</mat-icon><span *ngIf="superUser">Approve
                      Period Reading</span><span *ngIf="!superUser">Submit Period Reading</span>
                  </button>
                  <mat-divider></mat-divider>
                </div>
                <div *ngIf="!selectedReadingValue.approvalStatus.includes('DECOMMISSIONED')">
                <div 
                  *ngIf="!isPrevRead && superUser && selectedReadingValue.approvalStatus != 'REJECTED' && readingCaptureType != 'ONLINE' && selectedReadingValue.approvalStatus !== 'OVERWRITTEN' && selectedReadingValue.approvalStatus !== 'APPROVED' && !outsideTDate">
                  <button  
                   matTooltipPosition="left" matTooltip="Reject Reading" mat-menu-item
                    (click)="rejectMeterPeriod()" class="mobile-hidden mat-light-green-700-bg">
                    <mat-icon style="font-size: large;color: red">close</mat-icon>Reject Period Reading
                  </button>
                  <mat-divider></mat-divider>
                </div>
                </div>
                <div>
                  <button matTooltipPosition="left" matTooltip="View Reading" mat-menu-item
                    (click)="openMeterChargeDialog(i)" class="mobile-hidden mat-light-green-700-bg">
                    <mat-icon style="font-size: large;color: #41535D;">poll</mat-icon>View Charges
                  </button>
                </div>
                <!-- <mat-divider></mat-divider>
                <button (click)="commentService.openCommentDialog(selectedReadingValue,'periodReadingComment', propertyName)" matTooltipPosition="left" matTooltip="Add Comment" mat-menu-item class="mobile-hidden mat-light-green-700-bg">
                  <mat-icon style="font-size: large;color: #41535D;">add</mat-icon>Add Comment
                </button> -->
              </div>
            </mat-menu>
          </ng-container>

          <mat-header-row *matHeaderRowDef="meterColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row;columns: meterColumns;"></mat-row>
          <mat-footer-row *matFooterRowDef="meterColumns;"></mat-footer-row>
        </mat-table>
        <div style="margin: 2em;width: 100%;" *ngIf="selection.selected.length > 0 && !noReadings && checkReadings('S')"
          fxLayout="row" fxLayoutAlign="center center">
          <button mat-raised-button
            style="margin-bottom: 10px; margin-right: 1em; background-color: #41535D;color: white;height: 45px;"
            id="btnClass" *ngIf="checkReadings('A')" (click)="approveMultipleReadings()"><mat-icon
              style="color: green;padding-right: 10px;">check</mat-icon>{{superUser ? 'Approve' : 'Submit'}}
            {{readingList.length > 1 ? ' Readings' : ' Reading'}}</button>
          <button mat-raised-button style="margin-bottom: 10px; background-color: #41535D;color: white;height: 45px;"
            id="btnClass" *ngIf="superUser && checkReadings('R')" (click)="rejectMultipleReadings()"><mat-icon
              style="color: red;padding-right: 10px;">close</mat-icon>Reject {{readingList.length > 1 ? ' Readings' : '
            Reading'}}</button>
        </div>
        <div *ngIf="noReadings && isDownloaded" style="margin: 2em">
          <span style="font-size: large; font-weight: bold;">No Readings for current period</span>
        </div>
      </sym-content-card>

      <sym-content-card [title]="'Meter Details'">
        <div fxLayout="row" fxFlex="100">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row" style="padding-bottom: 1em;"><span fxFlex="20"
                style="font-weight: bold;font-size: 14px;">Meter No:</span><span fxFlex="20"
                *ngIf="meterPeriodStatus && meterPeriodStatus.meterNo"
                style="font-size: 14px;">{{meterPeriodStatus.meterNo}}</span></div>
            <div fxLayout="row" style="padding-bottom: 1em;"><span fxFlex="20"
                style="font-weight: bold;font-size: 14px;">Utility Type:</span><span fxFlex="30"
                *ngIf="meterPeriodStatus && meterPeriodStatus.utilityType"
                style="font-size: 14px;">{{meterPeriodStatus.utilityType}}</span></div>
            <div fxLayout="row" style="padding-bottom: 1em;"><span fxFlex="20"
                style="font-weight: bold;font-size: 14px;">Meter Type:</span><span fxFlex="30"
                *ngIf="meterPeriodStatus && meterPeriodStatus.utilityType"
                style="font-size: 14px;">{{meterPeriodStatus.tradingName.includes('BULK') ? 'BULK' : 'UNIT'}}</span>
            </div>
            <div fxLayout="row" style="padding-bottom: 1em;"><span fxFlex="20"
                style="font-weight: bold;font-size: 14px;">Supplier:</span><span fxFlex="50"
                *ngIf="supplierDetails && supplierDetails.supplierName"
                style="font-size: 14px;">{{supplierDetails.supplierName}}</span></div>
            <div fxLayout="row" style="padding-bottom: 1em;"><span fxFlex="20"
                style="font-weight: bold;font-size: 14px;">Tariff(s):</span>
              <div fxFlex="50" style="display: flex;flex-direction: column;"><span style="font-size: 14px;"
                  *ngFor="let tariff of tariffs">{{tariff}}</span></div>
            </div>
          </div>
          <!-- <div fxLayout="column" fxFlex="50">
            <div fxLayout="row" style="padding-bottom: 1em;"><span fxFlex="20" style="font-weight: bold;">Tenant Code:</span><span *ngIf="unitList && unitList.tenantOccupation && unitList.tenantOccupation.tenant" fxFlex="20">{{unitList.tenantOccupation.tenant.code}}</span></div>
            <div fxLayout="row" style="padding-bottom: 1em;"><span fxFlex="20" style="font-weight: bold;">Trading Name:</span><span *ngIf="unitList && unitList.tenantOccupation && unitList.tenantOccupation.tenant" fxFlex="40">{{unitList.tenantOccupation.tenant.tradingName}}</span></div>
          </div> -->
        </div>
      </sym-content-card>

      <!-- <sym-content-card [title]="'Current Tenant Details'">
        <div *ngFor="let unit of meterUnits" fxLayout="column">
          <div fxLayout="row" fxFlex="100">
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="35" style="font-weight: bold;font-size: 14px;">Full Name:</span><span fxFlex="35" *ngIf="unit.rentableUnit.tenantOccupation.tenant && unit.rentableUnit.tenantOccupation.tenant.contactDetails.contactName" style="font-size: 14px;">{{unit.rentableUnit.tenantOccupation.tenant.contactDetails.contactName}}</span>
              </div>
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="35" style="font-weight: bold;font-size: 14px;">Contact Number:</span><span fxFlex="35" *ngIf="unit.rentableUnit.tenantOccupation.tenant && unit.rentableUnit.tenantOccupation.tenant.contactDetails.contactTelephone" style="font-size: 14px;">{{unit.rentableUnit.tenantOccupation.tenant.contactDetails.contactTelephone}}</span>
              </div>
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="35" style="font-weight: bold;font-size: 14px;">Lease Start:</span><span fxFlex="35" *ngIf="unit.rentableUnit.tenantOccupation.leaseStart" style="font-size: 14px;">{{unit.rentableUnit.tenantOccupation.leaseStart|date:'yyyy-MM-dd'}}</span>
              </div>
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="35" style="font-weight: bold;font-size: 14px;">Unit Number:</span><span fxFlex="35" *ngIf="unit.rentableUnit.unitNo" style="font-size: 14px;">{{unit.rentableUnit.unitNo}}</span>
              </div>
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="35" style="font-weight: bold;font-size: 14px;">Unit Code:</span><span fxFlex="35" *ngIf="unit.rentableUnit.unitCode" style="font-size: 14px;">{{unit.rentableUnit.unitCode}}</span>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50">
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="30" style="font-weight: bold;font-size: 14px;">Tenant Code:</span><span fxFlex="50" *ngIf="unit.rentableUnit.tenantOccupation.tenant && unit.rentableUnit.tenantOccupation.tenant.code" style="font-size: 14px;">{{unit.rentableUnit.tenantOccupation.tenant.code}}</span>
              </div>
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="30" style="font-weight: bold;font-size: 14px;">Trading Name:</span><span fxFlex="50" *ngIf="unit.rentableUnit.tenantOccupation.tenant && unit.rentableUnit.tenantOccupation.tenant.tradingName" style="font-size: 14px;">{{unit.rentableUnit.tenantOccupation.tenant.tradingName}}</span>
              </div>
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="30" style="font-weight: bold;font-size: 14px;">Lease End:</span><span fxFlex="50" *ngIf="unit.rentableUnit.tenantOccupation.leaseEnd" style="font-size: 14px;">{{unit.rentableUnit.tenantOccupation.leaseEnd|date:'yyyy-MM-dd'}}</span>
              </div>
              <div fxLayout="row" style="padding-bottom: 1em;">
                <span fxFlex="30" style="font-weight: bold;font-size: 14px;">Accomodation Type:</span><span fxFlex="50" *ngIf="unit.rentableUnit.accomodationType" style="font-size: 14px;">{{unit.rentableUnit.accomodationType}}</span>
              </div>
            </div>
          </div>
    
          <div fxLayout="column" fxLayoutAlign="center center" style="padding-bottom: 2em;">
            <mat-divider style="width: 93%;left: 20px;"></mat-divider>
          </div>
        </div>
      </sym-content-card> -->
    </div>
  </mat-tab>
  <mat-tab label="Event History" *ngIf="superUser">
    <sym-event-history [utilityType]="utilityType" *ngIf="tabIndex === 2" [meterId]="meterId"></sym-event-history>
  </mat-tab>

</mat-tab-group>