import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify, Auth } from 'aws-amplify';
import { aws_exports } from './environments/awsexport.dev';

Amplify.configure( aws_exports);


Amplify.configure({
  API: {
    graphql_headers: async () => ({
      'Authorization': localStorage.getItem("accessToken")
    }),
  },
})


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));