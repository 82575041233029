<div class="page-layout blank" fxLayout="column">
   <sym-page-header [breadcrumbText]="'T'" [primaryBtn]="primaryBtn" [title]="supplierName + ' - ' + (tariffType && tariffType.name ? tariffType.name : '-') "></sym-page-header> 
  <!-- <sym-page-header [breadcrumbText]="'T'" [title]="supplierName + ' - ' + (tariffType && tariffType.name ? tariffType.name : '-') "></sym-page-header> -->
  <mat-tab-group mat-align-tabs="start" #tabGroup animationDuration="200ms">
    <mat-tab label="Detail">
      <sym-content-card>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column">
            <span class="line-header">Utility Provider</span>
            <span class="line-content">{{supplierName}}</span>
          </div>
          <div>
            <mat-chip-list *ngIf="periodTariff &&  periodTariff.startDate && periodTariff.endDate">
              <mat-chip><mat-icon style="font-size: 22px;color: #323232;padding-right: 8px;">date_range</mat-icon>{{periodTariff.startDate| date:'dd.MM.yyyy'}} to {{periodTariff.endDate| date:'dd.MM.yyyy'}}</mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div style="padding: 12px 0;">
          <mat-divider></mat-divider>
        </div>

        <span class="line-header">Validity Date</span>
        <span class="line-content">{{selectedTariffDate + ' / ' + (selectedTariffDate + 1)}}</span>
        <div style="padding: 12px 0;">
          <mat-divider></mat-divider>
        </div>

        <span class="line-header">Tariff Name</span>
        <span class="line-content">{{tariffType && tariffType.name ? tariffType.name : '-'}}</span>
        <div style="padding: 12px 0;">
          <mat-divider></mat-divider>
        </div>

        <span class="line-header">Tariff Detail</span>
        <span class="line-content">{{tariffType && tariffType.description ? tariffType.description : '-'}}</span>
        <div style="padding: 12px 0;">
          <mat-divider></mat-divider>
        </div>

        <div *ngIf="tariffType && tariffType.tariffCategory" style="display: flex;flex-direction: column;">
          <span class="line-header">Tariff Category</span>
          <span class="line-content">{{tariffType.tariffCategory}}</span>
          <div style="padding: 12px 0;">
            <mat-divider></mat-divider>
          </div>
        </div>

        <span class="line-header">Tariff Type</span>
        <span class="line-content">{{tariffConstraints && tariffConstraints.utilityType ? tariffConstraints.utilityType : '-'}}</span>
        <div style="padding: 12px 0;">
          <mat-divider></mat-divider>
        </div>

        <!-- TODO: Water Restrictions need to be added -->
        <div *ngIf="tariffConstraints && tariffConstraints.utilityType !== 'Electricity'" fxLayout="column">
          <span class="line-header">Water Restrictions</span>
          <span class="line-content">Level 0</span>
          <div style="padding: 12px 0;">
            <mat-divider></mat-divider>
          </div>
        </div>
      </sym-content-card>
    </mat-tab>

    <mat-tab label="Constraints" *ngIf="tariffConstraints && constraintCount !== -1">
      <sym-tariff-constraints [constraints]="constraintArr" [constraintCount]="constraintCount"></sym-tariff-constraints>
    </mat-tab>

    <mat-tab label="Charges" *ngIf="!noCharges && periodTariff">
      <sym-content-card *ngIf="tariffConstraints && tariffConstraints.utilityType === 'Electricity'">
        <div fxLayout="row" fxLayoutAlign="start center" style="padding-top: 16px;">
          <button *ngIf="fixedCharges.length > 0" mat-flat-button [class.selectedButton]="selectedCharge === 0" [class.normalButton]="selectedCharge !== 0" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
            id="btnClass" (click)="selectedCharge = 0">
            FIXED CHARGES
          </button>
          <button *ngIf="demandCharges.length > 0" mat-flat-button [class.selectedButton]="selectedCharge === 1" [class.normalButton]="selectedCharge !== 1" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
            id="btnClass" (click)="selectedCharge = 1">
            DEMAND CHARGES
          </button>
          <button *ngIf="utilisationCharges.length > 0 || touList.length > 0" mat-flat-button [class.selectedButton]="selectedCharge === 2" [class.normalButton]="selectedCharge !== 2" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px"
            id="btnClass" (click)="selectedCharge = 2">
            ENERGY CHARGES
          </button>
          <button *ngIf="surcharge" mat-flat-button [class.selectedButton]="selectedCharge === 3" [class.normalButton]="selectedCharge !== 3" style="margin-bottom: 10px;padding: 8px 16px;"
            id="btnClass" (click)="selectedCharge = 3">
            CALCULATION PARAMETERS
          </button>
        </div>
        <div *ngIf="seasonsList && seasonsList.length > 1" style="display: flex;">
          <div *ngFor="let seasons of seasonsList" style="margin-right: 4px;flex: 1;">
            <div *ngFor="let season of seasons">
              <mat-chip-list>
                <mat-chip>
                  <mat-icon style="font-size: 22px;color: #323232;padding-right: 8px;">date_range</mat-icon>
                  <span style="font-weight: bold;padding-right: 4px;border-right: 1px solid rgb(68, 67, 67);">{{season.name}}</span>
                  <span style="margin-left: 4px;">{{season.startDate| date:'dd.MM.yyyy'}} to {{season.endDate| date:'dd.MM.yyyy'}}</span>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div style="padding: 12px 0;">
          <mat-divider></mat-divider>
        </div>
        
        <div *ngIf="selectedCharge === 0">
          <div *ngIf="fixedCharges.length > 0">
            <div *ngFor="let charge of fixedCharges" fxLayout="column">
              <div *ngIf="charge.rates && charge.rates.length === 1" fxLayout="column">
                <span class="line-header">{{charge.display}}</span>
                <span class="line-content">R {{charge.rates[0].rate  | number: '1.2-4'}}</span>
              </div>
              <div *ngIf="charge.rates && charge.rates.length > 1" fxLayout="row" fxFlex="100">
                <div *ngFor="let rates of charge.rates" fxLayout="column" fxFlex="50">
                  <span class="line-header">{{charge.display}}</span>
                  <span class="line-content">R {{rates.rate  | number: '1.2-4'}}</span>
                </div>
              </div>
              <span *ngIf="charge.display && charge.display.includes('Network Capacity Charge') && supplierName === 'Eskom'" style="padding-top: .75em;">
                Please note the Eskom Network Capacity Charge (R/kVA/m) is captured under Network Access Charge (R/kVA) under the Fixed Charges tab
              </span>
              <div style="padding: 12px 0;">
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedCharge === 1">
          <div *ngIf="demandCharges.length > 0">
            <div *ngIf="(maxDemandWindow && (maxDemandWindow.percentageOver || maxDemandWindow.value)) || minUnits">
              <div style="margin: 20px 0;">
                <span style="color: #9B9020;font-weight: bold;">Demand Charge Details:</span>
              </div>
              <div style="padding: 12px 0;">
                <mat-divider></mat-divider>
              </div>
              <div class="grid-display">    
                <div class="row-display" *ngIf="maxDemandWindow.percentageOver">
                  <span class="line-header">Percentage Over</span>
                  <span class="line-content">{{maxDemandWindow.percentageOver | percent}}</span>
                </div>
    
                <div class="row-display" *ngIf="maxDemandWindow.value">
                  <span class="line-header">Monthly Value</span>
                  <span class="line-content">{{maxDemandWindow.value}} {{maxDemandWindow.value > 1 ? ' Months' : ' Month'}}</span>
                </div>

                <div class="row-display" *ngIf="minUnits">
                  <span class="line-header">Monthly minimum kVA</span>
                  <span class="line-content">{{minUnits}} kVA</span>
                </div>
              </div>
              <div style="padding: 12px 0;">
                <mat-divider></mat-divider>
              </div>
            </div>

            <div *ngFor="let charge of demandCharges" fxLayout="column">
              <div *ngIf="charge.rates && charge.rates.length === 1" fxLayout="column">
                <span class="line-header">{{charge.display}}</span>
                <span class="line-content">R {{charge.rates[0].rate  | number: '1.2-4'}}</span>
              </div>
              <div *ngIf="charge.rates && charge.rates.length > 1" fxLayout="row" fxFlex="100">
                <div *ngFor="let rates of charge.rates" fxLayout="column" fxFlex="50">
                  <span class="line-header">{{charge.display}}</span>
                  <span class="line-content">R {{rates.rate  | number: '1.2-4'}}</span>
                </div>
              </div>
              <div style="padding: 12px 0;">
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedCharge === 2">
          <div *ngIf="utilisationCharges.length > 0 || touList.length > 0" style="display: flex; flex-direction: column;">
            <div *ngFor="let charge of utilisationCharges" fxLayout="column">
              <div *ngIf="charge.rates && charge.rates.length === 1" fxLayout="column">
                <span class="line-header">{{charge.display}}</span>
                <span class="line-content">R {{charge.rates[0].rate  | number: '1.2-4'}}</span>
              </div>

              <div *ngIf="charge.rates && charge.rates.length > 1 && charge.display !== 'Active Energy Charge' && charge.display !== 'Distribution Network Demand Charge'" fxLayout="row" fxFlex="100">
                <div *ngFor="let rates of charge.rates" fxLayout="column" fxFlex="50">
                  <span class="line-header">{{charge.display}}</span>
                  <span class="line-content">R {{rates.rate  | number: '1.2-4'}}</span>
                </div>
              </div>
              <div style="padding: 12px 0;">
                <mat-divider></mat-divider>
              </div> 
            </div>

            <div *ngIf="touList && touList.length > 0"  fxFlex="100">
              <div *ngFor="let charge of touList">
                <div *ngFor="let rates of charge.rates">
                  <div *ngIf="rates.tou && rates.tou.length > 0">
                    <div *ngIf="rates.season === 'Low'" fxLayout="column">
                      <div fxLayout="column">
                        <span style="color: #9B9020;font-weight: bold;padding: 12px 0;">{{charge.display}} - Low Demand Season</span>
                        <div style="padding: 12px 0;">
                          <mat-divider></mat-divider>
                        </div>
                      </div>
                      <div fxLayout="row">
                        <div *ngFor="let tou of rates.tou" fxFlex="100">
                          <div fxFlex="33" fxLayout="column">
                            <span class="line-header">{{tou.display}}</span>
                            <span class="line-content">R {{tou.rate  | number: '1.2-4'}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div *ngIf="rates.season === 'High'" fxLayout="column">
                      <div fxLayout="column">
                        <span style="color: #9B9020;font-weight: bold;padding: 12px 0;">{{charge.display}} - High Demand Season</span>
                        <div style="padding: 12px 0;">
                          <mat-divider></mat-divider>
                        </div>
                      </div>
                      <div fxLayout="row">
                        <div *ngFor="let tou of rates.tou" fxFlex="100">
                          <div fxFlex="33" fxLayout="column">
                            <span class="line-header">{{tou.display}}</span>
                            <span class="line-content">R {{tou.rate  | number: '1.2-4'}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div style="padding: 12px 0;">
                      <mat-divider></mat-divider>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedCharge === 3">
          <div fxLayout="column" *ngIf="surcharge">
            <span class="line-header">Surcharge</span>
            <span class="line-content">{{surcharge | percent}}</span>
            <div style="padding: 12px 0;">
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </sym-content-card>

      <sym-content-card *ngIf="tariffConstraints && tariffConstraints.utilityType !== 'Electricity'">
        <div fxLayout="row" fxLayoutAlign="start center" style="padding-top: 16px;">
          <button *ngIf="utilisationCharges.length > 0 && !hasStandard || utilisationCharges.length > 1 && hasStandard" mat-flat-button [class.selectedButton]="selectedCharge === 0" [class.normalButton]="selectedCharge !== 0" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
            id="btnClass" (click)="selectedCharge = 0">
            FIXED CHARGES
          </button>
          <button *ngIf="surcharge || percentageQty" mat-flat-button [class.selectedButton]="selectedCharge === 1" [class.normalButton]="selectedCharge !== 1" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
            id="btnClass" (click)="selectedCharge = 1">
            CALCULATION PARAMETERS
          </button>
          <button *ngIf="blockCharges.length > 0 || hasStandard" mat-flat-button [class.selectedButton]="selectedCharge === 2" [class.normalButton]="selectedCharge !== 2" style="margin-bottom: 10px;padding: 8px 16px;margin-right: 8px;"
            id="btnClass" (click)="selectedCharge = 2">
            VARIABLE CHARGES
          </button>
        </div>
        <div style="padding: 12px 0;">
          <mat-divider></mat-divider>
        </div>

        <div *ngIf="selectedCharge === 0">
          <div *ngIf="utilisationCharges.length > 0">
            <div *ngFor="let charge of utilisationCharges" fxLayout="column">
              <div fxLayout="column" *ngIf="charge.display && !charge.display.includes('Standard')">
                <span class="line-header">{{charge.display}}</span>
                <span class="line-content">R {{charge.rates[0].rate  | number: '1.2-4'}}</span>
                <div style="padding: 12px 0;">
                  <mat-divider></mat-divider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedCharge === 1">
          <div fxLayout="column" *ngIf="surcharge">
            <span class="line-header">Surcharge</span>
            <span class="line-content">{{surcharge | percent}}</span>
            <div style="padding: 12px 0;">
              <mat-divider></mat-divider>
            </div>
          </div>

          <div fxLayout="column" *ngIf="percentageQty">
            <span class="line-header">% of Water Consumption</span>
            <span class="line-content">{{percentageQty | percent}}</span>
            <div style="padding: 12px 0;">
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>

        <div *ngIf="selectedCharge === 2">
          <div *ngIf="blockCharges && blockCharges.length > 0 &&
            blockCharges[0].rates && blockCharges[0].rates.length > 0 && 
                  blockCharges[0].rates[0].blocks && blockCharges[0].rates[0].blocks.length > 0;else standardCharge" fxLayout="column">
            <div fxLayout="column">
              <span style="color: #9B9020;font-weight: bold;padding: 12px 0;">Block Charges</span>
              <div style="padding: 12px 0;">
                <mat-divider></mat-divider>
              </div>
            </div>

            <div *ngFor="let block of blockCharges[0].rates[0].blocks">
              <div fxLayout="row">
                <div fxFlex="33" fxLayout="column">
                  <span class="line-header">{{block.display}}</span>
                  <span class="line-content">R {{block.rate | number: '1.2-4'}}</span>
                </div>
              </div>
              <div style="padding: 12px 0;">
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>

          <ng-template #standardCharge>
            <div *ngIf="utilisationCharges.length > 0">
              <div *ngFor="let charge of utilisationCharges" fxLayout="column">
                <div fxLayout="column" *ngIf="charge.display && charge.display.includes('Standard')">
                  <span class="line-header">{{charge.display}}</span>
                  <span class="line-content">R {{charge.rates[0].rate  | number: '1.2-4'}}</span>
                  <div style="padding: 12px 0;">
                    <mat-divider></mat-divider>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </sym-content-card>
    </mat-tab>

  </mat-tab-group>
</div>


