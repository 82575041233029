import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../loading/loading.service';
import { UploadServiceService } from 'src/app/core/services/upload-service.service';
import { from, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { PropertiesService } from 'src/app/services/properties.service';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';

@Component({
  selector: 'sym-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  notifications: any;
  notificationAmount: any;
  userEmail: any;
  userRole: any;
  showUploadStatus = false;
  stopPolling = false;
  showLoader = false;
  closeUploadStatus = false;
  displayNotifications = false;
  gotoNotificationsMethod = () => {
    this.gotoNotifications()
  }
  constructor(
    private router: Router,
    public loadingservice: LoadingService,
    public userMessagesService: UserMessagesService,
    public loadingService: LoadingService,
    public uploadService: UploadServiceService,
    private propertyService: PropertiesService
  ) { }

  ngOnInit() {
    this.userEmail = JSON.parse(localStorage.getItem("email"));
    this.userRole = JSON.parse(localStorage.getItem("userRole"));
    this.uploadService.startUpload.subscribe((data: any) => {
      if (data === 'true') {
        this.getStatus();
        this.showUploadStatus = true;
        this.displayNotifications = false;
        this.showLoader = true;
        this.stopPolling = false;
        this.notifications = "IN_PROGRESS";
      }
    });

    this.propertyService.getNotifications.subscribe((data: any) => {
     if(data){
       this.getNotifications();
     }
    })
    this.getNotifications();
  }

  getNotifications() {
    this.displayNotifications = false;
    from(this.propertyService.getAllComments()).subscribe((res: any) => {
      if(res.data.getNotifications.periodReadingNotifications){
        this.notificationAmount = res.data.getNotifications.periodReadingNotifications.length;
      }
      if(res.data.getNotifications.propertyNotifications){
        this.notificationAmount = this.notificationAmount + res.data.getNotifications.propertyNotifications.length;
      }
      if(res.data.getNotifications.unitNotifications){
        this.notificationAmount = this.notificationAmount + res.data.getNotifications.unitNotifications.length;
      }
      if(res.data.getNotifications.meterNotifications){
        this.notificationAmount = this.notificationAmount + res.data.getNotifications.meterNotifications.length;
      }
      if(this.notificationAmount >=1) {
        this.displayNotifications = true;
      }
    }, (error) => {
    })
  }
  getStatus() {
    const fileName = JSON.parse(localStorage.getItem('fileName'));
    const fileType = JSON.parse(localStorage.getItem('uploadType'));
    interval(10000)
      .pipe(takeWhile(() => !this.stopPolling))
      .subscribe(() => {
        from(this.uploadService.getStatus(fileType)).subscribe((res: any) => {
          this.notifications = res.data.getUploadStatus.status;
          if (res.data.getUploadStatus.status === "COMPLETED") {
            location.reload();
            this.stopPolling = true;
            this.showLoader = false;
            this.closeUploadStatus = true;
            this.getNotifications();
          }else if(res.data.getUploadStatus.status === "ERROR") {
            this.stopPolling = true;
            this.showLoader = false;
            this.closeUploadStatus = true;
            message_types.error.displaySupport = true;
            message_types.error.messageBody = 'Failed to upload tenancy schedule';
            this.userMessagesService.openMessage(message_types.error);
          }
        }, (error) => {
          this.stopPolling = true;
          this.showLoader = false;
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to upload tenancy schedule';
          this.userMessagesService.openMessage(message_types.error);
        })
      });
    
  }

  hideUploadStatus() {
    this.showUploadStatus = false;
    this.showLoader = false;
    this.closeUploadStatus = false;
    this.getNotifications();
  }

  gotoNotifications() {
    this.notifications = '';
    this.router.navigateByUrl('/notifications');
  }
}
