import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { BaseModule } from 'src/app/core/base/base.module';
import { MaterialModule } from 'src/app/core/material/material.module';


@NgModule({
    declarations: [ConfirmDialogComponent],
    imports: [
        BaseModule,
        MaterialModule
    ],
    exports: [
        ConfirmDialogComponent
    ]
})
export class ConfirmDialogModule { }
