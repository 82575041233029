import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentDialogComponent } from './comment-dialog.component';
import { BaseModule } from 'src/app/core/base/base.module';
import { MaterialModule } from 'src/app/core/material/material.module';
import { CommentDialogService } from './comment-dialog.service';
import { CardDialogModule } from 'src/app/shared/components/card-dialog/card-dialog.module';



@NgModule({
    declarations: [CommentDialogComponent],
    imports: [
        MaterialModule,
        BaseModule,
        CardDialogModule
    ],
    exports: [CommentDialogComponent],
    providers: [CommentDialogService]
})
export class CommentDialogModule { }
