import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';
import { PropertiesService } from 'src/app/services/properties.service';
import { LoadingService } from '../loading/loading.service';
import * as fileSaver from "file-saver";
import { FileLookupService } from 'src/app/core/services/file-lookup.service';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';

@Component({
  selector: 'app-reading-download-dialog',
  templateUrl: './reading-download-dialog.component.html',
  styleUrls: ['./reading-download-dialog.component.scss']
})
export class ReadingDownloadDialogComponent implements OnInit {

  stopChargeDownload: boolean = false;
  downloadOption: any;
  downloadOptions: any[] =  [
    {
      id: 'Period Readings', value: 'reading'
    },
    {
      id: 'Charge Calculations', value: 'charge'
    }
  ];
  cid: any;
  readingPeriod: any;
  periodURL: any;
  propertyName: any;

  closeMethod = () => {
    this.dialogRef.close()
  }


  constructor(public dialogRef: MatDialogRef<ReadingDownloadDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private loadingservice: LoadingService, private propertyService: PropertiesService, private userMessagesService: UserMessagesService, private periodService: FileLookupService) { 
  }

  ngOnInit(): void {
    this.cid = this.data.cid;
    this.readingPeriod = this.data.readingPeriod;
    this.propertyName = this.data.propertyName;
    const docDownload = localStorage.getItem('documentDownload');

    if(docDownload) {
      this.stopChargeDownload = true;
    } else {
      this.stopChargeDownload = false;
    }
  }

  exportReading() {
    if(this.downloadOption === "reading") {
      this.loadingservice.openBlockingLoader(
        "Downloading Period Readings",
        "primary"
      );
      from(
        this.propertyService.getPresignedPeriodReadingURL(
          this.cid,
          this.readingPeriod
        )
      ).subscribe(
        (res: any) => {
          this.loadingservice.closeBlockingLoader();
          message_types.success.messageBody = 'The file has been generated successfully. Download will now start';
          message_types.success.displaySupport = false;
          this.userMessagesService.openMessage(message_types.success);
          this.periodURL = res.data.exportPeriodReadingData.presignedUrl;
          this.getDocumentFromS3("periodReading", this.propertyName + " - Period Reading - " + this.readingPeriod);
          this.closeDialog();
        },
        (error) => {
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to export reading';
          this.userMessagesService.openMessage(message_types.error);
          this.loadingservice.closeBlockingLoader();
        }
      );
    } else {
      this.loadingservice.openBlockingLoader(
        "Downloading Charge Calculations",
        "primary"
      );
      from(this.propertyService.createChargeCalculationDocument(this.cid, this.readingPeriod)).subscribe(
        (res: any) => {
          this.loadingservice.closeBlockingLoader();
          if(res.data.createChargeCalculation.isSuccessful) {
            message_types.in_progress.messageBody = 'The file is being generated and will download shortly thereafter';
            message_types.in_progress.displaySupport = false;
            message_types.in_progress.duration = 6000;
            localStorage.setItem('documentDownload', JSON.stringify(true));
            this.userMessagesService.openMessage(message_types.in_progress);
            this.closeDialog();
            this.checkChargeDoc();
          }
        },
        (error) => {
          message_types.error.displaySupport = true;
          message_types.error.messageBody = 'Failed to export reading';
          this.userMessagesService.openMessage(message_types.error);
          this.loadingservice.closeBlockingLoader();
        }
      );
    }
  }

  checkChargeDoc() {
    from(this.propertyService.createChargeCalculationURL(this.cid, this.readingPeriod)).subscribe((res: any) => {
      if(res.data.createChargeCalculationURL.isSuccessful && (res.data.createChargeCalculationURL.status && res.data.createChargeCalculationURL.status === 'COMPLETED')) {
        message_types.success.messageBody = 'The file has been generated successfully. Download will now start';
        message_types.success.displaySupport = false;
        this.userMessagesService.openMessage(message_types.success);
        localStorage.removeItem('documentDownload');
        this.stopChargeDownload = false;
        this.periodURL = res.data.createChargeCalculationURL.presignedUrl;
        this.getDocumentFromS3("chargeCalculation", this.propertyName + " - Charge Calculation - " + this.readingPeriod);
      } else if(res.data.createChargeCalculationURL.isSuccessful && (res.data.createChargeCalculationURL.status && res.data.createChargeCalculationURL.status === 'PROCESSING')) {
        setTimeout(() => {
          this.checkChargeDoc();
        }, 5000)
      } else if(!res.data.createChargeCalculationURL.isSuccessful || (res.data.createChargeCalculationURL.status && res.data.createChargeCalculationURL.status === 'ERROR')){
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'There was an error generating the file';
        this.userMessagesService.openMessage(message_types.error);
        localStorage.removeItem('documentDownload');
        this.stopChargeDownload = false;
      }
    }, (error) => {
      localStorage.removeItem('documentDownload');
      this.stopChargeDownload = false;
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'There was an error generating the file';
      this.userMessagesService.openMessage(message_types.error);
    });
  }

  getDocumentFromS3(itemName, fileName) {
    localStorage.setItem(itemName, JSON.stringify(true));
    this.periodService.getDocumentFromS3(this.periodURL).subscribe(
      (res: any) => {
        this.downloadExportReading(res, fileName);
      },
      (error) => {
        message_types.error.displaySupport = true;
        message_types.error.messageBody = 'Failed to export reading';
        this.userMessagesService.openMessage(message_types.error);
        this.loadingservice.closeBlockingLoader();
      }
    );
  }

  downloadExportReading(res, fileName) {
    // this.loadingservice.closeBlockingLoader();
    let blob: any = new Blob([res], { type: "text/json; charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    fileSaver.saveAs(blob, fileName + ".xlsx");
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
