import { Injectable } from "@angular/core";
import { Rate, TariffCharge, TariffWaterCharge } from "./tariff-charge.model";

@Injectable()
export class TariffEditService {

  updateChargeDetails = {
    'Basic Charge (Per Month)': 'basicCharge',
    'Service Charge (Per Day)': 'serviceCharge',
    'Administration Charge (Per Day)': 'adminCharge',
    'Network Demand Charge Per KVA': 'networkDemandCharge',
    'Excess Network Charge': 'excessNetworkCharge',
    'Network Access Charge Per KVA': 'networkAccessCharge',
    'Reactive Energy Charge': 'reactiveEnergyCharge',
    'Low Votlage Subsidy Charge': 'subsidyCharge',
    'Active Energy Charge': 'touCharges',
    'Energy Demand Charge (Per kWh)': 'demandEnergyCharge',
    'Transmission Network Charge': 'transNetworkCharge',
    'Ancillary Service Charge': 'ancillaryCharge',
    'Electrification & Rural Network Subsidy': 'elecRuralCharge',
    'Affordability Subsidy': 'affordabilityCharge',
    'Demand Management Levy': 'demandMangementLevy',
    'Capacity Amp Charge (R/Ampere)': 'capacityAmpCharge',
    'Capacity Charge (Per Month)': 'capacityCharge',
    'Standard Water Charge - Per kl': 'standardCharge',
    'Standard Sanitation Charge - Per kl': 'standardCharge',
    'Percentage of Water Consumption': 'waterConsCharge',
    'Network Demand Charge (Per kWh)': 'networkDemandCharge',
    'Network Capacity Charge (Per Day)': 'networkCapacityCharge',
    'Active Energy Charge (Per kWh)': 'activeCharge',
    'Distribution Network Demand Charge': 'distributionCharge',
    'Network SurCharge (Per kWh)': 'networkSurcharge'
  }

  tariffElecCharge: any = {
    fixedCharge: {
      basicCharge: [],
      serviceCharge: [],
      adminCharge: [],
      capacityCharge: [],
      capacityAmpCharge: [],
      networkCapacityCharge: []
    },
    demandCharge: {
      networkDemandCharge: [],
      networkAccessCharge: [],
      demandEnergyCharge: [],
      excessNetworkCharge: [],
      reactiveEnergyCharge: [],
      transNetworkCharge: [],
      subsidyCharge: []
    },
    energyCharge: {
      activeCharge: [],
      networkSurcharge: [],
      ancillaryCharge: [],
      elecRuralCharge: [],
      affordabilityCharge: [],
      networkDemandCharge: [],
      touCharges: {
        lowSeason: {},
        highSeason: {}
      },
      distributionCharge: {
        lowSeason: {},
        highSeason: {}
      }
    }
  };

  tariffWaterCharge: TariffWaterCharge = {
    blockCharges: [],
    basicCharge: 0,
    surchargePercentage: 0,
    variableCharge: 0,
    standardCharge: 0,
    demandMangementLevy: 0,
    level0: 0,
    level1: 0,
    level2: 0,
    blockType: 'Step',
    finalBlock: 0,
    waterConsCharge: 0,
    percentageQty: null
  };

  tariffChargeDetails  = {
    fixedCharge: {
      basicCharge: {
        display: 'Basic Charge (Per Month)',
        type: 'Fixed',
        units: 'Month'
      },
      serviceCharge: {
        display: 'Service Charge (Per Day)',
        type: 'Fixed',
        units: 'Day'
      },
      capacityCharge: {
        display: 'Capacity Charge (Per Month)',
        type: 'Fixed',
        units: 'Month'
      },
      adminCharge: {
        display: 'Administration Charge (Per Day)',
        type: 'Fixed',
        units: 'Day'
      },
      capacityAmpCharge: {
        display: 'Capacity Amp Charge (R/Ampere)',
        type: 'Fixed',
        units: 'amps'
      },
      networkCapacityCharge: {
        display: 'Network Capacity Charge (Per Day)',
        type: 'Fixed',
        units: 'Day'
      }
    },

    demandCharge: {
      networkDemandCharge: {
        display: 'Network Demand Charge Per KVA',
        type: 'Demand',
        units: 'kVA'
      },
      excessNetworkCharge: {
        display: 'Excess Network Charge',
        type: 'Demand',
        units: 'kVA'
      },
      networkAccessCharge: {
        display: 'Network Access Charge Per KVA',
        type: 'Demand',
        units: 'kVA'
      },
      transNetworkCharge: {
        display: 'Transmission Network Charge',
        type: 'Demand',
        units: 'kVA'
      },
      demandEnergyCharge: {
        display: 'Energy Demand Charge (Per kVA)',
        type: 'Demand',
        units: 'kVA'
      },
      reactiveEnergyCharge: {
        display: 'Reactive Energy Charge',
        type: 'Demand',
        units: 'kVArh'
      },
      subsidyCharge: {
        display: 'Low Votlage Subsidy Charge',
        type: 'Demand',
        units: 'kVA'
      },
    },

    energyCharge: {
      activeCharge: {
        display: 'Active Energy Charge (Per kWh)',
        type: 'Utilisation',
        units: 'kWh'
      },
      networkSurcharge: {
        display: 'Network SurCharge (Per kWh)',
        type: 'Utilisation',
        units: 'kWh'
      },
      ancillaryCharge: {
        display: 'Ancillary Service Charge',
        type: 'Utilisation',
        units: 'kWh'
      },
      elecRuralCharge: {
        display: 'Electrification & Rural Network Subsidy',
        type: 'Utilisation',
        units: 'kWh'
      },
      affordabilityCharge: {
        display: 'Affordability Subsidy',
        type: 'Utilisation',
        units: 'kWh'
      },
      networkDemandCharge: {
        display: 'Network Demand Charge (Per kWh)',
        type: 'Utilisation',
        units: 'kWh'
      }
    }
  }
    createNormalCharge(display: string, rate: Rate[], type: string, units: string, surcharge?: number, percentageQty?: number, minUnits?: number): TariffCharge {
      console.log(surcharge);
      let chargeObject: any = {
        type: type,
        units: units,
        rates: rate,
        display: display
      };

      if(minUnits && units.includes('kVA') && display.includes('Network Demand Charge')) {
        chargeObject.minUnits = minUnits;
      } else {
        chargeObject.minUnits = null;
      }

      if(surcharge && !display.includes('SurCharge')) {
        chargeObject.surcharge = surcharge;
      } else {
        chargeObject.surcharge = null
      }

      if(percentageQty) {
        chargeObject.percentageQty = percentageQty;
      }

      return chargeObject
    }

    setTariffChargeObjects() {
      this.tariffElecCharge = {
        minUnits: 0,
        surchargePercentage: 0,
        fixedCharge: {
          basicCharge: [],
          serviceCharge: [],
          adminCharge: [],
          capacityCharge: [],
          capacityAmpCharge: [],
          networkCapacityCharge: []
        },
        demandCharge: {
          networkDemandCharge: [],
          networkAccessCharge: [],
          excessNetworkCharge: [],
          reactiveEnergyCharge: [],
          subsidyCharge: [],
          demandEnergyCharge: [],
          transNetworkCharge: []
        },
        energyCharge: {
          activeCharge: [],
          networkSurcharge: [],
          ancillaryCharge: [],
          elecRuralCharge: [],
          affordabilityCharge: [],
          networkDemandCharge: [],
          touCharges: {
            lowSeason: {},
            highSeason: {}
          },
          distributionCharge: {
            lowSeason: {},
            highSeason: {}
          }
        }
      };

      this.tariffWaterCharge = {
        blockCharges: [],
        basicCharge: 0,
        surchargePercentage: 0,
        variableCharge: 0,
        standardCharge: 0,
        demandMangementLevy: 0,
        waterConsCharge: 0,
        level0: 0,
        level1: 0,
        level2: 0,
        blockType: 'Step',
        finalBlock: 0,
        percentageQty: null
      };
    }

    setupUpdateCharges(utilityType: string, tariffCharges: any[]) {      
      for(let charge of tariffCharges) {
        console.log(charge)
        if(utilityType === 'Electricity') {
          if(charge.surcharge) {
            this.tariffElecCharge.surchargePercentage = charge.surcharge;
          }
        } else {
          if(charge.surcharge) {
            this.tariffWaterCharge.surchargePercentage = charge.surcharge;
          }
        }

        if((this.updateChargeDetails[charge.display] || 
          charge.display.includes('Demand Management Levy')) && 
          charge.display !== 'Block Charges' && 
          charge.display !== 'Distribution Network Demand Charge' && charge.display !== 'Active Energy Charge') {
          let tariffName = ''

          if(charge.display.includes('Demand Management Levy')) {
            tariffName = this.updateChargeDetails['Demand Management Levy'];
          } else {
            tariffName = this.updateChargeDetails[charge.display];
            console.log(tariffName)
          }

          if(charge.percentageQty) {
            this.tariffWaterCharge.percentageQty = charge.percentageQty;
          }

          if(charge.minUnits) {
            this.tariffElecCharge.minUnits = charge.minUnits;
          }

          console.log(charge.type)

          for(let rate of charge.rates) {
            if(charge.type === 'Fixed') {
              console.log('Here at fixed')
              if(!charge.display.includes('Management Levy') && utilityType === 'Electricity') {
                this.tariffElecCharge.fixedCharge[tariffName].push(rate.rate);
              } else {
                this.tariffWaterCharge[tariffName] = rate.rate
                console.log(this.tariffWaterCharge)
              }
            } else if(charge.type === 'Demand') {
              this.tariffElecCharge.demandCharge[tariffName].push(rate.rate);
            } else if(charge.type === 'Utilisation') {
              if(utilityType === 'Electricity') {
                this.tariffElecCharge.energyCharge[tariffName].push(rate.rate);
              } else {
                this.tariffWaterCharge[tariffName] = rate.rate
                console.log(this.tariffWaterCharge)
              }
            }
          }
          
        } else if(charge.display === 'Active Energy Charge' || charge.display === 'Distribution Network Demand Charge') {
          let chargeName = charge.display === 'Active Energy Charge' ? 'touCharges' : 'distributionCharge'
          let lowSeason = charge.rates.find(r => r.season === 'Low');
          let highSeason = charge.rates.find(r => r.season === 'High');
          this.tariffElecCharge.energyCharge[chargeName].lowSeason = {
            'offPeak': lowSeason.tou.find(t => t.display === 'Off-Peak').rate,
            'standard': lowSeason.tou.find(t => t.display === 'Standard').rate,
            'peak': lowSeason.tou.find(t => t.display === 'Peak').rate
          }

          this.tariffElecCharge.energyCharge[chargeName].highSeason = {
            'offPeak': highSeason.tou.find(t => t.display === 'Off-Peak').rate,
            'standard': highSeason.tou.find(t => t.display === 'Standard').rate,
            'peak': highSeason.tou.find(t => t.display === 'Peak').rate
          }
        } else if(charge.display === 'Block Charges') {
          let blockCharges = charge.rates[0];

          for(let block of blockCharges.blocks) {
            this.tariffWaterCharge.blockCharges.push({
              blockStart: block.start,
              blockEnd: block.end,
              charge: block.rate
            })
          }

          this.tariffWaterCharge.finalBlock = this.tariffWaterCharge.blockCharges[this.tariffWaterCharge.blockCharges.length - 1].charge;
          this.tariffWaterCharge.blockCharges.pop();

          this.tariffWaterCharge.blockType = blockCharges.blockType;
        }
      }

      console.log(this.tariffWaterCharge)

      if(utilityType === 'Electricity') {
        return {...this.tariffElecCharge};
      } else {
        return {...this.tariffWaterCharge};
      }
    }

    getTariffCharge(utilityType: string) {
      if(utilityType === 'Electricity') {
        return this.tariffElecCharge;
      } else {
        return this.tariffWaterCharge;
      }
    }

    setupTariffCharges(utilityType: string, tariffChargeObject: any, seasons: any[], hasTou: boolean) {
        let tariffCharges = [];
    
        if(tariffChargeObject.surchargePercentage) {
          if(tariffChargeObject.surchargePercentage >= 1) {
            tariffChargeObject.surchargePercentage = tariffChargeObject.surchargePercentage / 100;
          }
        }

        if(utilityType === 'Electricity') {
          console.log(tariffChargeObject)
          for(const chargeType in tariffChargeObject) {
            if(tariffChargeObject[chargeType]) {
              for(const key in tariffChargeObject[chargeType]) {
                if(key != 'touCharges' && key !== 'distributionCharge') {
                  let chargeDetail = this.tariffChargeDetails[chargeType][key];
                  let rates = [];
                  let objectCharges = tariffChargeObject[chargeType][key].filter(t => t !== null);
                  console.log(objectCharges)
                  if(objectCharges.length > 0) {
                    if(seasons.length > 0) {
                      let rate = 0;

                      for(let i = 0; i < seasons.length; i++) {
               
                        if(objectCharges.length > 1) {
                          rate = objectCharges[i] ? objectCharges[i] : rate;
                          let val = {
                            rate:  objectCharges[i],
                            season: seasons[i].name
                          }
                          
                          rates[i] = val;
                         
                        } else if(objectCharges.length === 1) {
                          rates.push({
                            rate: objectCharges[0],
                            season: seasons[i].name
                          });
                        }
                      }
                      
                    } else {
                      rates = rates = [{
                        rate: objectCharges[0],
                        season: 'All'
                      }];
                    }
                    tariffCharges.push(this.createNormalCharge(chargeDetail.display, rates, chargeDetail.type, chargeDetail.units, tariffChargeObject.surchargePercentage, 0, tariffChargeObject.minUnits));
                  }
                }
              }
            }
          }

          if(tariffChargeObject.energyCharge.touCharges.highSeason.peak) {
            let charge = 
            {
              type: 'Utilisation',
              units: 'kWh',
              rates: [
                {
                  season: 'Low',
                  tou: [
                    {
                      rate: tariffChargeObject.energyCharge.touCharges.lowSeason.offPeak,
                      display: 'Off-Peak',
                    },
                    {
                      rate: tariffChargeObject.energyCharge.touCharges.lowSeason.standard,
                      display: 'Standard',
                    },
                    {
                      rate: tariffChargeObject.energyCharge.touCharges.lowSeason.peak,
                      display: 'Peak',
                    },
                  ],
                },
                {
                  season: 'High',
                  tou: [
                    {
                      rate: tariffChargeObject.energyCharge.touCharges.highSeason.offPeak,
                      display: 'Off-Peak',
                    },
                    {
                      rate: tariffChargeObject.energyCharge.touCharges.highSeason.peak,
                      display: 'Peak',
                    },
                    {
                      rate: tariffChargeObject.energyCharge.touCharges.highSeason.standard,
                      display: 'Standard',
                    },
                  ]
                },
              ],
              display: 'Active Energy Charge',
              surcharge: tariffChargeObject.surchargePercentage
            }
            
            tariffCharges.push(charge);            
          }

          if(tariffChargeObject.energyCharge.distributionCharge.lowSeason.standard) {
            let charge = 
            {
              type: 'Utilisation',
              units: 'kWh',
              rates: [
                {
                  season: 'Low',
                  tou: [
                    {
                      rate: tariffChargeObject.energyCharge.distributionCharge.lowSeason.offPeak,
                      display: 'Off-Peak',
                    },
                    {
                      rate: tariffChargeObject.energyCharge.distributionCharge.lowSeason.standard,
                      display: 'Standard',
                    },
                    {
                      rate: tariffChargeObject.energyCharge.distributionCharge.lowSeason.peak,
                      display: 'Peak',
                    },
                  ],
                },
                {
                  season: 'High',
                  tou: [
                    {
                      rate: tariffChargeObject.energyCharge.distributionCharge.highSeason.offPeak,
                      display: 'Off-Peak',
                    },
                    {
                      rate: tariffChargeObject.energyCharge.distributionCharge.highSeason.peak,
                      display: 'Peak',
                    },
                    {
                      rate: tariffChargeObject.energyCharge.distributionCharge.highSeason.standard,
                      display: 'Standard',
                    },
                  ]
                },
              ],
              display: 'Distribution Network Demand Charge',
              surcharge: tariffChargeObject.surchargePercentage
            };

            tariffCharges.push(charge);
          }

        } else {
          if(tariffChargeObject.percentageQty) {
            if(tariffChargeObject.percentageQty >= 1) {
              tariffChargeObject.percentageQty = tariffChargeObject.percentageQty / 100;
            }
          }
          
          if(tariffChargeObject.basicCharge) {
            tariffCharges.push(this.createNormalCharge('Basic Charge (Per Month)', [{
              rate: tariffChargeObject.basicCharge,
              season: 'All'
            }], 'Fixed', 'Month', tariffChargeObject.surchargePercentage, tariffChargeObject.percentageQty));
          }
    
          if(tariffChargeObject.waterConsCharge) {
            tariffCharges.push(this.createNormalCharge('Percentage of Water Consumption', [{
              rate: tariffChargeObject.waterConsCharge,
              season: 'All'
            }], 'Utilisation', 'kl', tariffChargeObject.surchargePercentage, tariffChargeObject.percentageQty));
          }

          if(tariffChargeObject.demandMangementLevy) {
            tariffCharges.push(this.createNormalCharge('Demand Management Levy (Per ' + tariffChargeObject.demandManagementUnit + ')', [{
              rate: tariffChargeObject.demandMangementLevy,
              season: 'All'
            }], 'Fixed', tariffChargeObject.demandManagementUnit, tariffChargeObject.surchargePercentage, tariffChargeObject.percentageQty));
          }
    
          if(tariffChargeObject.variableCharge === 0) {
            if(tariffChargeObject.blockCharges && tariffChargeObject.blockCharges.length != 0) {
              let blockCharges = [];
              let count = 1;
              for(let charge of tariffChargeObject.blockCharges) {
                blockCharges.push({
                  start: charge.blockStart,
                  end: charge.blockEnd ? charge.blockEnd : null,
                  display: `Block ${count}: ${charge.blockStart} - ${charge.blockEnd}`,
                  rate: charge.charge
                });
                count++;
              }

              blockCharges.push({
                start: blockCharges[blockCharges.length - 1].end + 1,
                end: 0,
                display: `Block ${count}: ${blockCharges[blockCharges.length - 1].end + 1} - ∞`,
                rate: tariffChargeObject.finalBlock 
              });

              let variableObject: any = {
                rates: [{
                  blocks: blockCharges,
                  blockType: tariffChargeObject.blockType,
                  season: 'All',
                }],
                type: 'Utilisation',
                units: 'kl',
                display: 'Block Charges'
              }

              if(tariffChargeObject.surchargePercentage) {
                variableObject.surcharge = tariffChargeObject.surchargePercentage;
              }
    
              if(tariffChargeObject.percentageQty) {
                variableObject.percentageQty = tariffChargeObject.percentageQty;
              }

              tariffCharges.push(variableObject)
            }
          } else {
            if(tariffChargeObject.standardCharge) {
              tariffCharges.push(this.createNormalCharge(`Standard ${utilityType} Charge - Per kl`, [{
                rate: tariffChargeObject.standardCharge,
                season: 'All'
              }], 'Utilisation', 'kl', tariffChargeObject.surchargePercentage, tariffChargeObject.percentageQty));
            }
          }
        }
    
      return tariffCharges;
  }
}
