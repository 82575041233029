<div class="page-layout blank" fxLayout="column">
    <mat-tab-group mat-align-tabs="start" #tabGroup animationDuration="200ms"  (selectedTabChange)="changeTab($event)">
      <mat-tab label="Generate Files">
        
        <sym-content-card>
          <div *ngIf="!selectedButton" fxLayoutAlign="start center" fxLayoutGap="15px" fxLayout="row" fxFlex="90">
            <button mat-button [ngStyle]="changeButtonStyle(!selectedButton)" (click)="changeButton(false)" style="padding: 0 16px;">Property QR Booklet</button>
            <button mat-button [ngStyle]="changeButtonStyle(selectedButton)" (click)="changeButton(true)" style="padding: 0 16px;">Meter Stickers</button>
            <mat-form-field appearance="outline" class="input-fields" fxFlex="40">
              <mat-label>File Name</mat-label>
              <input matInput placeholder="eg Bedford Booklet" [(ngModel)]="fileName">
            </mat-form-field>
  
            <!-- <button matTooltipPosition="above" [ngStyle]="{'opacity': (property && fileName ? '1' : '.5')}" [disabled]="!property || !fileName" matTooltip="Generate new qr booklet" type="submit" mat-raised-button style="background-color: #41535D;color: white;" (click)="generatePropertyBooklet(property)" fxFlex="20">
              Generate Booklet
            </button> -->
            <sym-primary-button [primaryBtn]="primaryBtn"></sym-primary-button>
          </div>
          <div *ngIf="selectedButton" fxLayout="column">
            <div fxLayoutAlign="start center" fxLayoutGap="15px" fxLayout="row" fxFlex="90">
              <button mat-button [ngStyle]="changeButtonStyle(!selectedButton)" (click)="changeButton(false)" style="padding: 0 16px;">Property QR Booklet</button>
              <button mat-button [ngStyle]="changeButtonStyle(selectedButton)" (click)="changeButton(true)" style="padding: 0 16px;">Meter Stickers</button>
    
              <mat-form-field appearance="outline" class="input-fields" fxFlex="40">
                <mat-label>File Type</mat-label>
                <mat-select [(ngModel)]="selectedFileType" placeholder="Property" fxFlex="100">
                  <mat-option *ngFor="let sticker of stickerTypes" [value]="sticker.val">
                    {{sticker.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <sym-primary-button [disabled]="checkDisabled()" [primaryBtn]="primaryBtn2"></sym-primary-button>
            </div>

          <div style="padding: 1em 0;">
            <mat-divider ></mat-divider>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field style="width: 30%;" appearance="outline" class="input-fields">
              <mat-label>Search</mat-label>
              <input matInput [(ngModel)]="searchText" (keyup)="applyFilter()">
              <button [ngStyle]="{'visibility': searchText ? '' : 'hidden'}" matSuffix mat-icon-button (click)="searchText = ''; applyFilter()"><mat-icon class="clear-icon">close</mat-icon></button>
            </mat-form-field>
          </div>

            <mat-table [dataSource]="meterSource" style="margin-top: 15px;">
              <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef fxFlex="20" style="color: grey;">
                  <mat-checkbox (change)="masterToggle($event)">
                  </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;">
                  <mat-checkbox [(ngModel)]="element.status" [checked]="element.status"></mat-checkbox>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef fxFlex="45" style="color: grey;">
                  Meter Number
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="45" style="color: black;">
                  {{element.meterNo}}
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="meterType">
                <mat-header-cell *matHeaderCellDef fxFlex="25" style="color: grey;">
                  Meter Type
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="25" style="color: black;">
                  <div fxLayout="column">
                    {{element.meterType}}
                  </div>
                </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="utilityType">
                <mat-header-cell *matHeaderCellDef fxFlex="20" style="color: grey;">
                  Utility Type
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="20" style="color: black;">
                  {{element.utilityType}}
                </mat-cell>
              </ng-container>
    
              <mat-header-row *matHeaderRowDef="meterColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: meterColumns"></mat-row>
            </mat-table>
    
            <mat-paginator #Meter="matPaginator" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
              showFirstLastButtons></mat-paginator>
          </div>
        </sym-content-card>
      </mat-tab>
      <mat-tab label="Generated Files" *ngIf="property">
        <sym-content-card [title]="property.name + ' Generated Files'" fxFlex="100" [titleButton]="titleButton">
            <div class="table-responsive" fxLayout="column" style="margin: 10px;" *ngIf="responseLoaded">
              <mat-progress-bar mode="query" *ngIf="showLoader"></mat-progress-bar>
              <mat-table [dataSource]="documentsSource">
                <ng-container matColumnDef="date">
                  <mat-header-cell *matHeaderCellDef fxFlex="30" style="color: grey;">
                    Date Created
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="30" style="color: black;">
                      {{element.createDate |date:'yyyy/MM/dd'}}
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="fileName">
                  <mat-header-cell *matHeaderCellDef fxFlex="32" style="color: grey;">
                    File Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="32" style="color: black;">
                    <div fxLayout="column">
                      {{element.filename}}
                    </div>
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="documentType">
                  <mat-header-cell *matHeaderCellDef fxFlex="32" style="color: grey;">
                    Document Type
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="32" style="color: black;">
                      {{element.documentType}}
                  </mat-cell>
                </ng-container>
  
                <ng-container fxLayoutAllign="end center" matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef fxFlex="5" style="color: grey;">Actions </mat-header-cell>
                  <mat-cell *matCellDef="let element;" fxFlex="5">
                    <button matTooltipPosition="left" matTooltip="Download Document" mat-icon-button
                      class="mobile-hidden mat-light-green-700-bg" (click)="downloadDocument(element)">
                      <mat-icon style="color: #41535D;">cloud_download</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="documentColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: documentColumns"></mat-row>
              </mat-table>
  
              <mat-paginator #Document="matPaginator" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
                showFirstLastButtons></mat-paginator>
            </div>
        </sym-content-card>
      </mat-tab>
    </mat-tab-group>
  </div>
  