import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import gql from 'graphql-tag'
import { API, Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  @Output() getNotifications = new EventEmitter<boolean>();

  result: any;
  meterReadingEndPoint = environment.api.meterReading;
  meterReadingAPI = {
    "aws_appsync_graphqlEndpoint" : this.meterReadingEndPoint
  }

  meterManagementEndPoint = environment.api.meterManagement;
  meterManagementAPI = {
    "aws_appsync_graphqlEndpoint" : this.meterManagementEndPoint
  }

  constructor() {
   }

  getAllProperties() {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($limit: Int = 50) {
        getPropertyList(limit: $limit) {
          properties {
            address {
              city
              country
              line1
              line2
              postcode
              suburb
            }
            code
            createdAt
            id
            meterCount
            name
            tenantCount
            comments {
              comment
              commentDate
            }
            monthlyStatus {
              unitsOutstanding
              unitsCaptured
            }
          }
        }
      }     
      `,
    })
  }

  getAllFilesForTenant() {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery {
        getAllFiles {
          fileList {
            fileName
            fileType
            uploadStatus
            uploadedAt
            uploadedBy
            id
          }
          errorMessage
          isSuccessful
        }
      }
      `
    })
  }

  approveTenantFileChanges(fileId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($fileId: String = "") {
        approveTenantFileChanges(fileId: $fileId) {
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        fileId: fileId
      }
    })
  }

  rejectTenantFileChanges(fileId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($fileId: String = "") {
        rejectTenantFileChanges(fileId: $fileId) {
          errorMessage
          isSuccessful
        }
      }
      `,
      variables: {
        fileId: fileId
      }
    })
  }

  getFileActionsForFile(fileId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($fileId: String = "") {
        getFileActionsForFile(fileId: $fileId) {
          errorMessage
          fileActionList {
            actionType
            fileActionChanges {
              fieldName
              newValue
              oldValue
            }
            fileId
            id
            name
            objectType
            updatedObject
          }
          isSuccessful
        }
      }
      `,
      variables: {
        fileId: fileId
      }
    })
  }

  getTenantByPropertyId(propertyId, limit) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      query MyQuery($limit: Int = 10, $propertyId: String = "") {
        getAllTenantsPerProperty(limit: $limit, propertyId: $propertyId) {
          isSuccessful
          nextToken
          propertyId
          unitsOnProperty {
            tenantsPerUnitPerPropertyList {
              tenantOnProperty {
                tradingName
                code
                id
                contactDetails {
                  contactTelephone
                  contactName
                  contactEmails
                }
                tenantOccupationList {
                  leaseEnd
                  leaseStart
                  leaseType
                }
              }
            }
            id
            propertyId
          }
        }
      }      
      `,
      variables: {
        propertyId: propertyId,
        limit: limit
      }
    })
  }

  getAllTenants(limit) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($limit: Int = 0) {
        getAllTenants(limit: $limit) {
          tenantList {
            contactDetails {
              contactEmails
              contactName
              contactTelephone
            }
            tenantOccupationList {
              isExpired
              leaseEnd
              leaseStart
              leaseType
              propertyId
            }
            tradingName
            propertyId
            id
            code
          }
          isSuccessful
          nextToken
        }
        getAllTenantsCount {
          count
        }
      }
      
      `,
      variables: {
        limit: limit
      }
    })
  }

  getNextTenant(token, limit) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($nextToken: String = "", $limit: Int = 0) {
        getAllTenants(limit: $limit, nextToken: $nextToken) {
          tenantList {
            contactDetails {
              contactEmails
              contactName
              contactTelephone
            }
            tenantOccupationList {
              isExpired
              leaseEnd
              leaseStart
              leaseType
              propertyId
            }
            tradingName
            propertyId
            id
            code
          }
          isSuccessful
          nextToken
        }
      }
      `,
      variables: {
        nextToken: token,
        limit: limit
      }
    })
  }

  getPropertiesPerUser() {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($limit: Int = 100) {
        getPropertyListPerUser(limit: $limit) {
          properties {
            property {
              id
              name
              utilitySupplierIds
            }
          }
        }
      }      
      `
    })
  }
  
  deletePropertyById(id) {}

  superUserAddProperty(property, supplierIds){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($city: String = "", $country: String = "", $line1: String = "", $line2: String = "", $postcode: String = "", $suburb: String = "", $code: String = "", $name: String = "", $utilitySupplierIds: [String] = "") {
        createProperty(property: {address: {city: $city, country: $country, line1: $line1, line2: $line2, postcode: $postcode, suburb: $suburb}, code: $code, name: $name, utilitySupplierIds: $utilitySupplierIds}) {
          isSuccessful
        }
      }
      `,
      variables: {
       city: property.address.city,
       country: 'South Africa',
       line1: property.address.line1,
       line2: property.address.line2,
       postcode: property.address.postcode,
       suburb: property.address.suburb,
       code: property.code,
       name: property.name,
       agentCode: property.agentCode,
       utilitySupplierIds: supplierIds
      },
    })
  }

  addProperty(property, supplierIds){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($city: String = "", $country: String = "", $line1: String = "", $line2: String = "", $postcode: String = "", $suburb: String = "", $code: String = "", $name: String = "", $utilitySupplierIds: [String] = "") {
        createProperty(property: {address: {city: $city, country: $country, line1: $line1, line2: $line2, postcode: $postcode, suburb: $suburb}, code: $code, name: $name, utilitySupplierIds: $utilitySupplierIds}) {
          isSuccessful
        }
      }      
      `,
      variables: {
       city: property.address.city,
       country: 'South Africa',
       line1: property.address.line1,
       line2: property.address.line2,
       postcode: property.address.postcode,
       suburb: property.address.suburb,
       code: property.code,
       name: property.name,
       utilitySupplierIds: supplierIds
      },
    })
  }

  updateProperty(property, supplierIds){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($id: String = "", $name: String = "", $city: String = "", $country: String = "", $line1: String = "", $line2: String = "", $postcode: String = "", $suburb: String = "", $utilitySupplierIds: [String] = "") {
        updateProperty(property: {id: $id, name: $name, address: {city: $city, country: $country, line1: $line1, line2: $line2, postcode: $postcode, suburb: $suburb}, utilitySupplierIds: $utilitySupplierIds}) {
          isSuccessful
        }
      }      
      `,
      variables: {
       city: property.address.city,
       country: 'South Africa',
       line1: property.address.line1,
       line2: property.address.line2,
       postcode: property.address.postcode,
       suburb: property.address.suburb,
       name: property.name,
       id: property.id,
       utilitySupplierIds: supplierIds
      },
    })
  }

  getMeterReadingsPerProperty(propertyId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "") {
        getMeterReadingsPerProperty(propertyId: $propertyId) {
          readingList {
            readingValueList {
              sequence
              value
            }
            status
            readingBy
            readingTS
            comment
            comments {
              comment
            }
            meter {
              commissionDate
              commissionReading
              id
              isManual
              meterNo
              name
              noOfPhotos
              utilityType
              comments {
                comment
              }
              isCommonArea
            }
          }
        }
      }
      
      `,
      variables: {
        propertyId: propertyId
      },
    })
  }

      
  getPeriodReadingsPerProperty(propertyId, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $readingPeriod: String = "") {
        getPeriodReadingsPerProperty(limit: 500, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          periodReadingList {
            calculatedChargeList {
              chargeType
              rate
              readingPeriod
              utilitySupplierCharge
              utilization
            }
            meterId
            tradingName
            utilityType
            prevReading {
              readingValueList {
                value
              }
            }
            reading {
              readingValueList {
                value
                sequence
              }
              readingBy
              readingTS
              readingType
            }
            propertyId
            unitId
            approvalStatus
            readingPeriod
            flagReason
            meterNo
          }
        }
      }      
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: period
      },
    })
  }

  approvePeriodReading(meterId, propertyId, period, unitId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "", $unitId: String = "") {
        approvePeriodReading(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod, unitId: $unitId) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period,
        unitId: unitId
      },
    })
  }

  approvePerioReadingPerMeter(meterId, propertyId, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      mutation ApprovingForMeterWithoutUnitId($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        approvePeriodReadingsPerMeter(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period
      }
    })
  }

  approveBulkPeriodReading(meterId, propertyId, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      mutation MyMutation($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        approveBulkPeriodReading(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period,
      }
    })
  }

  approveMultiplePeriodReadings(meterId, propertyId, period, unitIds) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "", $unitIds: [String] = "") {
        approvePeriodReadingList(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod, unitIds: $unitIds) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period,
        unitIds: unitIds
      },
    })
  }
  
  rejectMultiplePeriodReadings(meterId, propertyId, period, unitIds) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "", $unitIds: [String] = "") {
        rejectPeriodReadingList(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod, unitIds: $unitIds) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meterId,
        propertyId: propertyId,
        readingPeriod: period,
        unitIds: unitIds
      },
    })
  }

  rejectPeriodReading(meter) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($meterId: String = "", $propertyId: String = "", $readingPeriod: String = "", $unitId: String = "") {
        rejectPeriodReading(meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod, unitId: $unitId) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meter.meterId,
        propertyId: meter.propertyId,
        readingPeriod: meter.readingPeriod,
        unitId: meter.unitId
      },
    }) 
  }

  getPresignedChargeCalculationURL(propertyId, readingPeriod) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($propertyId: String = "", $readingPeriod: String = "") {
        exportChargeCalculationData(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          presignedUrl
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: readingPeriod
      },
    })
  }

  createChargeCalculationDocument(propertyId, readingPeriod) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      mutation MyMutation($propertyId: String = "", $readingPeriod: String = "") {
        createChargeCalculation(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: readingPeriod
      }
    })
  }

  createChargeCalculationURL(propertyId, readingPeriod) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      mutation MyMutation($propertyId: String = "", $readingPeriod: String = "") {
        createChargeCalculationURL(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          presignedUrl
          errorMessage
          status
        }
      } 
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: readingPeriod
      }
    })
  }
  

  getPresignedPeriodReadingURL(propertyId, readingPeriod) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($propertyId: String = "", $readingPeriod: String = "") {
        exportPeriodReadingData(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          presignedUrl
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: readingPeriod
      },
    })
  }

  deleteProperty(id) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($propertyId: String = "") {
        deleteProperty(propertyId: $propertyId)
      }
      `,
      variables: {
        propertyId: id,
      },
    })
  }
  uploadSchedule(){}

  approveAllPeriodReadings(propertyId, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($propertyId: String = "", $readingPeriod: String = "") {
        approvePeriodReadingsPerProperty(propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
        }
      }
      `,
      variables: {
        propertyId: propertyId,
        readingPeriod: period,
      },
    })
  }

  addPropertyComment(comment) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($message: String = "", $propertyId: String = "") {
        addPropertyNotification(message: $message, propertyId: $propertyId) {
          isSuccessful
        }
      }      
      `,
      variables: {
        propertyId: comment.propertyId,
        message: comment.comment,
      },
    })
  }

  getAllComments() {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery {
        getNotifications(getReadNotifications: false) {
          periodReadingNotifications {
            message
            meterNo
            notificationDate
            notificationId
            notificationType
            propertyName
            unitNo
            sentBy
            readBy
            readingPeriod
          }
          propertyNotifications {
            message
            notificationDate
            notificationId
            propertyName
            sentBy
            notificationType
            readBy
          }
          unitNotifications {
            message
            notificationDate
            notificationId
            propertyName
            sentBy
            unitNo
            notificationType
            readBy
          }
          meterNotifications {
            message
            meterNo
            notificationDate
            notificationId
            notificationType
            propertyName
            readBy
            sentBy
            unitNo
          }
        }
      }
      `,
      variables: {},
    })
  }

  getAllPreviousComments(){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery {
        getNotifications(getReadNotifications: true) {
          periodReadingNotifications {
            message
            meterNo
            notificationDate
            notificationId
            notificationType
            propertyName
            unitNo
            sentBy
            readBy
          }
          propertyNotifications {
            message
            notificationDate
            notificationId
            propertyName
            sentBy
            notificationType
            readBy
          }
          unitNotifications {
            message
            notificationDate
            notificationId
            propertyName
            sentBy
            unitNo
            notificationType
            readBy
          }
          meterNotifications {
            message
            meterNo
            notificationDate
            notificationId
            notificationType
            propertyName
            readBy
            sentBy
            unitNo
          }
        }
      }
       
      `,
      variables: {},
    })
  }

  MarkCommentAsRead(id) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($notificationIds: [String!] = "") {
        markNotificationsAsRead(notificationIds: $notificationIds) {
          isSuccessful
        }
      }   
      `,
      variables: {
        notificationIds: id
      },
    })
  }
  
  pingNotifications(data: any) {
    this.getNotifications.emit(data);
  }

  getPropertiesForPeriod() {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery {
        getPropertyList(limit: 100) {
          properties {
            id
            name
          }
        }
      }  
      `,
      variables: { },
    })
  }

  getPreviousPeriodReadingsInPeriod(propertyId, startDate, endDate, meterId, limit) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      query MyQuery($endDate: AWSDateTime!, $propertyId: String = "", $startDate: AWSDateTime!, $meterId: ID = "", $limit: Int = 10) {
        getMeterReadingsPerMeterPerPeriod(endDate: $endDate, propertyId: $propertyId, startDate: $startDate, meterId: $meterId, limit: $limit) {
          isSuccessful
          readingList {
            readingValueList {
              sequence
              imageKey
              value
              category
              name
            }
            statusReason
            createdBy
            created
            readingTS
            readingType
            meterReadingType
            status
          }
        }
      }      
      `,
      variables: {
        propertyId: propertyId,
        startDate: startDate,
        endDate: endDate,
        meterId: meterId,
        limit: limit
      }
    })
  }

  replacePeriodReading(meterId, readingTS, period) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      mutation MyMutation($meterId: String = "", $readingPeriod: String = "", $readingTS: AWSDateTime!) {
        replacePeriodReading(meterId: $meterId, readingPeriod: $readingPeriod, readingTS: $readingTS) {
          isSuccessful
        }
      }
      `,
      variables: {
        meterId: meterId,
        readingTS: readingTS,
        readingPeriod: period
      }
    })
  }

  getPeriodReadingHistoryList(meterId, propertyId, limit, readingPeriod) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: `
      query MyQuery($limit: Int = 5, $meterId: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        getPeriodReadingHistoryList(limit: $limit, meterId: $meterId, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          periodReadingHistoryList {
            approvalStatus
            email
            event
            readingPeriod
            timestamp
            utilityType
            reading {
              readingValueList {
                sequence
                value
                imageKey
                name
                category
              }
              meterReadingType
              readingTS
              readingBy
              createdBy
              created
              status
              statusReason
              readingType
              utilityType
              comment
            }
            tenantNames
          }
          isSuccessful
        }
      }                      
    `,
    variables: {
      meterId: meterId,
      propertyId: propertyId,
      limit: limit,
      readingPeriod: readingPeriod
    }
    })
  }

  getPeriodReadingForProperty(reading) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($endDate: AWSDateTime!, $propertyId: String = "", $startDate: AWSDateTime!) {
        getRecentPeriodReadingsPerProperty(propertyId: $propertyId, endDate: $endDate, startDate: $startDate) {
          periodReadingList {
            approvalStatus
            meterId
            tradingName
            unitNo
            utilityType
            prevReading {
              readingValueList {
                value
              }
            }
            reading {
              readingValueList {
                value
                sequence
              }
              readingBy
              readingTS
              comments {
                comment
                commentDate
              }
              meter {
                meterNo
                name
                noOfPhotos
              }
            }
            propertyId
            unitId
          }
        }
      }
      
      `,
      variables: { 
        endDate: reading.endDate,
        startDate: reading.startDate,
        propertyId: reading.propertyId
      },
    }) 
  }

  getPeriodReadingsForUnit(meterNo, readingPeriod, propertyId, limit) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($limit: Int = 0, $meterNo: String = "", $propertyId: String = "", $readingPeriod: String = "") {
        getPeriodReadingsPerMeter(limit: $limit, meterNo: $meterNo, propertyId: $propertyId, readingPeriod: $readingPeriod) {
          isSuccessful
          periodReadingList {
            meterId
            tradingName
            unitNo
            utilityType
            prevReading {
              readingValueList {
                value
              }
            }
            reading {
              readingValueList {
                value
                sequence
              }
              readingBy
              readingTS
              comments {
                comment
                commentDate
              }
            }
            propertyId
            unitId
            approvalStatus
          }
        }
      }
      
      `,
      variables: { 
        propertyId: propertyId,
        meterNo: meterNo,
        limit: limit,
        readingPeriod: readingPeriod
      },
    }) 
  }

  getBulkMeterListPerProperty(propertyId){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($limit: Int = 100, $propertyId: String = "") {
        getBulkMeterListPerProperty(limit: $limit, propertyId: $propertyId) {
          meterList {
            meter {
              isManual
              meterNo
              meterType
              name
              noOfPhotos
              utilityType
              commissionDate
              id
              status
              commissionReading
              commissionReading2
              commissionReading3
              commissionReading4
              tariff
              tariffId
              voltageClassId
            }
          }
        }
      }
      `,
      variables: { 
        propertyId: propertyId,
      },
    }) 
  }

  createBulkMeter(meter, propertyId, unitId) {
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($amps: String = "", $commissionDate: AWSDateTime, $commissionReading: String = "", $commissionReading2: String = "", $commissionReading3: String = "", $commissionReading4: String = "", $isManual: Boolean = false, $meterNo: String = "", $name: String = "", $noOfPhotos: Int = 10, $utilityType: UtilityType = ELEC_1P, $tariffId: String = "", $tariff1: String = "", $voltageClassId: String = "", $propertyId: String = "") {
        createBulkMeter(meter: {amps: $amps, commissionDate: $commissionDate, commissionReading: $commissionReading, commissionReading2: $commissionReading2, commissionReading3: $commissionReading3, commissionReading4: $commissionReading4, isManual: $isManual, meterNo: $meterNo, name: $name, noOfPhotos: $noOfPhotos, tariff: $tariff1, tariffId: $tariffId, utilityType: $utilityType, voltageClassId: $voltageClassId}, propertyId: $propertyId) {
          isSuccessful
        }
      }
      
      `,
      variables: { 
        name: meter.name,
        utilityType: meter.utilityType.name,
        propertyId: propertyId,
        unitId: unitId,
        isManual: meter.isManual,
        meterNo: meter.serialNumber,
        commissionDate: meter.commissionDate,
        commissionReading: meter.commissionReading,
        commissionReading2: meter.commissionReading2,
        commissionReading3: meter.commissionReading3,
        commissionReading4: meter.commissionReading4,
        noOfPhotos: meter.utilityType.image,
        tariff1: meter.tariff,
        tariffId: meter.tariffId,
        voltageClassId: meter.voltageId,
        amps: meter.amps
      },
    })
  }

  getBulkMeterReadingsPerProperty(propertyId){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "") {
        getBulkMeterReadingsPerProperty(propertyId: $propertyId) {
          bulkMeterList {
             readingList {
                    readingBy
                    readingTS
                    meter {
                      utilityType
                      id
                      name
                      comments {
                        comment
                      }
                      isCommonArea
                      isManual
                      meterNo
                      commissionDate
                      commissionReading
                      status
                      noOfPhotos
                    }
                    readingValueList {
                      sequence
                      value
                    }
                    comments {
                      comment
                    }
                    comment
                  }
          }
        }
      }
      `,
      variables: {
        propertyId: propertyId,
      },
    })  
  }

  getAllPropertiesForQr(){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($limit: Int = 100) {
        getPropertyList(limit: $limit) {
          properties {
            id
            name
          }
        }
      }
      `,
      variables: {},
    }) 
  }

  getDocumentsPerProperty(property){
    API.configure(this.meterManagementAPI)
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "") {
        getPropertyDocumentList(propertyId: $propertyId) {
          documentList {
            createDate
            documentType
            filename
            id
          }
        }
      }
      `,
      variables: {
        propertyId: property,
      },
    }) 
  }

  getMetersPerProperty(property){
    API.configure(this.meterReadingAPI);
    return API.graphql({
      query: gql`
      query MyQuery($propertyId: String = "", $limit: Int = 200) {
        getUnitMeterListPerProperty(limit: $limit, propertyId: $propertyId) {
          meterList {
            meter {
              id
              meterNo
              meterType
              utilityType
            }
          }
        }
        getBulkMeterListPerProperty(limit: 10, propertyId: $propertyId) {
          meterList {
            meter {
              id
              meterNo
              meterType
              utilityType
            }
          }
        }
      }      
      `,
      variables: {
        propertyId: property.id,
      },
    }) 
  }

  generatePropertyBooklet(fileName, id){
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($filename: String = "", $propertyId: String = "") {
        requestPropertyBooklet(filename: $filename, propertyId: $propertyId) {
          isSuccessful
        }
      }
      `,
      variables: {
        filename: fileName,
        propertyId: id
      },
    }) 
  }

  generateMeterBooklet(fileName,id,meters){
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($filename: String = "", $meterIds: [String] = "", $propertyId: String = "") {
        requestPropertyMeterListStickers(filename: $filename, meterIds: $meterIds, propertyId: $propertyId) {
          isSuccessful
        }
      }
      `,
      variables: {
        filename: fileName,
        propertyId: id,
        meterIds: meters
      },
    })   
  }

  generateExternalMeterBooklet(fileName,id,meters){
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      mutation MyMutation($filename: String = "", $meterIds: [String] = "", $propertyId: String = "") {
        requestPropertyMeterListStickersExternal(filename: $filename, meterIds: $meterIds, propertyId: $propertyId) {
          isSuccessful
        }
      }
      `,
      variables: {
        filename: fileName,
        propertyId: id,
        meterIds: meters
      },
    })   
  }

  getDocumentUrlPerProperty(documentId,id){
    API.configure(this.meterManagementAPI);
    return API.graphql({
      query: gql`
      query MyQuery($documentId: String = "", $propertyId: String = "") {
        getPropertyDocumentPresignedUrl(documentId: $documentId, propertyId: $propertyId) {
          presignedUrl
        }
      }
      `,
      variables: {
        documentId: documentId,
        propertyId: id
      },
    }) 
  }
}
