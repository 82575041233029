<sym-card-dialog [title]="(isUpdate ? 'Update ' : 'Overwrite ') + 'Period ' + (meter.readingList.length > 2 ? 'Readings' : 'Reading')" [closeMethod]="closeMethod">
    <div  fxLayout="column" fxLayoutAlign="space-between" style="margin-top: 15px;margin: 15px;">
        <div fxLayout="column">
          <form [formGroup]="readingDetails" fxLayout="column">
            <div fxLayout="row" fxLayoutGap="14px">
              <div fxLayout="column" fxFlex="{{uploadSize > 1 ? '50' : '100'}}">
                <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
                  <mat-label>{{checkUtilityTypeText(1)}}</mat-label>
                  <input matInput type="number" data-cy="meterName" formControlName="reading1" required>
                </mat-form-field>
                <div fxLayout="row">  
                  <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r1 || !filesToUpload.r1.name">
                    <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 1" (click)="addFile(1)"><mat-icon style="color: white;">note_add</mat-icon></button>
                  </div>
                </div>
                <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r1 && filesToUpload.r1.name">
                  <div fxFlex="45">
                    <div style="padding: 10px">
                      <label style="padding-right: 5px;">Image 1 Name</label>
                    </div>
                  </div>
                  <div fxFlex="45">
                    <div style="padding: 10px">
                      <label style="padding-right: 5px;">Image 1 Size</label>
                    </div>
                  </div>
                </div>
                <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r1 && filesToUpload.r1.name">
                  <div fxFlex="45">
                    <div fxLayout="row" style="padding: 10px">
                      <label style="font-weight: bold;" matTooltip="{{filesToUpload.r1.name}}">{{ filesToUpload.r1.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r1.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r1.name}}</label>
                    </div>
                  </div>
                  <div fxFlex="45">
                    <div fxLayout="row" style="padding: 10px">
                      <strong>{{ filesToUpload.r1.size/1024/1024 | number:'1.0-2' }} MB</strong>
                    </div>
                  </div>
                  <div fxFlex="10">
                    <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(1)">
                    <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                        delete
                    </mat-icon>
                 </button>
                  </div>
                </div>
              </div>

            <div fxLayout="column" fxFlex="50" *ngIf="meter.utilityType.num >= 2">
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
                <mat-label>{{checkUtilityTypeText(2)}}</mat-label>
                <input matInput type="number" data-cy="meterName" formControlName="reading2" required>
              </mat-form-field>
              <div fxLayout="row">  
                <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r2 || !filesToUpload.r2.name">
                  <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 2" (click)="addFile(2)"><mat-icon style="color: white;">note_add</mat-icon></button>
                </div>
              </div>
              <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r2 && filesToUpload.r2.name">
                <div fxFlex="45">
                  <div style="padding: 10px">
                    <label style="padding-right: 5px;">Image 2 Name</label>
                  </div>
                </div>
                <div fxFlex="45">
                  <div style="padding: 10px">
                    <label style="padding-right: 5px;">Image 2 Size</label>
                  </div>
                </div>
              </div>
              <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r2 && filesToUpload.r2.name">
                <div fxFlex="45">
                  <div fxLayout="row" style="padding: 10px">
                    <label style="font-weight: bold;" matTooltip="{{filesToUpload.r2.name}}">{{ filesToUpload.r2.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r2.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r2.name}}</label>
                  </div>
                </div>
                <div fxFlex="45">
                  <div fxLayout="row" style="padding: 10px">
                    <strong>{{ filesToUpload.r2.size/1024/1024 | number:'1.0-2' }} MB</strong>
                  </div>
                </div>
                <div fxFlex="10">
                  <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(2)">
                  <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                      delete
                  </mat-icon>
               </button>
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutGap="14px" *ngIf="meter.utilityType.num >= 3">
            <div fxLayout="column" fxFlex="{{uploadSize > 3 ? '50' : '100'}}">
              <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
                <mat-label>{{checkUtilityTypeText(3)}}</mat-label>
                <input matInput type="number" data-cy="meterName" formControlName="reading3" required>
              </mat-form-field>
              <div fxLayout="row">  
                <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r3 || !filesToUpload.r3.name">
                  <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 3" (click)="addFile(3)"><mat-icon style="color: white;">note_add</mat-icon></button>
                </div>
              </div>
              <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r3 && filesToUpload.r3.name">
                <div fxFlex="45">
                  <div style="padding: 10px">
                    <label style="padding-right: 5px;">Image 3 Name</label>
                  </div>
                </div>
                <div fxFlex="45">
                  <div style="padding: 10px">
                    <label style="padding-right: 5px;">Image 3 Size</label>
                  </div>
                </div>
              </div>
              <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r3 && filesToUpload.r3.name">
                <div fxFlex="45">
                  <div fxLayout="row" style="padding: 10px">
                    <label style="font-weight: bold;" matTooltip="{{filesToUpload.r3.name}}">{{ filesToUpload.r3.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r3.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r3.name}}</label>
                  </div>
                </div>
                <div fxFlex="45">
                  <div fxLayout="row" style="padding: 10px">
                    <strong>{{ filesToUpload.r3.size/1024/1024 | number:'1.0-2' }} MB</strong>
                  </div>
                </div>
                <div fxFlex="10">
                  <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(3)">
                  <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                      delete
                  </mat-icon>
               </button>
                </div>
              </div>
            </div>

          <div fxLayout="column" fxFlex="50" *ngIf="meter.utilityType.num >= 4">
            <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
              <mat-label>{{checkUtilityTypeText(4)}}</mat-label>
              <input matInput type="number" data-cy="meterName" formControlName="reading4" required>
            </mat-form-field>
            <div fxLayout="row">  
              <div fxLayout="row" style="padding: 10px" *ngIf="!filesToUpload.r4 || !filesToUpload.r4.name">
                <button mat-fab class="uploadButton" matTooltip="(Optional) Select files from computer for Reading 4" (click)="addFile(4)"><mat-icon style="color: white;">note_add</mat-icon></button>
              </div>
            </div>
            <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r4 && filesToUpload.r4.name">
              <div fxFlex="45">
                <div style="padding: 10px">
                  <label style="padding-right: 5px;">Image 4 Name</label>
                </div>
              </div>
              <div fxFlex="45">
                <div style="padding: 10px">
                  <label style="padding-right: 5px;">Image 4 Size</label>
                </div>
              </div>
            </div>
            <div fxFlex="100" fxLayout="row" *ngIf="filesToUpload.r4 && filesToUpload.r4.name">
              <div fxFlex="45">
                <div fxLayout="row" style="padding: 10px">
                  <label style="font-weight: bold;" matTooltip="{{filesToUpload.r4.name}}">{{ filesToUpload.r4.name.length > 23 - checkWindowSpacing() ? (filesToUpload.r4.name | slice:0:(23 - checkWindowSpacing())) + '...' : filesToUpload.r4.name}}</label>
                </div>
              </div>
              <div fxFlex="45">
                <div fxLayout="row" style="padding: 10px">
                  <strong>{{ filesToUpload.r4.size/1024/1024 | number:'1.0-2' }} MB</strong>
                </div>
              </div>
              <div fxFlex="10">
                <button matTooltipPosition="left" matTooltip="Delete File" mat-icon-button (click)="removeFile(4)">
                <mat-icon style="font-size:large;color: red;margin-right: -10px;">
                    delete
                </mat-icon>
             </button>
              </div>
            </div>
          </div>
        </div>
          </form>
            
          
            <mat-form-field appearance="outline" style="margin-top: 5px;" fxFlex="48">
              <mat-label>Comment</mat-label>
              <input matInput data-cy="meterName" [(ngModel)]="comment">
            </mat-form-field>

            <mat-divider style="margin: 34px 12px"></mat-divider>
            <div fxLayout="row" fxLayoutAlign="end center">
              <button *ngIf="!hasToUpload" mat-raised-button id="btnClass" (click)="updateReading()" [ngStyle]="{'opacity': (readingDetails.valid ? '1' : '.5')}" [disabled]="!readingDetails.valid">
                <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>{{(isUpdate ? 'Update ' : 'Overwrite ')}} Period {{meter.readingList.length > 2 ? 'Readings' : 'Reading'}}
            </button>
            <button *ngIf="hasToUpload" mat-raised-button [ngStyle]="{'opacity': (count === uploadSize ? '1' : '.5')}" id="btnClass" (click)="updateReading()" [disabled]="count != uploadSize">
              <mat-icon style="color: white;margin-right: 5px;">save</mat-icon>{{(isUpdate ? 'Update ' : 'Overwrite ')}} Period {{meter.readingList.length > 2 ? 'Readings' : 'Reading'}}
            </button>

  
            </div>          
        </div>
    </div>
</sym-card-dialog>
