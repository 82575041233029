import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMessagesComponent } from './user-messages.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    UserMessagesComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    FlexModule,
    MatDividerModule,
    MatButtonModule
  ],
  exports: [
    UserMessagesComponent
  ]
})
export class UserMessagesModule { }
