import { Component, ViewChild } from '@angular/core';
import { LayoutService } from './layout.service';
import { onMainContentChange, onSideNavChange } from './sidenav/animation';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { NavService } from './sidenav/nav-service';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PeriodReadingsComponent } from '../features/period-readings/period-readings.component';
import { PropertyManagementComponent } from '../features/property-management/property-management.component';
import { QualityControlComponent } from '../features/quality-control/quality-control.component';
import { HeaderComponent } from './header/header.component';
import { TenantListComponent } from '../features/tenant-list/tenant-list.component';
import { TariffDetailsComponent } from '../features/tariff-details/tariff-details.component';
import { AddTariffComponent } from '../shared/components/add-tariff/add-tariff.component';
import { MeterManagementComponent } from '../features/meter-management/meter-management.component';

/** This component determines the overall display */
@Component({
  selector: 'sa-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [onMainContentChange, onSideNavChange]
})
export class LayoutComponent {
  /**
   * The viewchild property for the HTML element containing the drawer component
   */
  @ViewChild('drawer') sideDrawer: MatDrawer;
  @ViewChild('saHeader') header: HeaderComponent
  screenType: string = 'Small';
  component: any;
  selectedProperty: any;
  date: any;

  /**
   * Boolean value checking state change for sidenav
   */
  public sideNavChange: boolean;

  /**
   * The constructor
   * @param ls The LayoutService managing event emitters etc.
   * @param router Router service for navigation
   */
  constructor(public ls: LayoutService, public router: Router, public navService: NavService, private location: Location, public breakpointObserver: BreakpointObserver) {
    this.ls.sideNavState$.subscribe(res => {
      this.sideNavChange = res;
    });

    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.Small, Breakpoints.XLarge, Breakpoints.Medium, Breakpoints.XSmall]).subscribe(result => {
      if(result.breakpoints[Breakpoints.Large]) {
        this.screenType = 'Large'
      } else if(result.breakpoints[Breakpoints.XLarge]) {
        this.screenType = 'XLarge'
      } else if(result.breakpoints[Breakpoints.Medium]) {
        this.screenType = 'Medium'
      } else if(result.breakpoints[Breakpoints.Small]) {
        this.screenType = 'Small'
      } else if(result.breakpoints[Breakpoints.XSmall]) {
        this.screenType = 'XSmall'
      }
    });
  }

  onOutletLoaded(component) {
    const propertySelected: any = JSON.parse(sessionStorage.getItem('propertySelection'));
    if(propertySelected) {
      this.selectedProperty = propertySelected.property;
      this.date = propertySelected.date;
    }
    this.component = component;
    
    setTimeout(() => {
      this.header.enableInputs();
      this.header.hideSelectorsToggle(true);
      if(this.component instanceof PropertyManagementComponent) {
        this.header.disableInputs('Dates');
        this.header.hideSelectorsToggle(false);
        if(this.selectedProperty) {
          this.component.useProperty(this.selectedProperty)
          this.component.fetchTariffs.subscribe(() => this.header.getTariffs(this.selectedProperty, false))
        }
        component.addNewProperty.subscribe(res => {
          this.header.addProperty(res);
        })
        component.chooseProperty.subscribe(res => {
          this.header.setProperty(res);
        })
      } else if(this.component instanceof PeriodReadingsComponent) {
        if(this.selectedProperty && this.date) {
          this.component.setProperty(this.selectedProperty, this.date);
        }
        this.header.hideSelectorsToggle(false);
        component.chooseProperty.subscribe(res => {
          if(res === 1) {
            this.header.hideSelectorsToggle(true);
          } else {
            this.header.hideSelectorsToggle(false);
            this.header.enableInputs();
            if(res.id) {
              this.header.setProperty(res);
            } else if(res.name === 'View All') {
              this.header.changeToPropertyList();
            }
          }
        })
      } else if(this.component instanceof QualityControlComponent) {
        this.header.hideSelectorsToggle(false);
        this.header.disableInputs('All');
      } else if(component instanceof TenantListComponent) {
        this.header.disableInputs('Dates');
        this.header.hideSelectorsToggle(false);
      } else if(!(this.component instanceof TariffDetailsComponent) && !(this.component instanceof AddTariffComponent)) {
        localStorage.removeItem('updateTariff');
      }
    }, 10)
  }

  setProperty(value) {
    if(value && value != undefined && value != 'TenantListCall') {
      this.selectedProperty = value.property;
      this.date = value.date;
      if(this.component instanceof PropertyManagementComponent) {
        this.component.useProperty(value.property)
      } else if(this.component instanceof PeriodReadingsComponent) {
        this.component.setProperty(value.property, value.date)
      } else if(this.component instanceof TenantListComponent) {
        this.component.setProperty(value);
      }    
    } else {
      // const propertySelected = sessionStorage.getItem('propertySelection');
      if(this.component instanceof PeriodReadingsComponent || this.component instanceof PropertyManagementComponent) {
        this.component.ngOnInit();
      } else if(value === 'TenantListCall' && this.component instanceof TenantListComponent) {
        this.component.ngOnInit();
      }
    }
    
  }

  checkNavItem(routeText) {
    return this.navService.getNavigation().find(x => x.link.includes(this.location.path()))[routeText];
  }

  /**
   * Toggles Sidenav expanded state
   */
  sideNavButtonClicked() {
    this.ls.expanded = !this.ls.expanded;
    this.ls.sideNavState$.next(!this.sideNavChange);
  }

  /**
   * Method for determining loading state
   */
  // getLoading() {
  //   return this.loadingService.isBusy;
  // }
}
