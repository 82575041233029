import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import { from } from 'rxjs';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { PropertiesService } from 'src/app/services/properties.service';
import { ShopsService } from 'src/app/services/shops.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'sym-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class EventHistoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  propertyList: any;
  property: any;
  unitList: any;
  period;
  propertyId;
  @Input() meterId;
  @Input() utilityType;
  unit:any;
  responseLoaded = false;
  periodReadings: any[] = [];
  getPeriodReadingsMethod = () => {
    this.getPeriodReadings()
  }
  clearHistoryMethod = () => {
    this.clearHistory()
  }
  showMessage: boolean = false;
  periodReadingDataSource = new MatTableDataSource();
  meterColumns: string[] = [
    "date",
    "shopName",
    "tenant",
    "currentReading",
    "status",
    "comment",
  ];
  head = [['Date', 'Shop Name', 'Tenant', 'Meter','Previous Reading', 'Current Reading', 'Status']];

  constructor(private propertyService: PropertiesService, private loadingService: LoadingService, public userMessagesService: UserMessagesService, private shopsService: ShopsService,public datepipe: DatePipe, ) { }

  ngOnInit() {
    setTimeout(() => {
      const selectedProperty = JSON.parse(sessionStorage.getItem('propertySelection'))
      this.propertyId = selectedProperty.property.id;
      this.period = String(selectedProperty.date.year) + selectedProperty.date.month.value;
      this.getPeriodReadings()
    }, 50)
  }


  clearHistory() {
    this.property = '';
    this.unit = ''
    this.unitList = []
    this.periodReadings = [];
    this.showMessage = false;
  }

  getPeriodReadings() {
    this.responseLoaded = false;
    this.loadingService.openBlockingLoader("Retrieving period readings","primary");
    from(this.propertyService.getPeriodReadingHistoryList(this.meterId, this.propertyId, 100, this.period)).subscribe((res: any) => {
      this.periodReadings = res.data.getPeriodReadingHistoryList.periodReadingHistoryList;
      console.log(this.periodReadings )
      // for(let i = 0; i < this.periodReadings.length; i++) {
      //   this.periodReadings[i] = this.periodReadings[i].reading
      // }
      this.periodReadingDataSource = new MatTableDataSource(this.periodReadings);
      if(this.periodReadings.length == 0) {
        this.showMessage = true;
      } else { 
        this.showMessage = false;
      }
      this.periodReadingDataSource.paginator = this.paginator;
      this.responseLoaded = true;
      this.loadingService.closeBlockingLoader();
    },(error) => {
      this.loadingService.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to retrieve readings';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

downloadPdf(data){
  var prepare=[];
  var reading1 = 0;
  var reading2 = 0;
  this.periodReadings.forEach( e=>{
    var tempObj =[];
    var tempObject = [];
    var tempObject1 = [];
    e.reading.readingTS = this.datepipe.transform(e.reading.readingTS, 'yyyy-MM-dd');
    tempObj.push(e.reading.readingTS);
    tempObj.push(e.tradingName);
    tempObj.push( e.reading.readingBy);
    tempObj.push( e.utilityType);
    for(let reading of e.prevReading.readingValueList){
      reading1++;
      reading.value = 'Reading' + reading1 + ':' + reading.value
      tempObject.push(reading.value);
    }
    tempObj.push(tempObject);
    for(let reading of e.reading.readingValueList){
      tempObject1.push(reading.value);
    }
    tempObj.push(tempObject1);
    tempObj.push(e.isApproved);
    prepare.push(tempObj);
  });

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.setFontSize(11);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: this.head,
      body: prepare,
      theme: 'grid',
    })
    doc.save('audit_records.pdf');
  }

}
