import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DeleteDialogComponent } from './delete-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DeleteDialogService {
  dialogRef: any;
  constructor(
    public dialog: MatDialog
  ) { }

  openDeleteDialog(type, dataSet,name) {
    const dataType = {
      type: type,
      data: dataSet,
      name: name,
    }
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '40%',
      data: {type: dataType},
    });
    this.dialogRef.afterClosed().subscribe(result => {
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return true;
      }
    ));
  }
}