<sym-content-card *ngIf="selectedProperty" [title]="selectedProperty.name + ' - Meters'" [searchBar]="searchBar" [titleButton]="titleButton">
    <!-- <div fxLayoutAlign="start center" fxLayoutGap="15px" style="margin-bottom: 1em;">
      <div fxLayout="column">
        <mat-form-field style="margin-left: 10px;margin-top: 5px;font-size: small;width: 400px;padding-right: 15px;"
          appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput placeholder="Ex. Meter #" (keyup)="applyFilter($event)" #input>
        </mat-form-field>
      </div>
    </div> -->
    <table  mat-table [dataSource]="meterDatasource" style="margin-top: 15px;" 
    matSort (matSortChange)="announceSortChange($event)" matSortActive="status" matSortDirection="asc">
        <ng-container matColumnDef="meterNo">
          <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="17" style="color: grey;">
            Meter No 
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="17" style="color: black;cursor: pointer;" matTooltip="{{element.meterNo}}"
            matTooltipPosition="above">
            <div fxLayout="column">
              <div *ngIf="element.meterNo">
                {{element.meterNo.length > 15 ? (element.meterNo | slice: 0:15) + ' ...' : element.meterNo}}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="utilityType">
          <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="10" style="color: grey;">
            Utility Type
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="10" style="color: black;cursor: pointer;" matTooltip="{{element.utilityType}}"
            matTooltipPosition="above">
            <div fxLayout="column">
              <div *ngIf="element.utilityType">
                {{element.utilityType}}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="supplier">
          <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="12" style="color: grey;">
            Supplier
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="12" style="color: black;cursor: pointer;" matTooltip="{{element.supplierDetail && element.supplierDetail.supplierName ? element.supplierDetail.supplierName : ''}}"
            matTooltipPosition="above">
            <div fxLayout="column">
              <div *ngIf="element.supplierDetail && element.supplierDetail.supplierName">
                {{ element.supplierDetail.supplierName.length > 11 ? (element.supplierDetail.supplierName | slice: 0:11) + "..." : element.supplierDetail.supplierName}}
              </div>
              <div *ngIf="!element.supplierDetail || (element.supplierDetail && !element.supplierDetail.supplierName)">
                -
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tariff">
          <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="16" style="color: grey;">
            Tariff
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="16" style="color: black;cursor: pointer;" matTooltip="{{element.supplierDetail && element.supplierDetail.tariffName ? element.supplierDetail.tariffName : ''}}"
            matTooltipPosition="above">
            <div fxLayout="column">
              <div *ngIf="element.supplierDetail && element.supplierDetail.tariffName">
                {{ element.supplierDetail.tariffName.length === 1 && element.supplierDetail.tariffName[0].length > 17 ? (element.supplierDetail.tariffName | tariffDisplay | slice:0:20) + '...' : (element.supplierDetail.tariffName | tariffDisplay)}}
              </div>
              <div *ngIf="!element.supplierDetail || (element.supplierDetail && !element.supplierDetail.tariffName)">
                -
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="commissionDate">
          <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="10" style="color: grey;">
            Dates
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="10" style="color: black;cursor: pointer;" matTooltip="{{element.commissionDate}}"
            matTooltipPosition="above">
            <div fxLayout="column">
              <div *ngIf="element.commissionDate">
                {{element.commissionDate | date:'yyyy-MM-dd'}}
              </div>
              <div *ngIf="element.decommissionDate" style="padding-top: 5px;">
                <span style="color: red;">{{element.decommissionDate | date:'yyyy-MM-dd'}}</span>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="meterType">
          <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="8" style="color: grey;">
            Meter Type
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="8" style="color: black;cursor: pointer;" matTooltip="{{element.meterType}}"
            matTooltipPosition="above">
            <div fxLayout="column">
              <div *ngIf="element.meterType">
                {{ element.meterType | titlecase}}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isManual">
          <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="8" style="color: grey;">
            Reading Type
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="8" style="color: black;cursor: pointer;" matTooltip="{{element.isManual ? 'Manual' : 'Smart'}}"
            matTooltipPosition="above">
            <div fxLayout="column">
              <div>
                {{element.isManual ? 'Manual' : 'Smart'}}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell mat-sort-header mat-sort-header *matHeaderCellDef fxFlex="12" style="color: grey;">
            Status
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="12" style="color: black;cursor: pointer;" matTooltip="{{element.status}}"
            matTooltipPosition="above">
            <div fxLayout="column">
              <div *ngIf="element.status">
                {{element.status}}
              </div>
              <div *ngIf="!element.status">
                -
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container fxLayoutAllign="end center" matColumnDef="actions">
          <mat-header-cell mat-sort-header *matHeaderCellDef fxFlex="5" style="color: grey;">Actions </mat-header-cell>
          <mat-cell *matCellDef="let element; index as i;" fxFlex="5">
            <button matTooltipPosition="left" matTooltip="View Menu" mat-icon-button 
            *ngIf="!element.status.includes('DECOMMISSIONED')"
              class="mobile-hidden mat-light-green-700-bg" (click)="setSelectedMeter(element, i)" [matMenuTriggerFor]="meterMenu">
              <mat-icon style="font-size: x-large;color: black;">more_vert</mat-icon>
            </button>

            <mat-menu #meterMenu="matMenu" xPosition="before" style="padding: 10px;">
            <button matTooltip="Update Meter" (click)="updateMeter()" mat-menu-item aria-label="Update Meter"
                    class="mobile-hidden mat-light-green-700-big" *ngIf="superUser">
                    <mat-icon style="color: black;font-size: x-large;">update</mat-icon>Update Meter
                  </button>

                  <div *ngIf="outsideTDate">
                    <mat-divider></mat-divider>
                    <button matTooltip="Decommission Meter" mat-menu-item aria-label="Decommission Meter" 
               
                    (click)="decommissionMeter(i)"
                    class="mobile-hidden mat-light-green-700-big">
                      <mat-icon style="color: black;font-size: x-large;">autorenew</mat-icon>Decommission Meter
                    </button>
                  </div>
                   
               <!-- <mat-divider></mat-divider>
               <button matTooltip="Capture New Reading" mat-menu-item aria-label="Capture New Reading" (click)="captureNewReading()"
               class="mobile-hidden mat-light-green-700-big">
               <mat-icon style="color: black;font-size: x-large;">add</mat-icon>Capture New Reading
             </button> -->
              <!-- <mat-divider></mat-divider>
              <button matTooltip="Migrate Meter to a different Property" mat-menu-item aria-label="Migrate Meter to a different Property" (click)="openMigrateMeterDialog()"
               class="mobile-hidden mat-light-green-700-big">
               <mat-icon style="color: black;font-size: x-large;">domain</mat-icon>Migrate Meter
             </button>
              <mat-divider></mat-divider> -->
          </mat-menu>
          </mat-cell>

         
        </ng-container>

        <mat-header-row *matHeaderRowDef="meterColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: meterColumns"></mat-row>
      </table>

      <!-- <mat-paginator  ></mat-paginator> -->
      <mat-paginator #Meters="matPaginator" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
</sym-content-card>
