<div class="header sticky-header" [ngStyle]="{'background-color': delete ? '#9E1414' : ''}" fxLayout="row" fxLayoutAlign="space-between center">
    <span style="font-size: 24px;">{{title}}</span>
    <div>
      <button mat-icon-button (click)="closeMethod()" matTooltip="Close dialog">
        <mat-icon style="color: white;">
          close
        </mat-icon>
      </button>
    </div>
  </div>
  <div style="padding: 20px 32px;">
    <ng-content></ng-content>
  </div>
  
  <div *ngIf="buttonProperties && buttonProperties.text" >
    <div>
      <mat-divider style="margin: 12px 32px 20px 32px;color: black;"></mat-divider>
    </div>

    <div style="display: flex;flex-direction: row;align-items: center;justify-content: end;padding-right: 32px;">
      <button mat-raised-button [disabled]="!isValid" [ngStyle]="{'opacity': (isValid ? '1' : '.5')}" style="margin-bottom: 10px; background-color: #41535D;color: white;"
      id="btnClass" (click)="buttonProperties.method()">
        {{buttonProperties.text}}
      </button>
    </div>
  </div>