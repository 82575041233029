import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewCommentDialogComponent } from './view-comment-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ViewCommentDialogService {

  constructor(public dialog: MatDialog
  ) { }

  openCommentDialog(e) {

    const dialogRef = this.dialog.open(ViewCommentDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      width: '800px',
      height: '500px',
      data: { commentList: e }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
