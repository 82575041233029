import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from 'src/app/core/base/base.module';
import { MaterialModule } from 'src/app/core/material/material.module';
import { ViewCommentDialogComponent } from './view-comment-dialog.component';
import { ViewCommentDialogService } from './view-comment-dialog.service';



@NgModule({
    declarations: [ViewCommentDialogComponent],
    imports: [
        MaterialModule,
        BaseModule
    ],
    exports: [ViewCommentDialogComponent],
    providers: [ViewCommentDialogService]
})
export class ViewCommentDialogModule { }
