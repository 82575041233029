import { Component, OnInit, Input, ViewChild, AfterViewInit, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { from } from 'rxjs';
import * as fileSaver from 'file-saver';
import { CaptureReadingService } from 'src/app/shared/components/capture-reading/capture-reading.service';
import { LoadingService } from 'src/app/components/loading/loading.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { ShopsService } from 'src/app/services/shops.service';
import { TariffsService } from 'src/app/services/tariffs.service';
import { DecommissionMeterNodeComponent } from './decommission-meter-node/decommission-meter-node.component';
import { FloorplanService } from 'src/app/services/floor-plan.service';
import { MigrateMeterComponent } from './migrate-meter/migrate-meter.component';
import { FileLookupService } from 'src/app/core/services/file-lookup.service';
import { AddMeterNodeDialogComponent } from 'src/app/shared/components/add-meter-node-dialog/add-meter-node-dialog.component';
import { UpdateMeterNodeDialogComponent } from 'src/app/shared/components/update-meter-node-dialog/update-meter-node-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { UserMessagesService } from 'src/app/shared/micro-components/user-messages/user-messages.service';
import { message_types } from 'src/app/shared/micro-components/user-messages/message_types';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'sym-meter-management',
  templateUrl: './meter-management.component.html',
  styleUrls: ['./meter-management.component.scss']
})
export class MeterManagementComponent implements OnInit, AfterViewInit {

  titleButton: any = {
    method: () => {
      this.openCreateNodeDialog()
    },
    icon: 'add'      
  }

  searchBar: any = {
    method: (event) => {
      this.setDataSource(event)
    }
  }

  @ViewChild("Meters", { static: false }) meterPaginator: MatPaginator;

  qrCodeResponse: any;

  meterList: any[] = [];
  unitList: any[] = [];
  bulkMeters: any[] = [];

  count: number = 0;
  hasError: boolean = false;
  meterDatasource = new MatTableDataSource();
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.meterDatasource.sort) {
        this.meterDatasource.sort = sort;
    }
}
  selectedMeter: any;
  supplierDetails: any[] = [];
  outsideTDate: boolean = false;

  tariffList: any[] = [];
  superUser: boolean = false;

  selectedProperty: any;
  meterColumns: string[] = [
    'meterNo',
    'meterType',
    'utilityType',
    'supplier',
    'tariff',
    'commissionDate',
    'isManual',
    'status',
    'actions'
  ];

  constructor(private _liveAnnouncer: LiveAnnouncer,private userMessagesService: UserMessagesService,private meterManagementService: MeterManagementService, public dialog: MatDialog, private tariffService: TariffsService, private loadingService: LoadingService, private captureReadingService: CaptureReadingService, private shopsService: ShopsService, private tenantService: FileLookupService, private floorPlanService: FloorplanService) { }

  ngOnInit(): void {
    const userRole = JSON.parse(localStorage.getItem('userRole'));
    if(userRole === "ADMIN" || userRole === "Admin" || userRole === "SUPER_USER"){this.superUser = true};
  }

  setDataSource(filterValue) {
    this.meterDatasource.filter = filterValue.trim().toLowerCase();
    
  }

  retrieveDetails(property) {

    from(this.shopsService.getTargetDate(property.id, JSON.parse(localStorage.getItem('currentPeriod')))).subscribe((res:any) => {
      let date = new Date();

      let tDate = res.data.getTargetDate.targetDate;
      let sDate = res.data.getTargetDate.targetDate;
      tDate.endDate = new Date(tDate.endDate);
      sDate.startDate = new Date(tDate.startDate);
console.log(date)
      this.outsideTDate = date >   sDate.startDate && date < tDate.endDate ;
      console.log(date > sDate.startDate )
      console.log(date < tDate.endDate )
      console.log(this.outsideTDate)
    })

    if(property != this.selectedProperty) {
      this.selectedProperty = property;
      this.loadingService.openBlockingLoader('Retrieving Meter Details', 'primary');
      this.count = 0;
      this.hasError = false;
      this.tariffList = JSON.parse(sessionStorage.getItem('tariffs'));
      this.meterList = [];
      this.unitList = [];
      this.supplierDetails = this.selectedProperty.supplierDetails;
      this.getMeterNodesForProperty(property);
      this.getUnitList(property)
    }
  }

  openCreateNodeDialog() {
    const dialogRef = this.dialog.open(AddMeterNodeDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '64%',
      width: '60%',
      data: {
        supplier: this.selectedProperty.supplierDetails,
        propertyId: this.selectedProperty.id,
        tariffList: this.tariffList,
        bulkMeters: this.bulkMeters,
        title: 'Add New '
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        setTimeout(() => {
          this.reloadTable();
        }, 500)
      }
    });
  }
  ngAfterViewInit() {
    console.log(this.meterDatasource.data.length)
      this.meterDatasource.sort = this.matSort;
    this.meterDatasource.paginator = this.meterPaginator;
    
    
  }
  reloadTable() {
    this.tariffList = JSON.parse(sessionStorage.getItem('tariffs'));
    this.count = 1;
    this.loadingService.openBlockingLoader('Retrieving Meter Details', 'primary');
    this.getMeterNodesForProperty(this.selectedProperty);
  }

  closeLoader(error?) {
    if(!this.hasError && this.count === 2) {
      this.loadingService.closeBlockingLoader();
    } else if(this.hasError) {
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to retrieve Meters';
      this.userMessagesService.openMessage(message_types.error);
      this.loadingService.closeBlockingLoader();
    }
  }

  getImageFromS3(url){
    localStorage.setItem("qr",JSON.stringify(true));
    this.tenantService.getDocumentFromS3(url).subscribe((res:any) => {
      this.qrCodeResponse = res;
      this.downloadDocument();
      this.loadingService.closeBlockingLoader();
    },(error) => {
      this.loadingService.closeBlockingLoader();
      message_types.error.displaySupport = true;
      message_types.error.messageBody = 'Failed to download QR Code';
      this.userMessagesService.openMessage(message_types.error);
    })
  }

  downloadDocument() {
    let blob:any = new Blob([this.qrCodeResponse], { type: 'text/json; charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    fileSaver.saveAs(blob, 'qrCode.pdf');
  }

  getUnitList(property) {
    from(this.floorPlanService.getUnitListPerProperty(property.id)).subscribe((res:any) => {
      this.unitList = res.data.getUnitListPerProperty.unitList;
      this.count++;
      this.closeLoader();
    }, (error) => {
      if(!this.hasError) {
        this.hasError = true;
        this.closeLoader(error)
      }
    })
  }
meterIndex = 100000000000
  setSelectedMeter(meter, index) {
    this.meterIndex = index
    this.selectedMeter = meter;
  }

  checkSupplierDetails(tariffIds) {
    let tariffDetails = {
      tariffName: [],
      supplierName: []
    };

    if(tariffIds) {
      for(let tariffs of this.tariffList) {
        let tariff = tariffs.tariffList.filter(t => {
          return tariffIds.includes(t.id);
        });
        if(tariff.length > 0) {
          tariffDetails.tariffName = tariffDetails.tariffName.concat(tariff.map(t => t.name));
          tariffDetails.supplierName.push(tariffs.supplierName);

          if(tariffIds.length === 1 || tariffDetails.tariffName.length === tariffIds.length) {
            break;
          }
        }
      }
    }

    return tariffDetails;
  }

  getMeterNodesForProperty(property) {
    from(this.meterManagementService.getMeterNodesWithLimitByProperty(property.id, 'METER', 500)).subscribe((res:any) => {
      this.selectedProperty = property;
      const meterList = res.data.getNodesPerProperty.utilityNodeList;
      this.meterList = [];
      for(let i = 0; i < meterList.length; i++) {
        let meter = meterList[i];
        meter.meterNode.id = meterList[i].id;
        meter.meterNode.supplierDetail = this.checkSupplierDetails(meter.meterNode.tariffIds);
        this.meterList.push(meter.meterNode)
      }

      this.bulkMeters = [];
      for(let meter of this.meterList) {
        if(meter.meterType === 'BULK') {
          this.bulkMeters.push(meter)
        }
      }

      this.meterDatasource = new MatTableDataSource(this.meterList);
      this.meterDatasource.paginator = this.meterPaginator;
      this.count++;
      this.closeLoader();
    }, (error) => {
      if(!this.hasError) {
        this.hasError = true;
        this.closeLoader(error)
      }
    })
  }

  updateMeter() {
    const dialogRef = this.dialog.open(UpdateMeterNodeDialogComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '62%',
      width: '60%',
      data: {
        supplier: this.selectedProperty.supplierDetails,
        propertyId: this.selectedProperty.id,
        tariffList: this.tariffList,
        meter: this.selectedMeter,
        title: 'Update '
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.reloadTable();
      }
    });
  }

  openMigrateMeterDialog() {
    const dialogRef = this.dialog.open(MigrateMeterComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        propertyId: this.selectedProperty.id,
        meter: this.selectedMeter,
      },
      disableClose: true
    });
  }
  announceSortChange(sortState: Sort) {
    console.log(sortState)
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  decommissionMeter() {
    console.log(this.selectedMeter)
    const newDialogRef = this.dialog.open(DecommissionMeterNodeComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {
        supplier: this.selectedProperty.supplierDetails,
        propertyId: this.selectedProperty.id,
        tariffList: this.tariffList,
        meterId: this.selectedMeter.id,
        title: 'Decommission ',
        addMeter: false,
        meter: this.selectedMeter
      },
      disableClose: true
    });

    newDialogRef.afterClosed().subscribe(res => {
      this.reloadTable();
      if(res) {
     
      }
    });
    // const dialogRef = this.dialog.open(DecomissionMeterDialog, {
    //   width: '400px'
    // });

    // dialogRef.afterClosed().subscribe((res:string) => {
    //   if(res !== 'close') {
    //     const newDialogRef = this.dialog.open(DecommissionMeterNodeComponent, {
    //       panelClass: 'custom-user-dialog-container',
    //       height: res == 'yes' ? '64%' : '60%',
    //       width: '60%',
    //       data: {
    //         supplier: this.selectedProperty.supplierDetails,
    //         propertyId: this.selectedProperty.id,
    //         tariffList: this.tariffList,
    //         meterId: this.selectedMeter.id,
    //         title: 'Decommission ',
    //         addMeter: res === 'yes',
    //         meter: this.selectedMeter
    //       },
    //       disableClose: true
    //     });
  
    //     newDialogRef.afterClosed().subscribe(res => {
    //       if(res) {
    //         this.reloadTable();
    //       }
    //     });
    //   }
    // })
  }

}

@Component({
  selector: 'decomission-meter-dialog',
  templateUrl: './decomission-meter-dialog.html',
})
export class DecomissionMeterDialog {
  constructor(public dialogRef: MatDialogRef<DecommissionMeterNodeComponent>) {}

  closeDialog(decomissionMeter: boolean) {
    this.dialogRef.close(decomissionMeter)
  }
}

