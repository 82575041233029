<div style="display: flex;flex-direction: column;">
    <span style="font-size: 17px;font-weight: 600;color: #B9AB1C;margin-bottom: 14px;">{{title}}:</span>

    <span style="font-size: 16px;font-weight: 600;color: black;margin-bottom: 10px;">High Demand Season</span>
    <div fxLayout="row" fxFlex="100">

      <mat-form-field appearance="outline" fxFlex="34" style="margin-right: 8px;">
        <mat-label>Standard</mat-label>
        <input matInput [(ngModel)]="touCharge.highSeason.standard" placeholder="Standard" type="number" />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="34" style="margin-right: 8px;">
        <mat-label>Off Peak</mat-label>
        <input matInput [(ngModel)]="touCharge.highSeason.offPeak" placeholder="Off Peak" type="number" />
      </mat-form-field>
    
      <mat-form-field appearance="outline" fxFlex="34" style="margin-right: 8px;">
        <mat-label>Peak</mat-label>
        <input matInput [(ngModel)]="touCharge.highSeason.peak" placeholder="Peak" type="number" />
      </mat-form-field>
        
    </div>
    
    <span style="font-size: 16px;font-weight: 600;color: black;margin-bottom: 10px;">Low Demand Season</span>
    

    <div fxLayout="row" fxFlex="100">
      <mat-form-field appearance="outline" fxFlex="34" style="margin-right: 8px;">
        <mat-label>Standard</mat-label>
        <input matInput [(ngModel)]="touCharge.lowSeason.standard" placeholder="Standard" type="number" />
      </mat-form-field>
  
      <mat-form-field appearance="outline" fxFlex="34" style="margin-right: 8px;">
        <mat-label>Off Peak</mat-label>
        <input matInput [(ngModel)]="touCharge.lowSeason.offPeak" placeholder="Off Peak" type="number" />
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="34" style="margin-right: 8px;">
        <mat-label>Peak</mat-label>
        <input matInput [(ngModel)]="touCharge.lowSeason.peak" placeholder="Peak" type="number" />
      </mat-form-field>
     
    </div>

    <div style="margin: 20px 0;">
        <mat-divider></mat-divider>
    </div>
</div>