declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { BaseModule } from './core/base/base.module';
import { MaterialModule } from './core/material/material.module';
import { ConfirmDialogModule } from './shared/components/confirm-dialog/confirm-dialog.module';
import { HttpInterceptorService } from './core/services/http-interceptor-service';
import { DeleteDialogModule } from './shared/components/delete-dialog/delete-dialog.module';
import { DatePipe } from '@angular/common';
import { CommentDialogModule } from './shared/components/comment-dialog/comment-dialog.module';
import { ViewCommentDialogModule } from './components/view-comment-dialog/view-comment-dialog.module';
import { DisplayImageDialogModule } from './shared/components/display-image-dialog/display-image-dialog.module';
import { ReadingDownloadDialogModule } from './components/reading-download-dialog/reading-download-dialog.module';
import { CaptureReadingModule } from './shared/components/capture-reading/capture-reading.module';
import { PageHeaderModule } from './shared/components/page-header/page-header.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatButtonToggleModule,
        FormsModule,
        BaseModule,
        MaterialModule,
        AppRoutingModule,
        LayoutModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ConfirmDialogModule,
        DeleteDialogModule,
        CommentDialogModule,
        ViewCommentDialogModule,
        DisplayImageDialogModule,
        ReadingDownloadDialogModule,
        CaptureReadingModule,
        PageHeaderModule,
    ],
    providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        }, DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
