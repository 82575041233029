<div class="page-layout blank" fxLayout="column">
    <sym-page-header [title]="'Manage Properties'" [requireProperties]="true" *ngIf="superUser && (!selectedProperty || (selectedProperty && selectedProperty.name === 'View All'))" (propertySelect)="useProperty($event)" [primaryBtn]="primaryBtn" [propertySelector]="propertyList" [menuBtn]="menuBtn"></sym-page-header>

    <sym-page-header [title]="'Manage Properties'" [requireProperties]="true" *ngIf="(selectedProperty && selectedProperty.name !== 'View All')" (propertySelect)="useProperty($event)" [propertySelector]="propertyList"></sym-page-header>
    <!-- <sym-page-header [title]="'Manage Properties'" [requireProperties]="true" (propertySelect)="useProperty($event)" [propertySelector]="propertyList"></sym-page-header> -->

    <sym-content-card *ngIf="!selectedProperty || (selectedProperty && selectedProperty.name === 'View All')">
        <mat-table [dataSource]="propertyDS">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef fxFlex="25" class="table-header" style="color: black;"> Property Name
              </mat-header-cell>
              <mat-cell matTooltip="{{element.name}}" matTooltipPosition="above" *matCellDef="let element" (click)="setChosenProperty(element)" fxFlex="25" style="color: black;cursor: pointer;">
                {{element.name.length > 22 ? (element.name | slice: 0:22) + ' ...': element.name}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="code">
              <mat-header-cell *matHeaderCellDef fxFlex="15" class="table-header" style="color: black;"> Property Code
              </mat-header-cell>
              <mat-cell matTooltip="{{element.code}}" matTooltipPosition="above" *matCellDef="let element" (click)="setChosenProperty(element)" fxFlex="15" style="color: black;cursor: pointer;">
                {{element.code.length > 15 ? (element.code | slice: 0:15) + ' ...': element.code}}
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="address">
              <mat-header-cell *matHeaderCellDef fxFlex="30" class="table-header" style="color: black;"> Address
              </mat-header-cell>
              <mat-cell matTooltip="{{element.address}}" matTooltipPosition="above" *matCellDef="let element" (click)="setChosenProperty(element)" fxFlex="30" style="color: black;cursor: pointer;">
                {{element.address.length > 35 ? (element.address | slice: 0:35) + ' ...': element.address}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="numberOfAreas">
              <mat-header-cell *matHeaderCellDef fxFlex="15" class="table-header" style="color: black;"> # Areas
              </mat-header-cell>
              <mat-cell matTooltip="{{element.numberOfAreas}}" matTooltipPosition="above" *matCellDef="let element" (click)="setChosenProperty(element)" fxFlex="15" style="color: black;cursor: pointer;">
                {{element.numberOfAreas}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="numberOfUnits">
              <mat-header-cell *matHeaderCellDef fxFlex="15" class="table-header" style="color: black;"> # Units
              </mat-header-cell>
              <mat-cell matTooltip="{{element.numberOfUnits}}" matTooltipPosition="above" *matCellDef="let element" (click)="setChosenProperty(element)" fxFlex="15" style="color: black;cursor: pointer;">
                {{element.numberOfUnits}}
              </mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="propertyColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: propertyColumns;"></mat-row>
          </mat-table>
    </sym-content-card>

    <mat-tab-group *ngIf="selectedProperty && selectedProperty.name != 'View All'" (selectedTabChange)="changeTab($event)" mat-align-tabs="start" [selectedIndex]="tabIndex">
        <mat-tab label="Property Structure">
            <sym-property-structure *ngIf="tabIndex === 0" [property]="selectedProperty" #propertyStructure></sym-property-structure>
        </mat-tab>
        <mat-tab label="Manage Meters">
            <sym-meter-management *ngIf="tabIndex === 1" #metersComp></sym-meter-management>
        </mat-tab>
        <mat-tab label="QR Codes">
            <sym-qr-booklet #qrBooklet *ngIf="tabIndex === 2" [property]="selectedProperty"></sym-qr-booklet>
        </mat-tab>
        <mat-tab label="Target Dates">
            <sym-target-dates #dateComp *ngIf="tabIndex === 3" [selectedProperty]="selectedProperty"></sym-target-dates>
        </mat-tab>
      </mat-tab-group>
</div>