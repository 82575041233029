<div style="overflow: hidden; height: 120% !important">
  <sym-page-header
    *ngIf="
      tabIndex === 0 &&
      superUser &&
      (!selectedProperty ||
        (selectedProperty && selectedProperty.name != 'View All'))
    "
    [title]="'Period Readings'"
    [requireProperties]="true"
    [menuBtn]="menuBtn"
    [showSearch]="true"
    (searchFunction)="checkSearch($event)"
    [showFilters]="true"
  ></sym-page-header>

  <sym-page-header
    *ngIf="
      tabIndex === 0 &&
      !superUser &&
      (!selectedProperty ||
        (selectedProperty && selectedProperty.name != 'View All'))
    "
    [title]="'Period Readings Manager'"
    [requireProperties]="true"
    [menuBtn]="menuManagerBtn"
    [showSearch]="true"
    (searchFunction)="checkSearch($event)"
    [showFilters]="true"
  ></sym-page-header>

  <sym-page-header
    *ngIf="
      tabIndex != 0 ||
      (selectedProperty && selectedProperty.name === 'View All')
    "
    [title]="'Period Readings'"
    [requireProperties]="true"
    [showSearch]="false"
    (searchFunction)="checkSearch($event)"
    [showFilters]="false"
  ></sym-page-header>

  <mat-tab-group
    mat-align-tabs="start"
    #tabGroup
    animationDuration="200ms"
    (selectedTabChange)="changeTab($event)"
    [selectedIndex]="fromTenantView"
  >
    <mat-tab label="Period Readings" (click)="addRentableUnitChange = false">
      <sym-content-card
        *ngIf="selectedProperty && selectedProperty.name === 'View All'"
      >
        <mat-table [dataSource]="propertyDS">
          <div matColumnDef="name">
            <mat-header-cell
              *matHeaderCellDef
              fxFlex="40"
              class="table-header"
              style="color: black"
            >
              Property Name
            </mat-header-cell>
            <mat-cell
              matTooltip="{{ element.name }}"
              matTooltipPosition="above"
              *matCellDef="let element"
              (click)="setChosenProperty(element)"
              fxFlex="40"
              [ngStyle]="{
                color:
                  currentDate < endDate &&
                  currentDate > fiveDaysStart &&
                  (element.meterCounts.notApproved !== 0 ||
                    element.meterCounts.notSubmitted !== 0)
                    ? 'red'
                    : 'black'
              }"
              style="cursor: pointer"
            >
              {{ element.name }}
            </mat-cell>
          </div>

          <div matColumnDef="numberOfUnits">
            <mat-header-cell
              *matHeaderCellDef
              fxFlex="9"
              class="table-header"
              style="color: black"
            >
              # Units
            </mat-header-cell>
            <mat-cell
              matTooltip="{{ element.numberOfUnits }}"
              matTooltipPosition="above"
              *matCellDef="let element"
              (click)="setChosenProperty(element)"
              fxFlex="9"
              [ngStyle]="{
                color:
                  currentDate < endDate &&
                  currentDate > fiveDaysStart &&
                  (element.meterCounts.notApproved !== 0 ||
                    element.meterCounts.notSubmitted !== 0)
                    ? 'red'
                    : 'black'
              }"
              style="cursor: pointer; padding-left: 1em"
            >
              {{ element.numberOfUnits }}
            </mat-cell>
          </div>

          <div matColumnDef="meters">
            <mat-header-cell
              *matHeaderCellDef
              fxFlex="9"
              class="table-header"
              style="color: black"
            >
              # Meters
            </mat-header-cell>
            <mat-cell
              matTooltipPosition="above"
              *matCellDef="let element"
              (click)="setChosenProperty(element)"
              fxFlex="9"
              [ngStyle]="{
                color:
                  currentDate < endDate &&
                  currentDate > fiveDaysStart &&
                  (element.meterCounts.notApproved !== 0 ||
                    element.meterCounts.notSubmitted !== 0)
                    ? 'red'
                    : 'black'
              }"
              style="cursor: pointer; padding-left: 1em"
            >
              <div
                *ngIf="element.meterCounts && element.meterCounts.totalMeters"
              >
                {{ element.meterCounts.totalMeters }}
              </div>
              <div
                *ngIf="
                  !element.meterCounts ||
                  (element.meterCounts && !element.meterCounts.totalMeters)
                "
              >
                0
              </div>
            </mat-cell>
          </div>

          <div matColumnDef="captured">
            <mat-header-cell
              *matHeaderCellDef
              fxFlex="10"
              class="table-header"
              style="color: black"
            >
              # Captured
            </mat-header-cell>
            <mat-cell
              matTooltipPosition="above"
              *matCellDef="let element"
              (click)="setChosenProperty(element)"
              fxFlex="10"
              [ngStyle]="{
                color:
                  currentDate < endDate &&
                  currentDate > fiveDaysStart &&
                  (element.meterCounts.notApproved !== 0 ||
                    element.meterCounts.notSubmitted !== 0)
                    ? 'red'
                    : 'black'
              }"
              style="cursor: pointer; padding-left: 2em"
            >
              <div
                *ngIf="
                  element.meterCounts && element.meterCounts.totalCapturedMeters
                "
              >
                {{ element.meterCounts.totalCapturedMeters }}
              </div>
              <div
                *ngIf="
                  !element.meterCounts ||
                  (element.meterCounts &&
                    !element.meterCounts.totalCapturedMeters)
                "
              >
                0
              </div>
            </mat-cell>
          </div>

          <div matColumnDef="notCaptured">
            <mat-header-cell
              *matHeaderCellDef
              fxFlex="10"
              class="table-header"
              style="color: black"
            >
              # Not Captured
            </mat-header-cell>
            <mat-cell
              matTooltipPosition="above"
              *matCellDef="let element"
              (click)="setChosenProperty(element)"
              fxFlex="10"
              [ngStyle]="{
                color:
                  currentDate < endDate &&
                  currentDate > fiveDaysStart &&
                  (element.meterCounts.notApproved !== 0 ||
                    element.meterCounts.notSubmitted !== 0)
                    ? 'red'
                    : 'black'
              }"
              style="cursor: pointer; padding-left: 2em"
            >
              <div
                *ngIf="
                  element.meterCounts &&
                  element.meterCounts.totalNotCapturedMeters
                "
              >
                {{ element.meterCounts.totalNotCapturedMeters }}
              </div>
              <div
                *ngIf="
                  !element.meterCounts ||
                  (element.meterCounts &&
                    !element.meterCounts.totalNotCapturedMeters)
                "
              >
                0
              </div>
            </mat-cell>
          </div>

          <div matColumnDef="notSubmitted">
            <mat-header-cell
              *matHeaderCellDef
              fxFlex="10"
              class="table-header"
              style="color: black"
            >
              # Not Submitted
            </mat-header-cell>
            <mat-cell
              matTooltipPosition="above"
              *matCellDef="let element"
              (click)="setChosenProperty(element)"
              fxFlex="10"
              [ngStyle]="{
                color:
                  currentDate < endDate &&
                  currentDate > fiveDaysStart &&
                  (element.meterCounts.notApproved !== 0 ||
                    element.meterCounts.notSubmitted !== 0)
                    ? 'red'
                    : 'black'
              }"
              style="cursor: pointer; padding-left: 2em"
            >
              <div
                *ngIf="element.meterCounts && element.meterCounts.notSubmitted"
              >
                {{ element.meterCounts.notSubmitted }}
              </div>
              <div
                *ngIf="
                  !element.meterCounts ||
                  (element.meterCounts && !element.meterCounts.notSubmitted)
                "
              >
                0
              </div>
            </mat-cell>
          </div>

          <div matColumnDef="notApproved">
            <mat-header-cell
              *matHeaderCellDef
              fxFlex="11"
              class="table-header"
              style="color: black"
            >
              # Not Approved
            </mat-header-cell>
            <mat-cell
              matTooltipPosition="above"
              *matCellDef="let element"
              (click)="setChosenProperty(element)"
              fxFlex="11"
              [ngStyle]="{
                color:
                  currentDate < endDate &&
                  currentDate > fiveDaysStart &&
                  (element.meterCounts.notApproved !== 0 ||
                    element.meterCounts.notSubmitted !== 0)
                    ? 'red'
                    : 'black'
              }"
              style="cursor: pointer; padding-left: 2em"
            >
              <div
                *ngIf="element.meterCounts && element.meterCounts.notApproved"
              >
                {{ element.meterCounts.notApproved }}
              </div>
              <div
                *ngIf="
                  !element.meterCounts ||
                  (element.meterCounts && !element.meterCounts.notApproved)
                "
              >
                0
              </div>
            </mat-cell>
          </div>

          <mat-header-row *matHeaderRowDef="propertyColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: propertyColumns"></mat-row>
        </mat-table>
      </sym-content-card>

      <sym-content-card
        [ngStyle]="{
          visibility:
            selectedProperty && selectedProperty.name != 'View All'
              ? 'visible'
              : 'hidden'
        }"
      >
        <div fxLayout="column">
          <div fxLayout="column">
            <mat-table
              [dataSource]="meterPeriodDS"
              #Sort="matSort"
              matSort
              style="margin-top: 15px"
            >
              <div matColumnDef="date">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="11"
                  style="color: grey"
                  mat-sort-header
                  sortActionDescription="Sort by date"
                >
                  Date
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="11"
                  style="color: black; cursor: pointer"
                >
                  <div fxLayout="column">
                    <div *ngIf="element.readingTS">
                      {{ element.readingTS | date : "yyyy-MM-dd" }}
                    </div>
                    <div *ngIf="!element.readingTS">-</div>
                  </div>
                </mat-cell>
              </div>

              <div matColumnDef="meterNo">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="14"
                  style="color: grey"
                  mat-sort-header
                  sortActionDescription="Sort by name"
                >
                  Meter No
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="14"
                  style="color: black; cursor: pointer"
                  matTooltip="{{ element.meterNo }}"
                  matTooltipPosition="above"
                >
                  <div fxLayout="column">
                    <div *ngIf="element.meterNo">
                      {{
                        element.meterNo.length >= 19
                          ? (element.meterNo | slice : 0 : 9) + "..."
                          : element.meterNo
                      }}
                    </div>
                  </div>
                </mat-cell>
              </div>

              <div matColumnDef="readingType">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="9"
                  style="color: grey"
                  mat-sort-header
                  sortActionDescription="Sort by name"
                >
                  Reading Type
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="9"
                  style="color: black; cursor: pointer"
                  matTooltip="{{ element.readingCaptureType }}"
                  matTooltipPosition="above"
                >
                  <div fxLayout="column">
                    <div *ngIf="element.readingCaptureType">
                      {{ element.readingCaptureType }}
                    </div>
                  </div>
                </mat-cell>
              </div>

              <div matColumnDef="readings">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="20"
                  style="color: grey"
                >
                  Reading
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="20"
                  style="color: black; cursor: pointer"
                  [ngStyle]="{
                    height:
                      element.readingList && element.readingList.length === 3
                        ? '80px'
                        : element.readingList && element.readingList.length >= 4
                        ? '95px'
                        : ''
                  }"
                >
                  <div fxLayout="column">
                    <div
                      *ngIf="
                        element.readingList &&
                        element.readingList.length > 0 &&
                        element.utilityType !== 'ELEC_3P_TOU'
                      "
                    >
                      <div
                        *ngFor="
                          let reading of element.readingList;
                          let i = index
                        "
                      >
                        <div
                          *ngIf="
                            element.utilityType !== 'ELEC_1P_KVA' &&
                            element.utilityType !== 'ELEC_3P_KVA'
                          "
                        >
                          <div *ngIf="element.readingList.length === 1">
                            {{
                              reading.value != "NaN"
                                ? (reading.value | number : "1.0-4")
                                : ""
                            }}
                          </div>
                          <div *ngIf="element.readingList.length > 1">
                            P{{ i + 1 }}:
                            {{
                              reading.value != "NaN"
                                ? (reading.value | number : "1.0-4")
                                : ""
                            }}
                          </div>
                        </div>
                        <div
                          *ngIf="
                            element.utilityType === 'ELEC_1P_KVA' ||
                            element.utilityType === 'ELEC_3P_KVA'
                          "
                        >
                          <div
                            *ngIf="
                              element.readingList.length === 1 &&
                                (!reading.name ||
                                  (reading.name &&
                                    !reading.name.includes('KVA')));
                              else properKVA
                            "
                            fxLayout="column"
                          >
                            <div>
                              {{
                                element.utilityType === "ELEC_1P_KVA"
                                  ? "P"
                                  : "T"
                              }}:
                              {{
                                reading.value != "NaN"
                                  ? (reading.value | number : "1.0-4")
                                  : ""
                              }}
                            </div>
                            <div>KVA: -</div>
                          </div>
                          <ng-template #properKVA>
                            <div
                              *ngIf="
                                element.readingList.length === 2;
                                else moreKVA
                              "
                            >
                              <div *ngIf="element.readingList.length - 1 !== i">
                                {{
                                  element.utilityType === "ELEC_1P_KVA"
                                    ? "P" + i + 1
                                    : "T"
                                }}:
                                {{
                                  reading.value != "NaN"
                                    ? (reading.value | number : "1.0-4")
                                    : ""
                                }}
                              </div>
                              <div *ngIf="element.readingList.length - 1 === i">
                                KVA:
                                {{
                                  reading.value != "NaN"
                                    ? (reading.value | number : "1.0-4")
                                    : ""
                                }}
                              </div>
                            </div>
                            <ng-template #moreKVA>
                              <div *ngIf="reading.name !== 'Max KVA'">
                                {{
                                  reading.name === "Total"
                                    ? "T"
                                    : reading.name.includes("KVA") ||
                                      reading.name.includes("TOU")
                                    ? "KVA"
                                    : reading.name
                                }}:
                                {{
                                  reading.value != "NaN"
                                    ? (reading.value | number : "1.0-4")
                                    : ""
                                }}
                              </div>
                            </ng-template>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        element.readingList &&
                        element.readingList.length > 0 &&
                        element.utilityType === 'ELEC_3P_TOU'
                      "
                    >
                      <div
                        *ngFor="
                          let reading of element.readingList;
                          let i = index
                        "
                      >
                        <div *ngIf="i === 0">
                          S:
                          {{
                            reading.value != "NaN"
                              ? (reading.value | number : "1.0-4")
                              : ""
                          }}
                        </div>
                        <div *ngIf="i === 1">
                          OP:
                          {{
                            reading.value != "NaN"
                              ? (reading.value | number : "1.0-4")
                              : ""
                          }}
                        </div>
                        <div *ngIf="i === 2">
                          P:
                          {{
                            reading.value != "NaN"
                              ? (reading.value | number : "1.0-4")
                              : ""
                          }}
                        </div>
                        <div *ngIf="i === 3">
                          KVA:
                          {{
                            reading.value != "NaN"
                              ? (reading.value | number : "1.0-4")
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        !element.readingList ||
                        (element.readingList &&
                          element.readingList.length === 0)
                      "
                    >
                      -
                    </div>
                  </div>
                </mat-cell>
              </div>

              <div matColumnDef="tenant">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="11"
                  style="color: grey"
                >
                  Tenant
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="11"
                  style="color: black; cursor: pointer"
                  matTooltip="{{ element.tradingName }}"
                  matTooltipPosition="above"
                >
                  <div fxLayout="column">
                    <div *ngIf="element.tradingName">
                      {{
                        element.tradingName.length > 10
                          ? element.tradingName.slice(0, 10) + "..."
                          : element.tradingName
                      }}
                    </div>
                  </div>
                </mat-cell>
              </div>

              <div matColumnDef="charge">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="13"
                  style="color: grey"
                >
                  Total Charge
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="13"
                  style="color: black; cursor: pointer"
                >
                  <div fxLayout="column">
                    <div
                      *ngIf="
                        (element.totalCharge &&
                          !isCalculating &&
                          element.meterId != meterPeriodStatus.meterId) ||
                        (element.totalCharge &&
                          isCalculating &&
                          element.meterId != meterPeriodStatus.meterId)
                      "
                    >
                      R {{ element.totalCharge | number : "1.2-2" }}
                    </div>
                    <div
                      *ngIf="
                        isCalculating &&
                        element.meterId === meterPeriodStatus.meterId
                      "
                    >
                      Calculating...
                    </div>
                    <div
                      *ngIf="
                        (!element.totalCharge &&
                          !isCalculating &&
                          element.meterId != meterPeriodStatus.meterId) ||
                        (!element.totalCharge &&
                          isCalculating &&
                          element.meterId != meterPeriodStatus.meterId)
                      "
                    >
                      -
                    </div>
                  </div>
                </mat-cell>
              </div>

              <div matColumnDef="status">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="12"
                  style="color: grey"
                >
                  Status
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="12"
                  style="color: black; cursor: pointer"
                >
                  <div *ngIf="element.status.includes('DECOMMISSIONED')" fxLayout="column">
                    DECOMMISSIONED
                  </div>
                  <div *ngIf="!element.status.includes('DECOMMISSIONED')" fxLayout="column">
                    {{ element.status }}
                  </div>
                </mat-cell>
              </div>

              <div matColumnDef="flag">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="3"
                  style="color: grey"
                >
                  Flag
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="3"
                  style="color: black; cursor: pointer"
                  matTooltip="{{ setFlagWarningText(element) }}"
                  matTooltipPosition="above"
                >
                  <div fxLayout="column">
                    <div
                      *ngIf="
                        (element.flag && element.flag != '-') ||
                        (element.errorMessageList &&
                          element.errorMessageList.length > 0)
                      "
                    >
                      <mat-icon
                        style="color: red"
                        [ngStyle]="{
                          'font-size':
                            element.flag.includes('NEGATIVE') ||
                            element.flag.includes('ZERO')
                              ? 'large'
                              : 'x-large'
                        }"
                        >{{
                          element.flag.includes("OVER")
                            ? "warning"
                            : element.flag.includes("UNDER")
                            ? "trending_down"
                            : element.flag.includes("NEGATIVE")
                            ? "exposure_neg_1"
                            : element.flag.includes("ZERO")
                            ? "exposure_zero"
                            : "notification_important"
                        }}</mat-icon
                      >
                    </div>
                    <div
                      *ngIf="
                        (!element.flag ||
                          (element.flag && element.flag === '-')) &&
                        (!element.errorMessageList ||
                          (element.errorMessageList &&
                            element.errorMessageList.length === 0))
                      "
                    >
                      -
                    </div>
                  </div>
                </mat-cell>
              </div>
              <!-- Reduce the Size of the Table HERE *TODOD -->
              <div matColumnDef="target">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="6"
                  style="color: grey"
                >
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  (click)="goToQualityScreen(element)"
                  fxFlex="6"
                  style="color: black; cursor: pointer"
                  matTooltip="{{ element.utilityType }}"
                  matTooltipPosition="above"
                >
                  <div fxLayout="column">
                    <div style="font-size: small" fxLayout="row">
                      <div
                        *ngIf="
                          (targetDateList &&
                            targetDateList != null &&
                            currentDate >= targetDateList.targetDate &&
                            currentDate <= targetDateList.endDate &&
                            (element.status === 'NOT_CAPTURED' ||
                              element.status === 'FAULTY')) ||
                          element.status === 'REJECTED'
                        "
                      >
                        <mat-icon style="color: red; font-size: 25px">{{
                          element.utilityType.includes("ELEC")
                            ? "bolt"
                            : "water_drop"
                        }}</mat-icon>
                      </div>
                      <div
                        *ngIf="
                          element.status != 'NOT_CAPTURED' &&
                          element.status != 'FAULTY' &&
                          element.status != 'REJECTED'
                        "
                      >
                        <mat-icon style="color: green; font-size: 25px">{{
                          element.utilityType.includes("ELEC")
                            ? "bolt"
                            : "water_drop"
                        }}</mat-icon>
                      </div>
                      <div
                        *ngIf="
                          targetDateList &&
                          targetDateList != null &&
                          currentDate >= targetDateList.startDate &&
                          currentDate < targetDateList.targetDate &&
                          (element.status === 'NOT_CAPTURED' ||
                            element.status === 'FAULTY')
                        "
                      >
                        <mat-icon style="color: orange; font-size: 25px">{{
                          element.utilityType.includes("ELEC")
                            ? "bolt"
                            : "water_drop"
                        }}</mat-icon>
                      </div>
                      <div
                        *ngIf="
                          targetDateList &&
                          targetDateList != null &&
                          (currentDate < targetDateList.startDate ||
                            currentDate > targetDateList.endDate) &&
                          (element.status === 'NOT_CAPTURED' ||
                            element.status === 'FAULTY')
                        "
                      >
                        <mat-icon
                          style="
                            color: white;
                            font-size: 25px;
                            text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000,
                              0 -1px #000;
                          "
                          >{{
                            element.utilityType.includes("ELEC")
                              ? "bolt"
                              : "water_drop"
                          }}</mat-icon
                        >
                      </div>
                    </div>
                  </div>
                </mat-cell>
              </div>

              <div fxLayoutAlign="end center" matColumnDef="actions">
                <mat-header-cell
                  *matHeaderCellDef
                  fxFlex="10"
                  style="color: grey; flex-direction: column;justify-content: center;"
                  >Actions
                </mat-header-cell>
                <mat-cell *matCellDef="let element; index as i" fxFlex="10"
                style="color: grey; " fxLayoutAlign="end">
                <div >
                  <button style="width: 20px !important;"
               *ngIf="element.readingList[0] && element.readingList[0].imageKey !== null"
                    mat-icon-button
                    [disabled]="
                      (!superUser &&
                        element.status != 'CAPTURED' &&
                          element.status != 'MODIFIED' &&
                          element.status != 'NOT_CAPTURED' &&
                          element.status != 'REJECTED') ||
                      (!superUser &&
                        element.utilityType === 'ELEC_3P_TOU' &&
                        element.readingCaptureType === 'ONLINE') ||
                      (element.utilityType === 'ELEC_3P_TOU' &&
                        element.readingCaptureType === 'ONLINE' &&
                        element.status != 'SUBMITTED') ||
                      element.status === 'OVERWRITTEN' ||
                      (isCalculating &&
                        element.meterId === meterPeriodStatus.meterId) ||
                      (outsideTDate && element.status === 'NOT_CAPTURED')
                    "
                    (click)="setPeriodData(element, i); getReadingImage()"
                  >
                    <mat-icon *ngIf="!element.status.includes('DECOMMISSIONED')"    matTooltip="View Image" style="font-size: x-large; color: green"
                      >image_search</mat-icon
                    >
                    
                  </button>
                  <button style="width: 20px !important;"
                  *ngIf="!element.readingList[0] || element.readingList[0].imageKey === null"
                       mat-icon-button 
                       >
                       <mat-icon *ngIf="!element.status.includes('DECOMMISSIONED')"  matTooltip="No Image"  style="font-size: x-large; color: red">image_search</mat-icon>
                      </button>

                      
                  <button style="width: 20px !important;"
                    mat-icon-button
                    *ngIf="!element.status.includes('DECOMMISSIONED')" 
                    class="mobile-hidden mat-light-green-700-bg"
                    [matMenuTriggerFor]="periodMenu"
                    [disabled]="
                      (!superUser &&
                        element.status != 'CAPTURED' &&
                          element.status != 'MODIFIED' &&
                          element.status != 'NOT_CAPTURED' &&
                          element.status != 'REJECTED') ||
                      (!superUser &&
                        element.utilityType === 'ELEC_3P_TOU' &&
                        element.readingCaptureType === 'ONLINE') ||
                      (element.utilityType === 'ELEC_3P_TOU' &&
                        element.readingCaptureType === 'ONLINE' &&
                        element.status != 'SUBMITTED') ||
                      element.status === 'OVERWRITTEN' ||
                      (isCalculating &&
                        element.meterId === meterPeriodStatus.meterId) ||
                      (outsideTDate && element.status === 'NOT_CAPTURED')
                    "
                    (click)="setPeriodData(element, i)"
                  >
                    <mat-icon style="font-size: x-large; color: black"
                      >more_vert</mat-icon
                    >
                  </button>
                  </div>
                </mat-cell>

                <mat-menu
                  #periodMenu="matMenu"
                  xPosition="before"
                  style="padding: 10px"
                >
                  <div class="menu">
                    <div
                      *ngIf="
                        !outsideTDate &&
                        selectedReading &&
                        selectedReading.status !== 'NOT_CAPTURED'
                      "
                    >
                      <div
                        *ngIf="
                          selectedReading &&
                          ((!superUser &&
                            (selectedReading.status === 'CAPTURED' ||
                              selectedReading.status === 'MODIFIED')) ||
                            superUser)
                        "
                      >
                        <button
                          mat-menu-item
                          class="mobile-hidden mat-light-green-700-bg"
                          (click)="approvePeriodReading(selectedReading)"
                        >
                          <mat-icon style="font-size: x-large; color: green"
                            >check</mat-icon
                          >{{ superUser ? "Approve " : "Submit " }} Period
                          Reading
                        </button>
                        <mat-divider></mat-divider>
                      </div>
                      <!-- rejectPeriodReading(selectedReading)" -->
                      <div
                        *ngIf="
                          selectedReading &&
                          selectedReading.status !== 'REJECTED' &&
                          selectedReading.status !== 'APPROVED' &&
                          superUser &&
                          (selectedReading.utilityType !== 'ELEC_3P_TOU' ||
                            (selectedReading.utilityType === 'ELEC_3P_TOU' &&
                              selectedReading.readingCaptureType !== 'ONLINE'))
                        "
                      >
                        <button 
                          mat-menu-item
                          class="mobile-hidden mat-light-green-700-bg"
                          (click)="openRejectPeriodReading(false)"
                        >
                          <mat-icon style="font-size: x-large; color: red"
                            >close</mat-icon
                          >Reject Period Reading
                        </button>
                        <mat-divider></mat-divider>
                      </div>

                      <div
                        *ngIf="
                          selectedReading &&
                          selectedReading.readingList &&
                          selectedReading.readingList.length > 0 &&
                          (selectedReading.status !== 'OVERWRITTEN' ||
                            (!superUser &&
                              (selectedReading.status === 'CAPTURED' ||
                                selectedReading.status === 'MODIFIED' ||
                                selectedReading.status === 'REJECTED'))) &&
                          (selectedReading.utilityType !== 'ELEC_3P_TOU' ||
                            (selectedReading.utilityType === 'ELEC_3P_TOU' &&
                              selectedReading.readingCaptureType !== 'ONLINE'))
                        "
                      >
                        <button *ngIf="!selectedReading.status.includes('DECOMMISSIONED')"
                          mat-menu-item
                          class="mobile-hidden mat-light-green-700-bg"
                          (click)="modifyReading()"
                        >
                          <mat-icon style="font-size: x-large; color: black"
                            >save</mat-icon
                          >{{
                            selectedReading.status === "APPROVED" && superUser
                              ? "Overwrite"
                              : "Update"
                          }}
                          Period Reading
                        </button>
                        <mat-divider></mat-divider>
                      </div>
                    </div>

                    <div
                      *ngIf="
                        !outsideTDate &&
                        selectedReading &&
                        selectedReading.status === 'NOT_CAPTURED' &&
                        (selectedReading.utilityType !== 'ELEC_3P_TOU' ||
                          (selectedReading.utilityType === 'ELEC_3P_TOU' &&
                            selectedReading.readingCaptureType !== 'ONLINE'))
                      "
                    >
                      <button
                        mat-menu-item
                        class="mobile-hidden mat-light-green-700-bg"
                        (click)="captureNewReading()"
                      >
                        <mat-icon style="font-size: x-large; color: black"
                          >save</mat-icon
                        >Capture Period Reading
                      </button>
                      <mat-divider></mat-divider>
                    </div>

                    <div
                      *ngIf="
                        selectedReading &&
                        selectedReading.status !== 'NOT_CAPTURED' &&
                        selectedReading.readingList &&
                          selectedReading.readingList.length > 0
                      "
                    >
                      <button
                        mat-menu-item
                     
                        (click)="getReadingImage()"
                      >
                        <mat-icon style="font-size: x-large; color: black"
                          >image_search</mat-icon
                        >View
                        {{
                          selectedReading &&
                          selectedReading.readingList &&
                          selectedReading.readingList.length > 1
                            ? " Images"
                            : " Image"
                        }}
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>

              <mat-header-row
                *matHeaderRowDef="meterColumns; sticky: true"
              ></mat-header-row>
              <mat-row *matRowDef="let row; columns: meterColumns"></mat-row>
            </mat-table>

            <mat-paginator
              #Captures="matPaginator"
              (page)="onPageChangeEvent($event)"
              [length]="tableLength"
              [pageSize]="pageSize"
              [pageSizeOptions]="[5, 10, 25, 100]"
            ></mat-paginator>
          </div>
        </div>
      </sym-content-card>
    </mat-tab>
    <mat-tab label="Analytics" *ngIf="superUser">
      <sym-dashboard *ngIf="tabIndex === 1"></sym-dashboard>
    </mat-tab>
  </mat-tab-group>
  <div>
    <sym-export-period-reading
      (closed)="showExport = false"
      *ngIf="showExport"
      title="Export"
      propertyId="{{ cid }}"
    >
    </sym-export-period-reading>
  </div>
</div>
