import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileLookupService } from 'src/app/core/services/file-lookup.service';
import { UserMessagesService } from '../../micro-components/user-messages/user-messages.service';

@Component({
  selector: 'app-display-image-dialog',
  templateUrl: './display-image-dialog.component.html',
  styleUrls: ['./display-image-dialog.component.scss']
})
export class DisplayImageDialogComponent implements OnInit {

  title = "";
  meter;
  currentPosition = 0;
  reading;
  width: any = 0;
  height: any = 0;
  meterReadings: any[] = [];
  thumbnail: any;
  thumbNails: any[] = [];
  readingList = [];
  imageView: boolean = false;
  readingSelected: any;
  utilityType: string;
  closeMethod = () => {
    this.closeDialog()
  }
  pictureLoaded = false;
  constructor(public tenantService: FileLookupService,
              private renderer: Renderer2,
              @Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<DisplayImageDialogComponent>
              ) { }

  ngOnInit() {
    this.imageView = false;
    this.thumbNails = this.data.meter;
    console.log(this.thumbNails)
    this.reading = this.data.reading;
    console.log(this.reading)
    this.meterReadings = this.reading.readingList;
    console.log(this.meterReadings)
    this.utilityType = this.reading.utilityType;

    let readingText: string = '';
    
    for(let i = 0; i < this.meterReadings.length; i++) {
      if(this.utilityType.includes('TOU')) {
        if(i === 0) {
          readingText = 'Standard';
        } else if(i === 1) {
          readingText = 'Off-Peak';
        } else if(i === 2) {
          readingText = 'Peak';
        } else {
          readingText = 'KVA';
        }
      } else if(this.utilityType === 'ELEC_1P_KVA' || this.utilityType === 'ELEC_3P_KVA') {
        if(i === 0) {
          readingText = 'Phase 1';
        } else {
          readingText = 'KVA';
        }
      } else if(this.utilityType === 'ELEC_3P_3') {
        readingText = 'Phase ' + (i+1);
      } else if(this.utilityType === 'ELEC_3P_1') {
        readingText = 'Total'
      } else {
        readingText = 'Reading'
      }

      this.readingList.push({
        index: i,
        reading: this.meterReadings[i].value,
        text: readingText
      })
    }

    
    this.pictureLoaded = true;

    this.thumbNails = this.thumbNails.filter(t => typeof t === 'object')
    
    for(let i = 0; i < this.thumbNails.length; i++) {
      if(this.thumbNails[i] && typeof this.thumbNails[i] === 'object' && !this.thumbnail) {
        this.thumbnail = this.thumbNails[i];
        this.readingSelected = this.readingList[i];
        break;
      }
    }

    console.log(this.thumbNails)
  }

  getDimensions() {
    if(this.imageView) {
      return {
        'width': '100%',
        'height': 'auto'
      }
    } else {
      return {
        'width': '350px',
        'height': '300px'
      }
    }
  }

  rotateCW() {
    this.currentPosition = this.currentPosition >= 270? 0 : this.currentPosition += 90;

    const image = document.getElementById('myimage');
    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.currentPosition}deg)`
    )
  }

  rotateCCW() {
    this.currentPosition = this.currentPosition >= 360? 0 : this.currentPosition -= 90;

    const image = document.getElementById('myimage');
    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.currentPosition}deg)`
    )
  }

  viewImage(event) {
    this.pictureLoaded = true;
    this.thumbnail = this.thumbNails[event.value.index];
  }
  
  closeDialog() {
    this.dialogRef.close();
  }

}
